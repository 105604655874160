import React from 'react';

export const AssessedValues = ({ assessedValues, setAssessedValues, handleAssessedValueChange, setPendingSaves, totalAssessedValue, setStateRevModalOpen}) => {

  const addCounty = () => {
    setAssessedValues((prevState) => [...prevState, { county: 'New County', values: {amount: 0, program: '000', project: '00000', source: '0', location: '0000'} }]);
  };

  const deleteCounty = (index) => {
    setAssessedValues((prevState) => prevState.filter((_, i) => i !== index));
  };

  const formatCurrency = (value) => 
    value.toLocaleString('en-US', { style: 'currency', 
                                    currency: 'USD', 
                                    minimumFractionDigits: 0, 
                                    maximumFractionDigits: 0 });
  
  const columnHeaders = ['County Name', 'Amount'];

  return (
    <div className="av-card">
      <h3>Assessed Value</h3>
      <div className="av-table-button">
        <div className="assessed-values-table">
          <table>
            <thead>
              <tr>
                <th></th>
                {assessedValues.map((_, index) => (
                  <th key={index}></th>
                ))}
              </tr>
            </thead>
            <tbody>
              {columnHeaders.map((header, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{header}</td>
                  {assessedValues.map((county, colIndex) => (
                    <td key={colIndex}>
                      <input
                        type="text"
                        placeholder={header}
                        value={rowIndex === 0 ? county.county : rowIndex === 1 ? formatCurrency(county.values[header.toLowerCase()]) : county.values[header.toLowerCase()] || ''}
                        onChange={(e) => handleAssessedValueChange(e, colIndex, rowIndex === 0 ? 'county' : header.toLowerCase())}
                      />
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td></td>
                {assessedValues.map((_, colIndex) => (
                  <td key={colIndex}>
                    <div className='av-county-actions-buttons'>
                      <button onClick={() => {
                        setStateRevModalOpen(`${assessedValues[colIndex].county}`);
                      }}>Options</button>
                      <button onClick={() => {
                        deleteCounty(colIndex);
                        setPendingSaves(prev => ({ ...prev, assessedValues: true }));
                      }}>Delete</button>
                    </div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>  
        </div>
        <button className="add-county-btn" onClick={addCounty}>+</button>
      </div>
      <div className="total-av">Total: {formatCurrency(totalAssessedValue)}</div>
    </div>
  );
};

