import React, { useState, useEffect, useRef } from 'react';
import { database } from '../../firebaseConfig';
import { ref, get, set, update } from "firebase/database";
import '../../styles/SalaryScheduleClassified.css';
import {updateStaff, determineClassifiedObjectCode} from '../Utils/UpdateStaff';
import {handleStepDownDollarBlur, handleStepDownDollarChange, createSalaryTable} from '../Utils/SalaryScheduleUtils';

const balanceTheme = {
    backgroundColor: '#e7f4fc',
    inputColor: '#ffffff',
    textColor: '#333333'
};

const initialStepDowns = Array(40).fill(125);

const SalarySchedule = ({ selectedDistrictInfo, fiscalYear, version }) => {

    const [careerLanes, setCareerLanes] = useState([{name: '',
                                                     step1Value: '',
                                                     defaultStepDownDollar: '',
                                                     defaultStepDownPercent: '',
                                                     stepDownDollars: initialStepDowns}]);
    const [yearsToDisplay, setYearsToDisplay] = useState(40);
    const [salaryTable, setSalaryTable] = useState([]);
    const [displayMode, setDisplayMode] = useState('Salary');
    const [localAddOn, setLocalAddOn] = useState(0);
    const [stateAide, setStateAide] = useState(100); // Percentage, max 100
    const [selectedSchedule, setSelectedSchedule] = useState("Classified");
    const [availableScheduleNames, setAvailableScheduleNames] = useState([]);
    const [availableSalarySchedules, setAvailableSalarySchedules] = useState({})
    const [editStepDownDollar, setEditStepDownDollar] = useState(false);
    const [editStepDownPercent, setEditStepDownPercent] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [benefitRates, setBenefitRates] = useState({});
    const [certifiedSalarySchedules, setCertifiedSalarySchedules] = useState({});
    const menuRef = useRef(null);

    const salarySchedulePath = `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;
    const staffPath = `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;
    const benefitsPath = `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;
    const dataPaths = {'staffPath': staffPath, 'benefitsPath': benefitsPath}

    useEffect(() => {
      get(ref(database, `${salarySchedulePath}/classified/`)).then((result) => {
        const schedules = result.val() || {};
        setAvailableSalarySchedules(schedules);
        const scheduleNames = Object.keys(schedules);
        setAvailableScheduleNames(scheduleNames);
    
        // Set selectedSchedule to the first available schedule, if available
        if (scheduleNames.length > 0) {
          setSelectedSchedule(scheduleNames[0]);
        }
      });

      const loadBenefitRates = async () => {
        const benefitRatesPromise = await(get(ref(database, `${dataPaths['benefitsPath']}`)))
        setBenefitRates(benefitRatesPromise.val())
      }
      
      loadBenefitRates();

      const loadCertifiedSalarySchedules = async () => {
        const certifiedSchedulesPromise = await(get(ref(database, `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}/certified`)))
        setCertifiedSalarySchedules(certifiedSchedulesPromise.val())
      }

      loadCertifiedSalarySchedules()
    }, [selectedDistrictInfo, fiscalYear, version])
    
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (menuRef.current && !menuRef.current.contains(event.target)) {
            console.log('event.target: ', event.target)
            setMenuOpen(false);
          }
        };
    
        if (menuOpen) {
          document.addEventListener("mousedown", handleClickOutside);
        }
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [menuOpen]);

    useEffect(() => {
      const loadSavedSchedule = (scheduleName) => {
        const savedData = availableSalarySchedules[scheduleName];
    
        if (savedData) {
          setCareerLanes(savedData.careerLanes);
          setLocalAddOn(savedData.localAddOn || 0);
          setStateAide(savedData.stateAide || 100);
          setYearsToDisplay(savedData.yearsToDisplay || 40);
        }
      };

      loadSavedSchedule(selectedSchedule);
      if(careerLanes[0].name!=""){
        createSalaryTable(setSalaryTable, 
                          careerLanes, 
                          yearsToDisplay,
                          displayMode,
                          localAddOn, 
                          false,
                          stateAide,
                          'classified');
      }
        
    }, [selectedDistrictInfo, fiscalYear, version, selectedSchedule, availableSalarySchedules]);

    useEffect(() => {
      if(careerLanes[0].name!=""){
        createSalaryTable(setSalaryTable, 
                          careerLanes, 
                          yearsToDisplay,
                          displayMode,
                          localAddOn, 
                          false,
                          stateAide,
                          'classified');
      }
    }, [careerLanes, yearsToDisplay, displayMode, localAddOn, stateAide]);
    
    const toggleEditStepDownDollar = () => {
      setEditStepDownDollar((prev) => !prev);
      setEditStepDownPercent(false);
    }

    const toggleEditStepDownPercent = () => {
      setEditStepDownPercent((prev) => !prev);
      setEditStepDownDollar(false);
    }

    const handleSelectedScheduleChange = (e) => {
        setSelectedSchedule(e.target.value)
    }


  const handleBaseValueChange = (lane_name, value, lane_index) => {
    const updatedLanes = [...careerLanes];
    updatedLanes[lane_index].step1Value = parseFloat(value) || 0;
    setCareerLanes(updatedLanes);
  };


  const handleStepDownPercentChange = (index, value) => {
    const updatedLanes = [...careerLanes];
    updatedLanes[index].defaultStepDownPercent = parseFloat(value) || 0;
    
    updatedLanes[index].stepDownPerc = Array(updatedLanes[index].stepDownDollars.length)
        .fill(parseFloat(value) || 0);

    setCareerLanes(updatedLanes);
  };

  async function updateClassifiedStaff (updatedSalarySchedules) {
            
    const staffPromise = await get(ref(database, `${staffPath}`));
    const accountCodeSchemaPromise = await get(ref(database, `accountCodeSchema`))
    const benefitRatesPromise = await(get(ref(database, `${benefitsPath}`)))
    const accountCodeSchema = accountCodeSchemaPromise.val()[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]
    const staffData = staffPromise.val();
    const benefitRates = benefitRatesPromise.val();

    const funcCodes = Object.keys(staffData).filter(item=>item.length===4); // only get 4 digit function codes
    
    let buildings = []; let people = [];
    let lineItems = {'expense':{}, 'revenue':{}};
    funcCodes.forEach(fc => {
      buildings = Object.keys(staffData[fc]);
      buildings.forEach(bldg => {
        if(staffData[fc][bldg]['classified']){
          staffData[fc][bldg]['classified']['totals']['salary'] = 0;
          staffData[fc][bldg]['classified']['totals']['totalCost'] = 0;
          staffData[fc][bldg]['classified']['totals']['retirement'] = 0;
          staffData[fc][bldg]['classified']['totals']['insuranceBenefits'] = 0;
          staffData[fc][bldg]['classified']['totals']['medicare'] = 0;
          staffData[fc][bldg]['classified']['totals']['fte'] = 0;
          staffData[fc][bldg]['classified']['totals']['fica'] = 0;

          people = Object.keys(staffData[fc][bldg]['classified']['staff'] || {});
          people.forEach(person_id => {
              
            const person = staffData[fc][bldg]['classified']['staff'][person_id];

            const salary_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: '10', 
                                                                          function: person.function, 
                                                                          object: person.object,
                                                                          location: person.location,
                                                                          program: person.program,
                                                                          source: person.source,
                                                                          project: person.project})

            const retirement_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: '10', 
                                                                              function: person.function, 
                                                                              object: determineClassifiedObjectCode("retirement", parseInt(fc)),
                                                                              location: person.location,
                                                                              program: person.program,
                                                                              source: person.source,
                                                                              project: person.project})
            
            const medicare_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: '10', 
                                                                            function: person.function, 
                                                                            object: determineClassifiedObjectCode("medicare", parseInt(fc)),
                                                                            location: person.location,
                                                                            program: person.program,
                                                                            source: person.source,
                                                                            project: person.project})

            const insurance_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: '10', 
                                                                             function: person.function, 
                                                                             object: determineClassifiedObjectCode("insurance", parseInt(fc)),
                                                                             location: person.location,
                                                                             program: person.program,
                                                                             source: person.source,
                                                                             project: person.project})
        
            const fica_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: '10', 
                                                                        function: person.function, 
                                                                        object: determineClassifiedObjectCode("fica", parseInt(fc)),
                                                                        location: person.location,
                                                                        program: person.program,
                                                                        source: person.source,
                                                                        project: person.project})

            if(!lineItems['expense'][salary_line_item]){
                lineItems['expense'][salary_line_item] = 0;
                lineItems['expense'][retirement_line_item] = 0;
                lineItems['expense'][medicare_line_item] = 0;
                lineItems['expense'][insurance_line_item] = 0;
                lineItems['expense'][fica_line_item] = 0;
            }
            
            
            // if the salary was manually entered, don't use the salary schedule
            let effectiveSalary; let hourlyRate;
            if(availableScheduleNames.includes(person.scheduleToUse) && (person.lane!='' && person.step!='' && person.lane<updatedSalarySchedules[person.scheduleToUse]['careerLanes'].length)){
              const lane_names = updatedSalarySchedules[person.scheduleToUse]['careerLanes'].map(item => item.name)
                hourlyRate = updatedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step-1]['hourlyRate'];
                effectiveSalary = hourlyRate * person.totalHours * (person.fte || 1); // Calculate effective salary
            } else {
                effectiveSalary = person.salary || person.totalContract
                hourlyRate = effectiveSalary / (person.totalHours || 1)
            }

            if(!effectiveSalary){
              console.log('person has null salary: ', person)
            }
            
            const salary = effectiveSalary * (person.fte || 1);

            let insuranceBenefits = 0
            Object.keys(person.benefits).forEach(benefit => insuranceBenefits += person.benefits[benefit] * 12)
            
            const medicare = salary * benefitRates.medicareRate;
            let retirement = 0; let fica = 0;
            if(person.employeeType==='classified'){
              retirement = (salary + insuranceBenefits) * benefitRates.peersRate;
              fica = salary * benefitRates.socialSecurityRate;
            } else{
              retirement = (salary + insuranceBenefits) * benefitRates.certifiedRetirement;
              fica = salary * benefitRates.socialSecurityRate;
            }
            const totalCost = salary + insuranceBenefits + medicare + retirement + fica;

            staffData[fc][bldg]['classified']['staff'][person_id]['salary'] =  effectiveSalary || 0;
            staffData[fc][bldg]['classified']['staff'][person_id]['hourlyRate'] =  hourlyRate || 0;
            staffData[fc][bldg]['classified']['staff'][person_id]['totalCost'] = totalCost;
            staffData[fc][bldg]['classified']['staff'][person_id]['retirement'] = retirement;
            staffData[fc][bldg]['classified']['staff'][person_id]['insuranceBenefits'] = insuranceBenefits;
            staffData[fc][bldg]['classified']['staff'][person_id]['medicare'] = medicare;
            staffData[fc][bldg]['classified']['staff'][person_id]['fica'] = fica;

            staffData[fc][bldg]['classified']['totals']['salary'] += effectiveSalary || 0;
            staffData[fc][bldg]['classified']['totals']['totalCost'] += totalCost;
            staffData[fc][bldg]['classified']['totals']['retirement'] += retirement;
            staffData[fc][bldg]['classified']['totals']['insuranceBenefits'] += insuranceBenefits;
            staffData[fc][bldg]['classified']['totals']['medicare'] += medicare;
            staffData[fc][bldg]['classified']['totals']['fte'] += (person.fte || 1);
            staffData[fc][bldg]['classified']['totals']['fica'] += fica;

            lineItems['expense'][salary_line_item] += effectiveSalary;
            lineItems['expense'][retirement_line_item] += retirement;
            lineItems['expense'][insurance_line_item] += insuranceBenefits;
            lineItems['expense'][medicare_line_item] += medicare;
            lineItems['expense'][fica_line_item] += fica;
          })
        }
      })
  })
  
    console.log('saving line items: ', lineItems, 'districtCode: ', selectedDistrictInfo['DISTRICT_COUNTY_CODE'])
    Object.keys(lineItems).forEach(expense_revenue => {
              Object.keys(lineItems[expense_revenue]).forEach(acct_code => {
                if(acct_code.split('-').slice(4,5)!='undefined'){
                    const budgetRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${expense_revenue}/${acct_code}/budget/${version}`);
                    update(budgetRef, {budget: lineItems[expense_revenue][acct_code], 
                                       fund: getAccountItem(accountCodeSchema['budget'], acct_code, 'fund'),
                                       function: getAccountItem(accountCodeSchema['budget'], acct_code, 'function'),
                                       object: getAccountItem(accountCodeSchema['budget'], acct_code, 'object'),
                                       location: getAccountItem(accountCodeSchema['budget'], acct_code, 'location') || '0000',
                                       program: getAccountItem(accountCodeSchema['budget'], acct_code, 'program') || '000',
                                       source: getAccountItem(accountCodeSchema['budget'], acct_code, 'source') || '0',
                                       project: getAccountItem(accountCodeSchema['budget'], acct_code, 'project') || '00000',
                                       editable: false}
                          ).then()
                }
              })
            })
          
          
    await set(ref(database, `${staffPath}`), staffData);
    }

    function buildAccountCode(format, data) {
      return format.split('-') // Split the format string into an array
                   .map(key => data[key]) // Map each key to its corresponding value in the data object
                   .join('-'); // Join the values with '-'
    }

    function getAccountItem(schema, acctCode, item_name) {
      const keyList = schema.split('-');
      const valueList = acctCode.split('-');
  
      const index = keyList.indexOf(item_name);
      return index !== -1 ? valueList[index] : null; // Return null if target not found
    }
    
    const handleSaveToFirebase = async () => {
      if(careerLanes[0].name!=""){
        const salarySchedule = {};
        careerLanes.forEach((lane, laneIndex) => {
          salarySchedule[lane.name] = Array(yearsToDisplay).fill(0);
            for (let step = 0; step < yearsToDisplay; step++) {
              salarySchedule[lane.name][step] = {baseSalary: parseFloat(salaryTable[step][lane.name].replace('$','').replace(',','')),
                                                hourlyRate: parseFloat(salaryTable[step][lane.name].replace('$','').replace(',',''))}
            }
        });

        const dataToSave = {
            baseSalary: careerLanes[0].step1Value,
            careerLanes,
            salarySchedule,
            yearsToDisplay
        };

      try {
        await update(ref(database, `${salarySchedulePath}/classified/${selectedSchedule}`), dataToSave);
        alert("Salary schedule saved successfully!");

        setAvailableSalarySchedules(prev => {
          const updatedClassifiedSchedules = { ...prev, Classified: dataToSave };
      
          // Call updateStaff with the updated state
          updateStaff(certifiedSalarySchedules, updatedClassifiedSchedules, benefitRates, dataPaths, selectedDistrictInfo['DISTRICT_COUNTY_CODE'], fiscalYear, version)
      
          return updatedClassifiedSchedules;
        });

      } catch (error) {
          console.error("Error saving data:", error);
          alert("Failed to save salary schedule. Please try again.");
      }
    } else{
      try {
        alert("No salary schedule - updating staff");

        updateClassifiedStaff();

      } catch (error) {
          console.error("Error saving data:", error);
          alert("Failed to save salary schedule. Please try again.");
      }
    }
    };

  const handleScrollRight = () => {
    const tableContainer = document.querySelector('.salary-schedule-classified-main');
    tableContainer.scrollLeft += 200; // Adjust the number for scroll distance
  
    toggleArrowVisibility(); // Update visibility after scrolling
  };
  
  const handleScrollLeft = () => {
    const tableContainer = document.querySelector('.salary-schedule-classified-main');
    tableContainer.scrollLeft -= 200; // Scroll left by 200px
  
    toggleArrowVisibility(); // Update visibility after scrolling
  };

  const toggleArrowVisibility = () => {
    const tableContainer = document.querySelector('.salary-schedule-classified-main');
    const table = document.querySelector('.classified-salary-table');
    const rightArrow = document.querySelector('.scroll-button.right');
    const leftArrow = document.querySelector('.scroll-button.left');

    if(tableContainer){
      // Check if there is horizontal overflow
      const isOverflowing = (table?.clientWidth - tableContainer?.clientWidth) > 0;

      if (isOverflowing) {
        rightArrow.style.display = 'block'; // Show right arrow if there's overflow
      } else {
        rightArrow.style.display = 'none'; // Hide right arrow if no overflow
      }
    
      if (tableContainer.scrollLeft > 0) {
        leftArrow.style.display = 'block'; // Show left arrow when scrolled to the right
        leftArrow.style.opacity = '1'; // Ensure it's fully visible when scrolling to the right
      } else {
        leftArrow.style.opacity = '0'; // Fade out the left arrow
        setTimeout(() => {
          if (tableContainer.scrollLeft === 0) {
            leftArrow.style.display = 'none'; // Hide left arrow after fading out
          }
        }, 1000); // Wait for the opacity transition to finish (1 second)
      }
    }
  };

  document.addEventListener('DOMContentLoaded', toggleArrowVisibility);

    return (
            <div className = 'salary-schedule-classified-main'>
              <div className="scroll-right-container">
                <button className="scroll-button right" onClick={handleScrollRight}>
                  &rarr; {/* Right arrow */}
                </button>
              </div>
              <div className="scroll-left-container">
                <button className="scroll-button left" onClick={handleScrollLeft} style={{ display: 'none' }}>
                  &larr; {/* Left arrow */}
                </button>
              </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                    <label>Schedule Name:</label>
                    <select
                        id="schedule-select"
                        value={selectedSchedule}
                        onChange={(event) => handleSelectedScheduleChange(event)}
                        style={{marginLeft: '10px'}}
                    >
                        <option value="" disabled>
                        Select Schedule Name
                        </option>
                        {availableScheduleNames?.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                        ))}
                    </select>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                    
                    <label>Years to Display:</label>
                    <input
                        type="number"
                        value={yearsToDisplay}
                        onChange={(e) => setYearsToDisplay(parseInt(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '60px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                </div>
                {/*
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', color: balanceTheme.textColor }}>
                    <label>CPI (%):</label>
                    <input
                        type="number"
                        value={cpi}
                        onChange={(e) => setCpi(parseFloat(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', marginRight: '20px', width: '60px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                    <label>Project Years Out:</label>
                    <input
                        type="range"
                        min="0"
                        max="10"
                        step="1"
                        value={projectToFiveYears ? projectToFiveYears : 0}
                        onChange={(e) => setProjectToFiveYears(parseInt(e.target.value))}
                        style={{ marginLeft: '10px', width: '100px' ,border: '1px solid #b3c7d6', borderRadius: '4px'}}
                    />
                    <span style={{ marginLeft: '10px' }}>{projectToFiveYears}</span>
                </div>
                *}
                {/* Local Add On and State Aide Input Boxes */}

               
                {/*
                <h3>Manage Levels</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <select
                        onChange={(e) => {
                            setSelectedOption(e.target.value);
                            setEditLadderName(e.target.value); 
                        }}
                        value={selectedOption}
                        style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '150px' ,border: '1px solid #b3c7d6', borderRadius: '4px'}}
                    >
                        <option value="New Lane Name">New Lane Name</option>
                        {careerLanes.map((lane, index) => (
                            <option key={index} value={lane.name}>{lane.name}</option>
                        ))}
                    </select>
                    {selectedOption === "New Lane Name" ? (
                        <>
                            <select
                                onChange={(e) => setDegreeType(e.target.value)}
                                value={degreeType}
                                style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '80px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                            >
                                <option value="BS">BS</option>
                                <option value="MS">MS</option>
                                <option value="Spec">Spec</option>
                            </select>
                            <input
                                type="text"
                                placeholder="Years Experience"
                                value={newLadderName}
                                onChange={(e) => setNewLadderName(e.target.value)}
                                style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '120px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                            />
                            <button onClick={addCareerLane}>Add Lane</button>
                        </>
                    ) : (
                        <>
                            <input
                                type="text"
                                value={editLadderName}
                                onChange={(e) => setEditLadderName(e.target.value)}
                                style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '120px' ,border: '1px solid #b3c7d6', borderRadius: '4px'}}
                            />
                            <button onClick={updateLadderName}>Update Name</button>
                            <button onClick={removeSelectedLadder} style={{ marginLeft: '10px',border: '1px solid #b3c7d6', borderRadius: '4px' }}>Remove Selected Ladder</button>
                        </>
                    )}
                </div>
                */}
                
                <div style={{ marginBottom: '10px' }}>
                <button onClick={handleSaveToFirebase} style={{ marginTop: '20px', marginRight: '20px', padding: '10px', backgroundColor: '#007BFF', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Save Salary Schedule
                </button>
                View:
                <select
                    onChange={(e) => setDisplayMode(e.target.value)}
                    value={displayMode}
                    style={{
                        backgroundColor: '#e7f4fc',
                        color: '#333',
                        border: '1px solid #b3c7d6',
                        borderRadius: '4px',
                        padding: '5px',
                        cursor: 'pointer',
                        marginLeft: '10px'
                    }}
                >
                    {['Salary', 'Change by Years', '% Change by Years', 'Change by Level', '% Change by Level'].map((mode, index) => (
                        <option key={index} value={mode}>
                            {mode}
                        </option>
                    ))}
                </select>
            </div>

                
                {/******************** SALARY TABLE ********************/}
                <table className="classified-salary-table">
                  
                    <thead>
                    <tr>
                    <th>Year</th>
                    {careerLanes.map((lane) => (
                      
                      <th
                        key={lane.name}
                      >
                        
                        {lane.name}
                        {/* Three-dot menu button */}
                        {/*
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <button
                              onClick={() => setMenuOpen(lane.name)}
                              style={{
                                background: "none",
                                border: "none",
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                            >
                              ⋮
                            </button>
                          </div>
                          */}
                          {/* Dropdown menu */}
                          {/*
                          {menuOpen===lane.name && (
                            <div style={{ position: "relative"}} ref={menuRef}>
                            <div
                              style={{
                                position: "absolute",
                                left: "55%",
                                top: "51%",
                                background: "white",
                                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                borderRadius: "5px",
                                padding: "5px 10px",
                                zIndex: 100,
                              }}
                            >
                              <div
                                onClick={() => {
                                  console.log('delete clicked')
                                }}
                                style={{ padding: "5px", cursor: "pointer", whiteSpace: "nowrap" }}
                              >
                                Delete
                              </div>
                              <div
                                onClick={() => {
                                  console.log('rename clicked')
                                }}
                                style={{ padding: "5px", cursor: "pointer", whiteSpace: "nowrap" }}
                              >
                                Rename
                              </div>
                            </div>
                            </div>
                          )}
                          */}
                        

                      </th>
                    ))}
                  </tr>

                        <tr>
                            <td>Base Rate ($) </td>
                            {careerLanes.map((lane, index) => (
                                <td key={index}>
                                    <input
                                        type="number"
                                        value={lane.step1Value}
                                        onChange={(e) => handleBaseValueChange(lane.name, e.target.value, index)}
                                        style={{ backgroundColor: balanceTheme.inputColor, width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                        placeholder="Base Rate"
                                    />
                                </td>
                            ))}
                            </tr>
                            <tr>
                            <td>Step Down ($)
                            <button 
                              onClick={toggleEditStepDownDollar} 
                              style={{
                                margin: '5px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                backgroundColor: editStepDownDollar ? '#007BFF' : '#f9f9f9', // Conditionally set the color
                                color: editStepDownDollar ? 'white' : 'black', // Optional: Change text color to make it more readable
                                border: '1px solid #ccc' // Adjust border thickness to 1px and set a color
                              }}
                            >
                              Edit
                            </button>
                            </td>
                            {careerLanes.map((lane, index) => (
                              <td key={index}>
                                  <input
                                      type="number"
                                      value={lane.defaultStepDownDollar}
                                      onChange={(e) => handleStepDownDollarChange(careerLanes, setCareerLanes, index, e.target.value)}
                                      onBlur={(e) => handleStepDownDollarBlur(careerLanes, setCareerLanes, index, e.target.value)}
                                      style={{ backgroundColor: balanceTheme.inputColor, width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                      placeholder="Step Down"
                                  />
                              </td>
                            ))}
                            </tr>
                            <tr>
                            <td>Step Down (%)
                            <button 
                              onClick={toggleEditStepDownPercent} 
                              style={{
                                margin: '5px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                backgroundColor: editStepDownPercent ? '#007BFF' : '#f9f9f9', // Conditionally set the color
                                color: editStepDownPercent ? 'white' : 'black',// Optional: Change text color to make it more readable
                                border: '1px solid #ccc'
                              }}
                            >
                              Edit
                            </button>
                            </td>
                            {careerLanes.map((lane, index) => (
                                <td key={index}>
                                    <input
                                        type="number"
                                        value={lane.defaultStepDownPercent}
                                        onChange={(e) => handleStepDownPercentChange(index, e.target.value)}
                                        style={{ backgroundColor: balanceTheme.inputColor, width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                        placeholder="Step Down"
                                    />
                                </td>
                            ))}
                            </tr>
                    </thead>
                    <tbody>
                        {/* If in edit mode, show steps, otherwise show table */}
                        {editStepDownDollar ? (
                          salaryTable.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              <td>{row.step}</td>
                              {careerLanes.map((lane, laneIndex) => (
                                <td key={laneIndex}>
                                  <input
                                    type="text"
                                    value={lane["stepDownDollars"][rowIndex]}
                                    onChange={(e) => {
                                      let newValue = e.target.value;
                                      const updatedCareerLanes = [...careerLanes];
                                      updatedCareerLanes[laneIndex]["stepDownDollars"][rowIndex] = newValue;
                                      setCareerLanes(updatedCareerLanes);
                                    }}
                                    onBlur={(e) => {
                                      let newValue = e.target.value === '' ? 0 : e.target.value;
                                      const updatedCareerLanes = [...careerLanes];
                                      updatedCareerLanes[laneIndex]["stepDownDollars"][rowIndex] = parseFloat(newValue);
                                      setCareerLanes(updatedCareerLanes);
                                    }}
                                    disabled={rowIndex===0}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))
                        ) : editStepDownPercent ? (
                          salaryTable.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              <td>{row.step}</td>
                              {careerLanes.map((lane, laneIndex) => (
                                <td key={laneIndex}>
                                  <input
                                    type="text"
                                    value={lane["stepDownPerc"][rowIndex]}
                                    onChange={(e) => {
                                      let newValue = e.target.value;
                                      const updatedCareerLanes = [...careerLanes];
                                      updatedCareerLanes[laneIndex]["stepDownPerc"][rowIndex] = newValue;
                                      setCareerLanes(updatedCareerLanes);
                                    }}
                                    onBlur={(e) => {
                                      let newValue = e.target.value === '' ? 0 : e.target.value;
                                      const updatedCareerLanes = [...careerLanes];
                                      updatedCareerLanes[laneIndex]["stepDownPerc"][rowIndex] = parseFloat(newValue);
                                      setCareerLanes(updatedCareerLanes);
                                    }}
                                    disabled={rowIndex===0}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))
                        ) : (
                          salaryTable.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              <td>{row.step}</td>
                              {careerLanes.map((lane, laneIndex) => (
                                <td
                                  key={laneIndex}
                                  style={{
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  {row[lane.name]}
                                </td>
                              ))}
                            </tr>
                          ))
                        )}

                    </tbody>
                </table>
                {toggleArrowVisibility()}
            </div>
            
    );
};

export default SalarySchedule;

