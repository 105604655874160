import { get } from "firebase/database";
import { useEffect, useRef } from 'react';

export function getUniqueValues(data, key) {
  return [...new Set(data.map(item => item[key]))];
}

export const formatDollars = (num) => {
  return `$${Math.round(num).toLocaleString()}`;
};

export function sumFieldByGroup(data, groupFields, sumFields) {
  // Create a map to store the grouped sums
  const groupMap = {};

  // Calculate sums for each group
  data.forEach(obj => {
    // Create a composite key for the group based on the specified fields
    const groupKey = groupFields.map(field => obj[field]).join('|');

    if (!groupMap[groupKey]) {
      // Initialize the grouped object with the fields and set each sumField to 0
      groupMap[groupKey] = { 
        ...groupFields.reduce((acc, field) => {
          acc[field] = obj[field];
          return acc;
        }, {}), 
        ...obj, // Include all fields from the object
        ...sumFields.reduce((acc, field) => {
          acc[field] = 0;
          return acc;
        }, {}) // Initialize sumFields to 0
      };
    }

    // Add the values of all sumFields to the appropriate group
    sumFields.forEach(field => {
      groupMap[groupKey][field] += obj[field];
    });
  });

  // Return only the summarized objects
  return Object.values(groupMap);
}




export function assignLevels(data, labelKey) {

  
  let uniqueLabels = getUniqueValues(data, labelKey)


  const labelParentDict = data.reduce((acc, item) => {
    const label = String(item[labelKey]);
    const parent = String(item['PARENT']);

    if (!acc[label]) {
      acc[label] = String(parent);
    }
    return acc;
  }, {});

  
  let levelDict = {};

  uniqueLabels.forEach((label_item) => {
    let level = 0;
    let parent = labelParentDict[label_item]
    
    while(parent!='Self'){
      level+=1
      parent = labelParentDict[parent] || 'Self'
      if(parent==='Unknown'){parent = 'Self'}
    }
    levelDict[label_item] = level

  })


  return levelDict
}

export function getShade(value) {

  // Start with a light blue color (can adjust to any base RGB color)
  const baseColor = { r: 240, g: 248, b: 255 };
  
  // Calculate new RGB values by reducing each component based on the ratio
  const r = Math.floor(baseColor.r - 40*value);  
  const g = Math.floor(baseColor.g - 25*value);  
  const b = Math.floor(baseColor.b - 3*value);
  
  // Return the color as an RGB string
  return `rgb(${r}, ${g}, ${b})`;
}

export function arrToJSON(arr) {
  const jsonObject = {};
  arr.forEach(key => {
      jsonObject[key] = false;
  });
  return jsonObject
}

export const getFirebaseData = async (reference) => {
  
  try {
    const snapshot = await get(reference);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available at the reference.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching data from Firebase:", error);
    return null;
  }
};

export function findClosestMatch(list, target) {
  // Extract the first three digits of the target
  const targetPrefix = Math.floor(target / 10);
  
  // Filter the list to only include numbers with the same first three digits
  const candidates = list.filter(num => Math.floor(num / 10) === targetPrefix);
  
  // If no candidates match the prefix, return null or handle the case accordingly
  if (candidates.length === 0) {
      return null; // Or throw an error, depending on your use case
  }
  
  // Find the closest number among the candidates
  let closest = candidates[0];
  let minDifference = Math.abs(target - closest);
  
  candidates.forEach(num => {
      const difference = Math.abs(target - num);
      if (difference < minDifference) {
          closest = num;
          minDifference = difference;
      }
  });

  
  return closest;
}

export function expandDataWithYears(data, validYears, yearField = "YEAR") {

  return validYears.map(year => {
    // Check if the year exists in the dataset
    const existingEntry = data.find(entry => entry[yearField] === year);
    if (existingEntry) {
      // Return the existing entry if it matches the year
      return existingEntry;
    } else {
      // Create a new entry with budget = 0 and copy other fields from the first entry
      const templateEntry = data[0] || {}; // Use the first entry as a template, if available
      return {
        ...templateEntry,
        [yearField]: year,
        GENERAL_FUND: 0,
        SPECIAL_REVENUE: 0,
        DEBT_SERVICE: 0,
        CAPITAL_PROJECTS: 0,
        TOTAL_ALL_FUNDS: "$0"
      };
    }
  });

}

export const handleFiscalYearChange = (event, setFiscalYear) => {
  //const updatedAvailableVersions = getUniqueValues(currentBudget.filter(item=>item.YEAR===event.target.value), "version");
  setFiscalYear(event.target.value)
  //setAvailableVersions(updatedAvailableVersions)
  //setVersion(updatedAvailableVersions[updatedAvailableVersions.length-1])
}

export const handleVersionChange = (event, setVersion) => {
  setVersion(event.target.value)
    //if (event.target.value==='Create New'){
    //    console.log('creating new version')
    //    setNewVersionCreate(true);
    //} else {
    //    setVersion(event.target.value)
    //}
}

export const handleBoardApprovedChange = (event, setBoardApproved) => {
  console.log('changing board approved status')
  setBoardApproved(event.target.value)

}

export const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency
        }
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  useEffect(effectHook, dependencies);
};