import { database } from '../../firebaseConfig';
import { ref, get, set, update } from "firebase/database";

function buildAccountCode(format, data) {
  return format.split('-') // Split the format string into an array
               .map(key => data[key]) // Map each key to its corresponding value in the data object
               .join('-'); // Join the values with '-'
}

export const determineClassifiedObjectCode = (type, functionCode) => {
  const conditions = {
    salary: [
      { object: "6152", condition: [1111, 1131, 1151, 1191, 1221, 1281, 3512, 3812 ] },
      { object: "6161", condition: [2552, 1191] },
      { object: "6151"}, //default
    ],
    insurance: [
      { object: "6241" }, // Default
    ],
    retirement: [{ object: "6221" }], // Default
    fica: [{ object: "6231"}],
    medicare: [{ object: "6232" }], // Default
  };

  const typeConditions = conditions[type];
  if (!typeConditions) return null;

  for (const { object, condition } of typeConditions) {
    if (!condition || condition.includes(functionCode)) {
      return object;
    }
  }

  return null;
};

export const determineCertifiedObjectCode = (type, functionCode) => {
  const conditions = {
    salary: [
      { object: "6112", condition: [2211, 2411] },
      { object: "6131", condition: [1411, 1421] },
      { object: "6111" }, // Default
    ],
    insurance: [
      { object: "6241" }, // Default
    ],
    retirement: [{ object: "6211" }], // Default
    medicare: [{ object: "6232" }], // Default
    fica: [{ object: "6231"}],
  };

  const typeConditions = conditions[type];
  if (!typeConditions) return null;

  for (const { object, condition } of typeConditions) {
    if (!condition || condition.includes(functionCode)) {
      return object;
    }
  }

  return null;
};

export function getAccountItem(schema, acctCode, item_name) {
  const keyList = schema.split('-');
  const valueList = acctCode.split('-');

  const index = keyList.indexOf(item_name);
  return index !== -1 ? valueList[index] : null; // Return null if target not found
}

async function dropUnEditableBudgetItems(districtCode, fiscalYear, version) {
  const snapshot = await get(ref(database, `budget/${districtCode}/${fiscalYear}/expense`));
  const data = snapshot.val();

  Object.keys(data).forEach(item=> {
    if(!data[item]?.['budget']?.[version]?.['editable']){
      if(data[item]['budget'][version]){
        data[item]['budget'][version]['budget'] = 0
      } else {
        data[item] = null // clear old items that aren't connected to the current version
      }
    }
  })

  await set(ref(database, `budget/${districtCode}/${fiscalYear}/expense`), data)

  return console.log("Un-Editable Budget Items cleared");
}

export async function updateStaff (certifiedSalarySchedules, classifiedSalarySchedules, updatedBenefitRates, dataPaths, districtCode, fiscalYear, version) {

  console.log('certifiedSalarySchedules: ', certifiedSalarySchedules)
  console.log('classifiedSalarySchedules', classifiedSalarySchedules)

  await dropUnEditableBudgetItems(districtCode, fiscalYear, version);

  const availableScheduleNames = Object.keys(certifiedSalarySchedules || {}).concat(Object.keys(classifiedSalarySchedules || {}));
  const staffPromise = await get(ref(database, `${dataPaths['staffPath']}`));
  const accountCodeSchemaPromise = await get(ref(database, `accountCodeSchema`));
  const accountCodeSchema = accountCodeSchemaPromise.val()[districtCode]
  const staffData = staffPromise.val();
  const revenueMetadataPromise = await get(ref(database, `revenueMetadata/${districtCode}/${fiscalYear}/${version}/stateRevDistributions/codeInfo/5341 - Baseline Salary Grant`));
  const blsg_codeInfo = revenueMetadataPromise.val();

  const funcCodes = Object.keys(staffData).filter(item=>item.length===4); // only get 4 digit function codes
      
  let buildings = []; let people = []; let staffTypes = [];
  let lineItems = {'expense':{}, 'revenue':{}};

  const blsg_revenue_line_item = buildAccountCode(accountCodeSchema['revenue'], {fund: '20', 
    function: '5341', 
    object: '0000',
    location: blsg_codeInfo?.location || '0000',
    program: blsg_codeInfo?.program || '000',
    source: blsg_codeInfo?.source || '0',
    project: '34100'})

  lineItems['revenue'][blsg_revenue_line_item] = 0;

  funcCodes.forEach(fc => {
    buildings = Object.keys(staffData[fc]);
    buildings.forEach(bldg => {
      staffTypes = Object.keys(staffData[fc][bldg]);
      staffTypes.forEach(staffType => {
        if(staffType==='certified' || staffType === 'activity'){
          if (!staffData[fc]) staffData[fc] = {};
          if (!staffData[fc][bldg]) staffData[fc][bldg] = {};
          if (!staffData[fc][bldg][staffType]) staffData[fc][bldg][staffType] = {};
          if (!staffData[fc][bldg][staffType]['totals']) staffData[fc][bldg][staffType]['totals'] = {};

          staffData[fc][bldg][staffType]['totals']['salary'] = 0;
          staffData[fc][bldg][staffType]['totals']['totalCost'] = 0;
          staffData[fc][bldg][staffType]['totals']['retirement'] = 0;
          staffData[fc][bldg][staffType]['totals']['insuranceBenefits'] = 0;
          staffData[fc][bldg][staffType]['totals']['medicare'] = 0;
          staffData[fc][bldg][staffType]['totals']['fte'] = 0;
          
          people = Object.keys((staffData[fc][bldg]?.[staffType]?.staff) || {})
          people.forEach(person_id => {
              
            const person = staffData[fc][bldg][staffType]['staff'][person_id];

            const salary_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                          function: person.function, 
                                                                          object: person.object,
                                                                          location: person.location,
                                                                          program: person.program,
                                                                          source: person.source,
                                                                          project: person.project})

            const retirement_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                            function: person.function, 
                                                                            object: determineCertifiedObjectCode("retirement", parseInt(fc)),
                                                                            location: person.location,
                                                                            program: person.program,
                                                                            source: person.source,
                                                                            project: person.project})
            
            const medicare_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                              function: person.function, 
                                                                              object: determineCertifiedObjectCode("medicare", parseInt(fc)),
                                                                              location: person.location,
                                                                              program: person.program,
                                                                              source: person.source,
                                                                              project: person.project})
            
            const insurance_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                                function: person.function, 
                                                                                object: determineCertifiedObjectCode("insurance", parseInt(fc)),
                                                                                location: person.location,
                                                                                program: person.program,
                                                                                source: person.source,
                                                                                project: person.project})

            const fica_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                                  function: person.function, 
                                                                                  object: determineClassifiedObjectCode("fica", parseInt(fc)),
                                                                                  location: person.location,
                                                                                  program: person.program,
                                                                                  source: person.source,
                                                                                  project: person.project})

            // Baseline salary grant (blsg) 
            const blsg_salary_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                                  function: person.function, 
                                                                                  object: person.object,
                                                                                  location: person.location,
                                                                                  program: person.program,
                                                                                  source: '3',
                                                                                  project: '34100'})

            const blsg_retirement_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                                    function: person.function, 
                                                                                    object: determineCertifiedObjectCode("retirement", parseInt(fc)),
                                                                                    location: person.location,
                                                                                    program: person.program,
                                                                                    source: '3',
                                                                                    project: '34100'})

            const blsg_medicare_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                                            function: person.function, 
                                                                                            object: determineCertifiedObjectCode("medicare", parseInt(fc)),
                                                                                            location: person.location,
                                                                                            program: person.program,
                                                                                            source: '3',
                                                                                            project: '34100'})

            const blsg_fica_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                                        function: person.function, 
                                                                                        object: determineCertifiedObjectCode("fica", parseInt(fc)),
                                                                                        location: person.location,
                                                                                        program: person.program,
                                                                                        source: '3',
                                                                                        project: '34100'})

            // Initialize line items with zero if they don't exist
            lineItems['expense'][salary_line_item] = lineItems['expense'][salary_line_item] ?? 0;
            lineItems['expense'][retirement_line_item] = lineItems['expense'][retirement_line_item] ?? 0;
            lineItems['expense'][medicare_line_item] = lineItems['expense'][medicare_line_item] ?? 0;
            lineItems['expense'][insurance_line_item] = lineItems['expense'][insurance_line_item] ?? 0;
            lineItems['expense'][fica_line_item] = lineItems['expense'][fica_line_item] ?? 0;
            lineItems['expense'][blsg_salary_line_item] = lineItems['expense'][blsg_salary_line_item] ?? 0;
            lineItems['expense'][blsg_retirement_line_item] = lineItems['expense'][blsg_retirement_line_item] ?? 0;
            lineItems['expense'][blsg_medicare_line_item] = lineItems['expense'][blsg_medicare_line_item] ?? 0;
            lineItems['expense'][blsg_fica_line_item] = lineItems['expense'][blsg_fica_line_item] ?? 0;
              
            // effectiveSalary includes baseline salary grant, districtPaidSalary does not. fullFTESalary does not multiply by FTE
            let effectiveSalary; let districtPaidSalary; let fullFTESalary; let fullFTESalary_district;
       
            // activities
            if (['1411', '1421'].includes(fc)){
              if(person.activityCompMethod === "Percent of Base (%)"){
                const baseSalary = certifiedSalarySchedules['Certified']['baseSalary'];
                districtPaidSalary = (baseSalary * (person.activityCompAmount / 100)) || 0;
                effectiveSalary = (baseSalary * (person.activityCompAmount / 100)) || 0;
              } else if (person.activityCompMethod === 'Salary Schedule ($)'){
                if(person.scheduleToUse === 'Manual Entry'){
                  districtPaidSalary = person.salary
                  effectiveSalary = person.salary
                } else {
                  if(person.lane===0){
                    districtPaidSalary = person.salary
                    effectiveSalary = person.salary
                  } else {
                    let lane_names = certifiedSalarySchedules[person.scheduleToUse]['careerLanes'].map(item => item.name)
                    districtPaidSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['salary']
                    effectiveSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['salary']
                  }
                }
              } else {
                districtPaidSalary = person.salary;
                effectiveSalary = person.salary;
              }
            // other certified roles
            } else if(availableScheduleNames.includes(person.scheduleToUse) && (person.lane!=='' && person.step!=='' && person.lane<=certifiedSalarySchedules[person.scheduleToUse]['careerLanes'].length)){
              let lane_names = certifiedSalarySchedules[person.scheduleToUse]['careerLanes'].map(item => item.name)
              let num_steps = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]].length;
              // Sometimes step exceeds the number of steps in the schedule. Use the last step for these people
              if(person.step>=num_steps){
                fullFTESalary_district = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][num_steps-1]['baseSalary'];
                fullFTESalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][num_steps-1]['salary'];
                districtPaidSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][num_steps-1]['baseSalary'] * (person.fte || 1);
                effectiveSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][num_steps-1]['salary'] * (person.fte || 1);
              // Otherwise use their step
              } else {
                fullFTESalary_district = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['baseSalary']
                fullFTESalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['salary']
                districtPaidSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['baseSalary'] * (person.fte || 1);
                effectiveSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['salary'] * (person.fte || 1); // Calculate effective salary
              }
            }
            // people that don't have a salary schedule
              else {
                fullFTESalary_district = person.fullFTEsalary;
                fullFTESalary = person.fullFTEsalary;
                districtPaidSalary = (person.fte && person.fte > 0) ? person.fullFTEsalary * person.fte : person.fullFTEsalary;
                effectiveSalary = (person.fte && person.fte > 0) ? person.fullFTEsalary * person.fte : person.fullFTEsalary;
            }

            const extraDaysSalary = parseInt(person?.extraDays*fullFTESalary_district/(updatedBenefitRates?.contractDays || 1) || 0) * (person.fte || 1);
            const districtPaidContract = (districtPaidSalary || 0) + parseFloat(person.extendedContract || 0) + extraDaysSalary;
            const effectiveContract = (effectiveSalary || 0) + parseFloat(person.extendedContract || 0) + extraDaysSalary;

            let insuranceBenefits = 0
            // Don't calculate health insurance benefits if FTE is zero. Most commonly for stipends.
            if(person.fte>0){
              Object.keys(person.benefits).forEach(benefit => insuranceBenefits += person.benefits[benefit] > 0 ? updatedBenefitRates[benefit] * 12 * (person.fte || 1) : 0)
            }

            const medicare = districtPaidContract * updatedBenefitRates.medicareRate;
            const blsg_medicare_amount = ((effectiveSalary - districtPaidSalary) || 0) * (updatedBenefitRates.medicareRate || 0);
            const medicare_total = medicare + blsg_medicare_amount;

            let retirement = 0; let retirement_blsg = 0; let fica = 0; let fica_total = 0; let blsg_fica_amount = 0;
            if(person.employeeType==='classified'){
              retirement = (districtPaidContract + insuranceBenefits) * updatedBenefitRates.peersRate;
              retirement_blsg = ((effectiveSalary - districtPaidSalary) || 0) * updatedBenefitRates.peersRate;
              fica = districtPaidContract * updatedBenefitRates.socialSecurityRate;
              blsg_fica_amount =  ((effectiveSalary - districtPaidSalary) || 0) * (updatedBenefitRates.socialSecurityRate || 0 );
              fica_total = fica + blsg_fica_amount;
            } else{
              retirement = (districtPaidContract + insuranceBenefits) * updatedBenefitRates.certifiedRetirement;
              retirement_blsg = ((effectiveSalary - districtPaidSalary) || 0) * updatedBenefitRates.certifiedRetirement;
            }
            const retirement_total = retirement + retirement_blsg;
            
            const totalCost = effectiveContract + insuranceBenefits + medicare_total + retirement_total + fica_total;

            if(effectiveSalary===0 && insuranceBenefits>0){
              console.log('!!WARNING!! Role has insurance benefits but no salary')
              console.log('person: ', person)
            }

            staffData[fc][bldg][staffType]['staff'][person_id]['salary'] =  effectiveSalary || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['fullFTEsalary'] =  fullFTESalary || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['fullFTEsalary_district'] =  fullFTESalary_district || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['extraDaysSalary'] =  extraDaysSalary || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['salaryDistrict'] =  districtPaidSalary || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['salaryStateGrant'] =  (effectiveSalary - districtPaidSalary) || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['totalCost'] = totalCost || 0; 
            staffData[fc][bldg][staffType]['staff'][person_id]['retirement'] = retirement_total || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['retirementDistrict'] = retirement || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['retirementStateGrant'] = retirement_blsg || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['insuranceBenefits'] = insuranceBenefits || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['medicare'] = medicare_total || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['medicareDistrict'] = medicare || 0;
            staffData[fc][bldg][staffType]['staff'][person_id]['medicareStateGrant'] = blsg_medicare_amount || 0;

            staffData[fc][bldg][staffType]['totals']['salary'] += effectiveSalary || 0;
            staffData[fc][bldg][staffType]['totals']['totalCost'] += totalCost || 0;
            staffData[fc][bldg][staffType]['totals']['retirement'] += retirement_total || 0;
            staffData[fc][bldg][staffType]['totals']['insuranceBenefits'] += insuranceBenefits || 0;
            staffData[fc][bldg][staffType]['totals']['medicare'] += medicare_total || 0;
            staffData[fc][bldg][staffType]['totals']['fte'] += (person.fte || 1);

            lineItems['expense'][salary_line_item] += districtPaidContract || 0;
            lineItems['expense'][retirement_line_item] += retirement || 0;
            lineItems['expense'][insurance_line_item] += insuranceBenefits || 0;
            lineItems['expense'][medicare_line_item] += medicare || 0;
            lineItems['expense'][fica_line_item] += fica || 0;
            
            lineItems['expense'][blsg_salary_line_item] += (effectiveSalary - districtPaidSalary) || 0;
            lineItems['expense'][blsg_retirement_line_item] += retirement_blsg || 0;
            lineItems['expense'][blsg_medicare_line_item] += blsg_medicare_amount || 0;
            lineItems['expense'][blsg_fica_line_item] += blsg_fica_amount || 0;
            lineItems['revenue'][blsg_revenue_line_item] += (((effectiveSalary - districtPaidSalary) || 0) + (retirement_blsg || 0) + blsg_medicare_amount);
          })
        // Update classified staff
        } else {
          if (!staffData[fc]) staffData[fc] = {};
          if (!staffData[fc][bldg]) staffData[fc][bldg] = {};
          if (!staffData[fc][bldg][staffType]) staffData[fc][bldg][staffType] = {};
          if (!staffData[fc][bldg][staffType]['totals']) staffData[fc][bldg][staffType]['totals'] = {};

          staffData[fc][bldg]['classified']['totals']['salary'] = 0;
          staffData[fc][bldg]['classified']['totals']['totalCost'] = 0;
          staffData[fc][bldg]['classified']['totals']['retirement'] = 0;
          staffData[fc][bldg]['classified']['totals']['insuranceBenefits'] = 0;
          staffData[fc][bldg]['classified']['totals']['medicare'] = 0;
          staffData[fc][bldg]['classified']['totals']['fte'] = 0;
          staffData[fc][bldg]['classified']['totals']['fica'] = 0;

          people = Object.keys(staffData[fc][bldg]['classified']['staff'] || {});
          people.forEach(person_id => {
              
            const person = staffData[fc][bldg]['classified']['staff'][person_id];

            const salary_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                          function: person.function, 
                                                                          object: person.object,
                                                                          location: person.location,
                                                                          program: person.program,
                                                                          source: person.source,
                                                                          project: person.project})

            const retirement_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                              function: person.function, 
                                                                              object: determineClassifiedObjectCode("retirement", parseInt(fc)),
                                                                              location: person.location,
                                                                              program: person.program,
                                                                              source: person.source,
                                                                              project: person.project})
            
            const medicare_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                            function: person.function, 
                                                                            object: determineClassifiedObjectCode("medicare", parseInt(fc)),
                                                                            location: person.location,
                                                                            program: person.program,
                                                                            source: person.source,
                                                                            project: person.project})

            const insurance_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                              function: person.function, 
                                                                              object: determineClassifiedObjectCode("insurance", parseInt(fc)),
                                                                              location: person.location,
                                                                              program: person.program,
                                                                              source: person.source,
                                                                              project: person.project})
        
            const fica_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                        function: person.function, 
                                                                        object: determineClassifiedObjectCode("fica", parseInt(fc)),
                                                                        location: person.location,
                                                                        program: person.program,
                                                                        source: person.source,
                                                                        project: person.project})

            lineItems['expense'][salary_line_item] = lineItems['expense'][salary_line_item] ?? 0;
            lineItems['expense'][retirement_line_item] = lineItems['expense'][retirement_line_item] ?? 0;
            lineItems['expense'][medicare_line_item] = lineItems['expense'][medicare_line_item] ?? 0;
            lineItems['expense'][insurance_line_item] = lineItems['expense'][insurance_line_item] ?? 0;
            lineItems['expense'][fica_line_item] = lineItems['expense'][fica_line_item] ?? 0;
            
            // if the salary was manually entered, don't use the salary schedule
            let effectiveSalary; let hourlyRate;

            if(availableScheduleNames.includes(person.scheduleToUse) && (person.lane!=='' && person.step!=='' && person.lane<=classifiedSalarySchedules[person.scheduleToUse]['careerLanes'].length)){
              const lane_names = classifiedSalarySchedules[person.scheduleToUse]['careerLanes'].map(item => item.name)
              let num_steps = classifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]].length;
              if(person.step>num_steps){
                hourlyRate = classifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][num_steps-1]['hourlyRate'];
              } else {
                hourlyRate = classifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step-1]['hourlyRate'];
              }
              effectiveSalary = hourlyRate * person.totalHours * (person.fte || 1); // Calculate effective salary
            } else {
              // If hourlyRate is undefined, calculate it
              if(!person.hourlyRate){
                hourlyRate = person.totalContract / (person.totalHours || 1)
                effectiveSalary = hourlyRate * person.totalHours * (person.fte || 1);
              } else{
                hourlyRate = parseFloat(person.hourlyRate);
                effectiveSalary = hourlyRate * person.totalHours * (person.fte || 1);
              }
            }
            
            const extraDaysSalary = (person.fte || 1) * parseFloat(person.extraDays*((person.totalHours || 0) * hourlyRate)/updatedBenefitRates.contractDays || 0)
            const salary = effectiveSalary + (extraDaysSalary || 0) + (person.extendedContract || 0);

            let insuranceBenefits = 0
            // Don't calculate health insurance benefits if FTE is zero. Most commonly for stipends.
            if(person.fte>0){
              Object.keys(person.benefits).forEach(benefit => insuranceBenefits += (person.benefits[benefit]>0? updatedBenefitRates[benefit] * 12 * (person.fte || 1) : 0))
            }

            const medicare = salary * updatedBenefitRates.medicareRate;
            let retirement = 0; let fica = 0;
            if(person.employeeType==='classified'){
              retirement = (salary + insuranceBenefits) * updatedBenefitRates.peersRate;
              fica = salary * updatedBenefitRates.socialSecurityRate;
            } else{
              retirement = (salary + insuranceBenefits) * updatedBenefitRates.certifiedRetirement;
            }

            const totalCost = salary + insuranceBenefits + medicare + retirement + fica;

            staffData[fc][bldg]['classified']['staff'][person_id]['salary'] =  effectiveSalary || 0;
            staffData[fc][bldg]['classified']['staff'][person_id]['extraDaysSalary'] =  extraDaysSalary || 0;
            staffData[fc][bldg]['classified']['staff'][person_id]['hourlyRate'] =  hourlyRate || 0;
            staffData[fc][bldg]['classified']['staff'][person_id]['totalCost'] = totalCost || 0;
            staffData[fc][bldg]['classified']['staff'][person_id]['retirement'] = retirement || 0;
            staffData[fc][bldg]['classified']['staff'][person_id]['insuranceBenefits'] = insuranceBenefits || 0;
            staffData[fc][bldg]['classified']['staff'][person_id]['medicare'] = medicare || 0;
            staffData[fc][bldg]['classified']['staff'][person_id]['fica'] = fica || 0;

            staffData[fc][bldg]['classified']['totals']['salary'] += effectiveSalary || 0;
            staffData[fc][bldg]['classified']['totals']['totalCost'] += totalCost || 0;
            staffData[fc][bldg]['classified']['totals']['retirement'] += retirement || 0;
            staffData[fc][bldg]['classified']['totals']['insuranceBenefits'] += insuranceBenefits || 0;
            staffData[fc][bldg]['classified']['totals']['medicare'] += medicare || 0;
            staffData[fc][bldg]['classified']['totals']['fte'] += (person.fte || 1);
            staffData[fc][bldg]['classified']['totals']['fica'] += fica || 0;

            lineItems['expense'][salary_line_item] += effectiveSalary || 0;
            lineItems['expense'][retirement_line_item] += retirement || 0;
            lineItems['expense'][insurance_line_item] += insuranceBenefits || 0;
            lineItems['expense'][medicare_line_item] += medicare || 0;
            lineItems['expense'][fica_line_item] += fica || 0;
          })
        }
      })
    })
  })

  console.log('lineItems: ', lineItems)
  Object.keys(lineItems).forEach(expense_revenue => {
    Object.keys(lineItems[expense_revenue]).forEach(acct_code => {
      if(acct_code.split('-').slice(4,5)!=='undefined'){
          const budgetRef = ref(database, `budget/${districtCode}/${fiscalYear}/${expense_revenue}/${acct_code}/budget/${version}`);
          const schemaKey = expense_revenue === 'expense' ? 'budget' : 'revenue';
          update(budgetRef, {budget: lineItems[expense_revenue][acct_code], 
                              fund: getAccountItem(accountCodeSchema[schemaKey], acct_code, 'fund'),
                              function: getAccountItem(accountCodeSchema[schemaKey], acct_code, 'function'),
                              object: getAccountItem(accountCodeSchema[schemaKey], acct_code, 'object'),
                              location: getAccountItem(accountCodeSchema[schemaKey], acct_code, 'location') || '0000',
                              program: getAccountItem(accountCodeSchema[schemaKey], acct_code, 'program') || '000',
                              source: getAccountItem(accountCodeSchema[schemaKey], acct_code, 'source') || '0',
                              project: getAccountItem(accountCodeSchema[schemaKey], acct_code, 'project') || '00000',
                              editable: false}
                ).then()
      }
    })
  })
  
  await set(ref(database, `${dataPaths['staffPath']}`), staffData);
}

export async function updateSelectStaff (roles, certifiedSalarySchedules, classifiedSalarySchedules, updatedBenefitRates, dataPaths, districtCode, fiscalYear, version) {

  console.log('certifiedSalarySchedules: ', certifiedSalarySchedules)
  console.log('classifiedSalarySchedules', classifiedSalarySchedules)

  const fc = roles[0].function;
  const bldg = roles[0].location;
  const availableScheduleNames = Object.keys(certifiedSalarySchedules || {}).concat(Object.keys(classifiedSalarySchedules || {}));
  const staffPromise = await get(ref(database, `${dataPaths['staffPath']}`));
  const accountCodeSchemaPromise = await get(ref(database, `accountCodeSchema`));
  const accountCodeSchema = accountCodeSchemaPromise.val()[districtCode]
  const staffData = staffPromise.val();
 
  let people = []; let staffTypes = [];
  let lineItems = {'expense':{}, 'revenue':{}};
  
  staffTypes = Object.keys(staffData[fc][bldg]);
  staffTypes.forEach(staffType => {
    if(staffType==='certified' || staffType === 'activity'){
      if (!staffData[fc]) staffData[fc] = {};
      if (!staffData[fc][bldg]) staffData[fc][bldg] = {};
      if (!staffData[fc][bldg][staffType]) staffData[fc][bldg][staffType] = {};
      if (!staffData[fc][bldg][staffType]['totals']) staffData[fc][bldg][staffType]['totals'] = {};

      staffData[fc][bldg][staffType]['totals']['salary'] = 0;
      staffData[fc][bldg][staffType]['totals']['totalCost'] = 0;
      staffData[fc][bldg][staffType]['totals']['retirement'] = 0;
      staffData[fc][bldg][staffType]['totals']['insuranceBenefits'] = 0;
      staffData[fc][bldg][staffType]['totals']['medicare'] = 0;
      staffData[fc][bldg][staffType]['totals']['fte'] = 0;
      
      people = Object.keys((staffData[fc][bldg]?.[staffType]?.staff) || {})
      people.forEach(person_id => {
          
        const person = staffData[fc][bldg][staffType]['staff'][person_id];

        const salary_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                      function: person.function, 
                                                                      object: person.object,
                                                                      location: person.location,
                                                                      program: person.program,
                                                                      source: person.source,
                                                                      project: person.project})

        const retirement_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                        function: person.function, 
                                                                        object: determineCertifiedObjectCode("retirement", parseInt(fc)),
                                                                        location: person.location,
                                                                        program: person.program,
                                                                        source: person.source,
                                                                        project: person.project})
        
        const medicare_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                          function: person.function, 
                                                                          object: determineCertifiedObjectCode("medicare", parseInt(fc)),
                                                                          location: person.location,
                                                                          program: person.program,
                                                                          source: person.source,
                                                                          project: person.project})
        
        const insurance_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                            function: person.function, 
                                                                            object: determineCertifiedObjectCode("insurance", parseInt(fc)),
                                                                            location: person.location,
                                                                            program: person.program,
                                                                            source: person.source,
                                                                            project: person.project})

        const fica_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                              function: person.function, 
                                                                              object: determineClassifiedObjectCode("fica", parseInt(fc)),
                                                                              location: person.location,
                                                                              program: person.program,
                                                                              source: person.source,
                                                                              project: person.project})

        // Baseline salary grant (blsg) 
        const blsg_salary_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                              function: person.function, 
                                                                              object: person.object,
                                                                              location: person.location,
                                                                              program: person.program,
                                                                              source: '3',
                                                                              project: '34100'})

        const blsg_retirement_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                                function: person.function, 
                                                                                object: determineCertifiedObjectCode("retirement", parseInt(fc)),
                                                                                location: person.location,
                                                                                program: person.program,
                                                                                source: '3',
                                                                                project: '34100'})

        const blsg_medicare_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                                        function: person.function, 
                                                                                        object: determineCertifiedObjectCode("medicare", parseInt(fc)),
                                                                                        location: person.location,
                                                                                        program: person.program,
                                                                                        source: '3',
                                                                                        project: '34100'})

        const blsg_fica_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                                    function: person.function, 
                                                                                    object: determineCertifiedObjectCode("fica", parseInt(fc)),
                                                                                    location: person.location,
                                                                                    program: person.program,
                                                                                    source: '3',
                                                                                    project: '34100'})

        // Initialize line items with zero if they don't exist
        lineItems['expense'][salary_line_item] = lineItems['expense'][salary_line_item] ?? 0;
        lineItems['expense'][retirement_line_item] = lineItems['expense'][retirement_line_item] ?? 0;
        lineItems['expense'][medicare_line_item] = lineItems['expense'][medicare_line_item] ?? 0;
        lineItems['expense'][insurance_line_item] = lineItems['expense'][insurance_line_item] ?? 0;
        lineItems['expense'][fica_line_item] = lineItems['expense'][fica_line_item] ?? 0;
        lineItems['expense'][blsg_salary_line_item] = lineItems['expense'][blsg_salary_line_item] ?? 0;
        lineItems['expense'][blsg_retirement_line_item] = lineItems['expense'][blsg_retirement_line_item] ?? 0;
        lineItems['expense'][blsg_medicare_line_item] = lineItems['expense'][blsg_medicare_line_item] ?? 0;
        lineItems['expense'][blsg_fica_line_item] = lineItems['expense'][blsg_fica_line_item] ?? 0;
          
        // effectiveSalary includes baseline salary grant, districtPaidSalary does not. fullFTESalary does not multiply by FTE
        let effectiveSalary; let districtPaidSalary; let fullFTESalary; let fullFTESalary_district;
        
        // activities
        if (['1411', '1421'].includes(fc)){
          if(person.activityCompMethod === "Percent of Base (%)"){
            const baseSalary = certifiedSalarySchedules['Certified']['baseSalary'];
            districtPaidSalary = (baseSalary * (person.activityCompAmount / 100)) || 0;
            effectiveSalary = (baseSalary * (person.activityCompAmount / 100)) || 0;
          } else if (person.activityCompMethod === 'Salary Schedule ($)'){
            if(person.scheduleToUse === 'Manual Entry'){
              districtPaidSalary = person.totalContract
              effectiveSalary = person.totalContract
            } else {
              if(person.lane===0){
                districtPaidSalary = person.totalContract
                effectiveSalary = person.totalContract
              } else {
                let lane_names = certifiedSalarySchedules[person.scheduleToUse]['careerLanes'].map(item => item.name)
                districtPaidSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['salary']
                effectiveSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['salary']
              }
            }
          } else {
            districtPaidSalary = person.totalContract;
            effectiveSalary = person.totalContract;
          }
        // other certified roles
        } else if(availableScheduleNames.includes(person.scheduleToUse) && (person.lane!=='' && person.step!=='' && person.lane<=certifiedSalarySchedules[person.scheduleToUse]['careerLanes'].length)){
          let lane_names = certifiedSalarySchedules[person.scheduleToUse]['careerLanes'].map(item => item.name)
          let num_steps = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]].length;
          // Sometimes step exceeds the number of steps in the schedule. Use the last step for these people
          if(person.step>=num_steps){
            fullFTESalary_district = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][num_steps-1]['baseSalary'];
            fullFTESalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][num_steps-1]['salary'];
            districtPaidSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][num_steps-1]['baseSalary'] * (person.fte || 1);
            effectiveSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][num_steps-1]['salary'] * (person.fte || 1);
          // Otherwise use their step
          } else {
            fullFTESalary_district = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['baseSalary'];
            fullFTESalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['salary'];
            districtPaidSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['baseSalary'] * (person.fte || 1);
            effectiveSalary = certifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step]['salary'] * (person.fte || 1); // Calculate effective salary
          }
        }
        // people that don't have a salary schedule
          else {
            fullFTESalary_district = person.fullFTEsalary;
            fullFTESalary = person.fullFTEsalary;
            districtPaidSalary = (person.fte && person.fte > 0) ? person.fullFTEsalary * person.fte : person.fullFTEsalary;
            effectiveSalary = (person.fte && person.fte > 0) ? person.fullFTEsalary * person.fte : person.fullFTEsalary;
        }

        const extraDaysSalary = parseInt(person?.extraDays*fullFTESalary_district/(updatedBenefitRates?.contractDays || 1) || 0) * (person.fte || 1);
        const districtPaidContract = (districtPaidSalary || 0) + parseFloat(person.extendedContract || 0) + extraDaysSalary;
        const effectiveContract = (effectiveSalary || 0) + parseFloat(person.extendedContract || 0) + extraDaysSalary;

        let insuranceBenefits = 0
        // Don't calculate health insurance benefits if FTE is zero. Most commonly for stipends.
        if(person.fte>0){
          Object.keys(person.benefits).forEach(benefit => insuranceBenefits += person.benefits[benefit] > 0 ? updatedBenefitRates[benefit] * 12 * (person.fte || 1) : 0)
        }

        const medicare = districtPaidContract * updatedBenefitRates.medicareRate;
        const blsg_medicare_amount = ((effectiveSalary - districtPaidSalary) || 0) * (updatedBenefitRates.medicareRate || 0);
        const medicare_total = medicare + blsg_medicare_amount;

        let retirement = 0; let retirement_blsg = 0; let fica = 0; let fica_total = 0; let blsg_fica_amount = 0;
        if(person.employeeType==='classified'){
          retirement = (districtPaidContract + insuranceBenefits) * updatedBenefitRates.peersRate;
          retirement_blsg = ((effectiveSalary - districtPaidSalary) || 0) * updatedBenefitRates.peersRate;
          fica = districtPaidContract * updatedBenefitRates.socialSecurityRate;
          blsg_fica_amount =  ((effectiveSalary - districtPaidSalary) || 0) * (updatedBenefitRates.socialSecurityRate || 0 );
          fica_total = fica + blsg_fica_amount;
        } else{
          retirement = (districtPaidContract + insuranceBenefits) * updatedBenefitRates.certifiedRetirement;
          retirement_blsg = ((effectiveSalary - districtPaidSalary) || 0) * updatedBenefitRates.certifiedRetirement;
        }
        const retirement_total = retirement + retirement_blsg;
        
        const totalCost = effectiveContract + insuranceBenefits + medicare_total + retirement_total + fica_total;

        if(effectiveSalary===0 && insuranceBenefits>0){
          console.log('!!WARNING!! Role has insurance benefits but no salary')
          console.log('person: ', person)
        }

        staffData[fc][bldg][staffType]['staff'][person_id]['salary'] =  effectiveSalary || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['fullFTEsalary'] =  fullFTESalary || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['fullFTEsalary_district'] =  fullFTESalary_district || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['extraDaysSalary'] =  extraDaysSalary || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['salaryDistrict'] =  districtPaidSalary || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['salaryStateGrant'] =  (effectiveSalary - districtPaidSalary) || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['totalCost'] = totalCost || 0; 
        staffData[fc][bldg][staffType]['staff'][person_id]['retirement'] = retirement_total || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['retirementDistrict'] = retirement || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['retirementStateGrant'] = retirement_blsg || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['insuranceBenefits'] = insuranceBenefits || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['medicare'] = medicare_total || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['medicareDistrict'] = medicare || 0;
        staffData[fc][bldg][staffType]['staff'][person_id]['medicareStateGrant'] = blsg_medicare_amount || 0;

        staffData[fc][bldg][staffType]['totals']['salary'] += effectiveSalary || 0;
        staffData[fc][bldg][staffType]['totals']['totalCost'] += totalCost || 0;
        staffData[fc][bldg][staffType]['totals']['retirement'] += retirement_total || 0;
        staffData[fc][bldg][staffType]['totals']['insuranceBenefits'] += insuranceBenefits || 0;
        staffData[fc][bldg][staffType]['totals']['medicare'] += medicare_total || 0;
        staffData[fc][bldg][staffType]['totals']['fte'] += (person.fte || 1);

        lineItems['expense'][salary_line_item] += districtPaidContract || 0;
        lineItems['expense'][retirement_line_item] += retirement || 0;
        lineItems['expense'][insurance_line_item] += insuranceBenefits || 0;
        lineItems['expense'][medicare_line_item] += medicare || 0;
        lineItems['expense'][fica_line_item] += fica || 0;
        
        lineItems['expense'][blsg_salary_line_item] += (effectiveSalary - districtPaidSalary) || 0;
        lineItems['expense'][blsg_retirement_line_item] += retirement_blsg || 0;
        lineItems['expense'][blsg_medicare_line_item] += blsg_medicare_amount || 0;
        lineItems['expense'][blsg_fica_line_item] += blsg_fica_amount || 0;
        
      })
    // Update classified staff
    } else {
      if (!staffData[fc]) staffData[fc] = {};
      if (!staffData[fc][bldg]) staffData[fc][bldg] = {};
      if (!staffData[fc][bldg][staffType]) staffData[fc][bldg][staffType] = {};
      if (!staffData[fc][bldg][staffType]['totals']) staffData[fc][bldg][staffType]['totals'] = {};

      staffData[fc][bldg]['classified']['totals']['salary'] = 0;
      staffData[fc][bldg]['classified']['totals']['totalCost'] = 0;
      staffData[fc][bldg]['classified']['totals']['retirement'] = 0;
      staffData[fc][bldg]['classified']['totals']['insuranceBenefits'] = 0;
      staffData[fc][bldg]['classified']['totals']['medicare'] = 0;
      staffData[fc][bldg]['classified']['totals']['fte'] = 0;
      staffData[fc][bldg]['classified']['totals']['fica'] = 0;

      people = Object.keys(staffData[fc][bldg]['classified']['staff'] || {});
      people.forEach(person_id => {
          
        const person = staffData[fc][bldg]['classified']['staff'][person_id];

        const salary_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                      function: person.function, 
                                                                      object: person.object,
                                                                      location: person.location,
                                                                      program: person.program,
                                                                      source: person.source,
                                                                      project: person.project})

        const retirement_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                          function: person.function, 
                                                                          object: determineClassifiedObjectCode("retirement", parseInt(fc)),
                                                                          location: person.location,
                                                                          program: person.program,
                                                                          source: person.source,
                                                                          project: person.project})
        
        const medicare_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                        function: person.function, 
                                                                        object: determineClassifiedObjectCode("medicare", parseInt(fc)),
                                                                        location: person.location,
                                                                        program: person.program,
                                                                        source: person.source,
                                                                        project: person.project})

        const insurance_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                          function: person.function, 
                                                                          object: determineClassifiedObjectCode("insurance", parseInt(fc)),
                                                                          location: person.location,
                                                                          program: person.program,
                                                                          source: person.source,
                                                                          project: person.project})
    
        const fica_line_item = buildAccountCode(accountCodeSchema['budget'], {fund: person.fund, 
                                                                    function: person.function, 
                                                                    object: determineClassifiedObjectCode("fica", parseInt(fc)),
                                                                    location: person.location,
                                                                    program: person.program,
                                                                    source: person.source,
                                                                    project: person.project})

        lineItems['expense'][salary_line_item] = lineItems['expense'][salary_line_item] ?? 0;
        lineItems['expense'][retirement_line_item] = lineItems['expense'][retirement_line_item] ?? 0;
        lineItems['expense'][medicare_line_item] = lineItems['expense'][medicare_line_item] ?? 0;
        lineItems['expense'][insurance_line_item] = lineItems['expense'][insurance_line_item] ?? 0;
        lineItems['expense'][fica_line_item] = lineItems['expense'][fica_line_item] ?? 0;
        
        // if the salary was manually entered, don't use the salary schedule
        let effectiveSalary; let hourlyRate;

        if(availableScheduleNames.includes(person.scheduleToUse) && (person.lane!=='' && person.step!=='' && person.lane<=classifiedSalarySchedules[person.scheduleToUse]['careerLanes'].length)){
          const lane_names = classifiedSalarySchedules[person.scheduleToUse]['careerLanes'].map(item => item.name)
          let num_steps = classifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]].length;
          if(person.step>num_steps){
            hourlyRate = classifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][num_steps-1]['hourlyRate'];
          } else {
            hourlyRate = classifiedSalarySchedules[person.scheduleToUse]['salarySchedule'][lane_names[person.lane-1]][person.step-1]['hourlyRate'];
          }
          effectiveSalary = hourlyRate * person.totalHours * (person.fte || 1); // Calculate effective salary
        } else {
          // If hourlyRate is undefined, calculate it
          if(!person.hourlyRate){
            hourlyRate = person.totalContract / (parseInt(person.totalHours) || 1)
            effectiveSalary = hourlyRate * person.totalHours * (person.fte || 1);
          } else{
            hourlyRate = parseFloat(person.hourlyRate);
            effectiveSalary = hourlyRate * person.totalHours * (person.fte || 1);
          }
        }
        
        const extraDaysSalary = (person.fte || 1) * parseFloat(person.extraDays*((person.totalHours || 0) * hourlyRate)/updatedBenefitRates.contractDays || 0)
        const salary = effectiveSalary + (extraDaysSalary || 0) + (person.extendedContract || 0);

        let insuranceBenefits = 0
        // Don't calculate health insurance benefits if FTE is zero. Most commonly for stipends.
        if(person.fte>0){
          Object.keys(person.benefits).forEach(benefit => insuranceBenefits += (person.benefits[benefit]>0? updatedBenefitRates[benefit] * 12 * (person.fte || 1) : 0))
        }

        const medicare = salary * updatedBenefitRates.medicareRate;
        let retirement = 0; let fica = 0;
        if(person.employeeType==='classified'){
          retirement = (salary + insuranceBenefits) * updatedBenefitRates.peersRate;
          fica = salary * updatedBenefitRates.socialSecurityRate;
        } else{
          retirement = (salary + insuranceBenefits) * updatedBenefitRates.certifiedRetirement;
        }

        const totalCost = salary + insuranceBenefits + medicare + retirement + fica;

        staffData[fc][bldg]['classified']['staff'][person_id]['salary'] =  effectiveSalary || 0;
        staffData[fc][bldg]['classified']['staff'][person_id]['extraDaysSalary'] =  extraDaysSalary || 0;
        staffData[fc][bldg]['classified']['staff'][person_id]['hourlyRate'] =  hourlyRate || 0;
        staffData[fc][bldg]['classified']['staff'][person_id]['totalCost'] = totalCost || 0;
        staffData[fc][bldg]['classified']['staff'][person_id]['retirement'] = retirement || 0;
        staffData[fc][bldg]['classified']['staff'][person_id]['insuranceBenefits'] = insuranceBenefits || 0;
        staffData[fc][bldg]['classified']['staff'][person_id]['medicare'] = medicare || 0;
        staffData[fc][bldg]['classified']['staff'][person_id]['fica'] = fica || 0;

        staffData[fc][bldg]['classified']['totals']['salary'] += effectiveSalary || 0;
        staffData[fc][bldg]['classified']['totals']['totalCost'] += totalCost || 0;
        staffData[fc][bldg]['classified']['totals']['retirement'] += retirement || 0;
        staffData[fc][bldg]['classified']['totals']['insuranceBenefits'] += insuranceBenefits || 0;
        staffData[fc][bldg]['classified']['totals']['medicare'] += medicare || 0;
        staffData[fc][bldg]['classified']['totals']['fte'] += (person.fte || 1);
        staffData[fc][bldg]['classified']['totals']['fica'] += fica || 0;

        lineItems['expense'][salary_line_item] += effectiveSalary || 0;
        lineItems['expense'][retirement_line_item] += retirement || 0;
        lineItems['expense'][insurance_line_item] += insuranceBenefits || 0;
        lineItems['expense'][medicare_line_item] += medicare || 0;
        lineItems['expense'][fica_line_item] += fica || 0;
      })
    }
  })
    

  console.log('lineItems: ', lineItems)
  Object.keys(lineItems).forEach(expense_revenue => {
    Object.keys(lineItems[expense_revenue]).forEach(acct_code => {
      if(acct_code.split('-').slice(4,5)!=='undefined'){
          const budgetRef = ref(database, `budget/${districtCode}/${fiscalYear}/${expense_revenue}/${acct_code}/budget/${version}`);
          update(budgetRef, {budget: lineItems[expense_revenue][acct_code], 
                              fund: getAccountItem(accountCodeSchema['budget'], acct_code, 'fund'),
                              function: getAccountItem(accountCodeSchema['budget'], acct_code, 'function'),
                              object: getAccountItem(accountCodeSchema['budget'], acct_code, 'object'),
                              location: getAccountItem(accountCodeSchema['budget'], acct_code, 'location') || '0000',
                              program: getAccountItem(accountCodeSchema['budget'], acct_code, 'program') || '000',
                              source: getAccountItem(accountCodeSchema['budget'], acct_code, 'source') || '0',
                              project: getAccountItem(accountCodeSchema['budget'], acct_code, 'project') || '00000',
                              editable: false}
                ).then()
      }
    })
  })
  
  await set(ref(database, `${dataPaths['staffPath']}`), staffData);
}


export default updateStaff; 