import { create } from 'zustand';

const useAppStore = create((set) => ({
  // Initial state
  selectedDistrict: localStorage.getItem('selectedDistrict') || 'Warsaw R-IX',
  selectedPeerGroup: 'Default',
  boardView: false,
  
  // Actions
  setSelectedDistrict: (district) => {
    localStorage.setItem('selectedDistrict', district);
    set({ selectedDistrict: district });
  },
  
  setSelectedPeerGroup: (peerGroup) => set({ selectedPeerGroup: peerGroup }),
  
  setBoardView: (view) => set({ boardView: view }),
}));

export default useAppStore; 