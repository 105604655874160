import React, { useState, useEffect } from "react";
import NestedStaffManagementTable from "./NestedStaffManagementTable";
import "../../styles/StaffManagement.css";
import { database } from '../../firebaseConfig';
import { ref, update, get} from "firebase/database";
import updateStaff from '../Utils/UpdateStaff';

const StaffManagementTable = ({ selectedDistrictInfo, fiscalYear, version }) => {

  const districtCode = selectedDistrictInfo["DISTRICT_COUNTY_CODE"];
  const [benefitRates, setBenefitRates] = useState({});
  const [selectedBenefitKey, setSelectedBenefitKey] = useState('');
  const [accountCodeSchema, setAccountCodeSchema] = useState({});
  const [salarySchedules, setSalarySchedules] = useState({});
  const [dataPaths, setDataPaths] = useState({});
  const [refreshStaffTable, setRefreshStaffTable] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(true);

  const benefitRatesPath = `benefitRates/${districtCode}/${fiscalYear}/${version}`;

  useEffect(() => {
    async function fetchBenefitRates() {
      try {
        const benefitRatesSnapshot = await get(ref(database, benefitRatesPath));
        const benefitRatesFB = benefitRatesSnapshot.val()

        if (benefitRatesFB) {
          setBenefitRates(benefitRatesFB)
          setSelectedBenefitKey(Object.keys(benefitRatesFB).filter((key) => !['certifiedRetirement', 'medicareRate', 'peersRate', 'socialSecurityRate', 'contractDays'].includes(key))[0])
        } else {
          console.warn('No benefit rates found for the given parameters.');
        }
      } catch (error) {
        console.error('Error fetching benefit rates:', error);
      }
    }

    fetchBenefitRates();

    async function fetchAccountCodeSchema() {
      const accountCodeSchemaPromise = await get(ref(database, `accountCodeSchema`))
      setAccountCodeSchema(accountCodeSchemaPromise.val()[districtCode])
    }

    fetchAccountCodeSchema()

    async function fetchSalarySchedules() {
      const salarySchedulePromise = await get(ref(database, `salarySchedules/${districtCode}/${fiscalYear}/${version}`))
      console.log(salarySchedulePromise.val())
      setSalarySchedules(salarySchedulePromise.val())
    }

    fetchSalarySchedules()

    setDataPaths({'staffPath' : `staff/${districtCode}/${fiscalYear}/${version}`, 
                  'benefitsPath' : `benefitRates/${districtCode}/${fiscalYear}/${version}`
                })

    if(salarySchedules && accountCodeSchema && benefitRates){
      setDataIsLoading(false);
    }

  }, [database, districtCode, fiscalYear, version]);

  function saveBenefitsNoAlert(benefitRates) {
    const benefitsRef = ref(database, benefitRatesPath);
    const parsedBenefits = Object.fromEntries(
      Object.entries(benefitRates).map(([key, value]) => [key, isNaN(parseFloat(value)) ? 0 : parseFloat(value)])
    );

    update(benefitsRef, parsedBenefits).then(() => {console.log('benefitRates saved')})

  }

  async function saveBenefitsValues() {
    const benefitsRef = ref(database, benefitRatesPath);
  
    // Parse all values as floats before saving
    const parsedBenefits = Object.fromEntries(
      Object.entries(benefitRates).map(([key, value]) => [key, isNaN(parseFloat(value)) ? 0 : parseFloat(value)])
    );
  
    update(benefitsRef, parsedBenefits).then(() => {
      updateStaff(salarySchedules['certified'], salarySchedules['classified'], benefitRates, dataPaths, districtCode, fiscalYear, version).then(() => {
        alert("Benefits saved successfully!");
        setRefreshStaffTable(!refreshStaffTable);
      })
    });

  }

  const handleBenefitKeyChange = (event) => {
    setSelectedBenefitKey(event.target.value);
  };

  const handleBenefitValueChange = (event) => {
    setBenefitRates({ ...benefitRates, [selectedBenefitKey]: event.target.value});
  };
 
  return (
    
    <div className="staff-management-container">
    <h1>Staff Management</h1>
    {dataIsLoading ? <h2></h2> :
      <div className="benefits-inputs-container">
        <h3>Benefits</h3>
    
        <label className="benefits-input-label">
          Select Benefit:
          <select
            value={selectedBenefitKey}
            onChange={handleBenefitKeyChange}
            className="benefits-dropdown"
          >
            {Object.keys(benefitRates)
              .filter((key) => !['certifiedRetirement', 'medicareRate', 'peersRate', 'socialSecurityRate', 'contractDays'].includes(key)) // Exclude these keys
              .map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
          </select>
        </label>
    
        <label className="benefits-input-label">
          Cost per month ($):
          <input
            className="benefits-value-input"
            type="text"
            value={benefitRates[selectedBenefitKey]}
            onChange={handleBenefitValueChange}
          />
        </label>
    
        <button className="save-button" onClick={saveBenefitsValues}>Save</button>
        
      </div>
      }
      {dataIsLoading ? <p>Loading data...</p> : 
      Object.keys(benefitRates).length > 0 && (
        <NestedStaffManagementTable
          fiscalYear={fiscalYear}
          districtCode={districtCode}
          version={version}
          benefitRates={benefitRates}
          setBenefitRates={setBenefitRates}
          saveBenefits={saveBenefitsNoAlert}
          accountCodeSchema={accountCodeSchema}
          refreshTable={refreshStaffTable}
        />
      )}
    </div>
          )}

export default StaffManagementTable;
