export const FilterButton = ({ filtersApplied, setFilterModalOpen, clearFilters }) => {  

  return (
    <div className="filter-container">
      <button 
        onClick={() => setFilterModalOpen(true)} 
        className={filtersApplied ? "filter-button active" : "filter-button"}
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="16" 
          height="16" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke={filtersApplied ? "red" : "currentColor"} 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        Filter
      </button>

      {/* Clear Filters Button */}
      {filtersApplied && (
        <button 
          onClick={clearFilters} 
          className="clear-filters-button"
          title="Clear Filters"
        >
          ✕
        </button>
      )}
    </div>
  );
};
