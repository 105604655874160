import {useState} from 'react';

export const PropCDistribution = ({stateRev, stateRevDist, setStateRevDist, handleInputChange, setStateRevModalOpen}) => {
  const [hovered, setHovered] = useState(false);
  let share_general_fund; let share_special_revenue;

  const formatCurrency = (value) => 
    value.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });

  if(Object.keys(stateRev).length>0){
    share_general_fund = parseFloat(stateRev.propCamount.replace(/[^0-9.]/g, ''))*parseFloat(stateRevDist.PROPC_GENERAL_FUND || 0)/100;
    share_special_revenue = parseFloat(stateRev.propCamount.replace(/[^0-9.]/g, ''))*parseFloat(stateRevDist.PROPC_SPECIAL_REVENUE || 0)/100;
  }

  return (
    <div className="propc-container">
      <div className="header-with-tooltip">
        <h3>Distribution of 5113 <br/> School District Trust Fund <br/> (Proposition C) (%)</h3>
        <div className="tooltip-container">
          <span
            className="revenue-info-icon"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            ?
          </span>
          <div className={`revenue-tooltip ${hovered ? 'visible' : ''}`}>
            {"Per 2025 DESE Accounting Manual Section E.14: No less than 75 percent of one-half of the funds received shall be placed in the Special Revenue (Teachers)\
            Fund and the remaining percent placed in the General (Incidental) Fund"}
          </div>
        </div>
      </div>

      <div className="two-fund-grid-container">
        <label>General Fund</label>
        <input
          type="text"
          value={stateRevDist.PROPC_GENERAL_FUND}
          onChange={(e) => handleInputChange(e, setStateRevDist, 'PROPC_GENERAL_FUND', 'stateRevenueDist')}
        />
        <span>{formatCurrency(share_general_fund || 0)}</span>
        <label>Special Revenue</label>
        <input
          style={{ 
            fontWeight: parseFloat(stateRevDist.PROPC_SPECIAL_REVENUE) < 37.5 ? 'bold' : 'normal',
            color: parseFloat(stateRevDist.PROPC_SPECIAL_REVENUE) < 37.5 ? 'red' : 'inherit'
          }}
          type="text"
          value={stateRevDist.PROPC_SPECIAL_REVENUE}
          onChange={(e) => handleInputChange(e, setStateRevDist, 'PROPC_SPECIAL_REVENUE', 'stateRevenueDist')}
        />
        <span>{formatCurrency(share_special_revenue || 0)}</span>
        <label>Total</label>
        <span className='total-span' style={{ 
              fontWeight: (parseFloat(stateRevDist.PROPC_GENERAL_FUND) + 
                          parseFloat(stateRevDist.PROPC_SPECIAL_REVENUE)) !== 100 ? 'bold' : 'normal',
              color: (parseFloat(stateRevDist.PROPC_GENERAL_FUND) + 
                      parseFloat(stateRevDist.PROPC_SPECIAL_REVENUE)) !== 100 ? 'red' : 'inherit'
            }}>
              {parseFloat(stateRevDist.PROPC_GENERAL_FUND || 0) + 
              parseFloat(stateRevDist.PROPC_SPECIAL_REVENUE || 0)}
        </span>
        <span>{formatCurrency(share_general_fund + share_special_revenue)}</span>
      </div>
    </div>
  )

}