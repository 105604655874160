export const StateRevenueCalculatorK8 = ({setPendingSaves, stateRev, setStateRev, handleInputChange, waitingForAPI, baselineSalaryGrantAmount, setStateRevModalOpen}) => {

  const formatCurrency = (value) => 
    value.toLocaleString('en-US', { style: 'currency', 
                                    currency: 'USD', 
                                    minimumFractionDigits: 0, 
                                    maximumFractionDigits: 0 });

  return (
    <div className="state-revenue-container">
          <div className="state-revenue-header">
            <h2>State Revenue Calculator</h2>
            <div className="state-revenue-header-buttons">
              <button 
                className="save-state-revenue-button" 
                onClick={() => {
                  setPendingSaves(prev => ({ ...prev, stateRevenue: true }));
                  alert('Revenue data saved successfully'); // Simple notification
                }}
              >
                Save
              </button>
            </div>
          </div>
          <table className="state-revenue-table">
            <thead>
              <tr>
                <th style={{ width: "300px" }}></th>
                <th>2022-2023</th>
                <th>2023-2024</th>
                <th>2024-2025</th>
                <th>2025-2026</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Regular Year ADA (K-8)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearADA_curryear_m2_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear_m2_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearADA_curryear_m1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear_m1_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearADA_curryear_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                <input
                    type="number"
                    value={stateRev.regYearADA_curryear_p1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear_p1_K8', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Regular Year ADA (9-12)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearADA_curryear_m2_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear_m2_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearADA_curryear_m1_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear_m1_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearADA_curryear_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearADA_curryear_p1_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear_p1_912', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Summer School ADA (K-8)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_m2_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_m2_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_m1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_m1_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_p1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_p1_K8', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Summer School ADA (9-12)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_m2_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_m2_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_m1_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_m1_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_p1_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_p1_912', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Regular Year PK ADA</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearPKADA_curryear_m2_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearPKADA_curryear_m2_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearPKADA_curryear_m1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearPKADA_curryear_m1_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearPKADA_curryear_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearPKADA_curryear_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearPKADA_curryear_p1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearPKADA_curryear_p1_K8', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>September Membership (K-8)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear_m2_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear_m2_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear_m1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear_m1_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear_p1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear_p1_K8', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>September Membership (9-12)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear_m2_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear_m2_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear_m1_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear_m1_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear_p1_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear_p1_912', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>September Membership (PreK)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberPreK_curryear_m2_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberPreK_curryear_m2_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberPreK_curryear_m1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberPreK_curryear_m1_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberPreK_curryear_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberPreK_curryear_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberPreK_curryear_p1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberPreK_curryear_p1_K8', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>January Membership (K-8)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear_m2_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear_m2_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear_m1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear_m1_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear_p1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear_p1_K8', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>January Membership (9-12)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear_m2_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear_m2_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear_m1_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear_m1_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear_p1_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear_p1_912', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>January Membership (PreK)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMemPreK_curryear_m2_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMemPreK_curryear_m2_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMemPreK_curryear_m1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMemPreK_curryear_m1_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMemPreK_curryear_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMemPreK_curryear_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMemPreK_curryear_p1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMemPreK_curryear_p1_K8', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Summer School Membership (K-8)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear_m2_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear_m2_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear_m1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear_m1_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear_K8', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear_p1_K8}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear_p1_K8', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Summer School Membership (9-12)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear_m2_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear_m2_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear_m1_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear_m1_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear_912', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear_p1_912}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear_p1_912', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>F&RL State FTE January Count</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.FRL_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'FRL_curryear_m2', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.FRL_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'FRL_curryear_m1', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.FRL_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'FRL_curryear', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.FRL_curryear_p1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'FRL_curryear_p1', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Special Education December Count</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.specEdDec_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'specEdDec_curryear_m2', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.specEdDec_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'specEdDec_curryear_m1', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.specEdDec_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'specEdDec_curryear', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.specEdDec_curryear_p1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'specEdDec_curryear_p1', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>LEP October Count</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.LEPOctober_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'LEPOctober_curryear_m2', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.LEPOctober_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'LEPOctober_curryear_m1', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.LEPOctober_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'LEPOctober_curryear', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.LEPOctober_curryear_p1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'LEPOctober_curryear_p1', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Assessed Valuation Dec 31</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.assessedValue_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'assessedValue_curryear_m2', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.assessedValue_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'assessedValue_curryear_m1', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.assessedValue_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'assessedValue_curryear', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.assessedValue_curryear_p1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'assessedValue_curryear_p1', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Fines, Escheats, and Overplus</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.finesOverplus_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'finesOverplus_curryear_m2', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.finesOverplus_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'finesOverplus_curryear_m1', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.finesOverplus_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'finesOverplus_curryear', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.finesOverplus_curryear_p1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'finesOverplus_curryear_p1', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>State Adequacy Target</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.stateAdeq_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'stateAdeq_curryear_m2', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.stateAdeq_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'stateAdeq_curryear_m1', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.stateAdeq_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'stateAdeq_curryear', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.stateAdeq_curryear_p1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'stateAdeq_curryear_p1', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Amount per ADA Classroom Trust Fund</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.amountADATrust_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'amountADATrust_curryear_m2', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.amountADATrust_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'amountADATrust_curryear_m1', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.amountADATrust_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'amountADATrust_curryear', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.amountADATrust_curryear_p1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'amountADATrust_curryear_p1', 'stateRevenue')}
                  />
                </td>
              </tr>
              <tr>
                <td>Amount per Combined WAM & WADA Prop C</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.WAMWADAPropC_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'WAMWADAPropC_curryear_m2', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.WAMWADAPropC_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'WAMWADAPropC_curryear_m1', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.WAMWADAPropC_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'WAMWADAPropC_curryear', 'stateRevenue')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.WAMWADAPropC_curryear_p1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'WAMWADAPropC_curryear_p1', 'stateRevenue')}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {waitingForAPI ? (
            <div className="total-local-revenue">
              <p>Getting DESE data...</p>
            </div>
          ) : (
            
            <table className="state-revenue-outputs-table">
              {console.log('stateRev: ', stateRev)}
              <thead>
                <th style={{ width: "300px" }}></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td>Formula Calculation WAM/WADA</td>
                  <td></td>
                  <td></td>
                  <td>{stateRev.formCalcWAMWADA_thisyear}</td>
                  <td>{stateRev.formCalcWAMWADA}</td>
                </tr>
                <tr>
                  <td>Classroom Trust Total</td>
                  <td></td>
                  <td></td>
                  <td>{stateRev.classroomTrust_thisyear}</td>
                  <td style={{
                    position: 'relative', 
                    textAlign: 'center'
                  }}>{stateRev.classroomTrust}
                  <button 
                    className="revenue-vertical-dot-button" 
                    onClick={() => {
                      setStateRevModalOpen('5319 - Classroom Trust')
                    }}>  ⋮
                  </button>
                  </td>
                </tr>
                <tr>
                  <td>Basic Formula Total</td>
                  <td></td>
                  <td></td>
                  <td>{stateRev.basicFormula_thisyear}</td>
                  <td style={{
                    position: 'relative', 
                    textAlign: 'center'
                  }}>{stateRev.basicFormula}
                  <button 
                    className="revenue-vertical-dot-button" 
                    onClick={() => {
                      setStateRevModalOpen('5311 - Basic Formula')
                    }}>  ⋮
                  </button>
                  </td>
                </tr>
                <tr>
                  <td>Prop C Total</td>
                  <td></td>
                  <td></td>
                  <td>{stateRev.propCamount_thisyear}</td>
                  <td style={{
                    position: 'relative', 
                    textAlign: 'center'
                  }}>{stateRev.propCamount}
                  <button 
                    className="revenue-vertical-dot-button" 
                    onClick={() => {
                      setStateRevModalOpen('5113 - Prop C')
                    }}>  ⋮
                  </button>
                  </td>
                </tr>
                <tr>
                  <td>Baseline Salary Grant</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{
                    position: 'relative', 
                    textAlign: 'center'
                  }}>{formatCurrency(baselineSalaryGrantAmount || 0)}
                  <button 
                    className="revenue-vertical-dot-button" 
                    onClick={() => {
                      setStateRevModalOpen('5341 - Baseline Salary Grant')
                    }}>  ⋮
                  </button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
)
}
