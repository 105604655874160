import {useState} from 'react';

export const ClassroomTrustDistribution = ({stateRev, stateRevDist, setStateRevDist, handleInputChange, setStateRevModalOpen}) => {
  const [hovered, setHovered] = useState(false);
  let share_general_fund; let share_special_revenue; let share_debt_service; let share_capital_projects;

  console.log('stateRevDist: ', stateRevDist)
  const formatCurrency = (value) => 
    value.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });

  if(Object.keys(stateRev).length>0){
    share_general_fund = parseFloat(stateRev.classroomTrust.replace(/[^\d]/g, ''))*parseFloat(stateRevDist.CTF_GENERAL_FUND || 0)/100;
    share_special_revenue = parseFloat(stateRev.classroomTrust.replace(/[^\d]/g, ''))*parseFloat(stateRevDist.CTF_SPECIAL_REVENUE || 0)/100;
    share_debt_service = parseFloat(stateRev.classroomTrust.replace(/[^\d]/g, ''))*parseFloat(stateRevDist.CTF_DEBT_SERVICE || 0)/100;
    share_capital_projects = parseFloat(stateRev.classroomTrust.replace(/[^\d]/g, ''))*parseFloat(stateRevDist.CTF_CAPITAL_PROJECTS || 0)/100;
  }

  return (
    <div className="classroom-trust-container">
      <div className="header-with-tooltip">
        <h3>Distribution of 5319 <br /> Classroom Trust Fund (%)</h3>
        <div className="tooltip-container">
          <span
            className="revenue-info-icon"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            ?
          </span>
          <div className={`revenue-tooltip ${hovered ? 'visible' : ''}`}>
            {"Per 2025 DESE Accounting Manual Section E.21: Placed at the discretion of the local board except amounts received above 2009-2010 amounts shall only be \
            placed in General (Incidental) or Special Revenue (Teacher) fund per Section 163.043, RSMo"}
          </div>
        </div>
      </div>

      <div className="classroom-trust-grid-container">
        <label>General Fund</label>
        <input
          type="text"
          value={stateRevDist.CTF_GENERAL_FUND}
          onChange={(e) => handleInputChange(e, setStateRevDist, 'CTF_GENERAL_FUND', 'stateRevenueDist')}
        />
        <span>{formatCurrency(share_general_fund || 0)}</span>
        <label>Special Revenue</label>
        <input
          type="text"
          value={stateRevDist.CTF_SPECIAL_REVENUE}
          onChange={(e) => handleInputChange(e, setStateRevDist, 'CTF_SPECIAL_REVENUE', 'stateRevenueDist')}
        />
        <span>{formatCurrency(share_special_revenue || 0)}</span>
        <label>Debt Service</label>
        <input
          type="text"
          value={stateRevDist.CTF_DEBT_SERVICE}
          onChange={(e) => handleInputChange(e, setStateRevDist, 'CTF_DEBT_SERVICE', 'stateRevenueDist')}
        />
        <span>{formatCurrency(share_debt_service || 0)}</span>
        <label>Capital Projects</label>
        <input
          type="text"
          value={stateRevDist.CTF_CAPITAL_PROJECTS}
          onChange={(e) => handleInputChange(e, setStateRevDist, 'CTF_CAPITAL_PROJECTS', 'stateRevenueDist')}
        />
        <span>{formatCurrency(share_capital_projects || 0)}</span>
        <label>Total</label>
        <span className='total-span' style={{ 
              fontWeight: (parseFloat(stateRevDist.CTF_GENERAL_FUND) + 
                          parseFloat(stateRevDist.CTF_SPECIAL_REVENUE) + 
                          parseFloat(stateRevDist.CTF_DEBT_SERVICE) + 
                          parseFloat(stateRevDist.CTF_CAPITAL_PROJECTS)) !== 100 ? 'bold' : 'normal',
              color: (parseFloat(stateRevDist.CTF_GENERAL_FUND) + 
                      parseFloat(stateRevDist.CTF_SPECIAL_REVENUE) + 
                      parseFloat(stateRevDist.CTF_DEBT_SERVICE) + 
                      parseFloat(stateRevDist.CTF_CAPITAL_PROJECTS)) !== 100 ? 'red' : 'inherit'
            }}>
              {parseFloat(stateRevDist.CTF_GENERAL_FUND || 0)  + 
              parseFloat(stateRevDist.CTF_SPECIAL_REVENUE || 0) + 
              parseFloat(stateRevDist.CTF_DEBT_SERVICE || 0)  + 
              parseFloat(stateRevDist.CTF_CAPITAL_PROJECTS || 0)}
        </span>
        <span>{formatCurrency(share_general_fund + share_special_revenue + share_debt_service + share_capital_projects)}</span>
      </div>
    </div>
  )
}