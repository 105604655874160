
export const EmployeeDetailsModal = ({selectedRow, 
                                      setSelectedRow, 
                                      objectCodes, 
                                      availableSalarySchedules,
                                      saveDetails,
                                      setModalOpen}) => {

return (
  <div className="modal">
    <div className="modal-content">
      <h4>Edit Object Code & Fund</h4>
      <label>
        Project:
        <input
          type="text"
          value={selectedRow?.project}
          onChange={(e) =>
            setSelectedRow((prev) => ({
              ...prev,
              project: e.target.value,
            }))
          }
        />
      </label>
      <label>
        Program:
        <input
          type="text"
          value={selectedRow?.program}
          onChange={(e) =>
            setSelectedRow((prev) => ({
              ...prev,
              program: e.target.value,
            }))
          }
        />
      </label>
      <label>
        Source:
        <input
          type="number"
          value={selectedRow?.source}
          onChange={(e) =>
            setSelectedRow((prev) => ({
              ...prev,
              source: parseInt(e.target.value, 10),
            }))
          }
        />
      </label>
      <label>
        Object Code For Salary:
        <select
          value={selectedRow?.object}
          onChange={(e) =>
            setSelectedRow((prev) => ({
              ...prev,
              object: e.target.value,
            }))
          }
        >
          {Object.entries(objectCodes).map(([code, label]) => (
            <option key={code} value={code}>
              {code} - {label}
            </option>
          ))}
        </select>
      </label>
      <label>
        Fund For Total Compensation:
        <select
          value={selectedRow?.fund}
          onChange={(e) =>  setSelectedRow((prev) => ({
            ...prev,
            fund: e.target.value,
            }))}
        >
          <option value="10">GENERAL_FUND</option>
          <option value="20">SPECIAL_REVENUE</option>
          <option value="30">CAPITAL_FUND</option>
          <option value="40">DEBT_SERVICE_FUND</option>
        </select>
      </label>
      <label>
        Salary Schedule:
        <select
          value={selectedRow?.scheduleToUse}
          onChange={(e) => {
            setSelectedRow((prev) => ({
              ...prev,
              scheduleToUse: e.target.value,
            }));
          }
          }
        >
          {Object.keys(availableSalarySchedules || {}).map((schedule, index) => (
            <option key={index} value={schedule}>
              {schedule}
            </option>
          ))}
          <option key={999} value={"Manual Entry"}>
              {"Manual Entry"}
          </option>
        </select>
      </label>
      <label>
        Employee Type:
        <select
          value={selectedRow?.employeeType}
          onChange={(e) => {
            setSelectedRow((prev) => ({
              ...prev,
              employeeType: e.target.value,
            }));
          }
          }
        >
        <option key={0} value={"classified"}>
              {"Classified"}
        </option>
        <option key={1} value={"certified"}>
              {"Certified"}
        </option>
        </select>
      </label>
      <div className="modal-actions">
        <button onClick={async () => await saveDetails(selectedRow, ['project', 'source', 'program', 
                                                                     'objectCode', 'object', 'fund', 
                                                                     'scheduleToUse', 'employeeType'])}>Save</button>
        <button onClick={() => setModalOpen(false)}>Cancel</button>
      </div>
    </div>
  </div>
)

}