export const handleStepDownDollarBlur = (careerLanes, setCareerLanes, index, value) => {
    const updatedLanes = [...careerLanes];
    const stepDownDollars = updatedLanes[index].stepDownDollars;
    const newValue = parseFloat(value) || 0;

    // Find the first index where the value is zero (ignore position 0)
    const firstZeroIndex = stepDownDollars.slice(1, stepDownDollars.length).findIndex(num => num === 0);
    const fillLength = firstZeroIndex === -1 ? stepDownDollars.length : firstZeroIndex + 1;  // Add 1 here to include the first zeroed index

    // Create a new array with newValue up to fillLength and keep trailing zeros
    updatedLanes[index].stepDownDollars = [
        0, 
        ...Array(fillLength - 1).fill(newValue),  // We still fill from index 1 onward
        ...stepDownDollars.slice(fillLength)
    ];

    updatedLanes[index].defaultStepDownDollar = newValue;

    setCareerLanes(updatedLanes);
  };

export const handleStepDownDollarChange = (careerLanes, setCareerLanes, index, value) => {
  const updatedLanes = [...careerLanes];
  const stepDownDollars = updatedLanes[index].stepDownDollars;
  const newValue = value//parseFloat(value) || 0;

  // Find the first index where the value is zero (ignore position 0)
  const firstZeroIndex = stepDownDollars.slice(1, stepDownDollars.length).findIndex(num => num === 0);
  const fillLength = firstZeroIndex === -1 ? stepDownDollars.length : firstZeroIndex + 1;  // Add 1 here to include the first zeroed index

  // Create a new array with newValue up to fillLength and keep trailing zeros
  updatedLanes[index].stepDownDollars = [
      0, 
      ...Array(fillLength - 1).fill(newValue),  // We still fill from index 1 onward
      ...stepDownDollars.slice(fillLength)
  ];

  updatedLanes[index].defaultStepDownDollar = newValue;

  setCareerLanes(updatedLanes);
};

export const createSalaryTable = (setSalaryTable, 
                                  careerLanes, 
                                  stepsToDisplay, 
                                  displayMode,
                                  localAddOn, 
                                  showStateGrantView,
                                  stateAide,
                                  tableType) => {
  const tableData = [];
  const displayData = [];

  let row;
  if(tableType === 'certified'){
    // initialize row {'BS': 100, 'MS': 200, 'PhD': 300}
    row = careerLanes.reduce((acc, item, index, array) => {
      if (index === 0) {
        acc[item.name] = item.step1Value;
      } else {
        acc[item.name] = acc[array[index - 1].name] + parseFloat(item.stepAcross);
      }
      return acc;
    }, {});
  } else {
    row = careerLanes.reduce((acc, item) => {
      acc[item.name] = item.step1Value
      return acc;
    }, {});
  }

  // Creates an object like {'BS': [100, 200, 300], 'MS': [150, 250, 350]}
  let stepDownDollarAmounts = careerLanes.reduce((acc, item) => {
    acc[item.name] = item.stepDownDollars;
    return acc;
  }, {});

  let stepDownPercentAmounts = careerLanes.reduce((acc, item) => {
    acc[item.name] = item.stepDownPerc;
    return acc;
  }, {});

  tableData.push({...row}); // Clone the row object when adding to tableData

  // Build the rows of the table
  for (let step = 2; step <= stepsToDisplay; step++) {
    let newRow = {...row};
    
    careerLanes.forEach((lane) => {
      newRow[lane.name] += parseFloat(stepDownDollarAmounts[lane.name][step-1]) + (newRow[lane.name] * parseFloat(stepDownPercentAmounts[lane.name][step-1]) / 100);
    });
    
    row = newRow;
    tableData.push({...row});
  }

  // Adjust Display Data based on Display Mode
  for (let step = 1; step <= stepsToDisplay; step++) {
    let row =  {step, stepDown: careerLanes[0]['stepDownDollars'][step], escalator: careerLanes[0]['stepDownPerc'][step]};
    
    careerLanes.forEach((lane, laneIndex) => {
      let currentSalary = parseFloat((tableData[step - 1][lane.name] || '0'));
      
      let previousSalary = step > 1 
          ? parseFloat((tableData[step - 2][lane.name] || '0'))
          : 0;
      
      let previousLadderSalary = laneIndex > 0
          ? parseFloat((tableData[step - 1][careerLanes[laneIndex - 1].name] || '0'))
          : 0;

      if ((lane.name.charAt(0) !== 'B') && (laneIndex>0) && (step > 10)){
        const floor = 46000 - localAddOn

        if (showStateGrantView && currentSalary < floor) {
          currentSalary += ((floor - currentSalary)*(stateAide/100));
          previousSalary += ((floor - previousSalary)*(stateAide/100));
        }
        if (showStateGrantView && previousLadderSalary < floor) {
          previousLadderSalary += ((floor - previousLadderSalary)*(stateAide/100));
        }
      } else {
        const floor = 40000 - localAddOn

        if (showStateGrantView && currentSalary < floor) {
          currentSalary += ((floor - currentSalary)*(stateAide/100));
          previousSalary += ((floor - previousSalary)*(stateAide/100));
        }
        if (showStateGrantView && previousLadderSalary < floor) {
          previousLadderSalary += ((floor - previousLadderSalary)*(stateAide/100));
        }
        
      }

      switch (displayMode) {
        case 'Change by Years':
            row[lane.name] = step > 1 ? (currentSalary - previousSalary).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-';
            break;
        case 'Change by Level':
            row[lane.name] = laneIndex > 0 ? Math.round(currentSalary - previousLadderSalary).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-';
            break;
        case '% Change by Years':
            row[lane.name] = step > 1 ? ((currentSalary - previousSalary) / previousSalary * 100).toFixed(2) + '%' : '-';
            break;
        case '% Change by Level':
            row[lane.name] = laneIndex > 0 ? ((currentSalary - previousLadderSalary) / previousLadderSalary * 100).toFixed(2) + '%' : '-';
            break;
        default: // Salary
            if(currentSalary < 100){
              row[lane.name] = currentSalary.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 });
            } else{
              row[lane.name] = Math.round(currentSalary).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
            }
      }
    });
    displayData.push(row);
  }

  setSalaryTable(displayData);
}
