import React from 'react';
import '../../../styles/BudgetSummary.css';

const TransferCalculator = ({ isOpen, onClose, inputValues, onInputChange, onSubmit }) => {
  if (!isOpen) return null;

  return (
    <div className="sb287-modal-overlay">
      <div className="sb287-modal">
        <form>
          <div>
            <h3>SB 287 Transfer Calculator</h3>
            <label>WADA:</label>
            <input
              type="number"
              value={inputValues.WADA}
              onChange={(e) => onInputChange("WADA", e.target.value)}
            />
          </div>
          <div>
            <label>SAT:</label>
            <input
              type="number"
              value={inputValues.SAT}
              onChange={(e) => onInputChange("SAT", e.target.value)}
            />
          </div>
          <div>
            <label>Transfer Percent:</label>
            <input
              type="number"
              value={inputValues.transferPercent}
              onChange={(e) => onInputChange("transferPercent", e.target.value)}
              max="7"
            />
          </div>
          <button type="button" onClick={onSubmit}>
            Submit
          </button>
          <button type="button" onClick={onClose}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default TransferCalculator; 