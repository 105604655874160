import React, { useState, useEffect } from "react";
import { database } from "../../firebaseConfig";
import { ref, get, set, update } from "firebase/database";
import "../../styles/StaffManagement.css";
import {determineClassifiedObjectCode, updateSelectStaff} from "../Utils/UpdateStaff"
import {createNewRole, updateClassifiedRole, useDetailsAutoClose, updateBenefitCheckbox, subtractLineItemsFromBudget} from "../Utils/StaffTableUtils"
import { EmployeeDetailsModal } from "./EmployeeDetailsModal";

const formatDollars = (num) => `$${Math.round(num).toLocaleString('en-US')}`;

const OBJECT_CODES = {
  6151:	"Classified Salaries-Regular",
  6152:	"Instructional Aide Salaries",
  6153:	"Classified Substitute Salaries",
  6161:	"Classified Salaries - Part-Time",
  6171:	"Classified Employees Unused Leave and/or Severance Pay"
};

const ClassifiedEmployeeTable = ({
  districtCode,
  fiscalYear,
  version,
  benefitRates,
  buildingCode,
  functionCode,
  accountCodeSchema,
  onDataUpdate
}) => {

  const salarySchedulePath = `salarySchedules/${districtCode}/${fiscalYear}/${version}`;
  const staffPath = `staff/${districtCode}/${fiscalYear}/${version}`;
  const benefitsPath = `benefitRates/${districtCode}/${fiscalYear}/${version}`;
  const dataPaths = {'staffPath': staffPath, 'benefitsPath': benefitsPath}

  const [Rows, setRows] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [availableSalarySchedules, setAvailableSalarySchedules] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [certifiedSalarySchedules, setCertifiedSalarySchedules] = useState([]);
  const [notification, setNotification] = useState("");

  useDetailsAutoClose(); // This automatically closes the benefits dropdown when the user clicks outside of it

  function buildAccountCode(format, data) {
    return format.split('-') // Split the format string into an array
                 .map(key => data[key]) // Map each key to its corresponding value in the data object
                 .join('-'); // Join the values with '-'
  }

  const handleDeleteEmployee = async (row) => {
    const updatedRows = Rows.filter((r) => r.uuid !== row.uuid);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (!confirmDelete) return;

    // Remove the specific row from Firebase
    const rowRef = ref(
      database,
      `${staffPath}/${functionCode}/${buildingCode}/classified/staff/${row.uuid}`
    );
    await set(rowRef, null);

    subtractLineItemsFromBudget(row, determineClassifiedObjectCode, buildAccountCode, accountCodeSchema, districtCode, fiscalYear, version);

    // If no rows remain, remove the totals node
    if (updatedRows.length === 0) {
      const aggregateRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/classified/totals`
      );
      await set(aggregateRef, null);
        
    } else {
      // Recalculate totals
      const aggregated = updatedRows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.salary += row.salary || 0;
          acc.extendedContract += row.extendedContract || 0;
          acc.careerLadder += row.careerLadder || 0;
          acc.totalCost += row.totalCost || 0;
          acc.insuranceBenefits += row.insuranceBenefits || 0;
          acc.medicare += row.medicare;
          acc.retirement += row.retirement;
          return acc;
        },
        {
          fte: 0,
          salary: 0,
          extendedContract: 0,
          careerLadder: 0,
          totalCost: 0,
          insuranceBenefits: 0,
          medicare: 0,
          retirement: 0,
        }
      );

      const aggregateRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/classified/totals`
      );
      await set(aggregateRef, {
        ...aggregated,
        salaryWithExtendedContract: aggregated.salary + aggregated.extendedContract + aggregated.careerLadder,
      });
    }

    // Update local state
    setRows(updatedRows);
  }

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(""), 3000); // Clear the notification after 3 seconds
  };

  const openDetailsModal = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
  };
  
  const fetchBaseHourlyPay = async (scheduleToUse, lane, step) => {
    if (!lane || step === null || step === undefined) return 0;

    const lane_name = availableSalarySchedules[scheduleToUse]?.['careerLanes'][lane-1].name || 'None';
    const max_num_steps = availableSalarySchedules[scheduleToUse]?.['salarySchedule'][lane_name].length - 1;

    if(step<=max_num_steps){
      return Number(availableSalarySchedules[scheduleToUse]?.['salarySchedule'][lane_name][step-1]['hourlyRate']) || 0;
    } else {
      return Number(availableSalarySchedules[scheduleToUse]?.['salarySchedule'][lane_name][max_num_steps]['hourlyRate']) || 0;
    }
  };

  const saveDetails = async (selectedRow, modalFields) => {
  
    // Remove line items from budget in case object, project, program, etc have changed
    await subtractLineItemsFromBudget(Rows.filter(item=>item.uuid===selectedRow.uuid)[0], determineClassifiedObjectCode, buildAccountCode, accountCodeSchema, districtCode, fiscalYear, version)
    
    modalFields.forEach(item=> {
      if(selectedRow[item]){
        updateRow(selectedRow.uuid, item, selectedRow[item])
      }
    })

    setModalOpen(false);
  };

  useEffect(() => {
    if (Rows.some(row => row.hasChanged)) {
      saveDataToFirebase();
    }
  }, [Rows]);

  useEffect(() => {
  
    // Get available names of salary schedules for details drop down
    get(ref(database, `${salarySchedulePath}/`)).then(result=>{
      setAvailableSalarySchedules(result.val()['classified'])
      setCertifiedSalarySchedules(result.val()['certified'])
    });

  }, [districtCode, fiscalYear, salarySchedulePath]);

  const addRow = () => {
    const newRow = createNewRole();
    newRow.uuid = Date.now();
    const optionalBenefits = Object.keys(benefitRates).filter((key) => !['certifiedRetirement', 'medicareRate', 'peersRate', 'socialSecurityRate', 'contractDays'].includes(key));
    newRow['benefits'] = optionalBenefits.reduce((acc, key) => {acc[key] = 0; return acc;}, {});
    newRow['location'] = buildingCode;
    newRow['function'] = functionCode;
    newRow['fund'] = '10';
    newRow['object'] = '6151';
    newRow['source'] = '1';
    newRow['program'] = '000';
    newRow['project'] = '00000';
    newRow['employeeType'] = 'classified';
    setRows([...Rows, newRow])
  };

  const updateRow = async (uuid, key, value) => {
    let updatedRow = null;
    
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowIndex = updatedRows.findIndex((row) => row.uuid === uuid);

      let updatedValue;
      if (rowIndex !== -1) {
        const row = updatedRows[rowIndex];
        if(value===''){
          updatedValue = '';
        } else {
          updatedValue =
          key === "step" && value < 1
            ? 1
            : ["fte", "extendedContract", "careerLadder", "salary", "hourlyRate"].includes(key)
            ? !isNaN(value) ? parseFloat(value) : 0
            : value;
        }

        updatedRows[rowIndex] = { 
              ...row, 
              [key]: updatedValue,
              hasChanged: true,
              manualSalary: 
                key === "lane" 
                  ? value === "manualEntry" 
                  : row.manualSalary || false,
            };

        updatedRow = updatedRows[rowIndex];
      }

      return updatedRows;
    });

    console.log('updating row: ', updatedRow)
    // Use a timeout to wait for the state update
    setTimeout(async () => {
      if (updatedRow) {
        let hourlyRate;
     
        if(updatedRow.manualSalary || updatedRow.lane===0){
          hourlyRate = updatedRow.hourlyRate;
        } else {
          hourlyRate = await fetchBaseHourlyPay(updatedRow.scheduleToUse,
                                                updatedRow.lane,
                                                updatedRow.step)
        }
      
        updateClassifiedRole(uuid, hourlyRate, setRows, benefitRates);
      }
    }, 0);
  }

  const saveDataToFirebase = async () => {
      try {
        // 1. Save individual staff data
        const saveRowPromises = Rows.filter((row) => row.hasChanged).map((row) => {
          const rowRef = ref(
            database,
            `${staffPath}/${functionCode}/${buildingCode}/classified/staff/${row.uuid}`
          );
          return update(rowRef, row); // Use update instead of set for partial updates
        });
        await Promise.all(saveRowPromises);
        
        await updateSelectStaff(Rows, certifiedSalarySchedules, availableSalarySchedules, benefitRates, dataPaths, districtCode, fiscalYear, version);
        
        // Reset `hasChanged` for all rows
        setRows((prevRows) =>
          prevRows.map((row) => ({ ...row, hasChanged: false }))
        );
  
        showNotification("Saved");
      } catch (error) {
        showNotification("Error saving classified data: ", error);
        console.log('error: ', error)
      }
    };

  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        // Fetch individual staff rows
        const rowsRef = ref(
          database,
          `${staffPath}/${functionCode}/${buildingCode}/classified/staff`
        );
        const snapshot = await get(rowsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const rows = Object.values(data);
          setRows(rows);          
        }
      } catch (error) {
        console.error("Error loading staff data: ", error);
      }
    };
    setTimeout(() => {
      fetchStaffData();
    }, 0);
  }, [districtCode, fiscalYear, functionCode, buildingCode, staffPath]);
  
  useEffect(() => {
      const aggregated = Rows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.salary += row.salary || 0;
          acc.totalCost += row.totalCost || 0;
          return acc;
        },
        { fte: 0, salary: 0, totalCost: 0 }
      );
    
      onDataUpdate(aggregated); // Notify parent
    }, [Rows]); // Only re-run when `Rows` changes

  useEffect(() => {
      const aggregated = Rows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.salary += row.salary || 0;
          acc.totalCost += row.totalCost || 0;
          return acc;
        },
        { fte: 0, salary: 0, totalCost: 0 }
      );
    
      onDataUpdate(aggregated); // Notify parent
    }, [Rows]); // Only re-run when `Rows` changes

  useEffect(() => {
      if (isFirstRender && Rows.length > 0) {
        // Create a sorted copy (don't mutate the original)
        const sortedRows = [...Rows].sort((a, b) => 
          String(a.last).localeCompare(String(b.last))
        );
        
        // Replace your Rows state with the sorted version
        setRows(sortedRows);
        
        // Mark initial sort as complete
        setIsFirstRender(false);
      }
    }, [Rows, isFirstRender]);
  
  return (
    <div className="staff-table-container">
      {notification && (
      <div className="notification">
        {notification}
      </div>
    )}
      <h3>Classified Roles</h3>
      <table className="staff-management-table">
        <thead>
          <tr>
            <th>Last</th>
            <th>First</th>
            <th>FTE</th>
            <th>Benefits</th>
            <th>Position</th>
            <th>Lane</th>
            <th>Step</th>
            <th>Hourly Rate</th>
            <th>Hours</th>
            <th>Extra Days</th>
            <th>Extended Contract</th>
            <th>Salary</th>
            <th>Total Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Rows.map((row) => (
            <tr key={row.uuid}>
              <td>
                <input className="tableInput"
                  type="text"
                  value={row.last}
                  onChange={(e) => updateRow(row.uuid, "last", e.target.value)}
                />
              </td>
              <td>
                <input className="tableInput"
                  type="text"
                  value={row.first}
                  onChange={(e) => updateRow(row.uuid, "first", e.target.value)}
                />
              </td>
              <td>
                <input className="tableInput"
                  type="number"
                  value={row.fte}
                  onChange={(e) =>
                    updateRow(row.uuid, "fte", parseFloat(e.target.value))
                  }
                />
              </td>
              <td>
              <div className="dropdown-container">
                <details>
                  <summary>Show</summary>
                  <div className="dropdown-content">
                    {Object.entries(row.benefits).map(([name, status]) => (
                      <label key={name} className="dropdown-item">
                        <input
                          type="checkbox"
                          checked={!!status}
                          onChange={(e) => updateBenefitCheckbox(row.uuid, name, e.target.checked ? 1 : 0, updateClassifiedRole, setRows, benefitRates, 'classified')}
                        />{" "}
                        {name}
                      </label>
                    ))}
                  </div>
                </details>
              </div>
              </td>
              <td>
                <input
                  type="text"
                  value={row.position}
                  onChange={(e) => updateRow(row.uuid, "position", e.target.value)}
                />
              </td>
              <td>
              <select
                value={row.manualSalary ? 'manualEntry' : (availableSalarySchedules[row.scheduleToUse]?.['careerLanes'] || []).map(item => item.name)[row.lane-1]}
                className="tableInput"
                onChange={(e) => {
                  const options = (availableSalarySchedules[row.scheduleToUse]?.['careerLanes'] || []).map(item => item.name);
                  const selectedIndex = options.indexOf(e.target.value); // Get index of selected value
                  if(selectedIndex!==-1){
                    updateRow(row.uuid, "lane", selectedIndex + 1); // Adjust index to match `lane`
                  }else{
                    updateRow(row.uuid, "lane", "manualEntry")
                  }
                }}
              >
                <option value="">Select</option>
                
                {(availableSalarySchedules?.[row.scheduleToUse]?.['careerLanes'] || []).map(item => item.name).map((column) => (
                      <option key={column} value={column}>
                        {column}
                      </option>
                  ))}
                <option key={'manualEntry'} value={'manualEntry'}>{'Manual Entry'}</option>
              </select>
              </td>
              <td>
              {(row.manualSalary==='True' || row.manualSalary===true) ? ("") : (<input
                  type="number"
                  value={row.step}
                  onChange={(e) =>
                    updateRow(row.uuid, "step", parseInt(e.target.value, 10))
                  }
                />)}
              </td>
              <td>
              {row.scheduleToUse === 'Manual Entry' || row.lane === 0 ? (
                <input
                  className="tableInput"
                  type="number"
                  value={row.hourlyRate || ""}
                  onChange={(e) =>
                    updateRow(row.uuid, "hourlyRate", e.target.value)
                  }
                />
              ) : (
                row.hourlyRate.toFixed(2)
              )}
              </td>
              <td>
                <input className="tableInput"
                  type="number"
                  value={row.totalHours}
                  onChange={(e) =>
                    updateRow(row.uuid, "totalHours", e.target.value)
                  }
                />
              </td>
              <td>
                <input className="tableInput"
                  type="number"
                  value={row.extraDays}
                  onChange={(e) => {
                    updateRow(row.uuid, "extraDays", e.target.value)
                  }}
                />
              </td>
              <td>
                <input className="tableInput"
                  type="number"
                  value={row.extendedContract}
                  onChange={(e) =>
                    updateRow(row.uuid, "extendedContract", e.target.value)
                  }
                />
              </td>
              <td>{formatDollars(row.salary + (row.extraDaysSalary || 0) + (row.extendedContract || 0))}</td>
              <td>{formatDollars(row.totalCost)}</td>
              <td>
              <button
                onClick={async () => {
                  handleDeleteEmployee(row)
                }}
              >
                Delete
              </button>
              <button onClick={() => openDetailsModal(row)} className="details-btn" >Details</button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
    <tr>
      <td colSpan={2}>Totals:</td>
      <td>{Rows.reduce((acc, row) => acc + (row.fte || 0), 0)}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{Rows.reduce((acc, row) => acc + (row.hours || 0), 0)}</td>
      <td></td>
      <td>{formatDollars(Rows.reduce((acc, row) => acc + (row.extendedContract || 0), 0))}</td>
      <td>{formatDollars(Rows.reduce((acc, row) => acc + (row.salary || 0), 0))}</td>
      <td>{formatDollars(Rows.reduce((acc, row) => acc + (row.totalCost || 0), 0))}</td>
      <td></td>
    </tr>
  </tfoot>
      </table>
      {isModalOpen && <EmployeeDetailsModal selectedRow={selectedRow}
                                                  setSelectedRow={setSelectedRow} 
                                                  objectCodes={OBJECT_CODES}
                                                  availableSalarySchedules={availableSalarySchedules}
                                                  saveDetails={saveDetails}
                                                  setModalOpen={setModalOpen}/>}
      <button onClick={addRow} className ="add-row-btn" >Add Classified Role</button>

    </div>
  );
};

export default React.memo(ClassifiedEmployeeTable);

