import React, { useState, useEffect, useRef } from 'react';
import '../../styles/YearVersionSelect.css';
import { database } from '../../firebaseConfig';
import { ref, set, get, update } from "firebase/database";
import {getFirebaseData} from "../../utils.js"

const YearVersionSelect = ({
  selectedDistrictInfo,
  fiscalYear,
  setFiscalYear,
  availableFiscalYears,
  version,
  setVersion,
  availableVersions,
  boardApproved,
  setBoardApproved,
  refreshSelections
}) => {

  const [newVersionCreate, setNewVersionCreate] = useState(false);
  const [newYearCreate, setNewYearCreate] = useState(false);
  const [newVersionName, setNewVersionName] = useState('');
  const [newVersionBase, setNewVersionBase] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [versionIsSaving, setVersionIsSaving] = useState(false);
  const menuRef = useRef(null);

  const staffPath = `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  const getClassifiedObjectCode = (type, functionCode) => {
    const conditions = {
      salary: [
        { object: "6152", condition: [1111, 1131, 1151, 1191, 1221, 1281, 3512, 3812 ] },
        { object: "6161", condition: [2552, 1191] },
        { object: "6151"}, //default
      ],
      health: [
        { object: "6240", condition: [2222] },
        { object: "6241" }, // Default
      ],
      retirement: [{ object: "6221" }], // Default
      fica: [{ object: "6231"}],
      medicare: [{ object: "6232" }], // Default
    };

    const typeConditions = conditions[type];
    if (!typeConditions) return null;

    for (const { object, condition } of typeConditions) {
      if (!condition || condition.includes(functionCode)) {
        return object;
      }
    }

    return null;
  };

  const getCertifiedObjectCode = (type, functionCode) => {
    const conditions = {
      salary: [
        { object: "6112", condition: [2211, 2411] },
        { object: "6131", condition: [1411, 1421] },
        { object: "6111" }, // Default
      ],
      health: [
        { object: "6240", condition: [2222] },
        { object: "6241" }, // Default
      ],
      retirement: [{ object: "6211" }], // Default
      medicare: [{ object: "6232" }], // Default
    };

    const typeConditions = conditions[type];
    if (!typeConditions) return null;

    for (const { object, condition } of typeConditions) {
      if (!condition || condition.includes(functionCode)) {
        return object;
      }
    }

    return null;
  };

  async function updateClassifiedStaff (update_year, update_version) {
          
    const staffPromise = await get(ref(database, `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${update_year}/${update_version}`));
    const schedulePromise = await get(ref(database, `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${update_year}/${update_version}/classified/salaryTable`))
    const benefitRatesPromise = await(get(ref(database, `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${update_year}/${update_version}`)))
    const salarySchedule = schedulePromise.val()
    const staffData = staffPromise.val();
    const benefitRates = benefitRatesPromise.val();
    
    console.log('staffData: ', staffData)

    const funcCodes = Object.keys(staffData).filter(item=>item.length===4); // only get 4 digit function codes
    
    let buildings = []; let people = [];
    let lineItems = {};
    funcCodes.forEach(fc => {
      buildings = Object.keys(staffData[fc]);
      buildings.forEach(bldg => {
        if(staffData[fc][bldg]['classified']){
          staffData[fc][bldg]['classified']['totals']['salary'] = 0;
          staffData[fc][bldg]['classified']['totals']['totalCost'] = 0;
          staffData[fc][bldg]['classified']['totals']['retirement'] = 0;
          staffData[fc][bldg]['classified']['totals']['ppoCost'] = 0;
          staffData[fc][bldg]['classified']['totals']['lifeCost'] = 0;
          staffData[fc][bldg]['classified']['totals']['medicare'] = 0;
          staffData[fc][bldg]['classified']['totals']['fte'] = 0;
          people = Object.keys(staffData[fc][bldg]['classified']['staff'] || {});
          people.forEach(person_id => {
              
            const person = staffData[fc][bldg]['classified']['staff'][person_id];

            const salary_line_item = `10-${fc}-${getClassifiedObjectCode("salary", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
            const retirement_line_item = `10-${fc}-${getClassifiedObjectCode("retirement", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
            const medicare_line_item = `10-${fc}-${getClassifiedObjectCode("medicare", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
            const health_line_item = `10-${fc}-${getClassifiedObjectCode("health", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
            const fica_line_item = `10-${fc}-${getClassifiedObjectCode("fica", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;

            if(!lineItems[salary_line_item]){
                lineItems[salary_line_item] = 0;
                lineItems[retirement_line_item] = 0;
                lineItems[medicare_line_item] = 0;
                lineItems[health_line_item] = 0;
                lineItems[fica_line_item] = 0;
            }
            
            if(person.category!=''){
              try {
                const categoryIndex = parseInt(person.category);
                if (salarySchedule[categoryIndex] === undefined) {
                  throw new Error(`Category ${categoryIndex} is undefined in salarySchedule`);
                }

                const hourlyRate = salarySchedule[parseInt(person.category)][person.step]['hourlyRate'];
                const salary = hourlyRate*person.hours*person.fte;
                const ppoCost = benefitRates.ppoMonthly * 12 * person.fte * (person.health || 0);
                const lifeCost = benefitRates.lifeMonthly * 12 * person.fte * (person.life || 0);
                const fica = salary * benefitRates.socialSecurityRate;
                const medicare = salary * benefitRates.medicareRate;
                const retirement = (salary + ppoCost) * benefitRates.peersRate * person.retire;
                const totalCost = salary + ppoCost + lifeCost + medicare + retirement;
                
                staffData[fc][bldg]['classified']['staff'][person_id]['hourlyRate'] = hourlyRate;
                staffData[fc][bldg]['classified']['staff'][person_id]['salary'] = salary;
                staffData[fc][bldg]['classified']['totals']['salary'] += salary;
                staffData[fc][bldg]['classified']['totals']['totalCost'] += totalCost;
                staffData[fc][bldg]['classified']['totals']['retirement'] += retirement;
                staffData[fc][bldg]['classified']['totals']['ppoCost'] += ppoCost;
                staffData[fc][bldg]['classified']['totals']['lifeCost'] += lifeCost;
                staffData[fc][bldg]['classified']['totals']['medicare'] += medicare;
                staffData[fc][bldg]['classified']['totals']['fte'] += person.fte;
                staffData[fc][bldg]['classified']['totals']['fica'] += fica;

                lineItems[salary_line_item] += parseFloat(salary);
                lineItems[retirement_line_item] += parseFloat(retirement);
                lineItems[health_line_item] += parseFloat(ppoCost);
                lineItems[medicare_line_item] += parseFloat(medicare);
                lineItems[fica_line_item] += parseFloat(fica);

              } catch (error) {
                console.error(error.message);
              }
            }
          })
        }
      })
    })

    console.log('lineItems', lineItems)
    console.log('saving to ', `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${update_year}/expense/line_item/budget/${update_version}`)

    Object.keys(lineItems).forEach(acct_code => {
        if(acct_code.split('-').slice(4,5)!='undefined'){
            const budgetRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${update_year}/expense/${acct_code}/budget/${update_version}`);
            update(budgetRef, {budget: lineItems[acct_code], editable: false}).then(console.log(`updated ${acct_code} value: ${lineItems[acct_code]}`))
        }
    })
    
    
    await set(ref(database, `${staffPath}`), staffData);
  }

  async function updateCertifiedStaff (update_year, update_version) {
          
    const staffPromise = await get(ref(database, `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${update_year}/${update_version}`));
    const schedulePromise = await get(ref(database, `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${update_year}/${update_version}/certified/salarySchedule`))
    const benefitRatesPromise = await(get(ref(database, `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${update_year}/${update_version}`)))
    const salarySchedule = schedulePromise.val()
    const staffData = staffPromise.val();
    const benefitRates = benefitRatesPromise.val();
    
    console.log('staffData: ', staffData)

    const funcCodes = Object.keys(staffData).filter(item=>item.length===4); // only get 4 digit function codes
    
    let buildings = []; let people = [];
    let lineItems = {};
    funcCodes.forEach(fc => {
      buildings = Object.keys(staffData[fc]);
      buildings.forEach(bldg => {
        if(staffData[fc][bldg]['certified']){
          staffData[fc][bldg]['certified']['totals']['salary'] = 0;
          staffData[fc][bldg]['certified']['totals']['totalCost'] = 0;
          staffData[fc][bldg]['certified']['totals']['retirement'] = 0;
          staffData[fc][bldg]['certified']['totals']['ppoCost'] = 0;
          staffData[fc][bldg]['certified']['totals']['lifeCost'] = 0;
          staffData[fc][bldg]['certified']['totals']['medicare'] = 0;
          staffData[fc][bldg]['certified']['totals']['fte'] = 0;
          people = Object.keys(staffData[fc][bldg]['certified']['staff'] || {});
          people.forEach(person_id => {
              
            const person = staffData[fc][bldg]['certified']['staff'][person_id];

            const salary_line_item = `20-${fc}-${getCertifiedObjectCode("salary", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
            const retirement_line_item = `20-${fc}-${getCertifiedObjectCode("retirement", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
            const medicare_line_item = `20-${fc}-${getCertifiedObjectCode("medicare", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
            const health_line_item = `20-${fc}-${getCertifiedObjectCode("health", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;

            if(!lineItems[salary_line_item]){
                lineItems[salary_line_item] = 0;
                lineItems[retirement_line_item] = 0;
                lineItems[medicare_line_item] = 0;
                lineItems[health_line_item] = 0;
            }
            
            // if the salary was manually entered, don't use the salary schedule
            let effectiveSalary;
            if(person.educationalStatus!='manualEntry'){
                effectiveSalary = salarySchedule[person.educationalStatus][person.step-1]['salary'] * (person.fte || 1); // Calculate effective salary
            } else {
                effectiveSalary = person.salary
            }
            const contract = effectiveSalary + parseFloat(person.extendedContract || 0) + parseFloat(person.careerLadder || 0);
            
            const ppoCost = benefitRates.ppoMonthly * 12 * person.fte * (person.health || 0);
            const lifeCost = benefitRates.lifeMonthly * 12 * person.fte * (person.life || 0);
            const medicare = contract * benefitRates.medicareRate;
            const retirement = (contract + ppoCost) * benefitRates.certifiedRetirement;
            const totalCost = contract + ppoCost + lifeCost + medicare + retirement;
            
            staffData[fc][bldg]['certified']['staff'][person_id]['salary'] =  effectiveSalary || 0;
            staffData[fc][bldg]['certified']['staff'][person_id]['totalCost'] = totalCost;
            staffData[fc][bldg]['certified']['staff'][person_id]['retirement'] = retirement;
            staffData[fc][bldg]['certified']['staff'][person_id]['ppoCost'] = ppoCost;
            staffData[fc][bldg]['certified']['staff'][person_id]['lifeCost'] = lifeCost;
            staffData[fc][bldg]['certified']['staff'][person_id]['medicare'] = medicare;

            staffData[fc][bldg]['certified']['totals']['salary'] += effectiveSalary || 0;
            staffData[fc][bldg]['certified']['totals']['totalCost'] += totalCost;
            staffData[fc][bldg]['certified']['totals']['retirement'] += retirement;
            staffData[fc][bldg]['certified']['totals']['ppoCost'] += ppoCost;
            staffData[fc][bldg]['certified']['totals']['lifeCost'] += lifeCost;
            staffData[fc][bldg]['certified']['totals']['medicare'] += medicare;
            staffData[fc][bldg]['certified']['totals']['fte'] += person.fte;

            lineItems[salary_line_item] += contract;
            lineItems[retirement_line_item] += retirement;
            lineItems[health_line_item] += ppoCost + lifeCost;
            lineItems[medicare_line_item] += medicare;
          })
        }
      })
    })

    console.log('lineItems', lineItems)
    console.log('saving to ', `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${update_year}/expense/line_item/budget/${update_version}`)

    Object.keys(lineItems).forEach(acct_code => {
        if(acct_code.split('-').slice(4,5)!='undefined'){
            const budgetRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${update_year}/expense/${acct_code}/budget/${update_version}`);
            update(budgetRef, {budget: lineItems[acct_code], editable: false}).then(console.log(`updated ${acct_code} value: ${lineItems[acct_code]}`))
        }
    })
    
    
    await set(ref(database, `${staffPath}`), staffData);
  }

  const handleFiscalYearChange = (event) => {
    const selectedYear = event.target.value;
    if (selectedYear === '+ Create New'){
      if(Math.max(...availableFiscalYears.map(Number))<=2029){
        setNewYearCreate(true);
      } else {
        alert("Projections only go out to 3 years")
      }
    } else {
      setFiscalYear(selectedYear)
    }
  };

  useEffect(() => {
    if (fiscalYear !== null) {
        refreshSelections();
    }
  }, [fiscalYear]);

  const deleteYear = async () => {
    let dataRef = ref(database, `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/`);
    let data = await getFirebaseData(dataRef);
    if(data){
      data[version] = null;
      set(dataRef, data).then(console.log('data deleted'));
    }

    dataRef = ref(database, `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/`);
    data = await getFirebaseData(dataRef);
    if(data){
      data[version] = null;
      set(dataRef, data).then(console.log('data deleted'));
    }

    dataRef = ref(database, `revenueMetadata/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/`);
    data = await getFirebaseData(dataRef);
    if(data){
      data[version] = null;
      set(dataRef, data).then(console.log('data deleted'));
    }

    dataRef = ref(database, `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/`);
    data = await getFirebaseData(dataRef);
    if(data){
      data[version] = null;
      set(dataRef, data).then(console.log('data deleted'));
    }

    dataRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/expense`);
    data = await getFirebaseData(dataRef);
    if(data){
      Object.keys(data).forEach(item => {
        data[item]['budget'][version] = null
      })
      set(dataRef, data).then(console.log('data deleted'));
    }

    dataRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/revenue`);
    data = await getFirebaseData(dataRef);
    if(data){
      Object.keys(data).forEach(item => {
        data[item]['budget'][version] = null
      })
      set(dataRef, data).then(console.log('data deleted'));
    }

    dataRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/summary`);
    data = await getFirebaseData(dataRef);
    if(data){
      data[version] = null
      set(dataRef, data).then(console.log('data deleted'));
    }

  }

  const deleteVersion = async () => {
    for(let i = 0; i < availableFiscalYears.length; i++){
      let year = availableFiscalYears[i];
      let dataRef = ref(database, `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${year}/`);
      let data = await getFirebaseData(dataRef);
      if(data){
        data[version] = null;
        set(dataRef, data).then(console.log('data deleted'));
      }

      dataRef = ref(database, `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${year}/`);
      data = await getFirebaseData(dataRef);
      if(data){
        data[version] = null;
        set(dataRef, data).then(console.log('data deleted'));
      }

      dataRef = ref(database, `revenueMetadata/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${year}/`);
      data = await getFirebaseData(dataRef);
      if(data){
        data[version] = null;
        set(dataRef, data).then(console.log('data deleted'));
      }

      dataRef = ref(database, `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${year}/`);
      data = await getFirebaseData(dataRef);
      if(data){
        data[version] = null;
        set(dataRef, data).then(console.log('data deleted'));
      }

      dataRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${year}/expense`);
      data = await getFirebaseData(dataRef);
      if(data){
        Object.keys(data).forEach(item => {
          data[item]['budget'][version] = null
        })
        set(dataRef, data).then(console.log('data deleted'));
      }

      dataRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${year}/revenue`);
      data = await getFirebaseData(dataRef);
      if(data){
        Object.keys(data).forEach(item => {
          data[item]['budget'][version] = null
        })
        set(dataRef, data).then(console.log('data deleted'));
      }

      dataRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${year}/summary`);
      data = await getFirebaseData(dataRef);
      if(data){
        data[version] = null
        set(dataRef, data).then(console.log('data deleted'));
      }
    }

  }

  const handleVersionChange = (event) => {
    const selectedVersion = event.target.value;
    if (selectedVersion==='+ Create New'){
      setNewVersionCreate(true);
    } else {
      setVersion(selectedVersion);
    }
  };

  useEffect(() => {
    if (version !== null) {
        refreshSelections();
    }
  }, [version]);

  const handleBoardApprovedChange = (event) => {
    const selectedApproval = event.target.value;
    setBoardApproved(selectedApproval);
  };

  const handleVersionNameChange = (event) => {
    setNewVersionName(event.target.value)
  }

  const handleAssignVersionBase = (event) => {
    setNewVersionBase(event.target.value)
  }

  const incrementStaff = (data, salarySchedule, stepChange) => {
    console.log('!!!!!!!!!!!!!! incrementing staff. stepChange: ', stepChange)
    Object.keys(data).forEach(functionCode => {
      Object.keys(data[functionCode]).forEach(buildingCode => {
        Object.keys(data[functionCode][buildingCode]).forEach(staffType => {
          if(data[functionCode][buildingCode][staffType]['staff']){
            //data[functionCode][buildingCode][staffType]['totals']['salary'] = 0 //sum up salary totals 
            Object.keys(data[functionCode][buildingCode][staffType]['staff']).forEach(staffMember => {
              //////// CERTIFIED /////////
              if(staffType==='certified'){
                if(data[functionCode][buildingCode][staffType]['staff'][staffMember]['step']){
                  data[functionCode][buildingCode][staffType]['staff'][staffMember]['step'] += stepChange;
                  //const column = data[functionCode][buildingCode][staffType]['staff'][staffMember]['lane'];
                  //if(column!='manualEntry'){
                  //  const newStep = data[functionCode][buildingCode][staffType]['staff'][staffMember]['step'];
                  //  const newSalary = salarySchedule['Certified']['salarySchedule'][column][newStep]['salary'] * (data[functionCode][buildingCode][staffType]['staff'][staffMember]['fte']||0);
                  //  data[functionCode][buildingCode][staffType]['staff'][staffMember]['salary'] = newSalary;
    
                    // Update total salary
                  //  data[functionCode][buildingCode][staffType]['totals']['salary'] += newSalary;
                  //} else {
                    
                    // Update total salary
                    //data[functionCode][buildingCode][staffType]['totals']['salary'] += data[functionCode][buildingCode][staffType]['staff'][staffMember]['salary'];
                  //}
                }
              ///////// NON-CERTIFIED ////////////
              } else {
                if(data[functionCode][buildingCode][staffType]['staff'][staffMember]['step']){
                  data[functionCode][buildingCode][staffType]['staff'][staffMember]['step'] += stepChange;
                  //const column = data[functionCode][buildingCode][staffType]['staff'][staffMember]['category'];
                  //if(!['manualEntry', ''].includes(column)){
                  //  const newStep = data[functionCode][buildingCode][staffType]['staff'][staffMember]['step'];
                  //  const newHourlyRate = salarySchedule['Classified']['salaryTable'][String(column)][String(newStep)]['hourlyRate'];
                  //  const hours = data[functionCode][buildingCode][staffType]['staff'][staffMember]['hours'];
                  //  const newSalary = newHourlyRate * hours;
                  //  data[functionCode][buildingCode][staffType]['staff'][staffMember]['salary'] = newSalary;
                  //  data[functionCode][buildingCode][staffType]['totals']['salary'] += newSalary
                  //} else {
                  //  data[functionCode][buildingCode][staffType]['totals']['salary'] += data[functionCode][buildingCode][staffType]['staff'][staffMember]['salary'];
                  //}
                }
              }
            })
          }
        })
      })
    })
    return data
  }

  const instantiateBudget = async (inputYear, outputYear, inputVersionName, outputVersionName) => {
    const baseExpenseDataRef = ref(
      database,
      `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${inputYear}/expense`
    );
    
    const baseExpenseData = await getFirebaseData(baseExpenseDataRef);

    const baseRevenueDataRef = ref(
        database,
        `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${inputYear}/revenue`
    );
    
    const baseRevenueData = await getFirebaseData(baseRevenueDataRef);

    const baseSummaryDataRef = ref(
      database,
        `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${inputYear}/summary`
    )

    const baseSummaryData = await getFirebaseData(baseSummaryDataRef);

    // Ensure baseExpenseData and baseRevenueData are not null/undefined before proceeding
    if (baseExpenseData) {
        Object.keys(baseExpenseData).forEach(acctCode => {
            const dataRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${outputYear}/expense/${acctCode}/budget/${outputVersionName}`);
            set(dataRef, baseExpenseData[acctCode]?.budget?.[inputVersionName] || 0);
        });
    }

    if (baseRevenueData) {
        Object.keys(baseRevenueData).forEach(acctCode => {
            const dataRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${outputYear}/revenue/${acctCode}/budget/${outputVersionName}`);
            set(dataRef, baseRevenueData[acctCode]?.budget?.[inputVersionName] || 0);
        });
    }

    if (baseSummaryData) {
      const dataRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${outputYear}/summary/${outputVersionName}`);
      set(dataRef, baseSummaryData[inputVersionName] || 0);
    }
  }

  const instantiateStaff = async (inputYear, outputYear, inputVersion, outputVersion) => {

    console.log('instantiating staff inputYear: ', inputYear, 'outputYear: ', outputYear, 'inputVersion: ', inputVersion, 'outputVersion: ', outputVersion)
    const baseBenefitsRef = ref(
      database,
      `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${inputYear}/${inputVersion}`
    );
  
    const baseBenefitsData = await getFirebaseData(baseBenefitsRef);

    if (baseBenefitsData) {
      const dataRef = ref(database, `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${outputYear}/${outputVersion}`);
      set(dataRef, baseBenefitsData || 0);  
    }

    const baseStaffDataRef = ref(
      database,
      `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${inputYear}/${inputVersion}`
    );
  
    const baseStaffData = await getFirebaseData(baseStaffDataRef);

    const baseSalaryScheduleRef = ref(
      database,
      `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${inputYear}/${inputVersion}`
    );
  
    const baseSalaryScheduleData = await getFirebaseData(baseSalaryScheduleRef);

    if (baseSalaryScheduleData) {
      const dataRef = ref(database, `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${outputYear}/${outputVersion}`);
      set(dataRef, baseSalaryScheduleData || 0);
    }

    if(inputYear===outputYear){
      if (baseStaffData) {
        const dataRef = ref(database, `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${outputYear}/${outputVersion}`);
        set(dataRef, baseStaffData || 0);  
      }
    } else {
      if (baseStaffData){
        const incrementedStaff = incrementStaff(baseStaffData, baseSalaryScheduleData, parseInt(outputYear)-parseInt(inputYear));
        console.log('incrementedStaff: ', incrementedStaff)
        console.log('saving to: ', `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${outputYear}/${outputVersion}`)
        const dataRef = ref(database, `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${outputYear}/${outputVersion}`);
        set(dataRef, incrementedStaff || 0);  
      }
    }
  }

  const instantiateRevenueMetadata = async (inputYear, outputYear, inputVersion, outputVersion) => {
    const baseRevenueMetaRef = ref(
      database,
      `revenueMetadata/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${inputYear}/${inputVersion}`
    );
  
    const baseRevenueMetaData = await getFirebaseData(baseRevenueMetaRef);        

    if (baseRevenueMetaData) {
      const dataRef = ref(database, `revenueMetadata/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${outputYear}/${outputVersion}`);
      set(dataRef, baseRevenueMetaData || 0);  
    }
  }

  const instantiateBenefitRates = async (inputYear, outputYear, inputVersion, outputVersion) => {
    const benefitRatesMetaRef = ref(
      database,
      `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${inputYear}/${inputVersion}`
    );
  
    const benefitRatesMetaData = await getFirebaseData(benefitRatesMetaRef);        

    if (benefitRatesMetaData) {
      const dataRef = ref(database, `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${outputYear}/${outputVersion}`);
      set(dataRef, benefitRatesMetaData || 0);  
    }
  }

  const saveNewYear = async () => {
    document.body.style.cursor = "wait"; // Change to spinner
    const maxYear = Math.max(...availableFiscalYears.map(Number));
    const promises = [];
    for (const version of availableVersions) {
      promises.push(instantiateStaff(`${parseInt(maxYear)}`,`${parseInt(maxYear)+1}`, `${version}`, `${version}`))
      promises.push(instantiateBudget(`${parseInt(maxYear)}`,`${parseInt(maxYear)+1}`, `${version}`, `${version}`))
      promises.push(instantiateRevenueMetadata(`${parseInt(maxYear)}`,`${parseInt(maxYear)+1}`, `${version}`, `${version}`))
      promises.push(instantiateBenefitRates(`${parseInt(maxYear)}`,`${parseInt(maxYear)+1}`, `${version}`, `${version}`))
    }
    await Promise.all(promises);
    refreshSelections().then(() => {
      setNewYearCreate(false);
      document.body.style.cursor = "default";
    });
    setFiscalYear(parseInt(maxYear)+1)
    //await updateCertifiedStaff(parseInt(maxYear)+1, version)
    //await updateClassifiedStaff(parseInt(maxYear)+1, version)
  };

  const saveNewVersion = async () => {
    setVersionIsSaving(true);
    document.body.style.cursor = "wait"; // Change to spinner
    const promises = [];
    const budgetRef = ref(
      database,
      `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}`
    );
    const budget_promise = await get(budgetRef)
    const years = Object.keys(budget_promise.val())
    for (const year of years) {
      promises.push(instantiateBudget(year, year, newVersionBase, newVersionName));
      promises.push(instantiateStaff(year, year, newVersionBase, newVersionName));
      promises.push(instantiateRevenueMetadata(year, year, newVersionBase, newVersionName));
      promises.push(instantiateBenefitRates(year, year, newVersionBase, newVersionName));
    }
    await Promise.all(promises);
    refreshSelections().then(() => {
      setNewVersionCreate(false);
      setVersionIsSaving(false);
      document.body.style.cursor = "default";
    })
  };

  
  const showVersionModal = () => (
    <div className="version-modal">
      <div className="version-modal-content">
        <h4>Create New Version</h4>
        <label>New Version Name:
            <input
            type="text"
            value={newVersionName}
            onChange={handleVersionNameChange}
            placeholder="Enter version name"
            />
        </label>
        <label>Based on version:
            <select id="new-version-select" value={newVersionBase} onChange={handleAssignVersionBase}>
            <option value="" disabled>Select Version</option>
            {availableVersions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
        </select>
        </label>
        <button onClick={() => saveNewVersion()} disabled={!newVersionBase || versionIsSaving}>
          Save
        </button>
        <button onClick={() => setNewVersionCreate(false)}>Cancel</button>
      </div>
    </div>
  )

  const showNewYearModal = () => (
    <div className="new-year-modal">
      <div className="new-year-modal-content">
        <h4>Create New Year</h4>
        <p>Fiscal year {Math.max(...availableFiscalYears.map(Number))+1} will be created. All staff's steps will be incremented by one from fiscal year {Math.max(...availableFiscalYears.map(Number))}.</p>
        <button onClick={() => {saveNewYear()}}>Create</button>
        <button onClick={() => {setNewYearCreate(false);}}>Cancel</button>
      </div>
    </div>
  )

  return (
    <div className="year-version-selector">
      <label> Version: </label>
      <select
        id="school-select"
        value={version}
        onChange={(event) => handleVersionChange(event)}
      >
        <option value="" disabled>
          Select Version
        </option>
        {availableVersions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
        <option value="+ Create New">+ Create New</option>
      </select>

      <label>Fiscal Year: </label>
      <select
        id="school-select"
        value={fiscalYear}
        onChange={(event) => handleFiscalYearChange(event)}
      >
        <option value="" disabled>
          Select Fiscal Year
        </option>
        {availableFiscalYears.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
        <option value="+ Create New">+ Create New</option>
      </select>

      <label> Board Approved: </label>
      <select
        id="school-select"
        value={boardApproved}
        onChange={(event) => handleBoardApprovedChange(event)}
      >
        <option value="" disabled>
          Board Approved?
        </option>
        {['No', 'Yes'].map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {/* Three-dot menu button */}
      <div style={{ position: "relative" }} ref={menuRef}>
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          style={{
            background: "none",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          ⋮
        </button>

        {/* Dropdown menu */}
        {menuOpen && (
          <div
            style={{
              position: "absolute",
              left: "100%",
              top: "100%",
              background: "white",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              borderRadius: "5px",
              padding: "5px 10px",
              zIndex: 10,
            }}
          >
            <div
              onClick={() => {
                if (availableVersions.length === 1) {
                  alert("Unable to delete the only available version.");
                  return;
                }
              
                if (window.confirm(`Are you sure you want to delete version ${version}? It will be deleted across all years.`)) {
                  deleteVersion().then(() => {
                    setVersion(availableVersions[0]);
                    refreshSelections()
                  });
                }
              
                setMenuOpen(false);
              }}
              style={{ padding: "5px", cursor: "pointer", whiteSpace: "nowrap" }}
            >
              Delete Version
            </div>
            <div
              onClick={() => {
                if (availableFiscalYears.length === 1) {
                  alert("Unable to delete the only available year.");
                  return;
                }

                if(window.confirm(`Are you sure you want to delete fiscal year ${fiscalYear} from version ${version}?`)){
                  deleteYear().then(refreshSelections());
                }
                setMenuOpen(false);
              }}
              style={{ padding: "5px", cursor: "pointer", whiteSpace: "nowrap" }}
            >
              Delete Year
            </div>
          </div>
        )}
      </div>      
      <div>
        {newVersionCreate && showVersionModal()}
        {newYearCreate && showNewYearModal()}
      </div>
    </div>
  );
};

export default YearVersionSelect;
