import React, { useState, useCallback, useEffect, useRef } from "react";
import { database } from "../../firebaseConfig";
import { ref, get, set, remove } from "firebase/database";
import CertifiedEmployeeTable from "./CertifiedEmployeeTable";
import ClassifiedEmployeeTable from "./ClassifiedEmployeeTable";
import ActivityEmployeeTable from "./ActivityEmployeeTable";
import "../../styles/StaffManagement.css";
import {ExportStaffToCSV} from '../Utils/ExportToCSV';
import { StaffSettingsModal } from "./StaffSettingsModal";

const formatDollars = (num) => `$${Math.round(num).toLocaleString('en-US')}`;

const NestedStaffManagementTable = ({
  fiscalYear,
  districtCode,
  version,
  benefitRates,
  setBenefitRates,
  saveBenefits,
  accountCodeSchema,
  refreshTable
}) => {
  const [functionCodes, setFunctionCodes] = useState([1111, 1131, 1151, 1191, 1193, 1221, 1251, 1281, 1311, 1321, 1331,1411, 1421, 2122, 2125, 2132, 2211, 2222, 2311, 2321, 2329, 2411, 2523, 2524, 2541, 2552, 2561, 3511, 3512]);
  const [functionLabels, setFunctionLabels] = useState({});
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const staffPath = `staff/${districtCode}/${fiscalYear}/${version}`;

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setMenuOpen(false);
        }
      };
  
      if (menuOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [menuOpen]);

  useEffect(() => {
    const fetchFunctionLabels = async () => {
      const labels = {};
      try {
        await Promise.all(
          functionCodes.map(async (functionCode) => {
            const labelRef = ref(database, `functionCodeMap/${functionCode}/LABEL`);
            const snapshot = await get(labelRef);
            if (snapshot.exists()) {
              labels[functionCode] = snapshot.val();
            } else {
              labels[functionCode] = `Function ${functionCode}`; // Fallback if label doesn't exist
            }
          })
        );
        setFunctionLabels(labels);
      } catch (error) {
        console.error("Error fetching function labels:", error);
      }
    };

    fetchFunctionLabels();
  }, [functionCodes]);


  const [aggregatedData, setAggregatedData] = useState(
    functionCodes.reduce((acc, functionCode) => {
      acc[functionCode] = {
        fte: 0,
        salary: 0,
        totalCost: 0,
        buildings: {}, // Initialize with an empty object for buildings
      };
      return acc;
    }, {})
  );

  

  useEffect(() => {
    const fetchTotalsFromFirebase = async () => {
      const start = performance.now(); // Start timing
      setLoading(true); // Start loading spinner
      const updatedData = { ...aggregatedData }; // Start with current data

      console.log('fetching totals from firebase')
  
      const functionCodesRef = ref(
        database,
        `${staffPath}`
      );

      const fCPromise = await get(functionCodesRef);
      const functionCodesFB = fCPromise.val()

      setFunctionCodes(Object.keys(functionCodesFB))

      try {
        for (const functionCode of Object.keys(functionCodesFB).filter(item => /^[0-9]{4}$/.test(item))) {
          for (const buildingCode of Object.keys(functionCodesFB[functionCode])) {
            for (const role of Object.keys(functionCodesFB[functionCode][buildingCode])) { 
              const fte =
                role === "activity"
                  ? 0
                  : functionCodesFB[functionCode][buildingCode][role]['totals']['fte']
                  ? functionCodesFB[functionCode][buildingCode][role]['totals']['fte']
                  : 0;

              const salary = functionCodesFB[functionCode][buildingCode][role]['totals']['salary'] ? functionCodesFB[functionCode][buildingCode][role]['totals']['salary'] : 0;

              const totalCost = functionCodesFB[functionCode][buildingCode][role]['totals']['totalCost']
                ? functionCodesFB[functionCode][buildingCode][role]['totals']['totalCost']
                : 0;
  
              if (!updatedData[functionCode]) {
                updatedData[functionCode] = {
                  fte: 0,
                  salary: 0,
                  totalCost: 0,
                  buildings: {},
                };
              }
              if (!updatedData[functionCode].buildings[buildingCode]) {
                updatedData[functionCode].buildings[buildingCode] = {
                  certified: { fte: 0, salary: 0, totalCost: 0 },
                  classified: { fte: 0, salary: 0, totalCost: 0 },
                  activity: { fte: 0, salary: 0, totalCost: 0 },
                };
              }
  
              updatedData[functionCode].buildings[buildingCode][role] = {
                fte,
                salary,
                totalCost,
              };
  
              const combinedBuildingTotals = {
                fte:
                  updatedData[functionCode].buildings[buildingCode].certified.fte +
                  updatedData[functionCode].buildings[buildingCode].classified.fte +
                  updatedData[functionCode].buildings[buildingCode].activity.fte,
                salary:
                  updatedData[functionCode].buildings[buildingCode].certified.salary +
                  updatedData[functionCode].buildings[buildingCode].classified.salary +
                  updatedData[functionCode].buildings[buildingCode].activity.salary,
                totalCost:
                  updatedData[functionCode].buildings[buildingCode].certified.totalCost +
                  updatedData[functionCode].buildings[buildingCode].classified.totalCost +
                  updatedData[functionCode].buildings[buildingCode].activity.totalCost,
              };
  
              updatedData[functionCode].buildings[buildingCode] = {
                ...updatedData[functionCode].buildings[buildingCode],
                ...combinedBuildingTotals,
              };
  
              const functionLevelTotals = Object.values(
                updatedData[functionCode].buildings
              ).reduce(
                (acc, building) => {
                  acc.fte += building.fte || 0;
                  acc.salary += building.salary || 0;
                  acc.totalCost += building.totalCost || 0;
                  return acc;
                },
                { fte: 0, salary: 0, totalCost: 0 }
              );
  
              updatedData[functionCode] = {
                ...updatedData[functionCode],
                ...functionLevelTotals,
              };
            }
          }
        }
  
        setAggregatedData(updatedData); // Update state with the new data
      } catch (error) {
        console.error("Error fetching data from Firebase:", error);
      } finally {
        setLoading(false); // End loading spinner only after all data is processed
        const end = performance.now(); // End timing
        console.log(`fetchTotalsFromFirebase took ${end - start} milliseconds.`);
      }
    };
  
    console.log('fiscalYear changed')
    fetchTotalsFromFirebase();
  }, [fiscalYear, districtCode, version, refreshTable]); // Refetch data if fiscalYear or districtCode changes
  
  
  const [expandedFunctions, setExpandedFunctions] = useState({});
  const [expandedBuildings, setExpandedBuildings] = useState({});
  const [addingBuilding, setAddingBuilding] = useState(null); // To track if adding a building for a function
  const [addingFunctionCode, setAddingFunctionCode] = useState(null);
  const [newBuildingCode, setNewBuildingCode] = useState("");
  const [newFunctionCode, setNewFunctionCode] = useState("");
  const [addingToFunctionCode, setAddingToFunctionCode] = useState("");

  const toggleFunctionExpand = (functionCode) => {
    setExpandedFunctions((prev) => ({
      ...prev,
      [functionCode]: !prev[functionCode],
    }));
    setAddingBuilding(null); // Reset adding state when toggling
  };

  const toggleBuildingExpand = (functionCode, buildingCode) => {
    setExpandedBuildings((prev) => ({
      ...prev,
      [`${functionCode}-${buildingCode}`]: !prev[`${functionCode}-${buildingCode}`],
    }));
  };

  const handleDeleteRow = (functionCode, buildingCode) => {
    const deleteRef = ref(
      database,
      `${staffPath}/${functionCode}/${buildingCode}/`
    );

    setAggregatedData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[functionCode].buildings[buildingCode];
      return updatedData;
    })

    remove(deleteRef)
        .then(() => console.log('deleted from firebase'))
        .catch(() => console.log('update error'));
  }

  const addBuilding = (functionCode, buildingCode) => {
    setFunctionCodes((prevFunctionCodes) => [...prevFunctionCodes, functionCode]);

    console.log('functionCodes: ', functionCodes)
    
    setAggregatedData((prevData) => {
      const updatedData = { ...prevData };
  
      // Initialize the functionCode if it doesn't already exist
      if (!updatedData[functionCode]) {
        updatedData[functionCode] = {
          fte: 0,
          salary: 0,
          totalCost: 0,
          buildings: {}, // Initialize with an empty buildings object
        };
      }
  
      // Initialize the buildingCode if it doesn't already exist
      if (!updatedData[functionCode].buildings[buildingCode]) {
        updatedData[functionCode].buildings[buildingCode] = {
          certified: { fte: 0, salary: 0, totalCost: 0 },
          classified: { fte: 0, salary: 0, totalCost: 0 },
          activity: { fte: 0, salary: 0, totalCost: 0 },
        };
      }
  
      return updatedData;
    });

    const newBuildingRef = ref(
      database,
      `${staffPath}/${functionCode}/${buildingCode}/`
    );

    set(newBuildingRef, {
      certified: {totals: { fte: 0, salary: 0, totalCost: 0 }},
      classified: {totals: { fte: 0, salary: 0, totalCost: 0 }},
      activity: {totals: { fte: 0, salary: 0, totalCost: 0 }},
    }).then(() => console.log('new building saved')).catch(() => console.log('problem saving new building'))
  
    setAddingBuilding(null); // Reset the add building state
  };

const handleDataUpdate = useCallback(
  (functionCode, buildingCode, newData, type) => {
    setAggregatedData((prevData) => {
      const updatedData = { ...prevData };

      // Initialize functionCode if missing
      if (!updatedData[functionCode]) {
        updatedData[functionCode] = {
          fte: 0,
          salary: 0,
          totalCost: 0,
          buildings: {},
        };
      }

      // Initialize buildingCode if missing
      if (!updatedData[functionCode].buildings[buildingCode]) {
        updatedData[functionCode].buildings[buildingCode] = {
          certified: { fte: 0, salary: 0, totalCost: 0 },
          classified: { fte: 0, salary: 0, totalCost: 0 },
          activity: { fte: 0, salary: 0, totalCost: 0 },
        };
      }

      const currentBuilding = updatedData[functionCode].buildings[buildingCode];

      // Update specified type data
      currentBuilding[type] = {
        fte: newData.fte || 0,
        salary: newData.salary || 0,
        totalCost: newData.totalCost || 0,
      };

      // Calculate building totals
      const combinedBuildingTotals = {
        fte:
          currentBuilding.certified.fte +
          currentBuilding.classified.fte +
          currentBuilding.activity.fte,
        salary:
          currentBuilding.certified.salary +
          currentBuilding.classified.salary +
          currentBuilding.activity.salary,
        totalCost:
          currentBuilding.certified.totalCost +
          currentBuilding.classified.totalCost +
          currentBuilding.activity.totalCost,
      };

      updatedData[functionCode].buildings[buildingCode] = {
        certified: currentBuilding.certified,
        classified: currentBuilding.classified,
        activity: currentBuilding.activity,
        ...combinedBuildingTotals,
      };

      // Update function-level totals
      const functionLevelTotals = Object.values(updatedData[functionCode].buildings).reduce(
        (acc, building) => {
          acc.fte += building.fte || 0;
          acc.salary += building.salary || 0;
          acc.totalCost += building.totalCost || 0;
          return acc;
        },
        { fte: 0, salary: 0, totalCost: 0 }
      );

      updatedData[functionCode] = {
        ...updatedData[functionCode],
        ...functionLevelTotals,
      };

      return updatedData;
    });
  },
  []
);

const Spinner = () => (
    <div className="spinner"></div>
);

  return (
    <>
    {<StaffSettingsModal 
      isOpen={settingsModalOpen}
      staffSettings={benefitRates}
      onClose={() => {
        setSettingsModalOpen(false);
      }}
      onSubmit={(formData) => {
        setSettingsModalOpen(false);
        setBenefitRates((prev) => {
          const updatedBenefitRates = {...prev, contractDays: formData['contractDays']}
          saveBenefits(updatedBenefitRates)
          return updatedBenefitRates
        })
      }}
    />}
    {loading ? (
      <Spinner></Spinner>
    ) : (
    <table className="staff-management-table">
      <thead>
        <tr>
          <th>Function Code</th>
          <th>FTE</th>
          <th>Salary</th>
          <th style={{ position: "relative" }}>
          {/* Centered label */}
          <div style={{ textAlign: "center" }}>Total Cost</div>
          
          {/* Three-dot menu button positioned absolutely to the right */}
          <div 
            style={{ 
              position: "absolute", 
              top: "50%", 
              right: "10px",
              transform: "translateY(-50%)" 
            }} 
            ref={menuRef}
          >
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              style={{
                background: "none",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              ⋮
            </button>
            
            {/* Dropdown menu */}
            {menuOpen && (
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  top: "100%",
                  background: "white",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  zIndex: 10,
                }}
              >
                <div
                  onClick={() => {
                    ExportStaffToCSV(staffPath);
                    setMenuOpen(false);
                  }}
                  className="staff-settings-menu-item"
                >
                  Export to CSV
                </div>
                <div
                  onClick={() => {
                    setSettingsModalOpen(true);
                    setMenuOpen(false);
                  }}
                  className="staff-settings-menu-item"
                >
                  Settings
                </div>
              </div>
            )}
          </div>
        </th>
        </tr>
      </thead>
      <tbody>
        {functionCodes.map((functionCode) => (
          <React.Fragment key={functionCode}>
            {/* Function Row */}
            <tr onClick={() => toggleFunctionExpand(functionCode)} style={{ cursor: "pointer" }}>
              <td style={{ textAlign: "left" }}>
                <span className="arrow-icon">
                  {expandedFunctions[functionCode] ? "▼" : "▶"}
                </span>
                {functionLabels[functionCode] || `Function ${functionCode}`}
              </td>
              <td>{aggregatedData[functionCode]?.fte.toFixed(2) || 0}</td>
              <td>{formatDollars(aggregatedData[functionCode]?.salary || 0)}</td>
              <td>{formatDollars(aggregatedData[functionCode]?.totalCost || 0)}</td>
            </tr>
            {/* Render Add Building and Building Rows */}
            {expandedFunctions[functionCode] && (
              <>
                {/* Render Building Rows */}
                {Object.keys(aggregatedData[functionCode].buildings).map((buildingCode) => (
                  <React.Fragment key={`${functionCode}-${buildingCode}`}>
                    <tr onClick={() => toggleBuildingExpand(functionCode, buildingCode)} style={{ cursor: "pointer" }}>
                      <td style={{ paddingLeft: "2rem", textAlign: "left", position: "relative" }}>
                        <span className="arrow-icon">
                          {expandedBuildings[`${functionCode}-${buildingCode}`] ? "▼" : "▶"}
                        </span>
                        Building {buildingCode}
                        <button
                          onClick={() => {
                            if (window.confirm("Are you sure you want to delete this row?")) {
                              handleDeleteRow(functionCode, buildingCode);
                            }
                          }}
                          style={{
                            backgroundColor: "#ee6b6e",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            padding: "4px 8px",
                            cursor: "pointer",
                            position: "absolute",
                            right: "1rem", // Position button 1rem from the right edge of the <td>
                            top: "50%", // Vertically center the button
                            transform: "translateY(-50%)", // Adjust for centering
                          }}
                        >
                          Delete
                        </button>
                      </td>
                      <td>
                        {(aggregatedData[functionCode]?.buildings[buildingCode]?.fte || 0).toFixed(2)}
                      </td>
                      <td>
                        {formatDollars(
                          aggregatedData[functionCode]?.buildings[buildingCode]?.salary || 0
                        )}
                      </td>
                      <td>
                        {formatDollars(
                          aggregatedData[functionCode]?.buildings[buildingCode]?.totalCost || 0
                        )}
                      </td>
                    </tr>
                    

                    {/* Render tables for this building */}
                    {expandedBuildings[`${functionCode}-${buildingCode}`] && (
                      <tr>
                        <td colSpan={4}>
                          {["1411", "1421"].includes(functionCode.toString()) ? (
                            <ActivityEmployeeTable
                              districtCode={districtCode}
                              fiscalYear={fiscalYear}
                              version={version}
                              benefitRates={benefitRates}
                              buildingCode={buildingCode}
                              functionCode={functionCode}
                              accountCodeSchema={accountCodeSchema}
                              onDataUpdate={(newData) =>
                                handleDataUpdate(functionCode, buildingCode, newData, "activity")
                              }
                            />
                          ) : (
                            <>
                              <CertifiedEmployeeTable
                                districtCode={districtCode}
                                fiscalYear={fiscalYear}
                                version={version}
                                benefitRates={benefitRates}
                                buildingCode={buildingCode}
                                functionCode={functionCode}
                                accountCodeSchema={accountCodeSchema}
                                onDataUpdate={(newData) =>
                                  handleDataUpdate(functionCode, buildingCode, newData, "certified")
                                }
                              />
                              <ClassifiedEmployeeTable
                                districtCode={districtCode}
                                fiscalYear={fiscalYear}
                                version={version}
                                benefitRates={benefitRates}
                                buildingCode={buildingCode}
                                functionCode={functionCode}
                                accountCodeSchema={accountCodeSchema}
                                onDataUpdate={(newData) =>
                                  handleDataUpdate(functionCode, buildingCode, newData, "classified")
                                }
                              />
                            </>
                          )}
                        </td>
                        
                      </tr>
                    )}
                  </React.Fragment>
                  
                )
                )}
                {expandedFunctions[functionCode] && 
                <>
                <tr>
                  <td>
                    {addingBuilding && functionCode===addingToFunctionCode ? (
                      <div style={{ display: "inline-flex", alignItems: "center", marginLeft: "10px" }}>
                        <input
                          type="text"
                          placeholder="Enter building code"
                          value={newBuildingCode}
                          onChange={(e) => setNewBuildingCode(e.target.value)}
                          style={{
                            padding: "4px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            marginRight: "5px",
                          }}
                        />
                        <button
                          style={{
                            padding: "4px 8px",
                            backgroundColor: "blue",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (newBuildingCode.trim() !== "") {
                              addBuilding(functionCode, newBuildingCode);
                              setAddingBuilding(false); // Hide input after adding
                              setNewBuildingCode(""); // Clear input field
                            }
                          }}
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      <span
                        role="img"
                        aria-label="Add Building"
                        style={{ cursor: "pointer", marginLeft: "3px" }}
                        onClick={() => {
                          setAddingBuilding(true);
                          setAddingToFunctionCode(functionCode);
                        }} // Show input field
                      >
                        ➕ Add building
                      </span>
                    )}

                  </td>
                </tr>
              </>}
              </>
            )}
            
          </React.Fragment>
          
        ))}
        <tr>
        <td>
            {addingFunctionCode ? (
              <div style={{ display: "inline-flex", alignItems: "center", marginLeft: "10px" }}>
                <input
                  type="text"
                  placeholder="Enter function code"
                  value={newFunctionCode}
                  onChange={(e) => setNewFunctionCode(e.target.value)}
                  style={{
                    padding: "4px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    marginRight: "5px",
                  }}
                />
                <input
                  type="text"
                  placeholder="Enter Building code"
                  value={newBuildingCode}
                  onChange={(e) => setNewBuildingCode(e.target.value)}
                  style={{
                    padding: "4px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    marginRight: "5px",
                  }}
                />
                <button
                  style={{
                    padding: "4px 8px",
                    backgroundColor: "blue",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (newFunctionCode.trim() !== "") {
                      addBuilding(newFunctionCode, newBuildingCode);
                      setAddingFunctionCode(false); // Hide input after adding
                      setNewFunctionCode(""); // Clear input field
                      setNewBuildingCode("");
                    }
                  }}
                >
                  Save
                </button>
              </div>
            ) : (
              <span
                role="img"
                aria-label="Add Function Code"
                style={{ cursor: "pointer", marginLeft: "3px" }}
                onClick={() => {
                  setAddingFunctionCode(true);
                  //setAddingToFunctionCode(functionCode);
                }} // Show input field
              >
                ➕ Add function
              </span>
            )}

                  </td>
                  </tr>
      </tbody>
      <tfoot>
  <tr>
    <td><strong>Total</strong></td>
    <td>
      {functionCodes
        .reduce((total, functionCode) => total + aggregatedData[functionCode]?.fte || 0, 0)
        .toFixed(2)}
    </td>
    <td>
      {formatDollars(functionCodes
        .reduce((total, functionCode) => total + aggregatedData[functionCode]?.salary || 0, 0))}
    </td>
    <td>
      {formatDollars(functionCodes
        .reduce((total, functionCode) => total + aggregatedData[functionCode]?.totalCost || 0, 0))}
    </td>
  </tr>
</tfoot>

    </table>
    )}
  </>
  );
};

export default NestedStaffManagementTable;

