import React, { useState, useEffect } from "react";
import { database } from "../../firebaseConfig";
import { ref, get, set, update } from "firebase/database";
import "../../styles/StaffManagement.css";
import {determineCertifiedObjectCode, determineClassifiedObjectCode, updateSelectStaff} from "../Utils/UpdateStaff"
import {createNewRole, updateActivityRole, subtractLineItemsFromBudget} from "../Utils/StaffTableUtils"
import { EmployeeDetailsModal } from "./EmployeeDetailsModal";

const formatDollars = (num) => `$${Math.round(num).toLocaleString()}`;

const OBJECT_CODES = {
  6111: "Regular Salaries",
  6112: "Administrators", 
  6121: "Substitute Salaries",
  6122: "Other Part-Time Salaries",
  6131: "Certificated Staff Supplemental Pay",
  6141: "Certificated Employees Unused Leave and/or Severance Pay",
  6151:	"Classified Salaries-Regular",
  6152:	"Instructional Aide Salaries",
  6153:	"Classified Substitute Salaries",
  6161:	"Classified Salaries - Part-Time",
  6171:	"Classified Employees Unused Leave and/or Severance Pay"
};

const ActivityEmployeeTable = ({
  districtCode,
  fiscalYear,
  version,
  benefitRates,
  buildingCode,
  functionCode,
  accountCodeSchema,
  onDataUpdate,
}) => {

  const salarySchedulePath = `salarySchedules/${districtCode}/${fiscalYear}/${version}`;
  const staffPath = `staff/${districtCode}/${fiscalYear}/${version}`;
  const benefitsPath = `benefitRates/${districtCode}/${fiscalYear}/${version}`;
  const dataPaths = {'staffPath': staffPath, 'benefitsPath': benefitsPath}

  const [Rows, setRows] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [availableSalarySchedules, setAvailableSalarySchedules] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [classifiedSalarySchedules, setClassifiedSalarySchedules] = useState([]);

  function buildAccountCode(format, data) {
    return format.split('-') // Split the format string into an array
                 .map(key => data[key]) // Map each key to its corresponding value in the data object
                 .join('-'); // Join the values with '-'
  }

  const handleDeleteEmployee = async (row) => {
      const updatedRows = Rows.filter((r) => r.uuid !== row.uuid);
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this row?"
      );
      if (!confirmDelete) return;
  
      // Remove the specific row from Firebase
      const rowRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/activity/staff/${row.uuid}`
      );
      await set(rowRef, null);
  
      subtractLineItemsFromBudget(row, determineCertifiedObjectCode, buildAccountCode, accountCodeSchema, districtCode, fiscalYear, version);
  
      // If no rows remain, remove the totals node
      if (updatedRows.length === 0) {
        const aggregateRef = ref(
          database,
          `${staffPath}/${functionCode}/${buildingCode}/activity/totals`
        );
        await set(aggregateRef, null);
          
      } else {
        // Recalculate totals
        const aggregated = updatedRows.reduce(
          (acc, row) => {
            acc.fte += row.fte || 0;
            acc.salary += row.salary || 0;
            acc.extendedContract += row.extendedContract || 0;
            acc.careerLadder += row.careerLadder || 0;
            acc.totalCost += row.totalCost || 0;
            acc.insuranceBenefits += row.insuranceBenefits || 0;
            acc.medicare += row.medicare;
            acc.retirement += row.retirement;
            return acc;
          },
          {
            fte: 0,
            salary: 0,
            extendedContract: 0,
            careerLadder: 0,
            totalCost: 0,
            insuranceBenefits: 0,
            medicare: 0,
            retirement: 0,
          }
        );
  
        const aggregateRef = ref(
          database,
          `${staffPath}/${functionCode}/${buildingCode}/activity/totals`
        );
        await set(aggregateRef, {
          ...aggregated,
          salaryWithExtendedContract: aggregated.salary + aggregated.extendedContract + aggregated.careerLadder,
        });
      }
  
      // Update local state
      setRows(updatedRows);
    }

  const [notification, setNotification] = useState("");

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(""), 3000); // Clear the notification after 3 seconds
  };

  useEffect(() => {
    // Fetch activity rows from Firebase
    const fetchActivityData = async () => {
      try {
        const rowsRef = ref(
          database,
          `${staffPath}/${functionCode}/${buildingCode}/activity/staff`
        );
        const snapshot = await get(rowsRef);
        if (snapshot.exists()) {
          const data = Object.values(snapshot.val());
          setRows(data);
        }
      } catch (error) {
        console.error("Error loading activity data: ", error);
      }
    };

    fetchActivityData();
  }, [districtCode, fiscalYear, functionCode, buildingCode]);

  useEffect(() => {
    const totals = Rows.reduce(
      (acc, row) => {
        acc.salary += row.salary || 0;
        acc.totalCost += row.totalCost || 0;
        return acc;
      },
      { salary: 0, totalCost: 0 }
    );

    if (onDataUpdate) {
      onDataUpdate(totals);
    }
  }, [Rows]);

  const openDetailsModal = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
  };

  const saveDetails = async (selectedRow, modalFields) => {
    
    // Remove line items from budget in case object, project, program, etc have changed
    if(selectedRow.employeeType==='certified'){
      await subtractLineItemsFromBudget(Rows.filter(item=>item.uuid===selectedRow.uuid)[0], determineCertifiedObjectCode, buildAccountCode, accountCodeSchema, districtCode, fiscalYear, version)
    } else {
      await subtractLineItemsFromBudget(Rows.filter(item=>item.uuid===selectedRow.uuid)[0], determineClassifiedObjectCode, buildAccountCode, accountCodeSchema, districtCode, fiscalYear, version)
    }

    modalFields.forEach(item=> {
      if(selectedRow[item]){
        updateRow(selectedRow.uuid, item, selectedRow[item])
      }
    })

    setModalOpen(false);
  };

  useEffect(() => {
    if (Rows.some(row => row.hasChanged)) {
      saveDataToFirebase();
    }
  }, [Rows]);

  useEffect(() => {
  
    // Get available names of salary schedules for details drop down
    get(ref(database, `${salarySchedulePath}/`)).then(result=>{
      setAvailableSalarySchedules(result.val()['certified'])
      setClassifiedSalarySchedules(result.val()['classified'])
    });

  }, [districtCode, fiscalYear, salarySchedulePath]);

  useEffect(() => {
    const totals = Rows.reduce(
      (acc, row) => {
        acc.salary += row.salary || row.salary || 0;
        acc.totalCost += row.totalCost || 0;
        return acc;
      },
      { salary: 0, totalCost: 0 }
    );
  
    // Notify the parent
    if (onDataUpdate) {
      onDataUpdate(totals);
    }
  }, [Rows]);
  

  const fetchBaseSalary = () => {
    try {
      const baseSalary = availableSalarySchedules['Certified']['baseSalary']
      if(baseSalary){
        return baseSalary;
      }
      console.error("Base salary data is not available for the requested fiscal year.");
      return 0;
    } catch (error) {
      console.error("Error fetching base salary: ", error);
      return 0;
    }
  };

  const getSchedule = (lane, step, schedule) => {
    const lane_name = availableSalarySchedules[schedule]?.['careerLanes'][lane-1].name || 'None';
    const max_num_steps = availableSalarySchedules[schedule]?.['salarySchedule'][lane_name].length;
    
    if(step<max_num_steps){
      return availableSalarySchedules[schedule]?.['salarySchedule'][lane_name][step] || {};
    } else {
      return availableSalarySchedules[schedule]?.['salarySchedule'][lane_name][max_num_steps-1] || {};
    }
    
  }

  const fetchSalary = (scheduleToUse, lane, step) => {

    if (!lane || step === null || step === undefined)
      return 0;

    try {
      if (getSchedule(lane, step, scheduleToUse)['baseSalary']) return getSchedule(lane, step, scheduleToUse)['baseSalary'];
      console.error("No salary found for the given inputs");
      return 0;
    } catch (error) {
      console.error("Error fetching salary: ", error);
      return 0;
    }
  };

  const addRow = () => {
      const newRow = createNewRole();
      newRow.uuid = Date.now();
      newRow['location'] = buildingCode;
      newRow['function'] = functionCode;
      newRow['fund'] = '20';
      newRow['object'] = '6111';
      newRow['source'] = '3';
      newRow['program'] = '000';
      newRow['project'] = '00000';
      newRow['fte'] = 0; // so we don't attempt to calculate benefits
      newRow['employeeType'] = 'certified'
      setRows([...Rows, newRow])
    };

  const calculateSalary = (updatedRow) => {
    let salary = 0;
    if (updatedRow.activityCompMethod === "Direct Salary Input ($)") {
      salary = parseFloat(updatedRow.activityCompAmount) || 0;
    } else if (updatedRow.activityCompMethod === "Percent of Base (%)") {
      const baseSalary = fetchBaseSalary();
      salary = (baseSalary * (updatedRow.activityCompAmount / 100)) || 0;
    } else if (updatedRow.activityCompMethod === "Percent of Salary (%)") {
      const currentSalary = fetchSalary(
        updatedRow.scheduleToUse,
        updatedRow.lane,
        Math.max(updatedRow.step, 1)
      );
      salary = (currentSalary * (updatedRow.activityCompAmount / 100)) || 0;
    }
    return salary
  }
  
  const updateRow = async (uuid, key, value) => {
    
    console.log('updateRow: ', key, value)
    let updatedRow = null;
  
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowIndex = updatedRows.findIndex((row) => row.uuid === uuid);
  
      if (rowIndex !== -1) {
        const row = updatedRows[rowIndex];
        const updatedValue =
          key === "step" && value < 1
            ? 1
            : ["fte", "extendedContract", "careerLadder", "salary", "fullFTEsalary"].includes(key)
            ? !isNaN(value) ? parseFloat(value) : 0
            : value;
  
        updatedRows[rowIndex] = { 
          ...row, 
          [key]: updatedValue,
          hasChanged: true
        };
  
        updatedRow = updatedRows[rowIndex]; // Capture updated row immediately
      }
  
      return updatedRows;
    });
  
    // Use a timeout to wait for the state update
    setTimeout(async () => {
      if (updatedRow) {
        const salary = calculateSalary(updatedRow)
        updateActivityRole(uuid, salary, setRows, benefitRates);
      }
    }, 0);
  };
  
  const saveDataToFirebase = async () => {
    console.log('save data to firebase')
    console.log('Rows: ', Rows)
    try {
      // 1. Save individual staff data
      const saveRowPromises = Rows.filter((row) => row.hasChanged).map((row) => {
          const rowRef = ref(
            database,
            `${staffPath}/${functionCode}/${buildingCode}/activity/staff/${row.uuid}`
          );
          return update(rowRef, row); // Use `update` for partial updates
        });
      await Promise.all(saveRowPromises);
  
      await updateSelectStaff(Rows, availableSalarySchedules, classifiedSalarySchedules, benefitRates, dataPaths, districtCode, fiscalYear, version);
      
      // Reset `hasChanged` flag for saved rows
      setRows((prevRows) =>
        prevRows.map((row) => (row.hasChanged ? { ...row, hasChanged: false } : row))
      );
  
      showNotification("Saved");
    } catch (error) {
      showNotification("Error saving activity data: ", error);
      console.log('error: ', error)
    }
  };

  useEffect(() => {
      if (isFirstRender && Rows.length > 0) {
        // Create a sorted copy (don't mutate the original)
        const sortedRows = [...Rows].sort((a, b) => 
          String(a.last).localeCompare(String(b.last))
        );
        
        // Replace your Rows state with the sorted version
        setRows(sortedRows);
        
        // Mark initial sort as complete
        setIsFirstRender(false);
      }
    }, [Rows, isFirstRender]);

  return (
    <div className="staff-table-container">
      {notification && (
      <div className="notification">
        {notification}
      </div>
    )}
      <h3>Activity Roles</h3>
      <table className="staff-management-table">
        <thead>
          <tr>
            <th>Last</th>
            <th>First</th>
            <th>Activity Name</th>
            <th>Compensation Method</th>
            <th>Compensation Amount ($/%)</th>
            <th>Lane</th>
            <th>Step</th>
            <th>Salary</th>
            <th>Total Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Rows.map((row) => (
            <tr key={row.uuid}>
              
            <td>
              <input
                type="text"
                value={row.last}
                onChange={(e) =>
                  updateRow(row.uuid, "last", e.target.value)
                }
                onBlur={() => {
                  if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                }}
              />
            </td>
              <td>
                <input
                  type="text"
                  value={row.first}
                  onChange={(e) =>
                    updateRow(row.uuid, "first", e.target.value)
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.position}
                  onChange={(e) =>
                    updateRow(row.uuid, "position", e.target.value)
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <select
                  value={row.activityCompMethod}
                  onChange={(e) => updateRow(row.uuid, "activityCompMethod", e.target.value)}
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                >
                  <option value="Direct Salary Input ($)">
                    Direct Salary Input ($)
                  </option>
                  <option value="Percent of Base (%)">
                    Percent of Base (%)
                  </option>
                  <option value="Percent of Salary (%)">
                    Percent of Salary (%)
                  </option>
                  <option value="Salary Schedule ($)">
                    Salary Schedule ($)
                  </option>
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={row.activityCompMethod==='Salary Schedule ($)' ? '' : row.activityCompAmount}
                  onChange={(e) => {
                    updateRow(row.uuid, "activityCompAmount", e.target.value);
                  }}
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                  disabled={
                    (row.activityCompMethod === 'Salary Schedule ($)') ||
                    (row.activityCompMethod === "Percent of Salary (%)" &&
                    (!row.lane || row.step <= 0))
                  }
                />
              </td>
              <td>
                {['Salary Schedule ($)', 'Percent of Salary (%)'].includes(row.activityCompMethod) && (
                <select
                  value={availableSalarySchedules ? (availableSalarySchedules[row.scheduleToUse]?.['careerLanes'] || []).map(item => item.name)[row.lane-1] : 'Manual Entry'}
                  className="tableInput"
                  onChange={(e) => {
                    const options = (availableSalarySchedules[row.scheduleToUse]?.['careerLanes'] || []).map(item => item.name);
                    const selectedIndex = options.indexOf(e.target.value); // Get index of selected value
                    updateRow(row.uuid, "lane", selectedIndex + 1); // Adjust index to match `lane`
                  }}
                >
                  <option value="">Select</option>
                  
                  {availableSalarySchedules&&(availableSalarySchedules[row.scheduleToUse]?.['careerLanes'] || []).map(item => item.name).map((column) => (
                        <option key={column} value={column}>
                          {column}
                        </option>
                    ))}
                  <option key={'manualEntry'} value={'manualEntry'}>{'Manual Entry'}</option>
                </select>
                )}

              </td>
              <td>
              {['Salary Schedule ($)', 'Percent of Salary (%)'].includes(row.activityCompMethod) && (
                <input
                  type="number"
                  value={row.step}
                  onChange={(e) =>
                    updateRow(row.uuid, "step", parseInt(e.target.value, 10))
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                  min={1}
                />
                )}
              </td>
              <td>{formatDollars(row.salary)}</td>
              <td>{formatDollars(row.totalCost)}</td>
              <td>
                <button 
                  onClick={async () => {
                    handleDeleteEmployee(row)
                  }}
                >
                  Delete
                </button>
                <button onClick={() => openDetailsModal(row)}>Details</button>                
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="7">Totals</td>
            <td>{formatDollars(Rows.reduce((sum, row) => sum + (row.salary || 0), 0))}</td>
            <td>{formatDollars(Rows.reduce((sum, row) => sum + (row.totalCost || 0), 0))}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
      {isModalOpen && <EmployeeDetailsModal selectedRow={selectedRow}
                                                  setSelectedRow={setSelectedRow} 
                                                  objectCodes={OBJECT_CODES}
                                                  availableSalarySchedules={availableSalarySchedules}
                                                  saveDetails={saveDetails}
                                                  setModalOpen={setModalOpen}/>}
      <button onClick={addRow} className="add-row-btn">Add Activity Role</button>

    </div>
  );
};

export default ActivityEmployeeTable;