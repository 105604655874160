import { database } from "../../firebaseConfig";
import { ref, get } from 'firebase/database';

export const ExportStaffToCSV = async (staffPath) => {
  try {
    // 1. Connect to Firebase and get the data
    const dataRef = ref(database, `${staffPath}`); // Adjust the path as needed
    const snapshot = await get(dataRef);
    const data = snapshot.val();
    
    if (!data) {
      console.error('No data found in Firebase');
      alert('No data found to export');
      return;
    }
    
    // 2. Transform the hierarchical data into flat CSV format
    const csvRows = [];
    
    // Add CSV header
    csvRows.push(['employeeId', 'first', 'last', 'fund', 'function', 'object', 'location', 'source', 'project', 'roleType', 'employeeType', 'position', 'fte', 'salary schedule',
      'lane', 'step', 'hourlyRate', 'salary', 'salaryStateGrant', 'extraDays', 'extraDaysSalary', 'extendedContract', 'retirement', 'retirementStateGrant', 'medicare', 'medicareStateGrant',
      'insuranceBenefits', 'fica', 'totalCost']);
    
    // Process the nested data
    Object.keys(data).forEach(functionCode => {
      Object.keys(data[functionCode]).forEach(building => {
        Object.keys(data[functionCode][building]).forEach(roleType => {
          if(data[functionCode][building][roleType]['staff']){
            Object.keys(data[functionCode][building][roleType]['staff']).forEach((staffId) => {
              const row = data[functionCode][building][roleType]['staff'][staffId];
              csvRows.push([row.employeeId, row.first, row.last, row.fund, row.function, row.object, row.location, row.source, row.project, 
                row.roleType, row.employeeType, row.position, row.fte, row.scheduleToUse,
                row.lane, row.step, (row.hourlyRate ? row.hourlyRate.toFixed(2) : "0.00"), (row.salary ? row.salary.toFixed(2) : "0.00"), (row.salaryStateGrant ? row.salaryStateGrant.toFixed(2) : "0.00"), 
                (row.extraDays || 0), (row.extraDaysSalary ? row.extraDaysSalary.toFixed(2) : "0.00"), (row.extendedContract ? row.extendedContract.toFixed(2) : "0.00"), row.retirement.toFixed(2), 
                (row.retirementStateGrant ? row.retirementStateGrant.toFixed(2) : "0.00"),  
                row.medicare.toFixed(2), (row.medicareStateGrant ? row.medicareStateGrant.toFixed(2) : "0.00"), row.insuranceBenefits.toFixed(2),
                (row.fica ? row.fica.toFixed(2) : "0.00"), row.totalCost.toFixed(2)]);
            });
          }
        });
      });
    });
    
    // 3. Convert the rows to CSV format
    const csvContent = csvRows
      .map(row => row.map(cell => `"${cell}"`).join(','))
      .join('\n');
    
    // 4. Create a download link and trigger it
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    
    link.setAttribute('href', url);
    link.setAttribute('download', `staff_data_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
  } catch (error) {
    console.error('Error exporting data:', error);
    alert('Failed to export data: ' + error.message);
  } finally {
    console.log('Export operation completed');
  }
};

export const ExportBudgetToCSV = async (data) => {
  try {
    
    if (!data) {
      console.error('No data found in Firebase');
      alert('No data found to export');
      return;
    }
    
    // 2. Transform the hierarchical data into flat CSV format
    const csvRows = [];
    
    // Add CSV header
    csvRows.push(['acctCode', 'fund', 'function', 'object', 'location', 'program', 'source', 'project', 'type', 'description', 'budget']);
    
    // Process the nested data
    data.filter(item=>(!item.AGGREGATE)&&(item.fund)&&(item.GROUP!=='cash')).forEach(item => {
      csvRows.push([item.accountCode, item.fund, item.function, item.object, item.location, item.program, item.source, item.project,  item.GROUP, item.description, item.budget]);
    });

    console.log('csvRows: ', csvRows)
    
    // 3. Convert the rows to CSV format
    const csvContent = csvRows
      .map(row => row.map(cell => `"${cell}"`).join(','))
      .join('\n');
    
    // 4. Create a download link and trigger it
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    
    link.setAttribute('href', url);
    link.setAttribute('download', `budget_export_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
  } catch (error) {
    console.error('Error exporting data:', error);
    alert('Failed to export data: ' + error.message);
  } finally {
    console.log('Export operation completed');
  }
};
