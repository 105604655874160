import React, { useState, useEffect, useRef } from 'react';
import { database } from '../firebaseConfig';
import { ref, set, get, remove} from "firebase/database";
import '../styles/GeneralDrilldownTable.css'
import LineGraph from './LineGraph';
import {getUniqueValues, getShade, arrToJSON, formatDollars, getFirebaseData, expandDataWithYears} from '../utils'
import {getAccountItem} from './Utils/UpdateStaff.js'
import {BudgetMenuButton} from './Utils/BudgetMenuButton.js';
import {FilterButton} from './Utils/FilterButton.js'

// This function creates an expandable hierarchical table and expects the following from the data:
//
//   1) It is an array of JSON objects of the form [{'rowKey': value, 'columnKey': value, 'valueLabel': value, 'PARENT': value, 'LEVEL': value}]
//   2) The values can be numerical or strings for the rowKey, columnKey, and valueLabel. PARENT is expected to be a string...
//          ...corresponding to the parent object with rowKey === PARENT. LEVEL is an integer describing the level in the hierarchy...
//          ...of the LABEL. For example, if the rowKey's PARENT === 'Self', its level should be 0 indicating it is the top-most...
//          ...level in the hierarchy. The children of that rowKey should have level 1, the children of that level 2, etc.
//   3) Sometimes we only want to show a subset of the data in the table. For that, we can apply tableDataFilters...
//          ... that way we preserve all of the data for other functionality like graphs.
//   4) The table values may need formatted for the table but not for the graph. This is common in the case of...
//          ... dollar amounts. For this tableDataFormats can be specified like {'TOTAL_ALL_FUNDS': 'dollars'}

const GeneralDrilldownTable = ({data, tableDataFilters, tableDataFormats, rowKey, columnKey, valueLabel, rowLevelFunctions, fiscalYear, version, deseCodeMap, title, graphOptions, accountCodeSchema, onRefresh, setFilterModalOpen, filtersApplied, clearFilters}) => {
  
    const graphAvailable = rowLevelFunctions['graph'];
    const editAvailable = rowLevelFunctions['edit'];

    const [graphData, setGraphData] = useState(data);
    const [tableData, setTableData] = useState([]);

    // By default we expand only rows where PARENT == 'Self'
    const filterFunction = new Function('item', `return ${tableDataFilters};`);
    let tableData_raw = tableDataFilters? data.filter(filterFunction) : graphData;
    const defaultExpandedRows = getUniqueValues(tableData_raw.filter(item => item.PARENT==='Self'), rowKey).sort((a, b) => b.localeCompare(a));;

    const [expandedRows, setExpandedRows] = useState(defaultExpandedRows);
    const [areChildrenExpanded, setAreChildrenExpanded] = useState(arrToJSON(defaultExpandedRows))
    const [selectedGraphLabel, setSelectedGraphLabel] = useState(null);
    const [selectedTableLabel, setSelectedTableLabel] = useState(null);
    const [viewMode, setViewMode] = useState('graph');
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalRow, setModalRow] = useState({});
    const [itemExists, setItemExists] = useState(false);
    const [objectSuggestions, setObjectSuggestions] = useState([]);
    const [searchObject, setSearchObject] = useState('');
    const menuRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);

    function buildAccountCode(format, data) {
      return format.split('-') // Split the format string into an array
                   .map(key => data[key]) // Map each key to its corresponding value in the data object
                   .join('-'); // Join the values with '-'
    }

    const parseCurrency = (value) => {
      if(value){
        return parseFloat(value.replace(/[$,]/g, ''));
      } else {
        return 0
      }
    }

    useEffect(() => {
          const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
              setMenuOpen(false);
            }
          };
      
          if (menuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
          }
      
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [menuOpen]);

    useEffect(() => {
      setGraphData(data);
    }, [data])

    useEffect(() => {
      // filter the tableData if the user has specified tableDataFilters
      const filterFunction = new Function('item', `return ${tableDataFilters};`);
      let tableData_raw = tableDataFilters? graphData.filter(filterFunction) : graphData;
      
      // format the table data if the user has specified tableDataFormats
      let formattedTableData;
      if(tableDataFormats){
        switch(tableDataFormats[valueLabel]) {
          case 'dollars':
            formattedTableData = tableData_raw.map(item => ({
              ...item,
              [valueLabel]: formatDollars(item[valueLabel]),
            }));
          break;
          default:
            formattedTableData = tableData_raw.map(item => ({
              ...item,
              [valueLabel]: item[valueLabel],
            }));
            break;
        }
      }

      setTableData(formattedTableData)

    }, [graphData, tableDataFilters, tableDataFormats, valueLabel])

    ////////////////////////// FUNCTIONS /////////////////////////////

    const handleShowGraphClick = (label) => {
      setSelectedGraphLabel((prevLabel) => (prevLabel === label ? null : label));
    };

    const handleShowTableClick = (label) => {
      setSelectedTableLabel((prevLabel) => (prevLabel === label ? null : label));
    };

    // Handle view mode toggle (graph or raw data)
    const toggleViewMode = () => {
      setViewMode(viewMode === 'graph' ? 'spreadsheet' : 'graph');
    };

    const handleAddObject = (code) => {
 
      setModalRow(prev => ({
        ...prev,
        object: code.slice(0,4),
        //DESCRIPTION: deseCodeMap['object'][code.slice(0,4)].DETAIL_DESCRIPTION || "", // Set DESCRIPTION from deseCodeMap
      }));
      
      setSearchObject(code.slice(0,4))
      setObjectSuggestions([]);
    };
  

    const handleFundChange = (e) => {
      setModalRow(prev => ({
        ...prev, fund: e.target.value}))
    }

    
    const handleSearchChange = (e) => {
      const value = e.target.value.toLowerCase();
    
      setSearchObject(value);
      setObjectSuggestions(
        value
          ? Object.entries(deseCodeMap['object'])
              .filter(([code, details]) =>
                code.toString().startsWith(value) || details.DETAIL_DESCRIPTION.toLowerCase().includes(value.toLowerCase())
              )
              .map(([code, details]) => `${code.toString()} - ${details.DETAIL_DESCRIPTION}`)
          : []
      );     
      setModalRow(prev => ({
        ...prev, object: e.target.value.slice(0,4)})) 

    };

    const handleEditRow = (row) => {
      setModalRow(row);
      setModalOpen(true);
    }

    // Function to handle row deletion
    const handleDeleteRow = (row) => {

      const keyTotalRef = ref(
        database,
        `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${row['accountCode']}/budget/${version}`
      );
      
      remove(keyTotalRef)
        .then(() => {
          console.log('deleted from firebase')
          onRefresh();
        })
        .catch(() => console.log('update error'));

      // Update the state or data source to remove the row
      const updatedData = graphData.filter(obj => obj !== row);
      
      setGraphData(updatedData); // Assuming you have a state setter `setData`
    };

    const showExpenseModal = (row) => (
      <div className="add-row-modal">
        <div className="add-row-modal-content">
          <h4>Edit Line Item</h4>
          <label>Description:
            <input
              type="text"
              value={row.description || row.DESCRIPTION}
              onChange={(e) => setModalRow(prev => ({
                ...prev, description: e.target.value}))}
            />
          </label>
          <label>
            Building:
            <input
              type="text"
              value={row.location}
              onChange={(e) => setModalRow(prev => ({
                ...prev, location: e.target.value}))}
            />
          </label>
          <label>Object code:
            <input
              type="text"
              value={"" || searchObject || row.object}
              onChange={(e) => handleSearchChange(e)}
              placeholder="Search by code"
            />
            {objectSuggestions.length > 0 && (
              <ul className="suggestions-list">
                {objectSuggestions.map((item, index) => (
                  <li key={index} onClick={() => handleAddObject(item)}>
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </label>
          <label>Source:
            <input
              type="number"
              value={row.source}
              onChange={(e) => setModalRow(prev => ({
                ...prev, source: e.target.value}))}
            />
          </label>
          <label>
            Project:
            <input
              type="text"
              value={row.project}
              maxLength="5"
              onChange={(e) => setModalRow(prev => ({
                ...prev, project: e.target.value}))}
            />
          </label>
          <label>
            Program:
            <input
              type="text"
              value={row.program}
              maxLength="3"
              onChange={(e) => setModalRow(prev => ({
                ...prev, program: e.target.value}))}
            />
          </label>
          <label>
            Fund:
            <select id="fund-select" value={row.fund} onChange={handleFundChange}>
              <option value="" disabled>Select Fund</option>
              {['10','20','30','40'].map((option, index) => (
                  <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </label>
          <label>
            Amount:
            <input
              type="text"
              value={row.budget ?? ""}
              onChange={(e) => {
                const value = e.target.value;
                setModalRow(prev => ({
                  ...prev,
                  budget: value === "" ? "" : parseFloat(value)
                }));
              }}
            />

          </label>
          
          {itemExists && (
            <font color='#FF0000'> Error: {itemExists} already exists. Edit existing item instead.</font>
          )}
          
          <div className="add-row-modal-actions">
            <button onClick={() => {
              // when user clicks save, save to firebase
              let oldAccountCode = modalRow.accountCode;
              let newAccountCode = buildAccountCode(accountCodeSchema['budget'], {fund: row.fund, function: row.function, object: searchObject === "" ? row.object : searchObject, location: row.location, source: row.source, project: row.project, program: row.program})
              
              remove(ref(database, `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${oldAccountCode}/budget/${version}`))
                .then(() => console.log('Old account code removed'))
                .catch((error) => console.error('Error removing old account code:', error));


              const keyTotalRef = ref(
                database,
                `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${newAccountCode}/budget/${version}`
              );

              console.log('saving row: ', row, `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${newAccountCode}/budget/${version}`)

              // Check if label already exists. If it does, print error to user, otherwise create the record.
              get(keyTotalRef).then(result => {
                
                if (result.val() && result.val().budget > 0){
                  setItemExists(newAccountCode);
                } else {
                  setItemExists(false);
                  // only want to save a select number of columns to firebase
                  let row_to_save =  {
                    budget: isNaN(parseFloat(row.budget)) ? 0 : parseFloat(row.budget),
                    description: row.description,
                    function: row.function,
                    fund: row.fund,
                    object: row.object === "" ? searchObject : row.object,
                    location: row.location === "" ? "0000" : row.location,
                    program: row.program === "" ? "000" : row.program,
                    project: row.project === "" ? "00000" : row.project,
                    source: row.source,
                    editable: true
                    }

                    set(keyTotalRef, row_to_save)
                    .then(() => {
                    console.log('saved to firebase')
                    })
                    .catch(() => console.log('update error'));

                    const updatedRow = {
                      ...row,
                      LABEL: newAccountCode.slice(8,newAccountCode.length),
                      accountCode: newAccountCode,
                      DESCRIPTION: row.description, 
                      TOTAL_ALL_FUNDS: parseFloat(row.budget),
                      GENERAL_FUND: 0,
                      SPECIAL_REVENUE: 0,
                      DEBT_SERVICE: 0,
                      CAPITAL_PROJECTS: 0
                    };

                    switch(row.fund){
                      case '10':
                        updatedRow.GENERAL_FUND+=parseFloat(row.budget);
                        break;
                      case '20':
                        updatedRow.SPECIAL_REVENUE+=parseFloat(row.budget);
                        break;
                      case '30':
                        updatedRow.DEBT_SERVICE+=parseFloat(row.budget);
                        break;
                      case '40':
                        updatedRow.CAPITAL_PROJECTS+=parseFloat(row.budget);
                        break;
                      default: 
                        updatedRow.GENERAL_FUND+=parseFloat(row.budget);
                        break;
                    }
                    // Find the index of the existing entry with the same groupKey
                    const updatedData = [...graphData];
                    const index = updatedData.findIndex(entry => entry.groupKey === row.groupKey);
                    if (index !== -1) {
                      // Replace the existing entry
                      updatedData[index] = updatedRow;
                    } else {
                      // Append the new entry
                      updatedData.push(updatedRow);
                    }
                    setGraphData(updatedData)

                    setSearchObject('');
                    setObjectSuggestions([]);
                    setModalOpen(false);
                    onRefresh();
                    
                }
              })
              }}>Save</button>
            <button onClick={() => {setModalOpen(false); 
                                    setSearchObject(''); 
                                    setObjectSuggestions([]);
                                    setItemExists(false);
                                    }}>
                Cancel
             </button>
          </div>
        </div>
      </div>
    );

    const showRevenueModal = (row) => (
      <div className="add-row-modal">
        <div className="add-row-modal-content">
          <h4>Edit Line Item</h4>
          <label>Description:
            <input
              type="text"
              value={row.description || row.DESCRIPTION}
              onChange={(e) => setModalRow(prev => ({
                ...prev, description: e.target.value}))}
            />
          </label>
          <label>
            Building:
            <input
              type="text"
              value={row.location}
              onChange={(e) => setModalRow(prev => ({
                ...prev, location: e.target.value}))}
            />
          </label>
          <label>
            Project:
            <input
              type="text"
              value={row.project}
              maxLength="5"
              onChange={(e) => setModalRow(prev => ({
                ...prev, project: e.target.value}))}
            />
          </label>
          <label>
            Program:
            <input
              type="text"
              value={row.program}
              maxLength="3"
              onChange={(e) => setModalRow(prev => ({
                ...prev, program: e.target.value}))}
            />
          </label>
          <label>
            Source:
            <input
              type="text"
              value={row.source}
              onChange={(e) => setModalRow(prev => ({
                ...prev, source: e.target.value}))}
            />
          </label>
          <label>
            Fund:
            <select id="fund-select" value={row.fund} onChange={handleFundChange}>
              <option value="" disabled>Select Fund</option>
              {['10','20','30','40'].map((option, index) => (
                  <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </label>
          <label>
            Amount:
            <input
              type="text"
              value={row.budget ?? ""}
              onChange={(e) => {
                const value = e.target.value;
                setModalRow(prev => ({
                  ...prev,
                  budget: value === "" ? "" : parseFloat(value)
                }));
              }}
            />
          </label>
          
          {itemExists && (
            <font color='#FF0000'> Error: {itemExists} already exists. Edit existing item instead.</font>
          )}
          
          <div className="add-row-modal-actions">
          <button onClick={() => {
              // when user clicks save, save to firebase
              let oldAccountCode = modalRow.accountCode;
              let newAccountCode = buildAccountCode(accountCodeSchema['revenue'], {fund: row.fund, function: row.function, object: "0000", location: row.location, source: row.source, project: row.project, program: row.program})
              
              remove(ref(database, `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${oldAccountCode}/budget/${version}`))
                .then(() => console.log('Old account code removed'))
                .catch((error) => console.error('Error removing old account code:', error));


              const keyTotalRef = ref(
                database,
                `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${newAccountCode}/budget/${version}`
              );

              console.log('saving row: ', row, `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${newAccountCode}/budget/${version}`)

              // Check if label already exists. If it does, print error to user, otherwise create the record.
              get(keyTotalRef).then(result => {
                
                if (result.val() && result.val().budget > 0){
                  setItemExists(newAccountCode);
                } else {
                  setItemExists(false);
                  // only want to save a select number of columns to firebase
                  let row_to_save =  {
                    budget: isNaN(parseFloat(row.budget)) ? 0 : parseFloat(row.budget),
                    description: row.description,
                    function: row.function,
                    fund: row.fund,
                    object: row.object === "" ? "0000" : row.object,
                    location: row.location === "" ? "0000" : row.location,
                    program: row.program === "" ? "000" : row.program,
                    project: row.project === "" ? "00000" : row.project,
                    source: row.source,
                    editable: true
                    }

                    set(keyTotalRef, row_to_save)
                    .then(() => {
                    console.log('saved to firebase')
                    })
                    .catch(() => console.log('update error'));

                    const updatedRow = {
                      ...row,
                      LABEL: newAccountCode.slice(8,newAccountCode.length),
                      accountCode: newAccountCode,
                      DESCRIPTION: row.description, 
                      TOTAL_ALL_FUNDS: parseFloat(row.budget),
                      GENERAL_FUND: 0,
                      SPECIAL_REVENUE: 0,
                      DEBT_SERVICE: 0,
                      CAPITAL_PROJECTS: 0
                    };

                    switch(row.fund){
                      case '10':
                        updatedRow.GENERAL_FUND+=parseFloat(row.budget);
                        break;
                      case '20':
                        updatedRow.SPECIAL_REVENUE+=parseFloat(row.budget);
                        break;
                      case '30':
                        updatedRow.DEBT_SERVICE+=parseFloat(row.budget);
                        break;
                      case '40':
                        updatedRow.CAPITAL_PROJECTS+=parseFloat(row.budget);
                        break;
                      default:
                        updatedRow.GENERAL_FUND+=parseFloat(row.budget);
                        break;
                    }
                    // Find the index of the existing entry with the same groupKey
                    const updatedData = [...graphData];
                    const index = updatedData.findIndex(entry => entry.groupKey === row.groupKey);
                    if (index !== -1) {
                      // Replace the existing entry
                      updatedData[index] = updatedRow;
                    } else {
                      // Append the new entry
                      updatedData.push(updatedRow);
                    }
                    setGraphData(updatedData)

                    setSearchObject('');
                    setObjectSuggestions([]);
                    setModalOpen(false);
                    onRefresh();
                    
                }
              })
              }}>Save</button>
            <button onClick={() => {setModalOpen(false); 
                                    setSearchObject('');
                                    setObjectSuggestions([]);
                                    setItemExists(false);
                                    }}>
                Cancel
             </button>
          </div>
        </div>
      </div>
    );

    const showTable = (data, columns, formatted_column_names, year) => (
      <React.Fragment>
      <tr>
        <td colSpan={tableHeaders.length} style={{ padding: '20px', position: 'relative' }}>
        <div className="line-item-table-container">
          <table className="line-item-table"
          >
            <thead>
              <tr>
                {formatted_column_names.map((col) => (
                  <th key={col}>
                    {col}
                  </th>
                ))}
                <th>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {data.filter((item) => (item.YEAR === year)&&(item.budget!==0)).map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                <tr>
                  
                  {columns.map((col) => (
                    <td
                      key={col}
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      
                      {typeof row[col] === "number" ? formatDollars(row[col]) : row[col]}
                        {col==='LABEL' &&
                        <span
                          role="img"
                          aria-label="Show Graph"
                          style={{ cursor: 'pointer', marginLeft: '3px' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShowGraphClick(row[col]);
                          }}
                        >
                         📈
                        </span>
                        }
                    </td>
                   
                  ))}
                  
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}
                  >
                    {row.editable && (<button
                      onClick={() => {
                        handleEditRow(row);
                      }}
                      style={{
                        backgroundColor: "#6695ED",
                        width: "60px",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        padding: "4px",
                        marginBottom: "2px",
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </button>)}
                    {row.editable && (<button
                      onClick={() => {
                        if (window.confirm("Are you sure you want to delete this row?")) {
                          handleDeleteRow(row);
                        }
                      }}
                      style={{
                        backgroundColor: "#ee6b6e",
                        width: "60px",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        padding: "4px 8px",
                        cursor: "pointer",
                        margin: "2px"
                      }}
                    >
                      Delete
                    </button>)}
                    
                  </td>
                  
                </tr>
                {/* Render graph row conditionally */}
                {selectedGraphLabel === row[rowKey] && showGraph(graphData.filter(item => item['accountCode'] === row['accountCode']), row['description'], 8)}
                </React.Fragment>
              ))}
              
              <tr>
                <td colSpan={8} style={{padding: "10px"}}>
                  <span
                    role="img"
                    aria-label="Show Graph"
                    style= {{cursor: "pointer"}}
                    onClick={async (e) => {
                      e.stopPropagation();
                      let template = data[data.length-1];
                     
                      const getRef = ref(
                        database,
                        `budget/${template['ID']}/${template['YEAR']}/${template['GROUP']}/${template['accountCode']}/budget/${version}`
                      );
                      let templateDataFB = await getFirebaseData(getRef);

                      templateDataFB.PARENT = template['PARENT'];
                      templateDataFB.GROUP = template['GROUP'];
                      templateDataFB.GGGPARENT = template['GGGPARENT'];
                      templateDataFB.GGPARENT = template['GGPARENT'];
                      templateDataFB.GPARENT = template['GPARENT'];
                      templateDataFB.ID = template['ID'];
                      templateDataFB.YEAR = fiscalYear;
                      templateDataFB.DISTRICT_NAME = template['DISTRICT_NAME'];
                      templateDataFB.description = 'Add Description Here';
                      templateDataFB.project = '00000';
                      templateDataFB.program = '000';
                      templateDataFB.object = '';
                      templateDataFB.budget = '';
                      templateDataFB.fund = '10';
                      templateDataFB.version = version;
                      templateDataFB.editable = true;
                      templateDataFB.groupKey = Date.now();

                      if(template['GROUP']==='revenue'){
                        templateDataFB.function = getAccountItem(accountCodeSchema['revenue'], template.accountCode, 'function')
                        templateDataFB.source = getAccountItem(accountCodeSchema['revenue'], template.accountCode, 'source')
                      }

                      setModalRow(templateDataFB);
                      setModalOpen(true);
                    }}
                  >
                    ➕ Add row
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </td>
      </tr>
      </React.Fragment>
    );
    
    
    
    const showGraph = (graphData, label, span) => (
      <tr>
        <td colSpan={span} style={{ padding: '20px', position: 'relative' }}>
          <div style={{ width: '100%', height: '400px', overflow: 'hidden', position: 'relative' }}>
            {viewMode === 'graph' ? (
              <div style={{ width: '100%', height: '90%' }}>
                <LineGraph
                  data={graphData}
                  yAxis="TOTAL_ALL_FUNDS"
                  xAxis="YEAR"
                  groupLabel="DISTRICT_NAME"
                  yAxisLabel="Dollars ($)"
                  showLegend={graphOptions['showLegend']}
                  graphTitle={['expense', 'revenue'].includes(label)? String(label[0]).toUpperCase() + String(label).slice(1) : label}
                />
              </div>
            ) : (
              <div>
                <table style={{ backgroundColor: '#eee', border: '10px solid black', margin: '10px auto' }}>
                  <thead>
                    <tr key={label}>
                      <th style={{ textAlign: 'center', padding: '10px' }}>Year</th>
                      {tableHeaders.filter(label => label!=='').map((column) => (
                        <th key={column} style={{ textAlign: 'center', padding: '10px' }}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  {/* Make body of the table that displays the graph data */}
                  <tbody>
                    {getUniqueValues(graphData, 'YEAR').map((row_item, row_index) => (
                      <tr key={row_index}>
                        <td key={'year_label'} align="center">
                          {row_item}
                        </td>
                        {graphData.filter(rows => rows['YEAR'].includes(row_item)).map((row_item, index) => (
                          <td key={index}
                              align="center">
                            {formatDollars(row_item[valueLabel])}
                          </td>
                          ))
                        } 
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <button onClick={toggleViewMode} style={{ position: 'absolute', bottom: '5px', right: '10px' }}>
            {viewMode === 'graph' ? 'View Raw Data' : 'View Graph'}
          </button>
        </td>
      </tr>      
    )
  
    const toggleExpand = (label) => {
      const children = getUniqueValues(tableData.filter(item => item.PARENT === label), rowKey);
      const childrenG2 = getUniqueValues(tableData.filter(item => children.includes(item.PARENT)), rowKey);
      const childrenG3 = getUniqueValues(tableData.filter(item => childrenG2.includes(item.PARENT)), rowKey);
      // if the children are not already in the list of expandedRows, add them, otherwise remove them
      setExpandedRows((prev) => {
        const parentIndex = prev.indexOf(label);
        if (!children.every(element => prev.includes(element))) {
          // Insert children immediately after the parent
          const updatedRows = [...prev];
          updatedRows.splice(parentIndex + 1, 0, ...children);

          // update this JSON object to note that this element's children are expanded
          setAreChildrenExpanded((prev) => {
            const prevCopy = {...prev};
            prevCopy[label] = true;
            return prevCopy
          })
          
          return updatedRows;

        } else {
          // update this JSON object to note that this element's children are no longer expanded
          setAreChildrenExpanded((prev) => {
              const prevCopy = {...prev};
              prevCopy[label] = false;
              children.forEach(item => prevCopy[item] = false);
              childrenG2.forEach(item => prevCopy[item] = false);
              return prevCopy
            })


          let currentExpandedRows = prev.filter(row => (!children.includes(row))&&(!childrenG2.includes(row))&&(!childrenG3.includes(row)));
          // Remove children from expandedRows
          return currentExpandedRows;
        }
      });
    };

    function renderRow(tableData, graphData, label, columnNames, row_index){
        let rowData = tableData.filter(data_item => (data_item[rowKey] === label)&(columnNames.includes(data_item[columnKey])));
        const isExpandable = tableData.filter(entry => entry.PARENT === label).length > 0; // if item has children, it's expandable
        const isExpanded = areChildrenExpanded[label];

        // If there are missing values, fill in with zero
        if(getUniqueValues(rowData,columnKey).length!==columnNames.length-1){
          rowData = expandDataWithYears(rowData, columnNames.slice(1,columnNames.length), "YEAR", "TOTAL_ALL_FUNDS")
        }

        return (
          <React.Fragment key={row_index}>
            <tr>
            {/* First column is a text label that may or may not have an expansion arrow */}
            <td
                style={{
                  cursor: 'pointer',
                  backgroundColor: getShade(rowData[0].LEVEL),
                  paddingLeft: `${rowData[0].LEVEL * 20}px`,
                  maxWidth: '200px'
                }}>
            {/* What to do when user clicks the arrow*/}
            {isExpandable ? ( // if the label is expandable, put the arrow
              <span onClick={() => {
                if(selectedGraphLabel){
                  setSelectedGraphLabel(null)
                };
                toggleExpand(label)
              }}
                    style={{display: 'inline-block',
                            transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
                            transition: 'transform 0.15s',
                            marginRight: '5px',
                            marginLeft: '5px'}}> ▶ </span>
            ) : ( // otherwise, put a dash
              <span> - </span>
            )}
            {/* What to do when user clicks the label*/}
            <span onClick={() => {
              if(selectedGraphLabel){
                setSelectedGraphLabel(null)
              };
              if(editAvailable && !isExpandable){
                handleShowTableClick(label)
              } else {
                toggleExpand(label)
              }
            }
            }>{['expense', 'revenue'].includes(label)? String(label[0]).toUpperCase() + String(label).slice(1) : label}</span>
            {/* What to do when user clicks the graph icon*/}
            {graphAvailable && (<span
                  role="img"
                  aria-label="Show Graph"
                  style={{ cursor: 'pointer', marginLeft: '3px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowGraphClick(label);
                  }}
                >
                  📈
            </span>)}
            {/* What to do when user clicks the pencil icon*/}
            {editAvailable && !isExpandable && (<span
                  role="img"
                  aria-label="Show Table"
                  style={{ cursor: 'pointer', marginLeft: '3px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowTableClick(label);
                  }}
                >
                  ✎
            </span>)}
            </td>
            
            {/* All other columns are data */}
            {rowData.map((item, index)=> (
                <td key={index}
                    align="center"
                    style={{
                      backgroundColor: getShade(rowData[0].LEVEL)
                    }}>
                  {item[valueLabel]}
                </td>
            ))}
            </tr>
            {/* Show the graph if the graph button has been clicked */}
            {selectedGraphLabel === label && showGraph(graphData.filter(item => item[rowKey] === label), label, tableHeaders.length)}
            {isModalOpen && modalRow.GROUP === 'expense' && showExpenseModal(modalRow)}
            {isModalOpen && modalRow.GROUP === 'revenue' && showRevenueModal(modalRow)}
            {selectedTableLabel === label && showTable(graphData.filter(item => (item.PARENT === label) && (item.version === version)).sort((a, b) => Number(a.object) - Number(b.object)), 
                                                       ["LABEL", "DESCRIPTION", "GENERAL_FUND", "SPECIAL_REVENUE", "DEBT_SERVICE", "CAPITAL_PROJECTS", "TOTAL_ALL_FUNDS"],
                                                       ["Label", "Description", "General Fund", "Special Revenue", "Debt Service", "Capital Projects", "Total All Funds"],
                                                       fiscalYear)}
            </React.Fragment>
        )
    }
    ///////////////////////////////////////////////////////////////

    // get the labels for the columns of the table
    const tableHeaders = getUniqueValues(tableData, columnKey);

    tableHeaders.unshift('') // Add header for the first column
    
    return (
      <div className='high-level-table-container'>
        {setFilterModalOpen && 
          (<div className="title-filter-container">
            <h2>{title}</h2>
            <FilterButton 
              filtersApplied={filtersApplied}
              setFilterModalOpen={setFilterModalOpen}
              clearFilters={clearFilters}
            />
            </div>)}
          <table className="high-level-table">
            <thead>
              {/* column labels */}
              <tr>
                {tableHeaders.map((header, index) => (
                  <th key={index}>{header}
                    {header===fiscalYear && (
                      <BudgetMenuButton 
                        data = {graphData.filter(item=>item.YEAR===fiscalYear)}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {/* table body */}
            <tbody>
              {/* Three possible states: loading, no data, or has data */}
              {!tableData ? (
                <tr>
                  <td colSpan={tableHeaders.length}>Loading...</td>
                </tr>
              ) : tableData.length > 0 ? (
                <>
                  {expandedRows.map((label, index) =>
                    renderRow(tableData, graphData, label, tableHeaders, index)
                  )}
                  <tr style={{ backgroundColor: "#e0e0e0" }}>
                    <td>Net</td>
                    {tableHeaders.slice(1, tableHeaders.length).map((header, index) => (
                      <td key={index} style={{ textAlign: "center" }}>
                        {formatDollars(
                          parseCurrency(
                            tableData.find(item => item.LABEL === 'revenue' && item.YEAR === header)?.TOTAL_ALL_FUNDS
                          ) - 
                          parseCurrency(
                            tableData.find(item => item.LABEL === 'expense' && item.YEAR === header)?.TOTAL_ALL_FUNDS
                          )
                        )}
                      </td>
                    ))}
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan={tableHeaders.length}>No data to display. Try different filters.</td>
                </tr>
              )}
            </tbody>
            
              
          </table>
    </div>
    )
};

export default GeneralDrilldownTable;