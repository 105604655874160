export const Levy = ({ levy, setLevy, handleInputChange, totalLevy }) => {

return (
  <div className="levy-card">
    <h3>Levy</h3>
    <div className="levy-table">
      <div className="levy-input-group">
        <label>Operating: </label>
        <input
          type="number"
          value={levy.operating}
          onChange={(e) => handleInputChange(e, setLevy, 'operating', 'levy')}
        />
      </div>
      <div className="levy-input-group">
        <label>Teacher: </label>
        <input 
          type="number" 
          value={levy.teacher}
          onChange={(e) => handleInputChange(e, setLevy, 'teacher', 'levy')}
        />
      </div>
      <div className="levy-input-group">
        <label>Capital: </label>
        <input 
          type="number" 
          value={levy.capital}
          onChange={(e) => handleInputChange(e, setLevy, 'capital', 'levy')}
        />
      </div>
      <div className="levy-input-group">
        <label>Debt: </label>
        <input 
          type="number" 
          value={levy.debt}
          onChange={(e) => handleInputChange(e, setLevy, 'debt', 'levy')} 
        />
      </div>
    <div className="total-levy">Total: {totalLevy.toFixed(4)}</div>
    </div>
  </div>
)
}