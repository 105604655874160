import React, { useState } from 'react';

const CurrencyInput = ({ value, onChange, disabled = false }) => {
  const [isFocused, setIsFocused] = useState(false);

  // Format number as currency
  const formatAsCurrency = (num) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num);
  };

  return (
    <input
      type="text"
      value={isFocused ? value || '' : formatAsCurrency(value || 0)}
      onChange={(e) => {
        const rawValue = e.target.value.replace(/[^0-9.-]/g, '');
        onChange(rawValue);
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      disabled={disabled}
      style={{ 
        textAlign: 'right',
        paddingRight: '8px',
        marginRight: '-8px',
        width: '90%',
        border: 'none',
        fontSize: '16px'
      }}
    />
  );
};

export default CurrencyInput; 