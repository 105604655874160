import React, { useState, useEffect, useMemo } from "react";
import { database } from "../../firebaseConfig";
import { ref, get, set, update } from "firebase/database";
import "../../styles/StaffManagement.css";
import {determineCertifiedObjectCode, updateSelectStaff} from "../Utils/UpdateStaff"
import {createNewRole, 
        updateCertifiedRole, 
        useDetailsAutoClose, 
        updateBenefitCheckbox, 
        subtractLineItemsFromBudget} from "../Utils/StaffTableUtils"
import { EmployeeDetailsModal } from "./EmployeeDetailsModal";

const formatDollars = (num) => `$${Math.round(num).toLocaleString()}`;

const OBJECT_CODES = {
  6111: "Regular Cert Salaries",
  6112: "Administrator Salaries", 
  6121: "Substitute Salaries",
  6122: "Other Part-Time Cert Salaries",
  6131: "Cert Staff Supplemental Pay",
  6141: "Cert Employees Unused Leave and/or Severance Pay"
};

const CertifiedEmployeeTable = ({
    districtCode,
    fiscalYear,
    version,
    benefitRates,
    buildingCode,
    functionCode,
    accountCodeSchema,
    onDataUpdate,
  }) => {

  const salarySchedulePath = `salarySchedules/${districtCode}/${fiscalYear}/${version}`;
  const staffPath = `staff/${districtCode}/${fiscalYear}/${version}`;
  const benefitsPath = `benefitRates/${districtCode}/${fiscalYear}/${version}`;
  const dataPaths = {'staffPath': staffPath, 'benefitsPath': benefitsPath}

  const [Rows, setRows] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [objectCode, setObjectCode] = useState("");
  const [availableSalarySchedules, setAvailableSalarySchedules] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [classifiedSalarySchedules, setClassifiedSalarySchedules] = useState([]);
  const [notification, setNotification] = useState("");

  useDetailsAutoClose(); // This automatically closes the benefits dropdown when the user clicks outside of it

  function buildAccountCode(format, data) {
    return format.split('-') // Split the format string into an array
                 .map(key => data[key]) // Map each key to its corresponding value in the data object
                 .join('-'); // Join the values with '-'
  }

  const handleDeleteEmployee = async (row) => {
    const updatedRows = Rows.filter((r) => r.uuid !== row.uuid);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (!confirmDelete) return;

    // Remove the specific row from Firebase
    const rowRef = ref(
      database,
      `${staffPath}/${functionCode}/${buildingCode}/certified/staff/${row.uuid}`
    );
    await set(rowRef, null);

    subtractLineItemsFromBudget(row, determineCertifiedObjectCode, buildAccountCode, accountCodeSchema, districtCode, fiscalYear, version);

    // If no rows remain, remove the totals node
    if (updatedRows.length === 0) {
      const aggregateRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/certified/totals`
      );
      await set(aggregateRef, null);
        
    } else {
      // Recalculate totals
      const aggregated = updatedRows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.salary += row.salary || 0;
          acc.extendedContract += row.extendedContract || 0;
          acc.careerLadder += row.careerLadder || 0;
          acc.totalCost += row.totalCost || 0;
          acc.insuranceBenefits += row.insuranceBenefits || 0;
          acc.medicare += row.medicare;
          acc.retirement += row.retirement;
          return acc;
        },
        {
          fte: 0,
          salary: 0,
          extendedContract: 0,
          careerLadder: 0,
          totalCost: 0,
          insuranceBenefits: 0,
          medicare: 0,
          retirement: 0,
        }
      );

      const aggregateRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/certified/totals`
      );
      await set(aggregateRef, {
        ...aggregated,
        salaryWithExtendedContract: aggregated.salary + aggregated.extendedContract + aggregated.careerLadder,
      });
    }

    // Update local state
    setRows(updatedRows);
  }

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(""), 3000); // Clear the notification after 3 seconds
  };

  const openDetailsModal = (row) => {
    setSelectedRow(row);
    setObjectCode(row.objectCode || determineCertifiedObjectCode("salary", functionCode));
    setModalOpen(true);
  };

  const getSchedule = (lane, step, schedule) => {
    const lane_name = availableSalarySchedules[schedule]?.['careerLanes'][lane-1].name || 'None';
    const max_num_steps = availableSalarySchedules[schedule]?.['salarySchedule'][lane_name].length - 1;

    if(step<=max_num_steps){
      return availableSalarySchedules[schedule]?.['salarySchedule'][lane_name][step] || {};
    } else {
      return availableSalarySchedules[schedule]?.['salarySchedule'][lane_name][max_num_steps] || {};
    }
    
  }

  const fetchSalary = async (scheduleToUse, lane, step, salaryType) => {
    if (!lane || step === null || step === undefined)
      return 0;

    try {
      if (getSchedule(lane, step, scheduleToUse)[salaryType]) return getSchedule(lane, step, scheduleToUse)[salaryType];
      console.error("No salary found for the given inputs");
      return 0;
    } catch (error) {
      console.error("Error fetching salary: ", error);
      return 0;
    }
  };

  const saveDetails = async (selectedRow, modalFields) => {

    // Remove line items from budget in case object, project, program, etc have changed
    await subtractLineItemsFromBudget(Rows.filter(item=>item.uuid===selectedRow.uuid)[0], determineCertifiedObjectCode, buildAccountCode, accountCodeSchema, districtCode, fiscalYear, version)
    
    modalFields.forEach(item=> {
      if(selectedRow[item]){
        updateRow(selectedRow.uuid, item, selectedRow[item])
      }
    })

    setModalOpen(false);
  };

  // Use useEffect to call saveDataToFirebase after Rows is updated
  useEffect(() => {
    if (Rows.some(row => row.hasChanged)) {
      saveDataToFirebase();
    }
  }, [Rows]);

  useEffect(() => {

    // Get available names of salary schedules for details drop down
    get(ref(database, `${salarySchedulePath}/`)).then(result=>{
      setAvailableSalarySchedules(result.val()['certified'])
      setClassifiedSalarySchedules(result.val()['classified'])
    });

  }, [districtCode, fiscalYear, salarySchedulePath]);

  const addRow = async () => {
    const newRow = createNewRole();
    newRow.uuid = Date.now();
    const optionalBenefits = Object.keys(benefitRates).filter((key) => !['certifiedRetirement', 'medicareRate', 'peersRate', 'socialSecurityRate', 'contractDays'].includes(key));
    newRow['benefits'] = optionalBenefits.reduce((acc, key) => {acc[key] = 0; return acc;}, {});
    newRow['location'] = buildingCode;
    newRow['function'] = functionCode;
    newRow['fund'] = '20';
    newRow['object'] = '6111';
    newRow['source'] = '3';
    newRow['program'] = '000';
    newRow['project'] = '00000';
    newRow['employeeType'] = 'certified';
    newRow['scheduleToUse'] = 'Certified';
    newRow['lane'] = 1;
    newRow['step'] = 1;
    setRows([...Rows, newRow]);
    const baseSalary = await fetchSalary(newRow.scheduleToUse, newRow.lane, newRow.step, 'salary')
    updateCertifiedRole(newRow.uuid, baseSalary, baseSalary, setRows, benefitRates);
  };

  const updateRow = async (uuid, key, value) => {
    let updatedRow = null;
  
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowIndex = updatedRows.findIndex((row) => row.uuid === uuid);
  
      let updatedValue;
      if (rowIndex !== -1) {
        const row = updatedRows[rowIndex];
        if(value===''){
          updatedValue = '';
        } else {
          updatedValue =
            (key === "step" && value < 1)
              ? 1
              : ["fte", "extendedContract", "careerLadder", "salary", "fullFTEsalary"].includes(key) ? !isNaN(value) ? 
              parseFloat(value) : 0
              : value;
        }

        updatedRows[rowIndex] = {
          ...row, 
          [key]: updatedValue,
          hasChanged: true,
          lane: (key === "scheduleToUse" && value !== 'Manual Entry' && row.lane === 'manualEntry') ? 1 : key === 'lane' ? updatedValue : row.lane,
          step: (key === "scheduleToUse" && value !== 'Manual Entry' && row.lane === 'manualEntry') ? 1 : key === 'step' ? updatedValue : row.step,
          scheduleToUse: (key === "lane" && value === 'manualEntry') ? 'Manual Entry' : key === "scheduleToUse" ? updatedValue : row.scheduleToUse,
          totalContract: (row.fullFTEsalary || 0) * (row.fte || 1) + (row.extendedContract || 0)
        };
  
        updatedRow = updatedRows[rowIndex]; // Capture updated row immediately
      }
  
      return updatedRows;
    });
  
    // Use a timeout to wait for the state update
    setTimeout(async () => {
      if (updatedRow) {
        if (updatedRow.scheduleToUse!=='Manual Entry' && updatedRow.lane!=='manualEntry') {
          const districtSalary = await fetchSalary(
            updatedRow.scheduleToUse,
            key === "lane" ? value : updatedRow.lane,
            key === "step" ? Math.max(value, 1) : updatedRow.step,
            'baseSalary'
          )

          const fullSalary = await fetchSalary(
                updatedRow.scheduleToUse,
                key === "lane" ? value : updatedRow.lane,
                key === "step" ? Math.max(value, 1) : updatedRow.step,
                'salary'
              )
          updateCertifiedRole(uuid, fullSalary, districtSalary, setRows, benefitRates);
        } else {
          updateCertifiedRole(uuid, updatedRow.fullFTEsalary, updatedRow.fullFTEsalary, setRows, benefitRates);
        }
      }
    }, 0);
  };
  
  // Main save function
  const saveDataToFirebase = async () => {
    try {
      // 1. Save individual staff data
      const saveRowPromises = Rows.filter((row) => row.hasChanged).map((row) => {
        const rowRef = ref(
          database,
          `${staffPath}/${functionCode}/${buildingCode}/certified/staff/${row.uuid}`
        );
        return update(rowRef, row); // Use update instead of set for partial updates
      });
      await Promise.all(saveRowPromises);
      
      await updateSelectStaff(Rows, availableSalarySchedules, classifiedSalarySchedules, benefitRates, dataPaths, districtCode, fiscalYear, version);
      
      // Reset `hasChanged` for all rows
      setRows((prevRows) =>
        prevRows.map((row) => ({ ...row, hasChanged: false }))
      );

      showNotification("Saved");
    } catch (error) {
      showNotification("Error saving certified data: ", error);
      console.log('error: ', error)
    }
  };
  
  
  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        // Fetch individual staff rows
        const rowsRef = ref(
          database,
          `${staffPath}/${functionCode}/${buildingCode}/certified/staff`
        );
        const snapshot = await get(rowsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          let rows = Object.values(data); // Convert the fetched object into an array
          setRows(rows); // Populate the table rows
        }
      } catch (error) {
        console.error("Error loading staff data: ", error);
      }
    };
    setTimeout(() => {
      fetchStaffData();
    }, 0);
  }, [districtCode, fiscalYear, functionCode, buildingCode, staffPath]);  

  useEffect(() => {
    const aggregated = Rows.reduce(
      (acc, row) => {
        acc.fte += row.fte || 0;
        acc.salary += row.salary || 0;
        acc.totalCost += row.totalCost || 0;
        return acc;
      },
      { fte: 0, salary: 0, totalCost: 0 }
    );
  
    onDataUpdate(aggregated); // Notify parent
  }, [Rows]); // Only re-run when `Rows` changes

  useEffect(() => {
    if (isFirstRender && Rows.length > 0) {
      // Create a sorted copy (don't mutate the original)
      const sortedRows = [...Rows].sort((a, b) => 
        String(a.last).localeCompare(String(b.last))
      );
      
      // Replace your Rows state with the sorted version
      setRows(sortedRows);
      
      // Mark initial sort as complete
      setIsFirstRender(false);
    }
  }, [Rows, isFirstRender]);
  

  return (
    <div className="certified-staff-table-container">
      {notification && (
      <div className="notification">
        {notification}
      </div>
    )}
      <h3>Certified Roles</h3>
      <table className="certified-staff-table">
      <colgroup>
        <col style={{ width: "7%" }} />
        <col style={{ width: "7%" }} />
        <col style={{ width: "1%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "7%" }} />
        <col style={{ width: "1%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "5%" }} />
        <col style={{ width: "5%" }} />
      </colgroup>
        <thead>
          <tr>
            <th>Last</th>
            <th>First</th>
            <th>FTE</th>
            <th>Benefits</th>
            <th>Position</th>
            <th>Lane</th>
            <th>Step</th>
            <th>Salary</th>
            <th>Extra Days</th>
            <th>Extended Contract</th>
            <th>Effective Salary</th>
            <th>Total Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Rows.map((row) => (
            <tr key={row.uuid}>
              <td>
                <input
                  type="text"
                  value={row.last}
                  onChange={(e) =>
                    updateRow(row.uuid, "last", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.first}
                  onChange={(e) =>
                    updateRow(row.uuid, "first", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.fte}
                  min="0"
                  max="1"
                  step="0.01"
                  onChange={(e) => {
                    let value = parseFloat(e.target.value);

                    // Auto-correct values outside the range [0, 1]
                    if (value > 1) value = 1;
                    if (value < 0) value = 0;

                    // Update the input field and application state
                    e.target.value = value; // Correct the displayed value
                    updateRow(row.uuid, "fte", value);
                  }}
                />
              </td>
              <td>
              <div className="dropdown-container">
                <details>
                  <summary>Show</summary>
                  <div className="dropdown-content">
                    {Object.entries(row.benefits).map(([name, status]) => (
                      <label key={name} className="dropdown-item">
                        <input
                          type="checkbox"
                          checked={!!status}
                          onChange={(e) => updateBenefitCheckbox(row.uuid, name, e.target.checked ? 1 : 0, updateCertifiedRole, setRows, benefitRates, 'certified')}
                        />{" "}
                        {name}
                      </label>
                    ))}
                  </div>
                </details>
              </div>
              </td>

              <td>
                <input
                  type="text"
                  value={row.position}
                  onChange={(e) =>
                    updateRow(row.uuid, "position", e.target.value)
                  }
                />
              </td>
              <td>
                <select
                  value={row.scheduleToUse === 'Manual Entry' ? 'manualEntry' : (availableSalarySchedules[row.scheduleToUse]?.['careerLanes'] || []).map(item => item.name)[row.lane-1]}
                  onChange={(e) => {
                    const options = (availableSalarySchedules[row.scheduleToUse]?.['careerLanes'] || []).map(item => item.name);
                    const selectedIndex = options.indexOf(e.target.value); // Get index of selected value
                    if(selectedIndex!=-1){
                      updateRow(row.uuid, "lane", selectedIndex + 1); // Adjust index to match `lane`
                    }else{
                      updateRow(row.uuid, "lane", "manualEntry")
                    }
                  }}
                >
                  <option value="">Select</option>
                  {(availableSalarySchedules[row.scheduleToUse]?.['careerLanes'] || []).map(item => item.name).map((column) => (
                      <option key={column} value={column}>
                        {column}
                      </option>
                  ))}
                  <option key={'manualEntry'} value={'manualEntry'}>{'Manual Entry'}</option>
                </select>
              </td>
              <td>
                {(row.scheduleToUse === 'Manual Entry') ? ("") : (<input
                  type="number"
                  value={row.step}
                  onChange={(e) =>
                    updateRow(row.uuid, "step", parseInt(e.target.value, 10))
                  }
                />)}
              </td>
              
              <td>
                {(row.scheduleToUse === 'Manual Entry') ? (
                  <input 
                    type="text"
                    value={row.fullFTEsalary}
                    onChange={(e) =>
                      updateRow(row.uuid, "fullFTEsalary", e.target.value)
                    }
                  />
                ) : (
                  formatDollars(row.fullFTEsalary)
                )}
              </td>
              <td>
                <input
                  type="number"
                  value={row.extraDays}
                  onChange={(e) => {
                    updateRow(row.uuid, "extraDays", e.target.value)
                  }
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.extendedContract}
                  onChange={(e) => {
                    updateRow(row.uuid, "extendedContract", e.target.value)
                  }
                  }
                />
              </td>
              <td>{formatDollars(row.salary + (row.extraDaysSalary || 0) + (row.extendedContract || 0))}</td>
              <td>{formatDollars(row.totalCost)}</td>
              <td>
              <button
                onClick={async () => {
                  handleDeleteEmployee(row)
                }}
              >
                Delete
              </button>
              <button
                  onClick={() => openDetailsModal(row)}
                  className="details-btn"
                >
                  Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">Totals</td>
          <td>{Rows.reduce((sum, row) => sum + (row.fte || 0), 0).toFixed(2)}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{Rows.reduce((sum, row) => sum + (row.step || 0), 0)}</td>
          <td></td>
          <td></td>
          <td>
            {formatDollars(
              Rows.reduce((sum, row) => sum + (row.extendedContract || 0), 0)
            )}
          </td>
          <td>{formatDollars(Rows.reduce((sum, row) => sum + (row.salary || 0), 0))}</td>
          <td>
            {formatDollars(
              Rows.reduce((sum, row) => sum + (row.totalCost || 0), 0)
            )}
          </td>
          <td></td>
        </tr>
      </tfoot>
      </table>
      {/***** Employee details modal ******/}
      {isModalOpen && <EmployeeDetailsModal selectedRow={selectedRow}
                                            setSelectedRow={setSelectedRow} 
                                            objectCodes={OBJECT_CODES}
                                            availableSalarySchedules={availableSalarySchedules}
                                            saveDetails={saveDetails}
                                            setModalOpen={setModalOpen}/>}
      <button onClick={addRow} className ="add-row-btn" >Add Certified Role</button>
      
    </div>
  );
};

export default React.memo(CertifiedEmployeeTable);