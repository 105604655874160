import React, { useState, useEffect } from 'react';
import { database } from "../../firebaseConfig";
import { ref, get, set, update } from "firebase/database";
import '../../styles/RevenueCalc.css';
import {StateRevenueCalculatorK8} from './StateRevenueCalculatorK8';
import {StateRevenueCalculatorK12} from './StateRevenueCalculatorK12';
import {ClassroomTrustDistribution} from './ClassroomTrustDistribution';
import {PropCDistribution} from './PropCDistribution';
import {BasicFormulaDistribution} from './BasicFormulaDistribution';
import {StateRevenueModal} from './StateRevenueModal';
import {AssessedValues} from './AssessedValues';
import {Levy} from './Levy';
import {TaxCollectionRatio} from './TaxCollectionRatio';
import axios from "axios";

async function dropUnEditableRevenueItems(districtCode, fiscalYear, version) {
  const snapshot = await get(ref(database, `budget/${districtCode}/${fiscalYear}/revenue`));
  const data = snapshot.val();

  Object.keys(data).forEach(item=> {
    if(!data[item]?.['budget']?.[version]?.['editable'] && data[item]?.['budget']?.[version]?.['project']!=='34100'){
      if(data[item]['budget'][version]){
        data[item]['budget'][version]['budget'] = 0
      } else {
        data[item]['budget'][version] = null // clear old items that aren't connected to the current version
      }
    }
  })

  await set(ref(database, `budget/${districtCode}/${fiscalYear}/revenue`), data)

  return console.log("Un-Editable Budget Items cleared");
}

const RevenueCalc = ({selectedDistrictInfo, version, fiscalYear}) => {

  const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];
  const [levy, setLevy] = useState({ operating: 0, teacher: 0, capital: 0, debt: 0 });
  const [assessedValues, setAssessedValues] = useState([{ county: '', values: {amount: 0, program: '000', project: '00000', source: '0', location: '0000'} }]);
  const [taxCollection, setTaxCollection] = useState({ current: 0, delinquent: 0 });
  const [stateRev, setStateRev] = useState({});
  const [stateRevDist, setStateRevDist] = useState({CTF_GENERAL_FUND: 0, CTF_SPECIAL_REVENUE: 60, CTF_DEBT_SERVICE: 0, CTF_CAPITAL_PROJECTS: 40, 
                                                    PROPC_GENERAL_FUND: 0, PROPC_SPECIAL_REVENUE: 100, BFORM_GENERAL_FUND: 0, BFORM_SPECIAL_REVENUE: 100});
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [K8orFL, setK8orFL] = useState('')
  const [accountCodeSchema, setAccountCodeSchema] = useState({})
  const [pendingSaves, setPendingSaves] = useState({
    levy: false,
    assessedValues: false,
    taxCollection: false,
    vocationTuition: false,
    stateRevenue: false
  });
  const [waitingForAPI, setWaitingForAPI] = useState(false);
  const [stateRevModalOpen, setStateRevModalOpen] = useState(false);
  const [baselineSalaryGrantAmount, setBaselineSalaryGrantAmount] = useState(0);

  function buildAccountCode(format, data) {
    return format.split('-') // Split the format string into an array
                 .map(key => data[key]) // Map each key to its corresponding value in the data object
                 .join('-'); // Join the values with '-'
  }

  const loadData = async () => {
    try {
      const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;
      const levyRef = ref(database, `${revenueMetadataPath}/levy`);
      const assessedValueRef = ref(database, `${revenueMetadataPath}/estAssessedValue`);
      const distributionsRef = ref(database, `${revenueMetadataPath}/stateRevDistributions`);
      const taxCollectionRef = ref(database, `${revenueMetadataPath}/taxCollection`);
      const stateRevRef = ref(database, `${revenueMetadataPath}/stateRev`);
      const accountCodeSchemaPromise = await get(ref(database, `accountCodeSchema`))
      const [levySnapshot, assessedValueSnapshot, distributionsSnapshot, taxCollectionSnapshot, stateRevSnapshot] = await Promise.all([

        get(levyRef),
        get(assessedValueRef),
        get(distributionsRef),
        get(taxCollectionRef),
        get(stateRevRef),
      ]);

      const schema = accountCodeSchemaPromise.val()[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]
      setAccountCodeSchema(schema)
      
      if (stateRevSnapshot.exists()){
        const data = stateRevSnapshot.val();
        setStateRev(prev => ({
          ... prev,
          ... data
        }))
      }

      if (distributionsSnapshot.exists()){
        const data = distributionsSnapshot.val();
        setStateRevDist(data)
      }

      if (levySnapshot.exists()) {
        const data = levySnapshot.val();
        setLevy({
          operating: data.GENERAL_FUND || 0,
          teacher: data.SPECIAL_REVENUE || 0,
          debt: data.DEBT_SERVICE_FUND || 0,
          capital: data.CAPITAL_FUND || 0
        });
      }

      if (assessedValueSnapshot.exists()) {
        const data = assessedValueSnapshot.val();
        setAssessedValues(Object.keys(data).map((county) => (
          {
          county: county || '',
          values: {
            amount: data[county].amount || 0,
            program: data[county].program || '000',
            project: data[county].project || '00000',
            source: data[county].source || '0',
            location: data[county].location || '0000'
          }
        })));
      } else {
        setAssessedValues([{
          county: '',
          values: {
            amount: 0,
            program: '000',
            project: '00000',
            source: '0',
            location: '0000'
          }
        }]);
      }

      if (taxCollectionSnapshot.exists()) {
        const data = taxCollectionSnapshot.val();
        setTaxCollection({
          current: data.current || 0,
          delinquent: data.delinquent || 0
        });
      }

      const blsgAccountCode = buildAccountCode(schema['revenue'], {fund: '20', function: '5341', object: '0000', source: stateRevDist?.codeInfo?.['5341 - Baseline Salary Grant']?.source || '0', program: '000', project: '34100', location: '0000'})
      const baselineSalaryGrantPromise = await get(ref(database, `budget/${districtCode}/${fiscalYear}/revenue/${blsgAccountCode}/budget/${version}/budget`))
      setBaselineSalaryGrantAmount(baselineSalaryGrantPromise.val())

    }
     catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setIsInitialLoad(false);
    }
  };

  const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://app.balancehq.com' 
  : 'http://localhost:5001';

  const handleInputChange = (event, stateSetter, key, section) => {
    const value = event.target.value;//parseFloat(event.target.value.replace(/,/g, '')) || 0;
    stateSetter(prevState => ({ ...prevState, [key]: value }));
    if (section) {
      setPendingSaves(prev => ({ ...prev, [section]: true }));
    }
  };

  const handleAssessedValueChange = (event, countyIndex, key) => {
    let value;
    if(['program', 'project', 'source', 'location', 'county'].includes(key)){
      value = event.target.value.replace(/,/g, '');
    } else {
      value = parseFloat(event.target.value.replace(/[,|$]/g, '')) || 0;
    }

    setAssessedValues((prevState) => {
      const newState = [...prevState];
      if (key === 'county') {
        newState[countyIndex].county = value;
        return newState;
      } else {
        newState[countyIndex].values[key] = value;
        return newState;
      }
    });
    setPendingSaves(prev => ({ ...prev, assessedValues: true }));
  };

  const calculateTotal = (values) => 
    Object.values(values).reduce((acc, val) => acc + (typeof val === 'string' ? parseFloat(val) || 0 : val), 0);
  

  const calculateAssessedValueTotal = () => 
    Object.values(assessedValues).reduce((total, county) => total + county.values.amount, 0);


  const totalLevy = calculateTotal(levy);
  const totalAssessedValue = calculateAssessedValueTotal();
  const currentTaxRevenue = ((totalAssessedValue * totalLevy / 100) * (taxCollection.current / 100));
  const delinquentTaxRevenue = ((totalAssessedValue * totalLevy / 100) * (taxCollection.delinquent / 100));

  // Add new function to handle total calculations and saves
  const saveTotals = async () => {
    if (isInitialLoad || !accountCodeSchema) return;
  
    const baseBasicFormulaCode = {
      function: '5311',
      object: '0000',
      location: stateRevDist?.codeInfo?.['5311 - Basic Formula']?.location || '0000',
      program: stateRevDist?.codeInfo?.['5311 - Basic Formula']?.program || '000',
      source: stateRevDist?.codeInfo?.['5311 - Basic Formula']?.source || '0',
      project: stateRevDist?.codeInfo?.['5311 - Basic Formula']?.project || '00000'
    };

    const basicFormulaAccountCode1 = buildAccountCode(accountCodeSchema['revenue'], { ...baseBasicFormulaCode, fund: '10' })
    const basicFormulaAccountCode2 = buildAccountCode(accountCodeSchema['revenue'], { ...baseBasicFormulaCode, fund: '20' })
    
    const baseClassroomTrustCode = {
      function: '5319',
      object: '0000',
      location: stateRevDist?.codeInfo?.['5319 - Classroom Trust']?.location || '0000',
      program: stateRevDist?.codeInfo?.['5319 - Classroom Trust']?.program || '000',
      source: stateRevDist?.codeInfo?.['5319 - Classroom Trust']?.source || '0',
      project: stateRevDist?.codeInfo?.['5319 - Classroom Trust']?.project || '00000'
    };

    const classroomTrustAccountCode1 = buildAccountCode(accountCodeSchema['revenue'], { ...baseClassroomTrustCode, fund: '10' })
    const classroomTrustAccountCode2 = buildAccountCode(accountCodeSchema['revenue'], { ...baseClassroomTrustCode, fund: '20' })
    const classroomTrustAccountCode3 = buildAccountCode(accountCodeSchema['revenue'], { ...baseClassroomTrustCode, fund: '30' })
    const classroomTrustAccountCode4 = buildAccountCode(accountCodeSchema['revenue'], { ...baseClassroomTrustCode, fund: '40' })

    const basePropCCode = {
      function: '5113',
      object: '0000',
      location: stateRevDist?.codeInfo?.['5113 - Prop C']?.location || '0000',
      program: stateRevDist?.codeInfo?.['5113 - Prop C']?.program || '000',
      source: stateRevDist?.codeInfo?.['5113 - Prop C']?.source || '0',
      project: stateRevDist?.codeInfo?.['5113 - Prop C']?.project || '00000'
    };
    
    const propCAccountCode1 = buildAccountCode(accountCodeSchema['revenue'], { ...basePropCCode,  fund: '10', function: '5113'})
    const propCAccountCode2 = buildAccountCode(accountCodeSchema['revenue'], { ...basePropCCode, fund: '20', function: '5113'})

    let currentTaxRevenue = assessedValues.map(item => {
      const baseLocalAcctcode = { 
          fund: '10', 
          function: '5111', 
          object: '0000', 
          location: stateRevDist?.codeInfo?.[item.county]?.location || '0000', 
          program: stateRevDist?.codeInfo?.[item.county]?.program || '000', 
          source: stateRevDist?.codeInfo?.[item.county]?.source || '0', 
          project: stateRevDist?.codeInfo?.[item.county]?.project || '00000'
      };
  
      return {
          operatingAccountCode: buildAccountCode(accountCodeSchema['revenue'], { ...baseLocalAcctcode, fund: '10' }),
          teacherAccountCode: buildAccountCode(accountCodeSchema['revenue'], { ...baseLocalAcctcode, fund: '20' }),
          debtAccountCode: buildAccountCode(accountCodeSchema['revenue'], { ...baseLocalAcctcode, fund: '30' }),
          capitalAccountCode: buildAccountCode(accountCodeSchema['revenue'], { ...baseLocalAcctcode, fund: '40' }),
  
          operatingRevenue: (item.values.amount * levy.operating / 100) * (taxCollection.current / 100),
          teacherRevenue: (item.values.amount * levy.teacher / 100) * (taxCollection.current / 100),
          debtRevenue: (item.values.amount * levy.debt / 100) * (taxCollection.current / 100),
          capitalRevenue: (item.values.amount * levy.capital / 100) * (taxCollection.current / 100)
      };
    });

    let delinqTaxRevenue = assessedValues.map(item => {
      const baseLocalAcctcode = { 
        fund: '10', 
        function: '5112', 
        object: '0000', 
        location: stateRevDist?.codeInfo?.[item.county]?.location || '0000', 
          program: stateRevDist?.codeInfo?.[item.county]?.program || '000', 
          source: stateRevDist?.codeInfo?.[item.county]?.source || '0', 
          project: stateRevDist?.codeInfo?.[item.county]?.project || '00000'
      };

      return {
        operatingAccountCode: buildAccountCode(accountCodeSchema['revenue'], { ...baseLocalAcctcode, fund: '10'}),
        teacherAccountCode: buildAccountCode(accountCodeSchema['revenue'], { ...baseLocalAcctcode, fund: '20'}),
        debtAccountCode: buildAccountCode(accountCodeSchema['revenue'], { ...baseLocalAcctcode, fund: '30'}),
        capitalAccountCode: buildAccountCode(accountCodeSchema['revenue'], { ...baseLocalAcctcode, fund: '40'}),

        operatingRevenue: (item.values.amount * levy.operating / 100) * (taxCollection.delinquent / 100),
        teacherRevenue: (item.values.amount * levy.teacher / 100) * (taxCollection.delinquent / 100),
        debtRevenue: (item.values.amount * levy.debt / 100) * (taxCollection.delinquent / 100),
        capitalRevenue: (item.values.amount * levy.capital / 100) * (taxCollection.delinquent / 100)
      }
    });

    let classroomTrustValue = typeof stateRev.classroomTrust === 'string' 
    ? stateRev.classroomTrust.replace(/[^\d]/g, '') 
    : stateRev.classroomTrust;

    let propCValue = typeof stateRev.propCamount === 'string' 
    ? Math.floor(parseFloat(stateRev.propCamount.replace(/[^0-9.]/g, '')))
    : stateRev.propCamount;

    let basicFormulaValue = typeof stateRev.basicFormula === 'string' 
    ? stateRev.basicFormula.replace(/[^\d]/g, '') 
    : stateRev.basicFormula;

    let share_ctf_general_fund = (stateRevDist.CTF_GENERAL_FUND / 100) * parseInt(classroomTrustValue, 10);
    let share_ctf_special_revenue = (stateRevDist.CTF_SPECIAL_REVENUE/100)*parseInt(classroomTrustValue, 10);
    let share_ctf_debt_service = (stateRevDist.CTF_DEBT_SERVICE/100)*parseInt(classroomTrustValue, 10);
    let share_ctf_capital_projects = (stateRevDist.CTF_CAPITAL_PROJECTS/100)*parseInt(classroomTrustValue, 10);

    let share_propc_general_fund = (stateRevDist.PROPC_GENERAL_FUND / 100) * parseInt(propCValue, 10);
    let share_propc_special_revenue = (stateRevDist.PROPC_SPECIAL_REVENUE/100)*parseInt(propCValue, 10);

    let share_bform_general_fund = (stateRevDist.BFORM_GENERAL_FUND / 100) * parseInt(basicFormulaValue, 10);
    let share_bform_special_revenue = (stateRevDist.BFORM_SPECIAL_REVENUE/100)*parseInt(basicFormulaValue, 10);

    try {
      let updates = {
        [`budget/${districtCode}/${fiscalYear}/revenue/${propCAccountCode1}/budget/${version}`]: {
          budget: Math.round(parseFloat(share_propc_general_fund)),
          fund: '10',
          editable: false
        },
        [`budget/${districtCode}/${fiscalYear}/revenue/${propCAccountCode2}/budget/${version}`]: {
          budget: Math.round(parseFloat(share_propc_special_revenue)),
          fund: '20',
          editable: false
        },
        [`budget/${districtCode}/${fiscalYear}/revenue/${basicFormulaAccountCode1}/budget/${version}`] : {
          budget: Math.round(parseFloat(share_bform_general_fund)),
          fund: '10',
          editable: false
        },
        [`budget/${districtCode}/${fiscalYear}/revenue/${basicFormulaAccountCode2}/budget/${version}`] : {
          budget: Math.round(parseFloat(share_bform_special_revenue)),
          fund: '20',
          editable: false
        },
        [`budget/${districtCode}/${fiscalYear}/revenue/${classroomTrustAccountCode1}/budget/${version}`] : {
          budget: share_ctf_general_fund,
          fund: '10',
          editable: false
        },
        [`budget/${districtCode}/${fiscalYear}/revenue/${classroomTrustAccountCode2}/budget/${version}`] : {
          budget: share_ctf_special_revenue,
          fund: '20',
          editable: false
        },
        [`budget/${districtCode}/${fiscalYear}/revenue/${classroomTrustAccountCode3}/budget/${version}`] : {
          budget: share_ctf_debt_service,
          fund: '30',
          editable: false
        },
        [`budget/${districtCode}/${fiscalYear}/revenue/${classroomTrustAccountCode4}/budget/${version}`] : {
          budget: share_ctf_capital_projects,
          fund: '40',
          editable: false
        }
      };

      console.log('currentTaxRevenue: ', currentTaxRevenue)
      const revenueTotals = {};

      // Aggregate revenues by account codes
      currentTaxRevenue.forEach(item => {
          [
              { accountCode: item.operatingAccountCode, revenue: item.operatingRevenue, fund: '10' },
              { accountCode: item.teacherAccountCode, revenue: item.teacherRevenue, fund: '20' },
              { accountCode: item.debtAccountCode, revenue: item.debtRevenue, fund: '30' },
              { accountCode: item.capitalAccountCode, revenue: item.capitalRevenue, fund: '40' }
          ].forEach(({ accountCode, revenue, fund }) => {
              if (!revenueTotals[accountCode]) {
                  revenueTotals[accountCode] = { budget: 0, fund };
              }
              revenueTotals[accountCode].budget += revenue;
          });
      });

      delinqTaxRevenue.forEach(item => {
        [
            { accountCode: item.operatingAccountCode, revenue: item.operatingRevenue, fund: '10' },
            { accountCode: item.teacherAccountCode, revenue: item.teacherRevenue, fund: '20' },
            { accountCode: item.debtAccountCode, revenue: item.debtRevenue, fund: '30' },
            { accountCode: item.capitalAccountCode, revenue: item.capitalRevenue, fund: '40' }
        ].forEach(({ accountCode, revenue, fund }) => {
            if (!revenueTotals[accountCode]) {
                revenueTotals[accountCode] = { budget: 0, fund };
            }
            revenueTotals[accountCode].budget += revenue;
        });
    });

      Object.entries(revenueTotals).forEach(([accountCode, { budget, fund }]) => {
          updates[`budget/${districtCode}/${fiscalYear}/revenue/${accountCode}/budget/${version}`] = {
              budget,
              fund,
              editable: false
          };
      });

      console.log('updates to revenue items: ', updates)

      dropUnEditableRevenueItems(districtCode, fiscalYear, version).then(() => {
        const promises = Object.entries(updates).map(([path, value]) =>
          update(ref(database, path), value)
        );
    
        Promise.all(promises).then(()=>console.log('revenue items written to firebase'));
      });
  
      
    } catch (error) {
      console.error("Error saving totals:", error);
    }
  };  

  // Add useEffect to watch for changes that affect totals
  useEffect(() => {
    if (!isInitialLoad) {
      saveTotals();
    }
  }, [currentTaxRevenue, delinquentTaxRevenue, stateRev, isInitialLoad]);

  useEffect(() => {
    // Call API to see if this is a K8 or FL school
    async function fetchData() {
      const resp = await axios.post(`${API_BASE_URL}/api/process-spreadsheet`, 
      {districtCode: selectedDistrictInfo.DISTRICT_COUNTY_CODE})
      return resp.data
    }
    
    fetchData().then(result => setK8orFL(result.isDistrictK8orFL))
  
    loadData();
  }, []);

  useEffect(() => {
    // Call API to see if this is a K8 or FL school
    async function fetchData() {
      const resp = await axios.post(`${API_BASE_URL}/api/process-spreadsheet`, 
      {districtCode: selectedDistrictInfo.DISTRICT_COUNTY_CODE})
      return resp.data
    }
    
    fetchData().then(result => setK8orFL(result.isDistrictK8orFL))
  
    loadData();
  }, [districtCode, fiscalYear, version]);


  
  // Levy useEffect with debounce
  useEffect(() => {
    if (isInitialLoad || !pendingSaves.levy) return;
    
    const saveTimeout = setTimeout(async () => {
      try {
        const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;
        await set(ref(database, `${revenueMetadataPath}/levy`), {
          GENERAL_FUND: parseFloat(levy.operating),
          SPECIAL_REVENUE: parseFloat(levy.teacher),
          DEBT_SERVICE_FUND: parseFloat(levy.debt),
          CAPITAL_FUND: parseFloat(levy.capital)
        });
        setPendingSaves(prev => ({ ...prev, levy: false }));
        await saveTotals();
      } catch (error) {
        console.error("Error saving levy data:", error);
      }
    }, 1000); // 1 second debounce
    
    return () => clearTimeout(saveTimeout);
  }, [levy, pendingSaves.levy, isInitialLoad]);
  
  // Assessed Values useEffect with debounce
  useEffect(() => {
    if (isInitialLoad || !pendingSaves.assessedValues) return;
    
    const saveTimeout = setTimeout(async () => {
      try {
        const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;
        const assessedValueData = assessedValues.reduce((acc, county) => {
          if (county.county) {
            acc[county.county] = {
              amount: county.values.amount,
              program: county.values.program,
              project: county.values.project,
              source: county.values.source,
              location: county.values.location
            };
          }
          return acc;
        }, {});
        
        await set(ref(database, `${revenueMetadataPath}/estAssessedValue`), assessedValueData);
        setPendingSaves(prev => ({ ...prev, assessedValues: false }));
        await saveTotals();
      } catch (error) {
        console.error("Error saving assessed values data:", error);
      }
    }, 1000); // 1 second debounce
    
    return () => clearTimeout(saveTimeout);
  }, [assessedValues, pendingSaves.assessedValues, isInitialLoad]);
  
  // Tax Collection useEffect with debounce
  useEffect(() => {
    if (isInitialLoad || !pendingSaves.taxCollection) return;
    
    const saveTimeout = setTimeout(async () => {
      try {
        const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;
        await set(ref(database, `${revenueMetadataPath}/taxCollection`), {
          current: taxCollection.current,
          delinquent: taxCollection.delinquent
        });
        setPendingSaves(prev => ({ ...prev, taxCollection: false }));
        await saveTotals();
      } catch (error) {
        console.error("Error saving tax collection data:", error);
      }
    }, 1000); // 1 second debounce
    
    return () => clearTimeout(saveTimeout);
  }, [taxCollection, pendingSaves.taxCollection, isInitialLoad]);

  // State Revenue Distributions useEffect with debounce
  useEffect(() => {
    if (isInitialLoad || !pendingSaves.stateRevenueDist) return;
    
    const saveTimeout = setTimeout(async () => {
      try {
        const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;
        await set(ref(database, `${revenueMetadataPath}/stateRevDistributions`), {
          ...stateRevDist
        });
        setPendingSaves(prev => ({ ...prev, stateRevenueDist: false }));
        await saveTotals();
      } catch (error) {
        console.error("Error saving state revenue distributions data:", error);
      }
    }, 1000); // 1 second debounce
    
    return () => clearTimeout(saveTimeout);
  }, [stateRevDist, pendingSaves.stateRevenueDist, isInitialLoad]);
  
  // State Revenue useEffect with debounce
  useEffect(() => {
    if (isInitialLoad || !pendingSaves.stateRevenue) return;

    setWaitingForAPI(true)
    const saveTimeout = setTimeout(async () => {
      try {
        const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;
        
        console.log('data to spreadsheet API:', {
          ...stateRev,
          districtCode: selectedDistrictInfo.DISTRICT_COUNTY_CODE
        })

        // parse float before sending to API
        const parsedStateRev = Object.fromEntries(
          Object.entries(stateRev).map(([key, value]) => [
            key,
            isNaN(parseFloat(value)) ? 0 : parseFloat(value)
          ])
        );
        
        const response = await axios.post(`${API_BASE_URL}/api/process-spreadsheet`, {
          ...parsedStateRev,
          districtCode: selectedDistrictInfo.DISTRICT_COUNTY_CODE
        });

        console.log('API response: ', response.data)
        
        setStateRev(prev => ({
          ...prev, 
          basicFormula: response.data.basicFormula,
          classroomTrust: response.data.classroomTrust,
          propCamount: response.data.propCamount,
          formCalcWAMWADA: response.data.formCalcWAMWADA,
          basicFormula_thisyear: response.data.basicFormula_thisyear,
          classroomTrust_thisyear: response.data.classroomTrust_thisyear,
          propCamount_thisyear: response.data.propCamount_thisyear,
          formCalcWAMWADA_thisyear: response.data.formCalcWAMWADA_thisyear
        }));

        await set(ref(database, `${revenueMetadataPath}/stateRev`), {
          basicFormula: response.data.basicFormula,
          classroomTrust: response.data.classroomTrust,
          propCamount: response.data.propCamount,
          formCalcWAMWADA: response.data.formCalcWAMWADA,
          basicFormula_thisyear: response.data.basicFormula_thisyear,
          classroomTrust_thisyear: response.data.classroomTrust_thisyear,
          propCamount_thisyear: response.data.propCamount_thisyear,
          formCalcWAMWADA_thisyear: response.data.formCalcWAMWADA_thisyear,
          ...stateRev
        });
        
        setPendingSaves(prev => ({ ...prev, stateRevenue: false }));

        setWaitingForAPI(false)

        await saveTotals();
      } catch (error) {
        console.error("Error saving state revenue data:", error);
      }
    }, 1000); // 1 second debounce
    
    return () => clearTimeout(saveTimeout);
  }, [stateRev, pendingSaves.stateRevenue, isInitialLoad]);

  return (
    <>
    <div className="revenue-container">
        <Levy levy={levy}
              setLevy={setLevy}
              handleInputChange={handleInputChange}
              totalLevy={totalLevy}/>

        <AssessedValues assessedValues={assessedValues}
                        setAssessedValues={setAssessedValues}
                        handleAssessedValueChange={handleAssessedValueChange}
                        setPendingSaves={setPendingSaves}
                        totalAssessedValue={totalAssessedValue}
                        setStateRevModalOpen={setStateRevModalOpen}/>

        <TaxCollectionRatio taxCollection={taxCollection}
                            setTaxCollection={setTaxCollection}
                            handleInputChange={handleInputChange}
                            currentTaxRevenue={currentTaxRevenue}
                            delinquentTaxRevenue={delinquentTaxRevenue}/>

      {/* State Revenue Calculator */}
      
      {K8orFL === '' ? (
        <div className="state-revenue-container">
          <p>Loading State Revenue Calculator...</p>
        </div>
        ) : K8orFL === 'No' ? (
        <StateRevenueCalculatorK12
          setPendingSaves = {setPendingSaves}
          stateRev = {stateRev}
          setStateRev = {setStateRev}
          handleInputChange = {handleInputChange}
          waitingForAPI = {waitingForAPI}
          baselineSalaryGrantAmount = {baselineSalaryGrantAmount}
          setStateRevModalOpen={setStateRevModalOpen}
        />
        ) : (
        <StateRevenueCalculatorK8
          setPendingSaves = {setPendingSaves}
          stateRev = {stateRev}
          setStateRev = {setStateRev}
          handleInputChange = {handleInputChange}
          waitingForAPI = {waitingForAPI}
          baselineSalaryGrantAmount = {baselineSalaryGrantAmount}
          setStateRevModalOpen={setStateRevModalOpen}
        />
        )
      }

      {/* Classroom Trust Distribution */}
      <ClassroomTrustDistribution
        stateRev = {stateRev}
        stateRevDist = {stateRevDist}
        setStateRevDist = {setStateRevDist}
        handleInputChange = {handleInputChange}
        setStateRevModalOpen = {setStateRevModalOpen}
      />

      {/* Prop C Distribution */}
      <PropCDistribution
        stateRev = {stateRev}
        stateRevDist = {stateRevDist}
        setStateRevDist = {setStateRevDist}
        handleInputChange = {handleInputChange}
        setStateRevModalOpen = {setStateRevModalOpen}
      />

      {/* Basic Formula Distribution */}
      <BasicFormulaDistribution
        stateRev = {stateRev}
        stateRevDist = {stateRevDist}
        setStateRevDist = {setStateRevDist}
        handleInputChange = {handleInputChange}
        setStateRevModalOpen = {setStateRevModalOpen}
      />

      </div>

      <StateRevenueModal
        isOpen={stateRevModalOpen}
        stateRevDist={stateRevDist}
        onClose={() => {
          setStateRevModalOpen(false);
        }}
        onSubmit={(formData) => {
          setStateRevModalOpen(false);
          setStateRevDist((prevData) => ({
            ...prevData,
            codeInfo: {
              ...prevData.codeInfo,
              [stateRevModalOpen]: { ...formData }
            }
          }));
          setPendingSaves((prev) => ({ ...prev, stateRevenueDist: true }));
        }}
        revenueCode={stateRevModalOpen}
      />
    </>
  );
};

export default RevenueCalc;