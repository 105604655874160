import React, { useState, useEffect, useRef } from 'react';
import { database } from '../../firebaseConfig';
import { ref, get, set } from "firebase/database";
import '../../styles/SalaryScheduleCertified.css';
import updateStaff from '../Utils/UpdateStaff';
import {handleStepDownDollarBlur, handleStepDownDollarChange, createSalaryTable} from '../Utils/SalaryScheduleUtils';

const balanceTheme = {
    backgroundColor: '#e7f4fc',
    inputColor: '#ffffff',
    textColor: '#333333'
};

const initialBaseSalary = 38500;
const initialStepDowns = Array(40).fill(125);

const SalarySchedule = ({ selectedDistrictInfo, fiscalYear, version }) => {

  const [baseSalary, setBaseSalary] = useState(initialBaseSalary);
  const [careerLanes, setCareerLanes] = useState([{name: '',
                                                    stepAcross: '',
                                                    defaultStepDownDollar: '',
                                                    defaultStepDownPercent: '',
                                                    stepDownDollars: initialStepDowns}]);
  const [yearsToDisplay, setYearsToDisplay] = useState(40);
  const [salaryTable, setSalaryTable] = useState([]);
  const [displayMode, setDisplayMode] = useState('Salary');
  const [localAddOn, setLocalAddOn] = useState(0);
  const [stateAide, setStateAide] = useState(100); // Percentage, max 100
  const [showStateGrantView, setShowStateGrantView] = useState(true);
  const [selectedSchedule, setSelectedSchedule] = useState("Certified");
  const [availableSalarySchedules, setAvailableSalarySchedules] = useState({});
  const [availableScheduleNames, setAvailableScheduleNames] = useState([]);
  const [editStepDownDollar, setEditStepDownDollar] = useState(false);
  const [editStepDownPercent, setEditStepDownPercent] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [benefitRates, setBenefitRates] = useState({});
  const [classifiedSalarySchedules, setClassifiedSalarySchedules] = useState({});
  const menuRef = useRef(null);

  const salarySchedulePath = `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;
  const staffPath = `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;
  const benefitsPath = `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;
  const dataPaths = {'staffPath': staffPath, 'benefitsPath': benefitsPath}

  useEffect(() => {
    setShowStateGrantView(selectedSchedule === 'Certified');
  }, [selectedSchedule]);
  
  useEffect(() => {

    let schedules; let scheduleNames;
    get(ref(database, `${salarySchedulePath}/certified/`)).then(result => {
      if (result.exists()) {
        schedules = result.val() || {};
        setAvailableSalarySchedules(schedules);
        scheduleNames = Object.keys(schedules);
        setAvailableScheduleNames(scheduleNames);
        // Set selectedSchedule to Certified if available, otherwise set it to the first one in the list
        setSelectedSchedule(scheduleNames.includes("Certified") ? "Certified" : scheduleNames[0]);
      }
    });

    const loadBenefitRates = async () => {
      const benefitRatesPromise = await(get(ref(database, `${dataPaths['benefitsPath']}`)))
      setBenefitRates(benefitRatesPromise.val())
    }
    
    loadBenefitRates();

    const loadClassifiedSalarySchedules = async () => {
      const classifiedSchedulesPromise = await(get(ref(database, `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}/classified`)))
      setClassifiedSalarySchedules(classifiedSchedulesPromise.val())
    }

    loadClassifiedSalarySchedules()

  }, [salarySchedulePath]);  // Only run when salarySchedulePath changes
  
  useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          console.log('event.target: ', event.target)
          setMenuOpen(false);
        }
      };
  
      if (menuOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [menuOpen]);

  useEffect(() => {
    const loadSavedSchedule = (scheduleName) => {
      const savedData = availableSalarySchedules[scheduleName];
  
      if (savedData) {
        setBaseSalary(savedData.baseSalary);
        setCareerLanes(savedData.careerLanes);
        setLocalAddOn(savedData.localAddOn || 0);
        setStateAide(savedData.stateAide || 100);
        setYearsToDisplay(savedData.yearsToDisplay || 40);
      }
    };

    loadSavedSchedule(selectedSchedule);
    if(careerLanes[0].name!=""){
      createSalaryTable(setSalaryTable, 
                        careerLanes, 
                        yearsToDisplay,
                        displayMode,
                        localAddOn, 
                        showStateGrantView,
                        stateAide,
                        'certified');
    }
      
  }, [selectedDistrictInfo, fiscalYear, version, selectedSchedule, availableSalarySchedules]);
  
  useEffect(() => {
    if(careerLanes[0].name!=""){
      createSalaryTable(setSalaryTable, 
                        careerLanes, 
                        yearsToDisplay,
                        displayMode,
                        localAddOn, 
                        showStateGrantView,
                        stateAide,
                        'certified');
    }
  }, [baseSalary, careerLanes, yearsToDisplay, displayMode, localAddOn, stateAide]);

  useEffect(() => {
    const updatedLanes = [];
  
    careerLanes.forEach((lane, index) => {
      const newLane = {...lane}; // Create a copy
      
      if (index === 0) {
        newLane.step1Value = baseSalary;
      } else {
        newLane.step1Value = updatedLanes[index - 1].step1Value + parseFloat(lane.stepAcross);
      }
      
      updatedLanes.push(newLane);
    });
    
    // Only update state if there's a difference
    if (JSON.stringify(updatedLanes) !== JSON.stringify(careerLanes)) {
      setCareerLanes(updatedLanes);
    }
    
    if(careerLanes[0].name!=""){
      createSalaryTable(setSalaryTable,
                        careerLanes, 
                        yearsToDisplay,
                        displayMode,
                        localAddOn, 
                        showStateGrantView,
                        stateAide,
                        'certified');
    }
  }, [baseSalary, careerLanes, yearsToDisplay, displayMode, localAddOn, stateAide, showStateGrantView]);
    
  const toggleEditStepDownDollar = () => {
    setEditStepDownDollar((prev) => !prev);
    setEditStepDownPercent(false);
  }

  const toggleEditStepDownPercent = () => {
    setEditStepDownPercent((prev) => !prev);
    setEditStepDownDollar(false);
  }

  const handleSelectedScheduleChange = (e) => {
      setSelectedSchedule(e.target.value)
  }


  const handleStepAcrossChange = (index, value) => {
      const updatedLanes = [...careerLanes];
      updatedLanes[index].stepAcross = parseFloat(value) || 0;
      setCareerLanes(updatedLanes);
  };

  const handleStepDownPercentChange = (index, value) => {
    const updatedLanes = [...careerLanes];
    updatedLanes[index].defaultStepDownPercent = parseFloat(value) || 0;
    
    updatedLanes[index].stepDownPerc = Array(updatedLanes[index].stepDownDollars.length)
        .fill(parseFloat(value) || 0);

    setCareerLanes(updatedLanes);
  };

  const handleSaveToFirebase = async () => {

    // Prepare the salary schedule data with both 'salary' and 'baseSalary'
    const calculateBaseSalary = (lane, index, laneIndex) => {
      if (index === 0) {
          // First row calculations
          return laneIndex === 0 ? baseSalary : baseSalarySchedule[careerLanes[laneIndex - 1].name][0] + lane.stepAcross;
      }
      // For subsequent rows, always calculate from the previous row of the same lane
      const previousRowValue = baseSalarySchedule[lane.name][index - 1];
      return previousRowValue * (1 + (lane.stepDownPerc[index - 1] / 100)) + lane.stepDownDollars[index - 1];
    };
  
    const calculateDisplaySalary = (lane_name, lane_index, step, baseSalaryValue) => {
      if (!showStateGrantView) return baseSalaryValue;

      let state_aid_salary;
      if ((lane_name.charAt(0) !== 'B') && (lane_index>0) && (step > 10)) {
        state_aid_salary = 46000;
        const floor = state_aid_salary - localAddOn;

        if (baseSalaryValue < floor) {
          return baseSalaryValue + ((floor - baseSalaryValue) * (stateAide/100));
        } else {
          return baseSalaryValue;
        }
      } else {
        state_aid_salary = 40000;
        const floor = state_aid_salary - localAddOn;

        if (baseSalaryValue < floor) {
            return baseSalaryValue + ((floor - baseSalaryValue) * (stateAide/100));
        } else {
          return baseSalaryValue;
        }
      }

    };
  
    const baseSalarySchedule = {};
    careerLanes.forEach((lane, laneIndex) => {
      baseSalarySchedule[lane.name] = Array(yearsToDisplay+1).fill(0);
      for (let step = 0; step < yearsToDisplay+1; step++) {
          baseSalarySchedule[lane.name][step] = calculateBaseSalary(lane, step, laneIndex);
      }
    });
  
    const salarySchedule = {};
    careerLanes.forEach((lane, laneIndex) => {
      salarySchedule[lane.name] = Array.from({ length: yearsToDisplay+1 }, (_, step) => ({
          baseSalary: baseSalarySchedule[lane.name][step],
          salary: calculateDisplaySalary(lane.name, laneIndex, step, baseSalarySchedule[lane.name][step])
      }));
    });
  
    const dataToSave = {
      baseSalary,
      careerLanes,
      salarySchedule,
      yearsToDisplay
    };
  
    try {
      set(ref(database, `${salarySchedulePath}/certified/${selectedSchedule}`), dataToSave).then(() => {
        alert("Salary schedule saved successfully!");
        setAvailableSalarySchedules(prev => {
          const updatedCertifiedSchedules = { ...prev, [selectedSchedule]: dataToSave };
      
          // Call updateStaff with the updated state
          updateStaff(updatedCertifiedSchedules, classifiedSalarySchedules, benefitRates, dataPaths, selectedDistrictInfo['DISTRICT_COUNTY_CODE'], fiscalYear, version)
      
          return updatedCertifiedSchedules;
        });
      })
  
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Failed to save salary schedule. Please try again.");
    }
  };

  const handleScrollRight = () => {
    const tableContainer = document.querySelector('.salary-schedule-certified-main');
    tableContainer.scrollLeft += 200; // Adjust the number for scroll distance
  
    toggleArrowVisibility(); // Update visibility after scrolling
  };
    
  const handleScrollLeft = () => {
    const tableContainer = document.querySelector('.salary-schedule-certified-main');
    tableContainer.scrollLeft -= 200; // Scroll left by 200px
  
    toggleArrowVisibility(); // Update visibility after scrolling
  };

  const toggleArrowVisibility = () => {
    const tableContainer = document.querySelector('.salary-schedule-certified-main');
    const table = document.querySelector('.certified-salary-schedule-table');
    const rightArrow = document.querySelector('.scroll-button.right');
    const leftArrow = document.querySelector('.scroll-button.left');

    if(tableContainer){
      // Check if there is horizontal overflow
      const isOverflowing = (table?.clientWidth - tableContainer?.clientWidth) > 0;

      if (isOverflowing) {
        rightArrow.style.display = 'block'; // Show right arrow if there's overflow
      } else {
        rightArrow.style.display = 'none'; // Hide right arrow if no overflow
      }
    
      if (tableContainer.scrollLeft > 0) {
        leftArrow.style.display = 'block'; // Show left arrow when scrolled to the right
        leftArrow.style.opacity = '1'; // Ensure it's fully visible when scrolling to the right
      } else {
        leftArrow.style.opacity = '0'; // Fade out the left arrow
        setTimeout(() => {
          if (tableContainer.scrollLeft === 0) {
            leftArrow.style.display = 'none'; // Hide left arrow after fading out
          }
        }, 1000); // Wait for the opacity transition to finish (1 second)
      }
    }
  };

  document.addEventListener('DOMContentLoaded', toggleArrowVisibility);

  return (
    
          <div className = 'salary-schedule-certified-main' data-component="certified-salary">
            <div className="scroll-right-container">
              <button className="scroll-button right" onClick={handleScrollRight}>
                &rarr; {/* Right arrow */}
              </button>
            </div>
            <div className="scroll-left-container">
              <button className="scroll-button left" onClick={handleScrollLeft} style={{ display: 'none' }}>
                &larr; {/* Left arrow */}
              </button>
            </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                  <label>Schedule Name:</label>
                  <select
                      id="schedule-select"
                      value={selectedSchedule}
                      onChange={(event) => handleSelectedScheduleChange(event)}
                      style={{marginLeft: '10px'}}
                  >
                      <option value="" disabled>
                      Select Schedule Name
                      </option>
                      {availableScheduleNames?.map((option, index) => (
                      <option key={index} value={option}>
                          {option}
                      </option>
                      ))}
                  </select>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                  <label>Base Salary:</label>
                  <input
                      type="number"
                      value={baseSalary}
                      onChange={(e) => setBaseSalary(parseFloat(e.target.value))}
                      style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', marginRight: '20px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                      step = "50"
                  />
                  <label>Years to Display:</label>
                  <input
                      type="number"
                      value={yearsToDisplay}
                      onChange={(e) => setYearsToDisplay(parseInt(e.target.value))}
                      style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '60px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                  />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px', color: balanceTheme.textColor }}>
                  <label>Local Add On:</label>
                  <input
                      type="number"
                      value={localAddOn}
                      onChange={(e) => setLocalAddOn(parseFloat(e.target.value))}
                      style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', marginRight: '20px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                  />
                  <label>State Aide (%):</label>
                  <input
                      type="number"
                      value={stateAide}
                      onChange={(e) => setStateAide(Math.min(parseFloat(e.target.value), 100))}
                      style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                  />
              </div>

              {/* State Grant View Toggle */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                  <label>State & Local Aide:</label>
                  <input
                      type="checkbox"
                      checked={showStateGrantView}
                      onChange={() => setShowStateGrantView(!showStateGrantView)}
                      style={{ marginLeft: '10px' }}
                  />
              </div>
              <div style={{ marginBottom: '10px' }}>
              <button onClick={handleSaveToFirebase} style={{ marginTop: '20px', marginRight: '20px', padding: '10px', backgroundColor: '#007BFF', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                  Save Salary Schedule
              </button>
              
              <select
                  onChange={(e) => setDisplayMode(e.target.value)}
                  value={displayMode}
                  style={{
                      backgroundColor: '#e7f4fc',
                      color: '#333',
                      border: '1px solid #b3c7d6',
                      borderRadius: '4px',
                      padding: '5px',
                      cursor: 'pointer'
                  }}
              >
                  {['Salary', 'Change by Years', '% Change by Years', 'Change by Level', '% Change by Level'].map((mode, index) => (
                      <option key={index} value={mode}>
                          {mode}
                      </option>
                  ))}
              </select>
          </div>

              
              {/******************** SALARY TABLE ********************/}
              <table className="certified-salary-schedule-table">
                
                  <thead>
                  <tr>
                  <th>Year</th>
                  {careerLanes.map((lane) => (
                    
                    <th
                      key={lane.name}
                    >
                      
                      {lane.name}
                      {/* Three-dot menu button */}
                      {/*
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                          <button
                            onClick={() => setMenuOpen(lane.name)}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                          >
                            ⋮
                          </button>
                        </div>
                        */}
                        {/* Dropdown menu */}
                        {/*menuOpen===lane.name && (
                          <div style={{ position: "relative"}} ref={menuRef}>
                          <div
                            style={{
                              position: "absolute",
                              left: "55%",
                              top: "51%",
                              background: "white",
                              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              zIndex: 100,
                            }}
                          >
                            <div
                              onClick={() => {
                                console.log('delete clicked')
                              }}
                              style={{ padding: "5px", cursor: "pointer", whiteSpace: "nowrap" }}
                            >
                              Delete
                            </div>
                            <div
                              onClick={() => {
                                console.log('rename clicked')
                              }}
                              style={{ padding: "5px", cursor: "pointer", whiteSpace: "nowrap" }}
                            >
                              Rename
                            </div>
                          </div>
                          </div>
                        )*/}
                      

                    </th>
                  ))}
                </tr>

                      <tr>
                          <td>Step Across ($) </td>
                          {careerLanes.map((lane, index) => (
                              <td key={index}>
                                  <input
                                      type="number"
                                      value={lane.stepAcross}
                                      onChange={(e) => {
                                        const updatedLanes = [...careerLanes];
                                        updatedLanes[index].stepAcross = e.target.value;
                                        setCareerLanes(updatedLanes)
                                      }}
                                      onBlur={(e) => {
                                        const value = e.target.value === '' ? 0 : e.target.value;
                                        const updatedLanes = [...careerLanes];
                                        updatedLanes[index].stepAcross = parseFloat(value);
                                        setCareerLanes(updatedLanes)
                                      }}
                                      style={{ backgroundColor: balanceTheme.inputColor, width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                      placeholder="Step Across"
                                  />
                              </td>
                          ))}
                          </tr>
                          <tr>
                          <td>Step Down ($)
                          <button 
                            onClick={toggleEditStepDownDollar} 
                            style={{
                              margin: '5px',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              backgroundColor: editStepDownDollar ? '#007BFF' : '#f9f9f9', // Conditionally set the color
                              color: editStepDownDollar ? 'white' : 'black', // Optional: Change text color to make it more readable
                              border: '1px solid #ccc' // Adjust border thickness to 1px and set a color
                            }}
                          >
                            Edit
                          </button>
                          </td>
                          {careerLanes.map((lane, index) => (
                              <td key={index}>
                                  <input
                                      type="number"
                                      value={lane.defaultStepDownDollar}
                                      onChange={(e) => handleStepDownDollarChange(careerLanes, setCareerLanes, index, e.target.value)}
                                      onBlur={(e) => handleStepDownDollarBlur(careerLanes, setCareerLanes, index, e.target.value)}
                                      style={{ backgroundColor: balanceTheme.inputColor, width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                      placeholder="Step Down"
                                  />
                              </td>
                          ))}
                          </tr>
                          <tr>
                          <td>Step Down (%)
                          <button 
                            onClick={toggleEditStepDownPercent} 
                            style={{
                              margin: '5px',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              backgroundColor: editStepDownPercent ? '#007BFF' : '#f9f9f9', // Conditionally set the color
                              color: editStepDownPercent ? 'white' : 'black',// Optional: Change text color to make it more readable
                              border: '1px solid #ccc'
                            }}
                          >
                            Edit
                          </button>
                          </td>
                          {careerLanes.map((lane, index) => (
                              <td key={index}>
                                  <input
                                      type="number"
                                      value={lane.defaultStepDownPercent}
                                      onChange={(e) => handleStepDownPercentChange(index, e.target.value)}
                                      style={{ backgroundColor: balanceTheme.inputColor, width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                      placeholder="Step Down"
                                  />
                              </td>
                          ))}
                          </tr>
                  </thead>
                  <tbody>
                      {/* If in edit mode, show steps, otherwise show table */}
                      {editStepDownDollar ? (
                        salaryTable.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>{row.step}</td>
                            {careerLanes.map((lane, laneIndex) => (
                              <td key={laneIndex}>
                                <input
                                  type="text"
                                  value={lane["stepDownDollars"][rowIndex]}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    const updatedCareerLanes = [...careerLanes];
                                    updatedCareerLanes[laneIndex]["stepDownDollars"][rowIndex] = newValue;
                                    setCareerLanes(updatedCareerLanes);
                                  }}
                                  onBlur={(e) => {
                                    let newValue = e.target.value === '' ? 0 : e.target.value;
                                    const updatedCareerLanes = [...careerLanes];
                                    updatedCareerLanes[laneIndex]["stepDownDollars"][rowIndex] = parseFloat(newValue);
                                    setCareerLanes(updatedCareerLanes);
                                  }}
                                  disabled={rowIndex===0}
                                />
                              </td>
                            ))}
                          </tr>
                        ))
                      ) : editStepDownPercent ? (
                        salaryTable.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>{row.step}</td>
                            {careerLanes.map((lane, laneIndex) => (
                              <td key={laneIndex}>
                                <input
                                  type="text"
                                  value={lane["stepDownPerc"][rowIndex]}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    const updatedCareerLanes = [...careerLanes];
                                    updatedCareerLanes[laneIndex]["stepDownPerc"][rowIndex] = newValue;
                                    setCareerLanes(updatedCareerLanes);
                                  }}
                                  onBlur={(e) => {
                                    let newValue = e.target.value === '' ? 0 : e.target.value;
                                    const updatedCareerLanes = [...careerLanes];
                                    updatedCareerLanes[laneIndex]["stepDownPerc"][rowIndex] = parseFloat(newValue);
                                    setCareerLanes(updatedCareerLanes);
                                  }}
                                  disabled={rowIndex===0}
                                />
                              </td>
                            ))}
                          </tr>
                        ))
                      ) : (
                        salaryTable.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>{row.step}</td>
                            {careerLanes.map((lane, laneIndex) => (
                              <td
                                key={laneIndex}
                                style={{
                                  backgroundColor: "transparent",
                                }}
                              >
                                {row[lane.name]}
                              </td>
                            ))}
                          </tr>
                        ))
                      )}

                  </tbody>
              </table>
          
              {toggleArrowVisibility()}
          </div>
          
  );
};

export default SalarySchedule;

