import React, { useState, useEffect } from 'react';
import '../../styles/Dashboard.css';
import BudgetTable from './BudgetTable';
//Firebase Imports
import { ref, get, set } from "firebase/database";
import { database } from '../../firebaseConfig';
//Chart Imports
import 'chart.js/auto';
import { Chart } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const MonthlyReport = ({ selectedDistrictInfo }) => {
    const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];
    const [data, setData] = useState(null);
    const [historicalData, setHistoricalData] = useState({});
    const [selectedFunds, setSelectedFunds] = useState(['10', '20', '30', '40']);
    const [viewMode, setViewMode] = useState('value'); // "VALUE" or "YTD"
    const [highlights, setHighlights] = useState([]);
    const [currentYear, setCurrentYear] = useState(null);
    const [priorYear, setPriorYear] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(null);

    // Firebase reference
    const highlightsRef = ref(database, `highlights/${districtCode}/${currentYear}/${currentMonth}`);

    useEffect(() => {
        // Load highlights from Firebase or write defaults
        const loadOrInitializeHighlights = async () => {
            try {
                const snapshot = await get(highlightsRef);
                if (snapshot.exists()) {
                    setHighlights(snapshot.val());
                } else {
                    await writeDefaultHighlights(); // Write defaults if no data exists
                }
            } catch (error) {
                console.error("Error fetching highlights, initializing defaults:", error);
                await writeDefaultHighlights(); // Write defaults in case of error
            }
        };

        // Function to write default highlights to Firebase
        const writeDefaultHighlights = async () => {
            const defaultHighlights = [
                "Insert & edit highlights by clicking here...",
                "Add a new row by hitting \"enter\""
            ];
            try {
                setHighlights(defaultHighlights); // Update local state
                await set(highlightsRef, defaultHighlights); // Save to Firebase
            } catch (writeError) {
                console.error("Error writing default highlights to Firebase:", writeError);
            }
        };

        loadOrInitializeHighlights();
    }, [districtCode]); // Reload when the district changes

    const saveHighlights = async (newHighlights) => {
        try {
            await set(highlightsRef, newHighlights);
        } catch (error) {
            console.error("Error saving highlights:", error);
        }
    };

    const handleHighlightChange = (index, value) => {
        const newHighlights = [...highlights];
        newHighlights[index] = value;
        setHighlights(newHighlights);
        saveHighlights(newHighlights); // Save to Firebase
    };

    const handleKeyPress = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const newHighlights = [...highlights];
            newHighlights.splice(index + 1, 0, ""); // Add a new row
            setHighlights(newHighlights);
            saveHighlights(newHighlights); // Save to Firebase
        } else if (e.key === 'Backspace' && highlights[index] === "") {
            e.preventDefault();
            const newHighlights = [...highlights];
            newHighlights.splice(index, 1); // Remove the empty row
            setHighlights(newHighlights);
            saveHighlights(newHighlights); // Save to Firebase
        }
    };

    // Effect 1: Determine available years and months
    useEffect(() => {
        const determineYearsAndMonths = async () => {
            try {
                const districtRef = ref(database, `budget/${districtCode}`);
                const districtSnapshot = await get(districtRef);
    
                if (districtSnapshot.exists()) {
                    const budgetData = districtSnapshot.val();
                    setHistoricalData(budgetData);

                    // Get all available years and sort them in descending order
                    const availableYears = Object.keys(budgetData)
                        .map(year => parseInt(year))
                        .filter(year => !isNaN(year))
                        .sort((a, b) => b - a); // Sort descending
    
                    if (availableYears.length === 0) return;
    
                    let latestYear = null;
                    let latestMonth = null;
                    const monthOrder = ["July", "August", "September", "October", "November", "December", 
                                        "January", "February", "March", "April", "May", "June"];
    
                    for (const year of availableYears) {
                        const expenseData = budgetData[year]?.expense || {};
                        let monthsForYear = [];
    
                        // Limit iteration to the first 100 account codes
                        Object.keys(expenseData)
                            .slice(0, 100)
                            .forEach(accountCode => {
                                const observedMonths = Object.keys(expenseData[accountCode]?.observed || {});
                                monthsForYear.push(...observedMonths);
                            });
    
                        // Deduplicate and sort months based on monthOrder
                        monthsForYear = [...new Set(monthsForYear)]
                            .sort((a, b) => monthOrder.indexOf(b) - monthOrder.indexOf(a));
    
                        if (monthsForYear.length > 0) {
                            latestYear = year;
                            latestMonth = monthsForYear[0]; // The most recent month found
                            break; // Stop at the first year with valid months
                        }
                    }
    
                    if (latestYear && latestMonth) {
                        setCurrentYear(latestYear);
                        setPriorYear(availableYears.find(y => y < latestYear) || latestYear - 1);
                        setCurrentMonth(latestMonth);
                    }
                }
            } catch (error) {
                console.error("Error fetching available years and months:", error);
            }
        };
    
        determineYearsAndMonths();
    }, [districtCode]); // Runs only when districtCode changes
    

// Effect 2: Fetch data based on selected year and month
useEffect(() => {
    if (!currentYear || !currentMonth) return;

    const fetchData = async () => {
        try {
            const currentYearRef = ref(database, `budget/${districtCode}/${currentYear}`);
            const priorYearRef = ref(database, `budget/${districtCode}/${priorYear}`);

            const [currentYearSnapshot, priorYearSnapshot] = await Promise.all([
                get(currentYearRef),
                get(priorYearRef)
            ]);

            if (currentYearSnapshot.exists()) {
                const rawData = {
                    current: currentYearSnapshot.val(),
                    prior: priorYearSnapshot.exists() ? priorYearSnapshot.val() : null
                };
                setData(processData(rawData, viewMode));
            } else {
                console.warn("No data available for the current year");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, [districtCode, viewMode, currentYear, currentMonth]);

    
        const processData = (rawData, mode) => { 
            const result = {
                revenue: {}, 
                expense: {}, 
                cash: { Operating: 0, Debt: 0, Capital: 0 }, 
                priorYear: {
                    revenue: {}, 
                    expense: {}, 
                    cash: { Operating: 0, Debt: 0, Capital: 0 } 
                }
            };

            // Process current year data
            Object.keys(rawData.current).forEach(group => {
                Object.keys(rawData.current[group] || {}).forEach(accountCode => {
                    const currentMonthData = rawData.current[group][accountCode]['observed'][currentMonth];
                    if (!currentMonthData) return;

                    const value = mode === 'value' ? currentMonthData.value || 0 : currentMonthData.ytd || 0;
                    const fund = String(currentMonthData.fund);

                    if (group === 'revenue') {
                        result.revenue[fund] = (result.revenue[fund] || 0) + value;
                    } else if (group === 'expense') {
                        result.expense[fund] = (result.expense[fund] || 0) + value;
                    } else if (group === 'cash') {
                        if (fund === '10' || fund === '20') {
                            result.cash.Operating += value;  // Funds 10 & 20 combined
                        } else if (fund === '30') {
                            result.cash.Debt += value;       // Fund 30 (Debt) separate
                        } else if (fund === '40') {
                            result.cash.Capital += value;    // Fund 40 (Capital) separate
                        }
                    }
                });
            });

            // Process prior year data if available
            if (rawData.prior) {
                Object.keys(rawData.prior).forEach(group => {
                    Object.keys(rawData.prior[group] || {}).forEach(accountCode => {
                        const priorYearData = rawData.prior[group][accountCode]['observed'][currentMonth];
                        if (!priorYearData) return;

                        const priorValue = mode === 'value' ? priorYearData.value || 0 : priorYearData.ytd || 0;
                        const fund = String(priorYearData.fund);

                        if (group === 'revenue') {
                            result.priorYear.revenue[fund] = (result.priorYear.revenue[fund] || 0) + priorValue;
                        } else if (group === 'expense') {
                            result.priorYear.expense[fund] = (result.priorYear.expense[fund] || 0) + priorValue;
                        } else if (group === 'cash') {
                            if (fund === '10' || fund === '20') {
                                result.priorYear.cash.Operating += priorValue; // Funds 10 & 20 combined
                            } else if (fund === '30') {
                                result.priorYear.cash.Debt += priorValue; // Fund 30 (Debt) separate
                            } else if (fund === '40') {
                                result.priorYear.cash.Capital += priorValue; // Fund 40 (Capital) separate
                            }
                        }
                    });
                });
            }

            return result;
        };


    const renderYearMonthSelector = () => {
        const availableYears = Array.from({ length: (priorYear ? currentYear - priorYear + 1 : 1) }, (_, i) => (priorYear || currentYear) + i);
        const monthOrder = ["July", "August", "September", "October", "November", "December", 
                            "January", "February", "March", "April", "May", "June"];
    
        return (
            <div className="year-month-selector" style={{ display: "flex", justifyContent: "center", gap: "20px", marginBottom: "10px" }}>
                <div>
                    <select value={currentMonth || ''} onChange={(e) => setCurrentMonth(e.target.value)}>
                        {monthOrder.map(month => (
                            <option key={month} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <select value={currentYear || ''} onChange={(e) => setCurrentYear(parseInt(e.target.value))}>
                        {availableYears.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
            </div>
        );
    };    

    const handleFundSelection = (fund) => {
        setSelectedFunds(prev => prev.includes(fund) ? prev.filter(f => f !== fund) : [...prev, fund]);
    };

    const getTitle = () => {
        const baseTitle = viewMode === 'value' ? 'Monthly Revenue & Expense' : 'YTD Revenue & Expense';
        if (selectedFunds.length === 4) return `${baseTitle} - All Funds`;
        if (selectedFunds.includes('10') && selectedFunds.includes('20') && selectedFunds.length === 2) return `${baseTitle} - Operating Funds`;
        if (selectedFunds.length === 1) return `${baseTitle} - ${getFundLabel(selectedFunds[0])}`;
        return `${baseTitle} - ${selectedFunds.map(getFundLabel).join(', ')}`;
    };

    const getFundLabel = (fund) => {
        const fundLabels = {
            '10': 'General',
            '20': 'Teacher',
            '30': 'Debt',
            '40': 'Capital'
        };
        return fundLabels[fund] || fund;
    };

    const renderViewModeToggle = () => (
        <div className="fund-toggles" style={{ margin: '10px 0', display: 'flex', justifyContent: 'center' }}>
            <span style={{ marginRight: '10px' }}>Amount:</span>
            <label style={{ margin: '0 10px', display: 'inline-block' }}>
                <input
                    type="radio"
                    name="viewMode"
                    value="value"
                    checked={viewMode === 'value'}
                    onChange={() => setViewMode('value')}
                />
                Monthly Amount
            </label>
            <label style={{ margin: '0 10px', display: 'inline-block' }}>
                <input
                    type="radio"
                    name="viewMode"
                    value="ytd"
                    checked={viewMode === 'ytd'}
                    onChange={() => setViewMode('ytd')}
                />
                Year to Date
            </label>
        </div>
    );

    const renderFundToggles = () => {
        const fundLabels = {
            '10': 'General',
            '20': 'Teacher',
            '30': 'Debt',
            '40': 'Capital'
        };

        return (
            <div className="fund-toggles" style={{ margin: '10px 0', display: 'flex', justifyContent: 'center' }}>
                <span style={{ marginRight: '10px' }}>Fund:</span>
                {Object.keys(fundLabels).map(fund => (
                    <label key={fund} style={{ margin: '0 10px', display: 'inline-block' }}>
                        <input
                            type="checkbox"
                            checked={selectedFunds.includes(fund)}
                            onChange={() => handleFundSelection(fund)}
                        />
                        {fundLabels[fund]}
                    </label>
                ))}
            </div>
        );
    };

    const renderBarGraphs = () => {
        if (!data) return <div>Loading...</div>;

        const revenueExpenseChart = {
            labels: ['Revenue', 'Expense'],
            datasets: [
                {
                    label: `${currentYear-2}-${currentYear-1}`,
                    data: [
                        selectedFunds.reduce((sum, fund) => sum + (data.priorYear.revenue[fund] || 0), 0),
                        selectedFunds.reduce((sum, fund) => sum + (data.priorYear.expense[fund] || 0), 0)
                    ],
                    backgroundColor: ['#d8abc1', '#d8abc1']
                },
                {
                    label: `${currentYear-1}-${currentYear}`,
                    data: [
                        selectedFunds.reduce((sum, fund) => sum + (data.revenue[fund] || 0), 0),
                        selectedFunds.reduce((sum, fund) => sum + (data.expense[fund] || 0), 0)
                    ],
                    backgroundColor: ['#88b0d8', '#88b0d8']
                }
            ]
        };

        const cashBalanceChart = {
    labels: ['Operating', 'Debt', 'Capital'],
    datasets: [
        {
            label: `${currentYear-2}-${currentYear-1}`,
            data: [
                data.priorYear.cash.Operating || 0, 
                data.priorYear.cash.Debt || 0, 
                data.priorYear.cash.Capital || 0
            ],
            backgroundColor: ['#f8a488', '#f8a488', '#f8a488']
        },
        {
            label: `${currentYear-1}-${currentYear}`,
            data: [
                data.cash.Operating || 0, 
                data.cash.Debt || 0, 
                data.cash.Capital || 0
            ],
            backgroundColor: ['#87b6a7', '#87b6a7', '#87b6a7']
        }
    ]
};

        
const chartOptions = {
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                font: {
                    size: 14 // Set legend font size to 14
                }
            }
        },
        datalabels: {
            display: true,
            anchor: 'end',
            align: 'top',
            clip: false, // Allow labels to overflow beyond the chart area
            formatter: (value) => `$${(value / 1000000).toFixed(1)}m`,
            font: {
                size: 18, // Increase label size
                weight: 'bold'
            }
        }
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1.2,
    layout: {
        padding: {
            top: 20 // Add padding to the top to ensure labels aren't cut off
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            grid: {
                display: false // Remove gridlines
            },
            ticks: {
                font: {
                    size: 14 // Set y-axis font size to 14
                }
            }
        },
        x: {
            grid: {
                display: false // Remove gridlines
            },
            ticks: {
                font: {
                    size: 14
                }
            }
        }
    }
};



        return (
            <div className="graphs-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '45%' }}>
                    <h3>{getTitle()}</h3>
                    <Bar data={revenueExpenseChart} options={chartOptions} />
                    {renderFundToggles()}
                    {renderViewModeToggle()}
                </div>
                <div style={{ width: '45%' }}>
                    <h3>Cash Balance By Fund</h3>
                    <Bar data={cashBalanceChart} options={chartOptions} />
                </div>
            </div>
        );
    };

    return (
        <div className="dashboard">
            {renderYearMonthSelector()}
            <div className="title" style={{ fontSize: '40px' }}>{currentMonth} {currentYear} Financial Report</div>
            {renderBarGraphs()}
            <div className="key-highlights">
                <h2>Key Highlights</h2>
                <ul>
                    {highlights.map((highlight, index) => (
                        <li key={index} style={{ fontSize: '18px', marginBottom: '1%', marginLeft: '5%' }}>
                            <input
                                type="text"
                                value={highlight}
                                onChange={(e) => handleHighlightChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyPress(e, index)}
                                style={{
                                    fontSize: '18px',
                                    width: '90%',
                                    padding: '5px',
                                    border: 'none',
                                    outline: 'none',
                                    background: 'transparent'
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </div>
            <h2>Financial Details</h2>
            <div className="drilldownTableContainer">
            <BudgetTable
              districtCode={districtCode}
              year={currentYear}
              month={currentMonth}
              historicalData={historicalData}
              currentMonth={currentMonth}
              currentYear={currentYear}
            />
          </div>
        </div>
    );
};

export default MonthlyReport;
