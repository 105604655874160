import React, { useState, useEffect } from "react";
import {FilterSearchBox} from "./FilterSearchBox.js"

const BudgetFilterModal = ({ filters, setFilters, isOpen, onClose, onSubmit, deseCodeMap, uniqueLocations }) => {
  
  // Toggle dropdown visibility
  const [isFundDropdownOpen, setIsFundDropdownOpen] = useState(false);
  const [isObjectDropdownOpen, setIsObjectDropdownOpen] = useState(false);
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);

  // Sample options for the dropdown - replace with your actual options
  const fundOptions = [
    { value: "10", label: "Fund 1" },
    { value: "20", label: "Fund 2" },
    { value: "30", label: "Fund 3" },
    { value: "40", label: "Fund 4" },
  ];

  const objectOptions = Object.entries(deseCodeMap['object']).map(([key, value]) => ({
    value: key,
    label: `${key} - ${value.DETAIL_DESCRIPTION}`
  }));

  const locationOptions = uniqueLocations.map(location => ({
    value: location,
    label: location
  }));

  const handleSubmit = () => {
    onSubmit(filters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="budget-filter-modal-overlay">
      <div className="budget-filter-modal">
        <h2>Filter</h2>
        <div className="budget-filter-modal-form-group">
          <div className="budget-filter-modal-input">
            <label>Fund: </label>
            <FilterSearchBox
              isDropdownOpen = {isFundDropdownOpen} 
              setIsDropdownOpen = {setIsFundDropdownOpen}
              dropdownOptions = {fundOptions}
              filterData = {filters}
              setFilterData = {setFilters}
              field = {'funds'}
              />
          </div>
          <div className="budget-filter-modal-input">
            <label>Expense Object: </label>
            <FilterSearchBox
              isDropdownOpen = {isObjectDropdownOpen} 
              setIsDropdownOpen = {setIsObjectDropdownOpen}
              dropdownOptions = {objectOptions}
              filterData = {filters}
              setFilterData = {setFilters}
              field = {'objects'}
              />
          </div>
          <div className="budget-filter-modal-input">
            <label>Location: </label>
            <FilterSearchBox
              isDropdownOpen = {isLocationDropdownOpen} 
              setIsDropdownOpen = {setIsLocationDropdownOpen}
              dropdownOptions = {locationOptions}
              filterData = {filters}
              setFilterData = {setFilters}
              field = {'locations'}
              />
          </div>
        </div>
        <div className="budget-filter-modal-button-group">
          <button onClick={() => {
            onClose();
            setIsFundDropdownOpen(false);
            setIsObjectDropdownOpen(false);
            setIsLocationDropdownOpen(false);
          }}>Cancel</button>
          <button onClick={() => {
            handleSubmit();
            setIsFundDropdownOpen(false);
            setIsObjectDropdownOpen(false);
            setIsLocationDropdownOpen(false);
          }}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default BudgetFilterModal;