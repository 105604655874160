export const TaxCollectionRatio = ({taxCollection, setTaxCollection, handleInputChange, currentTaxRevenue, delinquentTaxRevenue}) => {

  const formatPercentage = (value) => {
    const num = isNaN(value) ? NaN : parseInt(value);
    return isNaN(num) ? "" : `${num}%`;
  };

  const formatCurrency = (value) => 
    value.toLocaleString('en-US', { style: 'currency', 
                                    currency: 'USD', 
                                    minimumFractionDigits: 0, 
                                    maximumFractionDigits: 0 });

  const calculateTotal = (values) => 
    Object.values(values).reduce((acc, val) => acc + (typeof val === 'string' ? parseFloat(val) || 0 : val), 0);

  return (
    <div className="tax-collection-card">
      <h3>Tax Collection Ratio</h3>
      <div className="tax-collection-table">
        <div className="tax-collection-input-group">
          <label>Current:</label>
          <input
            type="number"
            placeholder="Current"
            value={taxCollection.current}
            onChange={(e) => handleInputChange(e, setTaxCollection, 'current', 'taxCollection')}
          />
        </div>
        <div className="tax-collection-input-group">
          <label>Delinquent:</label>
          <input
            type="number"
            placeholder="Delinquent"
            value={taxCollection.delinquent}
            onChange={(e) => handleInputChange(e, setTaxCollection, 'delinquent', 'taxCollection')}
          />
        </div>
        <div className="total-tax-collection">
          Total: {formatPercentage(calculateTotal(taxCollection))}<br/>
          Current Revenue: {formatCurrency(currentTaxRevenue)}<br/>
          Delinquent Revenue: {formatCurrency(delinquentTaxRevenue)}<br/>
        </div>
      </div>
    </div>
  )
}