import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { ExportBudgetToCSV } from './ExportToCSV';

export const BudgetMenuButton = ({data}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside both the menu and the button
      if (
        menuRef.current && 
        !menuRef.current.contains(event.target) &&
        buttonRef.current && 
        !buttonRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  const handleButtonClick = (e) => {
    // Prevent event from bubbling up
    e.stopPropagation();
    setMenuOpen(!menuOpen);
  };

  const handleExportClick = (data) => {
    ExportBudgetToCSV(data);
    setMenuOpen(false);
  };

  return (
    <div 
      style={{ 
        position: "absolute", 
        top: "50%", 
        right: "10px",
        transform: "translateY(-50%)",
      }} 
    >
      <button
        ref={buttonRef}
        onClick={handleButtonClick}
        className='budget-settings-button'
      >
        ⋮
      </button>

      {menuOpen && ReactDOM.createPortal(
        <div
          ref={menuRef}
          style={{
            position: "fixed",
            right: buttonRef.current 
              ? `calc(97% - ${buttonRef.current.getBoundingClientRect().right}px)` 
              : "10px",
            top: buttonRef.current 
              ? `${buttonRef.current.getBoundingClientRect().bottom}px` 
              : "50%",
            transform: buttonRef.current 
              ? "translateX(-50%)" 
              : "translateY(-50%)",
            zIndex: 9999,
            background: "white",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            borderRadius: "5px",
            padding: "5px 10px",
            minWidth: "120px"
          }}
        >
          <div
            className='budget-settings-menu-item'
            onClick={() => {
              handleExportClick(data)
            }}
          >
            Export to CSV
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};