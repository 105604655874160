import React, { useState, useEffect, useCallback } from "react";
import { FaCalculator } from "react-icons/fa";
import { ref, get, update } from "firebase/database";
import { database } from "../../../firebaseConfig";
import "../../../styles/BudgetSummary.css"; // For custom styling
import TransferCalculator from './TransferCalculator';
import { useEffectDebugger } from "../../../utils";
import { useQuery } from '@tanstack/react-query';
import { useBudgetSummary, useRevenueMetadata, useUpdateBudgetSummary } from '../../../hooks/queries/budgetQueries';
import CurrencyInput from '../../Utils/CurrencyInput';


export const validateTransferSum = (row) => {
  if (!row.values) {
    return true;
  }
  return 0 === row.values.General + row.values.Teacher + row.values.Debt + row.values.Capital;
};

const transformArrayToObject = (valuesArray) => ({
  Total: valuesArray[0] || 0,
  Operating: valuesArray[1] || 0,
  General: valuesArray[2] || 0,
  Teacher: valuesArray[3] || 0,
  Debt: valuesArray[4] || 0,
  Capital: valuesArray[5] || 0
});

const BudgetSummary = ({currentBudget, districtCode, fiscalYear, version}) => {
  const [data, setData] = useState({
    assessedValuation: { headers: [], rows: [] },
    taxLevy: { headers: [], rows: [] },
    budgetSummary: { headers: [], rows: [] }
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    WADA: "",
    SAT: "",
    transferPercent: ""
  });
  const [pendingSave, setPendingSave] = useState(false);
  const [isTransfersCollapsed, setIsTransfersCollapsed] = useState(true);
  const [rawSummaryData, setRawSummaryData] = useState(null);
  const [rawRevenueData, setRawRevenueData] = useState(null);

  const budgetSummaryPath = `budget/${districtCode}/${fiscalYear}/summary/${version}`;
  const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;
  const fiscalYearString = `${fiscalYear-1}-${fiscalYear}`;
  const estimated_revenue = currentBudget.filter(item => (item.YEAR===fiscalYear) && (item.version===version) && (item.LEVEL===0) && (item.LABEL==='revenue'))[0]
  const estimated_expense = currentBudget.filter(item => (item.YEAR===fiscalYear) && (item.version===version) && (item.LEVEL===0) && (item.LABEL==='expense'))[0]
  
  const updateBudgetSummary = useUpdateBudgetSummary();

  const propagateFundBalance = async () => {
    const futureYearSnapshot = await get(ref(database, `budget/${districtCode}`));
    const futureVal = futureYearSnapshot.val();
    
    let futureYears = Object.keys(futureVal).filter(year => 
       (parseInt(year)>=parseInt(fiscalYear))
    );

    for (let i=1; i<futureYears.length; i++) {
      const workingYear = futureYears[i-1];
      const yearToUpdate = futureYears[i];
      const workingYearSummary = futureVal[workingYear].summary[version];
      const yearToUpdateSummary = futureVal[yearToUpdate].summary[version];

      // Update beginning balance next year with Estimated Unrestricted Money of current year
      const balanceKey = `Balance June 30 ${yearToUpdate-1}`;
      yearToUpdateSummary[balanceKey] = { ...workingYearSummary[`Estimated Unrestricted Money`]};

      // Add revenue & expense rows for next year
      const next_year_revenue = currentBudget.filter(item => (item.YEAR===String(parseInt(fiscalYear)+i)) && (item.version===version) && (item.LEVEL===0) && (item.LABEL==='revenue'))[0]
      const next_year_expense = currentBudget.filter(item => (item.YEAR===String(parseInt(fiscalYear)+i)) && (item.version===version) && (item.LEVEL===0) && (item.LABEL==='expense'))[0]
      yearToUpdateSummary['Estimated Revenue'] = {
        Total: parseInt(next_year_revenue.TOTAL_ALL_FUNDS),
        Operating: parseInt(next_year_revenue.GENERAL_FUND) + parseInt(next_year_revenue.SPECIAL_REVENUE),
        General: parseInt(next_year_revenue.GENERAL_FUND),
        Teacher: parseInt(next_year_revenue.SPECIAL_REVENUE),
        Debt: parseInt(next_year_revenue.DEBT_SERVICE),
        Capital: parseInt(next_year_revenue.CAPITAL_PROJECTS)
      }

      yearToUpdateSummary['Estimated Expenditures'] = {
        Total: parseInt(next_year_expense.TOTAL_ALL_FUNDS),
        Operating: parseInt(next_year_expense.GENERAL_FUND) + parseInt(next_year_expense.SPECIAL_REVENUE),
        General: parseInt(next_year_expense.GENERAL_FUND),
        Teacher: parseInt(next_year_expense.SPECIAL_REVENUE),
        Debt: parseInt(next_year_expense.DEBT_SERVICE),
        Capital: parseInt(next_year_expense.CAPITAL_PROJECTS)
      }

      yearToUpdateSummary['Revenue - Expenditures'] = {
        Total: parseInt(next_year_revenue.TOTAL_ALL_FUNDS) - parseInt(next_year_expense.TOTAL_ALL_FUNDS),
        Operating: (parseInt(next_year_revenue.GENERAL_FUND) + parseInt(next_year_revenue.SPECIAL_REVENUE))-(parseInt(next_year_expense.GENERAL_FUND) + parseInt(next_year_expense.SPECIAL_REVENUE)),
        General: parseInt(next_year_revenue.GENERAL_FUND) - parseInt(next_year_expense.GENERAL_FUND),
        Teacher: parseInt(next_year_revenue.SPECIAL_REVENUE) - parseInt(next_year_expense.SPECIAL_REVENUE),
        Debt: parseInt(next_year_revenue.DEBT_SERVICE) - parseInt(next_year_expense.DEBT_SERVICE),
        Capital: parseInt(next_year_revenue.CAPITAL_PROJECTS) - parseInt(next_year_expense.CAPITAL_PROJECTS)
      }

      
      
      yearToUpdateSummary['Balance Prior to Transfer'] = {
        Total: yearToUpdateSummary['Revenue - Expenditures'].Total + yearToUpdateSummary[balanceKey].Total,
        Operating: yearToUpdateSummary['Revenue - Expenditures'].Operating + yearToUpdateSummary[balanceKey].Operating,
        General: yearToUpdateSummary['Revenue - Expenditures'].General + yearToUpdateSummary[balanceKey].General,
        Teacher: yearToUpdateSummary['Revenue - Expenditures'].Teacher + yearToUpdateSummary[balanceKey].Teacher,
        Debt: yearToUpdateSummary['Revenue - Expenditures'].Debt + yearToUpdateSummary[balanceKey].Debt,
        Capital: yearToUpdateSummary['Revenue - Expenditures'].Capital + yearToUpdateSummary[balanceKey].Capital
      }
    

      // Calculate transfer totals
      const transferTotals = { Total: 0, Operating: 0, General: 0, Teacher: 0, Debt: 0, Capital: 0 };
      [
        'Student Activities Transfer',
        'SB 287 Transfer',
        'Zero Teach Fund Balance',
        'Transportation Transfer', 
        'Grant Match Transfer',
        'Capital Projects Transfer',
        'Food Service Transfer'
      ].forEach(transferType => {
        if (yearToUpdateSummary[transferType]) {
          Object.entries(yearToUpdateSummary[transferType]).forEach(([key, value]) => {
            transferTotals[key] += parseInt(value) || 0;
          });
        }
      });

      // Update Estimated Fund Balance with sum of Balance Prior to Transfer and transfer totals
      yearToUpdateSummary[`Estimated Fund Balance ${yearToUpdate}`] = {
        Total: yearToUpdateSummary['Balance Prior to Transfer'].Total + transferTotals.Total,
        Operating: yearToUpdateSummary['Balance Prior to Transfer'].Operating + transferTotals.Operating,
        General: yearToUpdateSummary['Balance Prior to Transfer'].General + transferTotals.General,
        Teacher: yearToUpdateSummary['Balance Prior to Transfer'].Teacher + transferTotals.Teacher,
        Debt: yearToUpdateSummary['Balance Prior to Transfer'].Debt + transferTotals.Debt,
        Capital: yearToUpdateSummary['Balance Prior to Transfer'].Capital + transferTotals.Capital
      };

      // Update Estimated Unrestricted Money by subtracting Estimated Restricted Money from Estimated Fund Balance
      yearToUpdateSummary['Estimated Unrestricted Money'] = {
        Total: yearToUpdateSummary[`Estimated Fund Balance ${yearToUpdate}`].Total - (yearToUpdateSummary['Estimated Restricted Money']?.Total || 0),
        Operating: yearToUpdateSummary[`Estimated Fund Balance ${yearToUpdate}`].Operating - (yearToUpdateSummary['Estimated Restricted Money']?.Operating || 0),
        General: yearToUpdateSummary[`Estimated Fund Balance ${yearToUpdate}`].General - (yearToUpdateSummary['Estimated Restricted Money']?.General || 0),
        Teacher: yearToUpdateSummary[`Estimated Fund Balance ${yearToUpdate}`].Teacher - (yearToUpdateSummary['Estimated Restricted Money']?.Teacher || 0),
        Debt: yearToUpdateSummary[`Estimated Fund Balance ${yearToUpdate}`].Debt - (yearToUpdateSummary['Estimated Restricted Money']?.Debt || 0),
        Capital: yearToUpdateSummary[`Estimated Fund Balance ${yearToUpdate}`].Capital - (yearToUpdateSummary['Estimated Restricted Money']?.Capital || 0)
      };

      await updateBudgetSummary.mutateAsync({
        districtCode,
        fiscalYear: yearToUpdate,
        version,
        updates: yearToUpdateSummary
      });
    }
  }

  // Query for budget summary data
  const { 
    data: summaryData, 
    isLoading: isSummaryLoading 
  } = useBudgetSummary(districtCode, fiscalYear, version);

  // Query for revenue metadata
  const { 
    data: revenueData, 
    isLoading: isRevenueLoading 
  } = useRevenueMetadata(districtCode, fiscalYear, version);

  // Effect for transforming data when either query result changes
  useEffect(() => {
    if (summaryData && revenueData) {
      transformData();
    }
  }, [summaryData, revenueData, estimated_revenue, estimated_expense]);

  // Update transformData to use query results instead of raw state
  const transformData = useCallback(() => {
    if (!summaryData || !revenueData) return;

    const assessedValuation = {};
    ['residential','commercial','personal','agriculture'].forEach(prop_type => {
      assessedValuation[prop_type] = 0;
      Object.keys(revenueData['estAssessedValue']).forEach(county => {
        assessedValuation[prop_type] += revenueData['estAssessedValue'][county][prop_type]
      })
    });

    const yearToFetch = parseInt(fiscalYear)-1;
    const beg_fb_capital = parseInt(summaryData[`Balance June 30 ${yearToFetch}`]?.Capital);
    const beg_fb_debt = parseInt(summaryData[`Balance June 30 ${yearToFetch}`]?.Debt);
    const beg_fb_teacher = parseInt(summaryData[`Balance June 30 ${yearToFetch}`]?.Teacher);
    const beg_fb_general = parseInt(summaryData[`Balance June 30 ${yearToFetch}`]?.General);

    // Add fiscal year check
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const currentFiscalYear = currentMonth > 5 ? currentYear + 1 : currentYear;
    const isCurrentOrFutureYear = parseInt(fiscalYear) >= currentFiscalYear;
    const isNextFiscalYear = parseInt(fiscalYear) === currentFiscalYear+1;

    // Transform the data into the required format
    const transformedData = {
      assessedValuation: {
        headers: ["", "Total", "Residential", "Commercial", "Personal", "Agriculture"],
        rows: [
          {
            label: "Estimated Assessed Valuation",
            values: {
              total: (assessedValuation?.agriculture || 0) + (assessedValuation?.commercial || 0) + 
                     (assessedValuation?.personal || 0) + (assessedValuation?.residential || 0),
              residential: (assessedValuation?.residential || 0),
              commercial: (assessedValuation?.commercial || 0),
              personal: (assessedValuation?.personal || 0),
              agriculture: (assessedValuation?.agriculture || 0)
            }
          }
        ]
      },
      taxLevy: {
        headers: ["", "Total", "Operating", "Special", "Debt", "Capital"],
        rows: [
          {
            label: "Tax Levy",
            values: {
              total: (revenueData?.levy?.GENERAL_FUND || 0) + (revenueData?.levy?.SPECIAL_REVENUE || 0) + 
                     (revenueData?.levy?.DEBT_SERVICE_FUND || 0) + (revenueData?.levy?.CAPITAL_FUND || 0),
              operating: (revenueData?.levy?.GENERAL_FUND || 0) + (revenueData?.levy?.SPECIAL_REVENUE || 0),
              special: (revenueData?.levy?.SPECIAL_REVENUE || 0),
              debt: (revenueData?.levy?.DEBT_SERVICE_FUND || 0),
              capital: (revenueData?.levy?.CAPITAL_FUND || 0)
            }
          }
        ]
      },
      budgetSummary: {
        headers: ["", "Total", "Operating", "General", "Teacher", "Debt", "Capital"],
        rows: [
          {
            label: `Balance June 30 ${parseInt(fiscalYear)-1}`,
            values: {
              Total: (beg_fb_general || 0) + (beg_fb_teacher || 0) + (beg_fb_debt || 0) + (beg_fb_capital || 0),
              Operating: (beg_fb_general || 0) + (beg_fb_teacher || 0),
              General: (beg_fb_general || 0),
              Teacher: (beg_fb_teacher || 0),
              Debt: (beg_fb_debt || 0),
              Capital: (beg_fb_capital || 0)
            },
            editable: isNextFiscalYear,
            formatFunction: formatCurrency
          },
          {
            label: `Estimated Revenue`,
            values: {
              Total: parseInt(estimated_revenue?.TOTAL_ALL_FUNDS) || 0,
              Operating: parseInt(estimated_revenue?.GENERAL_FUND || 0) + parseInt(estimated_revenue?.SPECIAL_REVENUE || 0),
              General: parseInt(estimated_revenue?.GENERAL_FUND) || 0,
              Teacher: parseInt(estimated_revenue?.SPECIAL_REVENUE) || 0,
              Debt: parseInt(estimated_revenue?.DEBT_SERVICE) || 0,
              Capital: parseInt(estimated_revenue?.CAPITAL_PROJECTS) || 0
            },
            editable: false,
            formatFunction: formatCurrency
          },
          {
            label: `Estimated Receipts and Balances`,
            values: {
              Total: parseInt(estimated_revenue?.TOTAL_ALL_FUNDS || 0) + (beg_fb_general || 0) + (beg_fb_teacher || 0) + (beg_fb_debt || 0) + (beg_fb_capital || 0),
              Operating: parseInt(estimated_revenue?.GENERAL_FUND || 0) + parseInt(estimated_revenue?.SPECIAL_REVENUE || 0) + (beg_fb_general || 0) + (beg_fb_teacher || 0),
              General: parseInt(estimated_revenue?.GENERAL_FUND || 0) + (beg_fb_general || 0),
              Teacher: parseInt(estimated_revenue?.SPECIAL_REVENUE || 0) + (beg_fb_teacher || 0),
              Debt: parseInt(estimated_revenue?.DEBT_SERVICE || 0) + (beg_fb_debt || 0),
              Capital: parseInt(estimated_revenue?.CAPITAL_PROJECTS || 0) + (beg_fb_capital || 0)
            },
            editable: false,
            formatFunction: formatCurrency
          },
          {
            label: `Estimated Expenditures`,
            values: {
              Total: parseInt(estimated_expense?.TOTAL_ALL_FUNDS) || 0,
              Operating: parseInt(estimated_expense?.GENERAL_FUND || 0) + parseInt(estimated_expense?.SPECIAL_REVENUE || 0),
              General: parseInt(estimated_expense?.GENERAL_FUND) || 0,
              Teacher: parseInt(estimated_expense?.SPECIAL_REVENUE) || 0,
              Debt: parseInt(estimated_expense?.DEBT_SERVICE) || 0,
              Capital: parseInt(estimated_expense?.CAPITAL_PROJECTS) || 0
            },
            editable: false,
            formatFunction: formatCurrency
          },
          {
            label: `Revenue - Expenditures`,
            values: {
              Total: parseInt(estimated_revenue?.TOTAL_ALL_FUNDS || 0) - parseInt(estimated_expense?.TOTAL_ALL_FUNDS || 0),
              Operating: (parseInt(estimated_revenue?.GENERAL_FUND || 0) + parseInt(estimated_revenue?.SPECIAL_REVENUE || 0)) - (parseInt(estimated_expense?.GENERAL_FUND || 0) + parseInt(estimated_expense?.SPECIAL_REVENUE || 0)),
              General: parseInt(estimated_revenue?.GENERAL_FUND || 0) - parseInt(estimated_expense?.GENERAL_FUND || 0),
              Teacher: parseInt(estimated_revenue?.SPECIAL_REVENUE || 0) - parseInt(estimated_expense?.SPECIAL_REVENUE || 0),
              Debt: parseInt(estimated_revenue?.DEBT_SERVICE || 0) - parseInt(estimated_expense?.DEBT_SERVICE || 0),
              Capital: parseInt(estimated_revenue?.CAPITAL_PROJECTS || 0) - parseInt(estimated_expense?.CAPITAL_PROJECTS || 0)
            },
            editable: false,
            formatFunction: formatCurrency
          },
          {
            label: `Balance Prior to Transfer`,
            values: {
              Total: ((beg_fb_general || 0) + (beg_fb_teacher || 0) + (beg_fb_debt || 0) + (beg_fb_capital || 0)) + (parseInt(estimated_revenue?.TOTAL_ALL_FUNDS || 0) - parseInt(estimated_expense?.TOTAL_ALL_FUNDS || 0)),
              Operating: ((beg_fb_general || 0) + (beg_fb_teacher || 0)) + (parseInt(estimated_revenue?.GENERAL_FUND || 0) + parseInt(estimated_revenue?.SPECIAL_REVENUE || 0)) - (parseInt(estimated_expense?.GENERAL_FUND || 0) + parseInt(estimated_expense?.SPECIAL_REVENUE || 0)),
              General: (beg_fb_general || 0) + (parseInt(estimated_revenue?.GENERAL_FUND || 0) - parseInt(estimated_expense?.GENERAL_FUND || 0)),
              Teacher: (beg_fb_teacher || 0) + (parseInt(estimated_revenue?.SPECIAL_REVENUE || 0) - parseInt(estimated_expense?.SPECIAL_REVENUE || 0)),
              Debt: (beg_fb_debt || 0) + (parseInt(estimated_revenue?.DEBT_SERVICE || 0) - parseInt(estimated_expense?.DEBT_SERVICE || 0)),
              Capital: (beg_fb_capital || 0) + (parseInt(estimated_revenue?.CAPITAL_PROJECTS || 0) - parseInt(estimated_expense?.CAPITAL_PROJECTS || 0))
            },
            editable: false,
            formatFunction: formatCurrency
          },
          {
            label: "Zero Teach Fund Balance",
            values: {
              Total: (parseInt(summaryData[`Zero Teach Fund Balance`]?.General) + parseInt(summaryData[`Zero Teach Fund Balance`]?.Teacher) + parseInt(summaryData[`Zero Teach Fund Balance`]?.Debt) + parseInt(summaryData[`Zero Teach Fund Balance`]?.Capital)) || 0,
              Operating: (parseInt(summaryData[`Zero Teach Fund Balance`]?.General) + parseInt(summaryData[`Zero Teach Fund Balance`]?.Teacher)) || 0,
              General: parseInt(summaryData[`Zero Teach Fund Balance`]?.General) || 0,
              Teacher: parseInt(summaryData[`Zero Teach Fund Balance`]?.Teacher) || 0,
              Debt: parseInt(summaryData[`Zero Teach Fund Balance`]?.Debt) || 0,
              Capital: parseInt(summaryData[`Zero Teach Fund Balance`]?.Capital) || 0
            },
            editable: isCurrentOrFutureYear,
            checkRowValid: validateTransferSum,
            formatFunction: formatCurrency
          },
          {
            label: "Transportation Transfer",
            values: {
              Total: (parseInt(summaryData[`Transportation Transfer`]?.General) + parseInt(summaryData[`Transportation Transfer`]?.Teacher) + parseInt(summaryData[`Transportation Transfer`]?.Debt) + parseInt(summaryData[`Transportation Transfer`]?.Capital)) || 0,
              Operating: (parseInt(summaryData[`Transportation Transfer`]?.General) + parseInt(summaryData[`Transportation Transfer`]?.Teacher)) || 0,
              General: parseInt(summaryData[`Transportation Transfer`]?.General) || 0,
              Teacher: parseInt(summaryData[`Transportation Transfer`]?.Teacher) || 0,
              Debt: parseInt(summaryData[`Transportation Transfer`]?.Debt) || 0,
              Capital: parseInt(summaryData[`Transportation Transfer`]?.Capital) || 0
            },
            editable: isCurrentOrFutureYear,
            checkRowValid: validateTransferSum,
            formatFunction: formatCurrency
          },
          {
            label: "Grant Match Transfer",
            values: {
              Total: (parseInt(summaryData[`Grant Match Transfer`]?.General) + parseInt(summaryData[`Grant Match Transfer`]?.Teacher) + parseInt(summaryData[`Grant Match Transfer`]?.Debt) + parseInt(summaryData[`Grant Match Transfer`]?.Capital)) || 0,
              Operating: (parseInt(summaryData[`Grant Match Transfer`]?.General) + parseInt(summaryData[`Grant Match Transfer`]?.Teacher)) || 0,
              General: parseInt(summaryData[`Grant Match Transfer`]?.General) || 0,
              Teacher: parseInt(summaryData[`Grant Match Transfer`]?.Teacher) || 0,
              Debt: parseInt(summaryData[`Grant Match Transfer`]?.Debt) || 0,
              Capital: parseInt(summaryData[`Grant Match Transfer`]?.Capital) || 0
            },
            editable: isCurrentOrFutureYear,
            checkRowValid: validateTransferSum,
            formatFunction: formatCurrency
          },
          {
            label: "Capital Projects Transfer",
            values: {
              Total: (parseInt(summaryData[`Capital Projects Transfer`]?.General) + parseInt(summaryData[`Capital Projects Transfer`]?.Teacher) + parseInt(summaryData[`Capital Projects Transfer`]?.Debt) + parseInt(summaryData[`Capital Projects Transfer`]?.Capital)) || 0,
              Operating: (parseInt(summaryData[`Capital Projects Transfer`]?.General) + parseInt(summaryData[`Capital Projects Transfer`]?.Teacher)) || 0,
              General: parseInt(summaryData[`Capital Projects Transfer`]?.General) || 0,
              Teacher: parseInt(summaryData[`Capital Projects Transfer`]?.Teacher) || 0,
              Debt: parseInt(summaryData[`Capital Projects Transfer`]?.Debt) || 0,
              Capital: parseInt(summaryData[`Capital Projects Transfer`]?.Capital) || 0
            },
            editable: isCurrentOrFutureYear,
            checkRowValid: validateTransferSum,
            formatFunction: formatCurrency
          },
          {
            label: "Food Service Transfer",
            values: {
              Total: (parseInt(summaryData[`Food Service Transfer`]?.General) + parseInt(summaryData[`Food Service Transfer`]?.Teacher) + parseInt(summaryData[`Food Service Transfer`]?.Debt) + parseInt(summaryData[`Food Service Transfer`]?.Capital)) || 0,
              Operating: (parseInt(summaryData[`Food Service Transfer`]?.General) + parseInt(summaryData[`Food Service Transfer`]?.Teacher)) || 0,
              General: parseInt(summaryData[`Food Service Transfer`]?.General) || 0,
              Teacher: parseInt(summaryData[`Food Service Transfer`]?.Teacher) || 0,
              Debt: parseInt(summaryData[`Food Service Transfer`]?.Debt) || 0,
              Capital: parseInt(summaryData[`Food Service Transfer`]?.Capital) || 0
            },
            editable: isCurrentOrFutureYear,
            checkRowValid: validateTransferSum,
            formatFunction: formatCurrency
          },
          {
            label: "Student Activities Transfer",
            values: {
              Total: (parseInt(summaryData[`Student Activities Transfer`]?.General) + parseInt(summaryData[`Student Activities Transfer`]?.Teacher) + parseInt(summaryData[`Student Activities Transfer`]?.Debt) + parseInt(summaryData[`Student Activities Transfer`]?.Capital)) || 0,
              Operating: (parseInt(summaryData[`Student Activities Transfer`]?.General) + parseInt(summaryData[`Student Activities Transfer`]?.Teacher)) || 0,
              General: parseInt(summaryData[`Student Activities Transfer`]?.General) || 0,
              Teacher: parseInt(summaryData[`Student Activities Transfer`]?.Teacher) || 0,
              Debt: parseInt(summaryData[`Student Activities Transfer`]?.Debt) || 0,
              Capital: parseInt(summaryData[`Student Activities Transfer`]?.Capital) || 0
            },
            editable: isCurrentOrFutureYear,
            checkRowValid: validateTransferSum,
            formatFunction: formatCurrency
          },
          {
            label: "SB 287 Transfer",
            values: {
              Total: (parseInt(summaryData[`SB 287 Transfer`]?.General) + parseInt(summaryData[`SB 287 Transfer`]?.Teacher) + parseInt(summaryData[`SB 287 Transfer`]?.Debt) + parseInt(summaryData[`SB 287 Transfer`]?.Capital)) || 0,
              Operating: (parseInt(summaryData[`SB 287 Transfer`]?.General) + parseInt(summaryData[`SB 287 Transfer`]?.Teacher)) || 0,
              General: parseInt(summaryData[`SB 287 Transfer`]?.General) || 0,
              Teacher: parseInt(summaryData[`SB 287 Transfer`]?.Teacher) || 0,
              Debt: parseInt(summaryData[`SB 287 Transfer`]?.Debt) || 0,
              Capital: parseInt(summaryData[`SB 287 Transfer`]?.Capital) || 0
            },
            editable: isCurrentOrFutureYear,
            checkRowValid: validateTransferSum,
            formatFunction: formatCurrency
          },
          {
            label: "Estimated Restricted Money",
            values: {
              Total: (parseInt(summaryData[`Estimated Restricted Money`]?.General) + parseInt(summaryData[`Estimated Restricted Money`]?.Teacher) + parseInt(summaryData[`Estimated Restricted Money`]?.Debt) + parseInt(summaryData[`Estimated Restricted Money`]?.Capital)) || 0,
              Operating: (parseInt(summaryData[`Estimated Restricted Money`]?.General) + parseInt(summaryData[`Estimated Restricted Money`]?.Teacher)) || 0,
              General: parseInt(summaryData[`Estimated Restricted Money`]?.General) || 0,
              Teacher: parseInt(summaryData[`Estimated Restricted Money`]?.Teacher) || 0,
              Debt: parseInt(summaryData[`Estimated Restricted Money`]?.Debt) || 0,
              Capital: parseInt(summaryData[`Estimated Restricted Money`]?.Capital) || 0
            },
            editable: isCurrentOrFutureYear,
            formatFunction: formatCurrency
          },
          {
            label: `Estimated Fund Balance ${fiscalYear}`,
            values: {
              Total: (parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.General) + parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Teacher) + parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Debt) + parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Capital)) || 0,
              Operating: (parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.General) + parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Teacher)) || 0,
              General: parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.General) || 0,
              Teacher: parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Teacher) || 0,
              Debt: parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Debt) || 0,
              Capital: parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Capital) || 0
            },
            editable: false,
            formatFunction: formatCurrency
          },
          {
            label: `Estimated Unrestricted Money`,
            values: {
              Total: (parseInt(summaryData[`Estimated Unrestricted Money`]?.General) + parseInt(summaryData[`Estimated Unrestricted Money`]?.Teacher) + parseInt(summaryData[`Estimated Unrestricted Money`]?.Debt) + parseInt(summaryData[`Estimated Unrestricted Money`]?.Capital)) || 0,
              Operating: (parseInt(summaryData[`Estimated Unrestricted Money`]?.General) + parseInt(summaryData[`Estimated Unrestricted Money`]?.Teacher)) || 0,
              General: parseInt(summaryData[`Estimated Unrestricted Money`]?.General) || 0,
              Teacher: parseInt(summaryData[`Estimated Unrestricted Money`]?.Teacher) || 0,
              Debt: parseInt(summaryData[`Estimated Unrestricted Money`]?.Debt) || 0,
              Capital: parseInt(summaryData[`Estimated Unrestricted Money`]?.Capital) || 0
            },
            editable: false,
            formatFunction: formatCurrency
          },
          {
            label: `% Unrestricted Fund Balance`,
            values: {
              Total: '',
              Operating: summaryData[`% Unrestricted Fund Balance`]?.Operating || 0,
              General: '',
              Teacher: '',
              Debt: '',
              Capital: ''
            },
            editable: false,
            formatFunction: formatPercent
          },
        ]
      }
    };

    const rows = transformedData.budgetSummary.rows;
    calculateRows(rows);
    setData(transformedData);
  }, [summaryData, revenueData, fiscalYear, estimated_revenue, estimated_expense]);


  const addRows = (rowA, rowB) => {
    return {
      Total: rowA.values.Total + rowB.values.Total,
      Operating: rowA.values.Operating + rowB.values.Operating,
      General: rowA.values.General + rowB.values.General,
      Teacher: rowA.values.Teacher + rowB.values.Teacher,
      Debt: rowA.values.Debt + rowB.values.Debt,
      Capital: rowA.values.Capital + rowB.values.Capital
    }
  }

  const subtractRows = (rowA, rowB) => {
    return {
      Total: rowA.values.Total - rowB.values.Total,
      Operating: rowA.values.Operating - rowB.values.Operating,
      General: rowA.values.General - rowB.values.General,
      Teacher: rowA.values.Teacher - rowB.values.Teacher,
      Debt: rowA.values.Debt - rowB.values.Debt,
      Capital: rowA.values.Capital - rowB.values.Capital
    }
  }

  const calculateRows = (rows) => {
    // Total is the sum of all values
    rows[0].values.Total = rows[0].values.General + rows[0].values.Teacher + rows[0].values.Debt + rows[0].values.Capital;
    // Operating is the sum of general and teacher
    rows[0].values.Operating = rows[0].values.General + rows[0].values.Teacher;

    // Recalculate Estimated Receipts and Balances (row 2)
    rows[2].values = addRows(rows[0], rows[1]);

    // Recalculate Revenue - Expenditures (row 4)
    rows[4].values = subtractRows(rows[1], rows[3]);


    // Recalculate Balance Prior to Transfer (row 5)
    rows[5].values = addRows(rows[4], rows[0]);

    // Calculate totals from the transfer rows
    const transferTotals = { Total: 0, Operating: 0, General: 0, Teacher: 0, Debt: 0, Capital: 0 };
    for (let i = 6; i <= 12; i++) {
      // Total is the sum of all values
      rows[i].values.Total = rows[i].values.General + rows[i].values.Teacher + rows[i].values.Debt + rows[i].values.Capital;
      // Operating is the sum of general and teacher
      rows[i].values.Operating = rows[i].values.General + rows[i].values.Teacher;
      // Update transfer totals
      Object.entries(rows[i].values).forEach(([key, value]) => {
        transferTotals[key] += parseInt(value) || 0;
      });
    }

    // Update Estimated Fund Balance with sum of Balance Prior to Transfer and transfer totals
    const balancePriorToTransfer = rows[5].values;
    const finalBalances = { ...balancePriorToTransfer };
    Object.entries(transferTotals).forEach(([key, value]) => {
      finalBalances[key] += value;
    });

    rows[14].values = finalBalances;

    // Calculate Estimated Unrestricted Money
    // Subtract Estimated Restricted Money (row 13) from Estimated Fund Balance (row 14)
    const unrestrictedMoney = { ...finalBalances };
    Object.entries(rows[13].values).forEach(([key, value]) => {
      const restrictedValue = parseInt(value) || 0;
      unrestrictedMoney[key] -= restrictedValue;
    });

    // Add or update the Estimated Unrestricted Money row (row 15)
    if (rows.length === 15) {
      // Add the new row if it doesn't exist
      rows.push({
        label: "Estimated Unrestricted Money",
        values: unrestrictedMoney
      });
    } else {
      // Update existing row
      rows[15].values = unrestrictedMoney;
    }

    // % Unrestricted fund balance
    rows[16].values = {
      Total: '',
      Operating: (100 * rows[15].values.Operating / rows[3].values.Operating).toFixed(2),
      General: '',
      Teacher: '',
      Debt: '',
      Capital: ''
    };
  }

  // Handle local state updates
  const handleCellChange = (sectionKey, rowIndex, colIndex, value) => {
    const newData = { ...data };
    newData[sectionKey].rows[rowIndex].values[colIndex] = parseInt(value) || 0;

    if (sectionKey === 'budgetSummary') {
      calculateRows(newData[sectionKey].rows);
    }

    setData(newData);
    setPendingSave(true); // Mark that we have unsaved changes
  };

  // Update effect to use mutation
  useEffect(() => {
    if (!pendingSave) return;

    const saveTimeout = setTimeout(async () => {
      try {
        const updates = {};
        data.budgetSummary.rows.forEach(row => {
          if (row.editable ||
              row.label === "Revenue - Expenditures" || 
              row.label === "Balance Prior to Transfer" ||
              row.label === "Estimated Fund Balance" ||
              row.label === "Estimated Unrestricted Money" ||
              row.label === "% Unrestricted fund balance") {
            updates[row.label] = {};
            Object.entries(row.values).forEach(([key, value]) => {
              updates[row.label][key] = value;
            });
          }
        });

        await updateBudgetSummary.mutateAsync({
          districtCode,
          fiscalYear,
          version,
          updates
        });
        await propagateFundBalance();
        setPendingSave(false);
      } catch (error) {
        console.error("Error saving changes:", error);
      }
    }, 2000);

    return () => clearTimeout(saveTimeout);
  }, [data, pendingSave]);

  const handleInputChange = (field, value) => {
    setInputValues(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleModalSubmit = () => {
    const transferAmount = inputValues.SAT*inputValues.WADA*inputValues.transferPercent/100;
    setInputValues((prev) => ({ ... prev, transferAmount: transferAmount}))
    // Update the data variable
    const newData = { ...data };
    const section = newData.budgetSummary;
    const sb287RowIndex = section.rows.findIndex((row) => row.label === "SB 287 Transfer");
    const updatedRow = { ...section.rows[sb287RowIndex] };
    if (sb287RowIndex !== -1) {
      // Update the values object for the row
      updatedRow.values = {
        ...updatedRow.values,
        Operating: -transferAmount,
        General: -transferAmount,
        Capital: transferAmount
      };
      section.rows[sb287RowIndex] = updatedRow;
    }
    
    calculateRows(newData.budgetSummary.rows);
    setData(newData);
    setPendingSave(true);

    setIsModalOpen(false); // Close modal after submission
  };

  // Add helper function to calculate transfer totals
  const calculateTransferTotals = (rows) => {
    const transferRows = rows.slice(6, 13); // Get all transfer rows
    return {
      label: "Total Transfers",
      values: {
        Total: transferRows.reduce((sum, row) => sum + (row.values.Total || 0), 0),
        Operating: transferRows.reduce((sum, row) => sum + (row.values.Operating || 0), 0),
        General: transferRows.reduce((sum, row) => sum + (row.values.General || 0), 0),
        Teacher: transferRows.reduce((sum, row) => sum + (row.values.Teacher || 0), 0),
        Debt: transferRows.reduce((sum, row) => sum + (row.values.Debt || 0), 0),
        Capital: transferRows.reduce((sum, row) => sum + (row.values.Capital || 0), 0)
      }
    };
  };

  // Add a currency formatter helper
  const formatCurrency = (num) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    }).format(num || 0);
  };

    // Add a currency formatter helper
  const formatPercent = (num) => {
    if (num === '') {
      return '';
    }
    return (num || 0) + '%';
  };

  return (
    <div className="budget-summary-container">
      <h2>Budget Summary {fiscalYearString}</h2>
      {isSummaryLoading || isRevenueLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {/* Assessed Valuation Table */}
          <table className="budget-summary-table">
            <thead>
              <tr>
                {data.assessedValuation.headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.assessedValuation.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row.label}</td>
                  <td>{formatCurrency(row.values.total)}</td>
                  <td>{formatCurrency(row.values.residential)}</td>
                  <td>{formatCurrency(row.values.commercial)}</td>
                  <td>{formatCurrency(row.values.personal)}</td>
                  <td>{formatCurrency(row.values.agriculture)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Tax Levy Table */}
          <table className="budget-summary-table">
            <thead>
              <tr>
                {data.taxLevy.headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.taxLevy.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row.label}</td>
                  <td>{formatCurrency(row.values.total)}</td>
                  <td>{formatCurrency(row.values.operating)}</td>
                  <td>{formatCurrency(row.values.special)}</td>
                  <td>{formatCurrency(row.values.debt)}</td>
                  <td>{formatCurrency(row.values.capital)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Budget Summary Table */}
          { data.budgetSummary.rows.length > 0 &&
          <table className="budget-summary-table">
            <thead>
              <tr>
                {data.budgetSummary.headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.budgetSummary.rows.slice(0, 6).map((row, rowIndex) => (
                /* First 6 rows render normally */
                <tr 
                  key={rowIndex}
                  style={{
                    backgroundColor: !row.checkRowValid || row.checkRowValid(row) ? 'inherit' : '#ffebee'
                  }}
                >
                  <td>
                    {row.label}
                    {row.label === "SB 287 Transfer" && (
                      <FaCalculator
                        className="calculator-icon"
                        onClick={() => setIsModalOpen(true)}
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                      />
                    )}
                  </td>
                  <td>{row.formatFunction ? row.formatFunction(row.values.Total || 0) : (row.values.Total)}</td>
                  <td>{row.formatFunction ? row.formatFunction(row.values.Operating || 0) : (row.values.Operating)}</td>
                  <td>
                    {row.editable ? (
                      <CurrencyInput 
                        value={row.values.General || 0} 
                        onChange={(value) => handleCellChange('budgetSummary', rowIndex, 'General', value)}
                      />
                    ) : (
                      row.formatFunction ? row.formatFunction(row.values.General || 0) : (row.values.General)
                    )}
                  </td>
                  <td>
                    {row.editable ? (
                      <CurrencyInput 
                        value={row.values.Teacher || 0} 
                        onChange={(value) => handleCellChange('budgetSummary', rowIndex, 'Teacher', value)}
                      />
                    ) : (
                      row.formatFunction ? row.formatFunction(row.values.Teacher || 0) : (row.values.Teacher)
                    )}
                  </td>
                  <td>
                    {row.editable ? (
                      <CurrencyInput 
                        value={row.values.Debt || 0} 
                        onChange={(value) => handleCellChange('budgetSummary', rowIndex, 'Debt', value)}
                      />
                    ) : (
                      row.formatFunction ? row.formatFunction(row.values.Debt || 0) : (row.values.Debt)
                    )}
                  </td>
                  <td>
                    {row.editable ? (
                      <CurrencyInput 
                        value={row.values.Capital || 0} 
                        onChange={(value) => handleCellChange('budgetSummary', rowIndex, 'Capital', value)}
                      />
                    ) : (
                      row.formatFunction ? row.formatFunction(row.values.Capital || 0) : (row.values.Capital)
                    )}
                  </td>
                </tr>
              ))}

            {!isTransfersCollapsed && (
              <tr>
                <td style={{ cursor: 'pointer' }} onClick={() => setIsTransfersCollapsed(!isTransfersCollapsed)}>
                  {isTransfersCollapsed ? '►' : '▼'} Transfers
                </td>
                <td colSpan={6}></td>
              </tr>)}

              {/* Show either collapsed summary or expanded rows */}
              {isTransfersCollapsed ? (
                <tr>
                  <td style={{ cursor: 'pointer' }} onClick={() => setIsTransfersCollapsed(!isTransfersCollapsed)}>
                      {isTransfersCollapsed ? '►' : '▼'} Total Transfers
                  </td>
                  {Object.entries(calculateTransferTotals(data.budgetSummary.rows).values).map(([key, value], index) => (
                    <td key={index}>
                      {formatCurrency(value || 0)}
                    </td>
                  ))}
                </tr>
              ) : (
                data.budgetSummary.rows.slice(6, 13).map((row, rowIndex) => (
                  <tr 
                    key={rowIndex + 6}
                    style={{
                      backgroundColor: !row.checkRowValid || row.checkRowValid(row) ? 'inherit' : '#ffebee'
                    }}
                  >
                    <td>
                      {row.label}
                      {row.label === "SB 287 Transfer" && (
                        <FaCalculator
                          className="calculator-icon"
                          onClick={() => setIsModalOpen(true)}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                        />
                      )}
                    </td>
                    <td>{row.formatFunction ? row.formatFunction(row.values.Total) : (row.values.Total)}</td>
                    <td>{row.formatFunction ? row.formatFunction(row.values.Operating) : (row.values.Operating)}</td>
                    <td>
                      {row.editable ? (
                        <CurrencyInput 
                          value={row.values.General} 
                          onChange={(value) => handleCellChange('budgetSummary', rowIndex + 6, 'General', value)}
                        />
                      ) : (
                        row.formatFunction ? row.formatFunction(row.values.General) : (row.values.General)
                      )}
                    </td>
                    <td>
                      {row.editable ? (
                        <CurrencyInput 
                          value={row.values.Teacher} 
                          onChange={(value) => handleCellChange('budgetSummary', rowIndex + 6, 'Teacher', value)}
                        />
                      ) : (
                        row.formatFunction ? row.formatFunction(row.values.Teacher) : (row.values.Teacher)
                      )}
                    </td>
                    <td>
                      {row.editable ? (
                        <CurrencyInput 
                          value={row.values.Debt} 
                          onChange={(value) => handleCellChange('budgetSummary', rowIndex + 6, 'Debt', value)}
                        />
                      ) : (
                        row.formatFunction ? row.formatFunction(row.values.Debt) : (row.values.Debt)
                      )}
                    </td>
                    <td>
                      {row.editable ? (
                        <CurrencyInput 
                          value={row.values.Capital} 
                          onChange={(value) => handleCellChange('budgetSummary', rowIndex + 6, 'Capital', value)}
                        />
                      ) : (
                        row.formatFunction ? row.formatFunction(row.values.Capital) : (row.values.Capital)
                      )}
                    </td>
                  </tr>
                ))
              )}

              {/* Render remaining rows */}
              {data.budgetSummary.rows.slice(13).map((row, rowIndex) => (
                <tr 
                  key={rowIndex + 13}
                  style={{
                    backgroundColor: !row.checkRowValid || row.checkRowValid(row) ? 'inherit' : '#ffebee'
                  }}
                >
                  <td>
                    {row.label}
                    {row.label === "SB 287 Transfer" && (
                      <FaCalculator
                        className="calculator-icon"
                        onClick={() => setIsModalOpen(true)}
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                      />
                    )}
                  </td>
                  <td>{row.formatFunction ? row.formatFunction(row.values.Total) : (row.values.Total)}</td>
                  <td>{row.formatFunction ? row.formatFunction(row.values.Operating) : (row.values.Operating)}</td>
                  <td>
                    {row.editable ? (
                      <CurrencyInput 
                        value={row.values.General || 0} 
                        onChange={(value) => handleCellChange('budgetSummary', rowIndex + 13, 'General', value)}
                      />
                    ) : (
                      row.formatFunction ? row.formatFunction(row.values.General) : (row.values.General)
                    )}
                  </td>
                  <td>
                    {row.editable ? (
                      <CurrencyInput 
                        value={row.values.Teacher || 0} 
                        onChange={(value) => handleCellChange('budgetSummary', rowIndex + 13, 'Teacher', value)}
                      />
                    ) : (
                      row.formatFunction ? row.formatFunction(row.values.Teacher) : (row.values.Teacher)
                    )}
                  </td>
                  <td>
                    {row.editable ? (
                      <CurrencyInput 
                        value={row.values.Debt || 0} 
                        onChange={(value) => handleCellChange('budgetSummary', rowIndex + 13, 'Debt', value)}
                      />
                    ) : (
                      row.formatFunction ? row.formatFunction(row.values.Debt) : (row.values.Debt)
                    )}
                  </td>
                  <td>
                    {row.editable ? (
                      <CurrencyInput 
                        value={row.values.Capital || 0} 
                        onChange={(value) => handleCellChange('budgetSummary', rowIndex + 13, 'Capital', value)}
                      />
                    ) : (
                      row.formatFunction ? row.formatFunction(row.values.Capital) : (row.values.Capital)
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
        </>
      )}
      <TransferCalculator 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        inputValues={inputValues}
        onInputChange={handleInputChange}
        onSubmit={handleModalSubmit}
      />
    </div>
  );
};

export default BudgetSummary;