import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ref, get, update } from "firebase/database";
import { database } from "../../firebaseConfig";

export const useBudgetSummary = (districtCode, fiscalYear, version) => {
  const budgetSummaryPath = `budget/${districtCode}/${fiscalYear}/summary/${version}`;
  
  return useQuery({
    queryKey: ['budgetSummary', districtCode, fiscalYear, version],
    queryFn: async () => {
      console.log('Fetching budget summary from:', budgetSummaryPath);
      const summaryRef = ref(database, budgetSummaryPath);
      const snapshot = await get(summaryRef);
      return snapshot.val();
    },
    enabled: !!districtCode && !!fiscalYear && !!version
  });
};

export const useUpdateBudgetSummary = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ districtCode, fiscalYear, version, updates }) => {
      const budgetSummaryPath = `budget/${districtCode}/${fiscalYear}/summary/${version}`;
      console.log('Updating budget summary:', { path: budgetSummaryPath, updates });
      console.log(`balance for ${fiscalYear}:`, updates[`Balance June 30 ${fiscalYear-1}`]);
      console.log(`estimatedunrestricted for ${fiscalYear}:`, updates['Estimated Unrestricted Money']);
      await update(ref(database, budgetSummaryPath), updates);
      return { districtCode, fiscalYear, version, updates };
    },
    onMutate: async ({ districtCode, fiscalYear, version, updates }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ 
        queryKey: ['budgetSummary', districtCode, fiscalYear, version] 
      });

      // Snapshot the previous value
      const previousData = queryClient.getQueryData([
        'budgetSummary', 
        districtCode, 
        fiscalYear, 
        version
      ]);

      // Optimistically update the cache
      queryClient.setQueryData(
        ['budgetSummary', districtCode, fiscalYear, version],
        (old) => ({
          ...old,
          ...updates
        })
      );

      // Return context with the previous data
      return { previousData, districtCode, fiscalYear, version };
    },
    onError: (err, variables, context) => {
      // If mutation fails, roll back to the previous value
      if (context) {
        const { previousData, districtCode, fiscalYear, version } = context;
        queryClient.setQueryData(
          ['budgetSummary', districtCode, fiscalYear, version],
          previousData
        );
      }
    },
    onSettled: (data, error, variables) => {
      // Always refetch after error or success to ensure cache is in sync
      const { districtCode, fiscalYear, version } = variables;
      queryClient.invalidateQueries({
        queryKey: ['budgetSummary', districtCode, fiscalYear, version]
      });
    }
  });
};

export const useRevenueMetadata = (districtCode, fiscalYear, version) => {
  const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;

  return useQuery({
    queryKey: ['revenueMetadata', districtCode, fiscalYear, version],
    queryFn: async () => {
      console.log('Fetching revenue metadata from:', revenueMetadataPath);
      const revenueRef = ref(database, revenueMetadataPath);
      const snapshot = await get(revenueRef);
      return snapshot.val();
    },
    enabled: !!districtCode && !!fiscalYear && !!version
  });
}; 