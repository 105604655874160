import React, { useState, useEffect } from 'react';
import GeneralDrilldownTable from '../../GeneralDrilldownTable';
import '../../../styles/BudgetBuilder.css';
import { database } from '../../../firebaseConfig';
import { ref, get } from "firebase/database";
import LineChart from './LineChart';
import BudgetSummary from './BudgetSummary';
import BudgetFilterModal from './BudgetFilterModal';
import {assignLevels, getUniqueValues, sumFieldByGroup, findClosestMatch} from '../../../utils'

const fundMapping = {
    "10": "GENERAL_FUND",
    "20": "SPECIAL_REVENUE",
    "30": "DEBT_SERVICE",
    "40": "CAPITAL_PROJECTS",
    "50": "GENERAL_FUND",
    "60": "GENERAL_FUND",
    "70": "GENERAL_FUND",
    "14": "GENERAL_FUND",
    "16": "GENERAL_FUND",
    "42": "CAPITAL_PROJECTS",
    "43": "CAPITAL_PROJECTS",
    "44": "CAPITAL_PROJECTS",
    "45": "CAPITAL_PROJECTS"
    };

const BudgetBuilder = ({ selectedDistrictInfo, deseCodeMap, fiscalYear, version }) => {

    const [currentBudget, setCurrentBudget] = useState(null);
    const [expenseData, setExpenseData] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [fundBalanceData, setFundBalanceData] = useState([]);
    const [isGraphVisible, setIsGraphVisible] = useState(false);
    const [accountCodeSchema, setAccountCodeSchema] = useState('');
    const [refreshData, setRefreshData] = useState(false);
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [filters, setFilters] = useState({funds: [], objects: [], locations: []});
    const [uniqueLocations, setUniqueLocations] = useState([]);
    const [filtersApplied, setFiltersApplied] = useState(false);

    const handleRefresh = () => {
        setRefreshData(prev => !prev);
    };

    const clearFilters = () => {
        setFiltersApplied(false);
        setFilters({funds: [], objects: [], locations: []})
    }

    const formHierarchy = (groupedData) => {

        const current_budget_children = Object.entries(groupedData).map(([key, value]) => ({
            groupKey: key,
            ...value
            }));

        const parentSum = sumFieldByGroup(current_budget_children, 
            ['ID', 'YEAR', 'version', 'PARENT'], 
            ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE'
            ]);

        let parent_result = parentSum.map(obj => ({
        ...obj, // Spread the original object
        LABEL: obj.PARENT,
        PARENT: obj.GPARENT,
        AGGREGATE: true
        }))

        const gparentSum = sumFieldByGroup(parent_result, 
        ['ID', 'YEAR', 'version', 'PARENT'], 
        ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE']);

        let gparent_result = gparentSum.map(obj => ({
        ...obj, // Spread the original object
        LABEL: obj.PARENT,
        PARENT: obj.GGPARENT,
        AGGREGATE: true
        }))

        const ggparentSum = sumFieldByGroup(gparent_result, 
        ['ID', 'YEAR', 'version', 'PARENT'], 
        ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE']);

        let ggparent_result = ggparentSum.map(obj => ({
        ...obj, // Spread the original object
        LABEL: obj.PARENT,
        PARENT: obj.GGGPARENT,
        AGGREGATE: true
        }))

        const gggparentSum = sumFieldByGroup(ggparent_result, 
            ['ID', 'YEAR', 'version', 'PARENT'], 
            ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE']);

        let gggparent_result = gggparentSum.map(obj => ({
        ...obj, // Spread the original object
        LABEL: obj.PARENT,
        PARENT: 'Self',
        AGGREGATE: true
        }))

        const finalBudget = [current_budget_children, parent_result, gparent_result, ggparent_result, gggparent_result].flat();

        // calculate the LEVEL of each LABEL. i.e. PARENT == 'Self' is level 0, its children level 1, etc.
        // this will help us indent the table properly according to the level

        
        let levelDict = assignLevels(finalBudget, 'LABEL');
        finalBudget.forEach(item => item['LEVEL'] = levelDict[item.LABEL]);

        return finalBudget
    }

    useEffect(() => {
        const loadBudget = async () => {
        
            // Fetch account code schema
            const accountCodeSchemaSnapshot = await get(ref(database, `accountCodeSchema`));
            setAccountCodeSchema(accountCodeSchemaSnapshot.val()?.[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]);
            
            // Only fetch data for years we need (you could make this dynamic based on UI selection)
            const yearsToFetch = ["2022", "2023", "2024", "2025", "2026"];
            
            const yearDataPromises = yearsToFetch.map(year => 
                get(ref(database, `/budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${year}`))
            );
            
            const yearSnapshots = await Promise.all(yearDataPromises);
            
            // Process each year's data
            let currentBudgetArray = [];
            const budgetData = {};
            
            yearSnapshots.forEach((snapshot, index) => {
                const year = yearsToFetch[index];
                const yearData = snapshot.val() ?? {};
                budgetData[year] = yearData;
                
                // Process this year's data (same logic as before but for a single year)
                const yearBudgetArray = Object.entries(yearData ?? {}).flatMap(([GROUP, accounts]) =>
                    Object.entries(accounts ?? {}).flatMap(([accountCode, budgetData]) => {
                        if (!budgetData?.budget) return [];
                        
                        return Object.entries(budgetData.budget)
                            .filter(([ver]) => ver === version)
                            .map(([ver, details]) => ({
                                ID: selectedDistrictInfo['DISTRICT_COUNTY_CODE'] || '',
                                YEAR: year || '',
                                GROUP: GROUP || '',
                                accountCode: accountCode || '',
                                version: ver || '',
                                DISTRICT_NAME: selectedDistrictInfo['DISTRICT_NAME'],
                                ...(details || {})
                            }));
                    })
                );
                
                currentBudgetArray = [...currentBudgetArray, ...yearBudgetArray];
            });

            const groupedData = {};

            currentBudgetArray.forEach(item => {

                let key = parseInt(item.accountCode.split('-')[1]);
                const groupData = deseCodeMap[item.GROUP] || {};
                // if the key doesn't exist, it is a custom function code 
                let customFuncCode = false;
                if(!groupData[key]){customFuncCode = true;}

                const keyData = (!customFuncCode? groupData[key] : groupData[findClosestMatch(Object.keys(groupData), key)]) || {};
                const objectCodeData = deseCodeMap['object'][parseInt(item.accountCode.split('-')[2])] || {};
                const fundKey = fundMapping[item.fund];
                const groupKey = `${item.YEAR}-${item.accountCode}-${item.version}`;
                
                if (!groupedData[groupKey]) {
                    groupedData[groupKey] = {
                        ...item,
                        LABEL: item.accountCode.slice(8,item.accountCode.length),
                        FUNC_CODE_DESCRIPTION: !customFuncCode ? keyData.DETAIL_DESCRIPTION : "Unknown",
                        DESCRIPTION: item.description ? item.description : (objectCodeData.DETAIL_DESCRIPTION ? objectCodeData.DETAIL_DESCRIPTION : "Unknown"),
                        IS_EDITABLE: keyData.IS_EDITABLE ? keyData.IS_EDITABLE : false,
                        PARENT: !customFuncCode ? `${key} - ${keyData.DETAIL_DESCRIPTION}` : `${key} - Unknown`,
                        GPARENT: keyData.SUBCLASS ? `${keyData.SUBCLASS} - ${keyData.SUBCLASS_DESCRIPTION}` : 'Unknown',
                        GGPARENT: keyData.CLASS ? `${keyData.CLASS} - ${keyData.CLASS_DESCRIPTION}` : 'Unknown',
                        GGGPARENT: keyData.GROUP? `${keyData.GROUP}` : item.GROUP,
                        GENERAL_FUND: 0,
                        SPECIAL_REVENUE: 0,
                        DEBT_SERVICE: 0,
                        CAPITAL_PROJECTS: 0,
                        TOTAL_ALL_FUNDS: 0,
                        AGGREGATE: false
                    }
                }
                if (fundKey) {
                    groupedData[groupKey][fundKey] = item.budget;
                    groupedData[groupKey]['TOTAL_ALL_FUNDS'] += item.budget;
                }
                
            })

            setUniqueLocations([...new Set(Object.values(groupedData).filter(item => item.location !== undefined).map(item => item.location))]);
            setFiltersApplied(Object.values(filters).some(arr => arr.length > 0))

            const filteredGroupedData = Object.fromEntries(
                Object.entries(groupedData).filter(([_, value]) => 
                    (filters.funds.length === 0 || (filters.funds.includes(value['fund']) && value['budget']>0)) &&
                    (filters.objects.length === 0 || (filters.objects.includes(value['object']) && value['budget']>0)) &&
                    (filters.locations.length === 0 || (filters.locations.includes(value['location']) && value['budget']>0))
                )
            );

            let finalBudget = formHierarchy(filteredGroupedData);
            
            setCurrentBudget(finalBudget);
            setExpenseData(finalBudget?.filter(item=>(item.LEVEL===0) && (item.LABEL==='expense') && (item.YEAR>2024)))
            setRevenueData(finalBudget?.filter(item=>(item.LEVEL===0) && (item.LABEL==='revenue') && (item.YEAR>2024)))

            let FB_list = []
            getUniqueValues(finalBudget?.filter(item=>(item.LEVEL===0) && (item.LABEL==='expense') && (item.YEAR>2024)), "YEAR").forEach(year=>{
                if(budgetData[year]['summary']){
                    FB_list.push({"YEAR": year, "Operating" : parseFloat(budgetData[year]['summary'][version]['% Unrestricted Fund Balance']['Operating'])})
                }
            })
            setFundBalanceData(FB_list)

        }

    loadBudget();

    }, [selectedDistrictInfo, version, fiscalYear, refreshData, deseCodeMap, filters])
    
    return (
        <>
        {currentBudget && <BudgetFilterModal 
            filters={filters}
            setFilters={setFilters}
            isOpen={filterModalOpen}
            onClose={() => {
                setFilterModalOpen(false);
            }}
            onSubmit={(data) => {
                setFilters(data);
            }}
            deseCodeMap={deseCodeMap}
            uniqueLocations={uniqueLocations}/>}
        <div className='budget-builder-container'>
            {currentBudget ? (
                <GeneralDrilldownTable
                    data = {currentBudget}
                    tableDataFilters={`item.AGGREGATE && item.YEAR > 2021 && !isNaN(item.TOTAL_ALL_FUNDS) && item.GROUP!='cash' && item.LABEL!='cash'`}
                    tableDataFormats= {{'TOTAL_ALL_FUNDS': 'dollars'}}
                    rowKey = 'LABEL'
                    columnKey = 'YEAR'
                    valueLabel = 'TOTAL_ALL_FUNDS'
                    rowLevelFunctions = {{'graph' : true, 'edit' : true}}
                    fiscalYear = {fiscalYear}
                    version = {version}
                    deseCodeMap={deseCodeMap}
                    title = {`Budget Builder ${parseInt(fiscalYear)-1}-${fiscalYear}`}
                    graphOptions = {{showLegend : false}}
                    accountCodeSchema = {accountCodeSchema}
                    onRefresh={handleRefresh}
                    setFilterModalOpen={setFilterModalOpen}
                    filtersApplied={filtersApplied}
                    clearFilters={clearFilters}
                />
            ) : (
                <p>Loading data...</p>
            )}
            
            <div 
                className='toggle-arrow' 
                onClick={() => setIsGraphVisible(!isGraphVisible)} 
            >
                {isGraphVisible ? '▼ Hide Graphs' : '► Show Graphs'}
            </div>
            
            {isGraphVisible && (
                (expenseData?.length>0 && revenueData?.length>0 && fundBalanceData?.length>0) ? (
                <div className='graphs-container'>
                <div className='revenue-expenses-graph-container'>
                    <LineChart datasets={[expenseData, revenueData]}
                               group_labels={['expense', 'revenue']}
                               value_labels={['GENERAL_FUND', 'SPECIAL_REVENUE']}
                               yaxisLabel={'$'} 
                               yMin={0}
                               yMax={12000000}/>
                </div>
                
                <div className='fund-balance-graph-container'>
                    <LineChart datasets={[fundBalanceData]}
                                group_labels={['fund_balance']}
                                value_labels={['Operating']}
                                yaxisLabel={'%'}
                                yMin={0}
                                yMax={150}/>
                </div>
                
                </div>
            ) : (
                <p>Loading graphs...</p>
            )
            )}
            
            {currentBudget?.filter(item=>item.YEAR===fiscalYear) ? (
            <BudgetSummary currentBudget={currentBudget}
                           districtCode={selectedDistrictInfo['DISTRICT_COUNTY_CODE']}
                           fiscalYear={fiscalYear}
                           version={version}
            />) : (
                <p>Loading budget summary...</p>
            )}
        </div>
        </>
    );
};

export default BudgetBuilder;