import React, { useState, useEffect } from "react";

export const StateRevenueModal = ({ isOpen, stateRevDist, onClose, onSubmit, revenueCode }) => {

  const [formData, setFormData] = useState({})

  // Reset formData when the component mounts
  useEffect(() => {
    setFormData({});
  }, []);

  useEffect(() => {
  
    if (stateRevDist && stateRevDist.codeInfo && stateRevDist.codeInfo[revenueCode]) {
      setFormData({
        location: stateRevDist.codeInfo[revenueCode].location || "",
        source: stateRevDist.codeInfo[revenueCode].source || "",
        project: stateRevDist.codeInfo[revenueCode].project || "",
        program: stateRevDist.codeInfo[revenueCode].program || "",
      });
    }
  }, [stateRevDist, revenueCode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="state-rev-modal-overlay">
      <div className="state-rev-modal">
        <h2>Code Details <br/> {revenueCode}</h2>
        <div className="state-rev-modal-form-group">
          <div className="state-rev-modal-input">
          <label>Location: </label>
          <input name="location" placeholder="Location" value={formData.location} onChange={handleChange}/>
          </div>
          <div className="state-rev-modal-input">
          <label>Source: </label>
          <input name="source" placeholder="Source" value={formData.source} onChange={handleChange}/>
          </div>
          <div className="state-rev-modal-input">
          <label>Project: </label>
          <input name="project" placeholder="Project" value={formData.project} onChange={handleChange}/>
          </div>
          <div className="state-rev-modal-input">
          <label>Program: </label>
          <input name="program" placeholder="Program" value={formData.program} onChange={handleChange}/>
          </div>
        </div>
        <div className="state-rev-modal-button-group">
          <button onClick={() => {
            setFormData({});
            onClose();
          }}>Cancel</button>
          <button onClick={() => {
            setFormData({});
            handleSubmit();
          }}>Submit</button>
        </div>
      </div>
    </div>
  );
};