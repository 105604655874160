import React, { useState } from "react";

// Get the display text for selected items
const getSelectedItemsDisplay = (formData, filterOptions, field) => {

  if (formData[field].length === 0) {
    return `Select ${field}...`;
  }
  
  // Map the selected values to their labels
  const selectedLabels = formData[field].map(value => {
    const option = filterOptions.find(opt => opt.value === value);
    return option ? option.label : value;
  });
  
  // Join the labels with commas
  return selectedLabels.join(", ");
};

// Handle checkbox changes for multi-select
const handleCheckboxChange = (e, setFilterData, field) => {
  const { value, checked } = e.target;
  
  setFilterData((prevData) => {
    // If checked, add to array; if unchecked, remove from array
    if (checked) {
      return {
        ...prevData,
        [field]: [...prevData[field], value]
      };
    } else {
      return {
        ...prevData,
        [field]: prevData[field].filter(item => item !== value)
      };
    }
  });
};
  
export const FilterSearchBox = ({isDropdownOpen, setIsDropdownOpen, dropdownOptions, filterData, setFilterData, field}) => {
  // Add state for search term
  const [searchTerm, setSearchTerm] = useState("");
  
  // Filter options based on search term
  const filteredOptions = dropdownOptions.filter(option => 
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  // Prevent dropdown from closing when clicking inside
  const handleDropdownClick = (e) => {
    e.stopPropagation();
  };
  
  return (
    <div className="multi-select-dropdown">
      <div 
        className="dropdown-header" 
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className="selected-options">
          {getSelectedItemsDisplay(filterData, dropdownOptions, field)}
        </div>
        <span className="dropdown-arrow">{isDropdownOpen ? '▲' : '▼'}</span>
      </div>
      
      {isDropdownOpen && (
        <div className="dropdown-options-container" onClick={handleDropdownClick}>
          <div className="dropdown-search-container">
            <input
              type="text"
              className="search-input"
              placeholder="Search ..."
              value={searchTerm}
              onChange={handleSearchChange}
              autoFocus
            />
          </div>
          
          <div className="dropdown-options">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div key={option.value} className="dropdown-option">
                  <label>
                    <input
                      type="checkbox"
                      value={option.value}
                      checked={filterData[field].includes(option.value)}
                      onChange={(e) => handleCheckboxChange(e, setFilterData, field)}
                    />
                    {option.label}
                  </label>
                </div>
              ))
            ) : (
              <div className="no-results">No matching {field} found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterSearchBox;