import React, { useState, useEffect } from "react";

export const StaffSettingsModal = ({ isOpen, staffSettings, onClose, onSubmit }) => {

  const [formData, setFormData] = useState({})

  // Reset formData when the component mounts
  useEffect(() => {
    setFormData({});
  }, []);

  useEffect(() => {
  
    if (staffSettings && staffSettings.contractDays) {
      setFormData({
        contractDays: staffSettings.contractDays || "",
      });
    }
  }, [staffSettings]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="staff-settings-modal-overlay">
      <div className="staff-settings-modal">
        <h2>Staff Settings</h2>
        <div className="staff-settings-modal-form-group">
          <div className="staff-settings-modal-input">
          <label>Contract Days: </label>
          <input name="contractDays" placeholder="Contract Days" value={formData.contractDays} onChange={handleChange}/>
          </div>
        </div>
        <div className="staff-settings-modal-button-group">
          <button onClick={() => {
            setFormData({});
            onClose();
          }}>Cancel</button>
          <button onClick={() => {
            setFormData({});
            handleSubmit();
          }}>Submit</button>
        </div>
      </div>
    </div>
  );
};