import newRoleData from "./newRole.json";
import { useEffect } from 'react';
import { database } from '../../firebaseConfig';
import { ref, update, get } from "firebase/database";

export const createNewRole = () => {
  const newRole = newRoleData;
  return newRole;
}

export const updateActivityRole = (uuid, baseSalary, setRows, benefitRates) => {

  setRows((prevRows) => {
    const updatedRows = [...prevRows];
    const rowIndex = updatedRows.findIndex((row) => row.uuid === uuid);

    let insuranceBenefits = 0;
    if (rowIndex !== -1) {
      const row = updatedRows[rowIndex];
      const effectiveSalary = baseSalary;
      const contract = effectiveSalary;
      
      // For now, don't calculate benefits for activity roles
      //if(row.fte>0){
      //  Object.keys(row.benefits).forEach(benefit => insuranceBenefits += row.benefits[benefit] > 0 ? benefitRates[benefit] * 12 * (row.fte || 1) : 0)
      //}

      const medicare = contract * benefitRates.medicareRate;
      let retirement = 0; let fica = 0;
      if(row.employeeType==='classified'){
        retirement = (contract + insuranceBenefits) * benefitRates.peersRate;
        fica = contract * benefitRates.socialSecurityRate;
      } else{
        retirement = (contract + insuranceBenefits) * benefitRates.certifiedRetirement;
      }

      const totalCost = contract + insuranceBenefits + medicare + retirement + fica;

      updatedRows[rowIndex] = {
        ...row,
        salary: effectiveSalary, // Update salary to reflect FTE
        fullFTEsalary: baseSalary,
        totalContract: contract,
        fica,
        medicare,
        retirement,
        insuranceBenefits,
        totalCost,
        hasChanged: true
      };
    }

    return updatedRows;
  });
};

export const updateCertifiedRole = (uuid, baseSalary, districtSalary, setRows, benefitRates) => {

  setRows((prevRows) => {
    const updatedRows = [...prevRows];
    const rowIndex = updatedRows.findIndex((row) => row.uuid === uuid);

    let insuranceBenefits = 0;
    if (rowIndex !== -1) {
      const row = updatedRows[rowIndex];
      const effectiveSalary = baseSalary * (row.fte || 1); // Calculate effective salary
      const extraDaysSalary = (row.fte || 1) * parseFloat(row.extraDays*districtSalary/benefitRates.contractDays || 0)
      const contract = effectiveSalary + parseFloat(row.extendedContract || 0) + extraDaysSalary;
      
      if(row.fte>0){
        Object.keys(row.benefits).forEach(benefit => insuranceBenefits += row.benefits[benefit] > 0 ? benefitRates[benefit] * 12 * (row.fte || 1) : 0)
      }

      const medicare = contract * benefitRates.medicareRate;
      let retirement = 0; let fica = 0;
      if(row.employeeType==='classified'){
        retirement = (contract + insuranceBenefits) * benefitRates.peersRate;
        fica = contract * benefitRates.socialSecurityRate;
      } else{
        retirement = (contract + insuranceBenefits) * benefitRates.certifiedRetirement;
      }

      const totalCost = contract + insuranceBenefits + medicare + retirement + fica;

      updatedRows[rowIndex] = {
        ...row,
        salary: effectiveSalary, // Update salary to reflect FTE
        fullFTEsalary: baseSalary,
        extraDaysSalary: extraDaysSalary,
        totalContract: contract,
        fica,
        medicare,
        retirement,
        insuranceBenefits,
        totalCost,
        hasChanged: true
      };
    }

    return updatedRows;
  });
};

export const updateClassifiedRole = (uuid, hourlyRate, setRows, benefitRates) => {

  setRows((prevRows) => {
    const updatedRows = [...prevRows];
    const rowIndex = updatedRows.findIndex((row) => row.uuid === uuid);

    let insuranceBenefits = 0;
    if (rowIndex !== -1) {
      const row = updatedRows[rowIndex];
      const extraDaysSalary = (row.fte || 1) * parseFloat(row.extraDays*((row.totalHours || 0) * hourlyRate)/benefitRates.contractDays || 0)
      const effectiveSalary = (row.totalHours || 0) * hourlyRate * (row.fte || 1) + extraDaysSalary + (row.extendedContract || 0); // Calculate effective salary
      
      if(row.fte>0){
        Object.keys(row.benefits).forEach(benefit => insuranceBenefits += row.benefits[benefit] > 0 ? benefitRates[benefit] * 12 * (row.fte || 1) : 0)
      }

      const medicare = effectiveSalary * benefitRates.medicareRate;
      let retirement = 0; let fica = 0;
      if(row.employeeType==='classified'){
        retirement = (effectiveSalary + insuranceBenefits) * benefitRates.peersRate;
        fica = effectiveSalary * benefitRates.socialSecurityRate;
      } else{
        retirement = (effectiveSalary + insuranceBenefits) * benefitRates.certifiedRetirement;
      }
      const totalCost = effectiveSalary + insuranceBenefits + medicare + retirement + fica;

      updatedRows[rowIndex] = {
        ...row,
        hourlyRate,
        salary: effectiveSalary,
        extraDaysSalary: extraDaysSalary,
        fica,
        medicare,
        retirement,
        insuranceBenefits,
        totalCost,
        hasChanged: true // Mark for saving to firebase
      };
    }
    return updatedRows;
  });
}

export const useDetailsAutoClose = () => {
  useEffect(() => {
    // Function to handle closing details when clicking outside
    const handleOutsideClick = (event) => {
      // Get all details elements
      const allDetails = document.querySelectorAll('details');
      
      // Loop through all details elements
      allDetails.forEach(details => {
        // Check if the click was outside this details element
        const isClickInside = details.contains(event.target);
        
        // If details is open and click is outside, close it
        if (details.open && !isClickInside && !event.target.closest('summary')) {
          details.open = false;
        }
      });
    };

    // Add event listener when component mounts
    document.addEventListener('click', handleOutsideClick);
    
    // Add event listener to prevent propagation on dropdown content
    const dropdownContents = document.querySelectorAll('.dropdown-content');
    
    const preventPropagation = (event) => {
      event.stopPropagation();
    };
    
    dropdownContents.forEach(content => {
      content.addEventListener('click', preventPropagation);
    });

    // Clean up event listeners when component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      dropdownContents.forEach(content => {
        content.removeEventListener('click', preventPropagation);
      });
    };
  }, []); // Empty dependency array means this effect runs once on mount
};

// Convert forEach to a for...of loop with async/await
const getLineItemsAndSubtract = async (components, row, determineObjectCode, buildAccountCode, accountCodeSchema, districtCode, fiscalYear, version) => {
  const lineItems = {};
  for (const [key, value] of Object.entries(components)) {
    // Determine the object code for the component
    const objectCode =
      (key === "salary" || key === "salary_blsg")
        ? row.object || determineObjectCode(key.replace("_blsg",""), row.function)
        : determineObjectCode(key.replace("_blsg",""), row.function);
    
    // If the key has to do with the baseline salary grant (blsg) do this
    if(key.includes("blsg")){
      const dynamicKey_blsg = buildAccountCode(accountCodeSchema['budget'], {
        fund: row.fund, 
        function: row.function, 
        object: objectCode, 
        location: row.location, 
        source: row.source, 
        program: row.program, 
        project: '34100'
      });

      const blsgRef = ref(database, `budget/${districtCode}/${fiscalYear}/expense/${dynamicKey_blsg}/budget/${version}`);
      const blsgSnapshot = await get(blsgRef);
      const blsgData = blsgSnapshot.val();
      
      if(blsgData){
        lineItems[dynamicKey_blsg] = {};
        lineItems[dynamicKey_blsg]['budget'] = blsgData.budget;
        lineItems[dynamicKey_blsg]['budget'] -= value;
      }
    // Otherwise do this
    } else {
      const dynamicKey = buildAccountCode(accountCodeSchema['budget'], {
        fund: row.fund, 
        function: row.function, 
        object: objectCode, 
        location: row.location, 
        source: row.source, 
        program: row.program, 
        project: row.project
      });

      const budgetRef = ref(database, `budget/${districtCode}/${fiscalYear}/expense/${dynamicKey}/budget/${version}`);
      const budgetSnapshot = await get(budgetRef);
      const budgetData = budgetSnapshot.val();
      
      if(budgetData){
        lineItems[dynamicKey] = {};
        lineItems[dynamicKey]['budget'] = budgetData.budget;
        lineItems[dynamicKey]['budget'] -= value;
      }
    }
  }
  return lineItems
};

export const subtractLineItemsFromBudget = async (row, determineObjectCode, buildAccountCode, accountCodeSchema, districtCode, fiscalYear, version) => {

  const components = {
    salary: (row.salaryDistrict || 0) + (row.extendedContract || 0),
    retirement: row.retirementDistrict || 0,
    medicare: row.medicareDistrict || 0,
    insurance: row.insuranceBenefits || 0,
    fica: row.fica || 0,
    salary_blsg: row.salaryStateGrant || 0,
    retirement_blsg: row.retirementStateGrant || 0,
    medicare_blsg: row.medicareStateGrant || 0,
  };

  let lineItems = {};

  lineItems = await getLineItemsAndSubtract(components, row, determineObjectCode, buildAccountCode, accountCodeSchema, districtCode, fiscalYear, version);

  if(Object.keys(lineItems).length>0){
    // Write to firebase
    const writePromises = Object.entries(lineItems).map(
              async ([dynamicKey, budgetData]) => {
                  const keyTotalRef = ref(
                      database,
                      `budget/${districtCode}/${fiscalYear}/expense/${dynamicKey}/budget/${version}`
                  );
                  await update(keyTotalRef, {...budgetData, editable: false});
              }
          );
      
    await Promise.all(writePromises);
  }
}

export const updateBenefitCheckbox = async (uuid, key, value, updateRole, setRows, benefitRates, roleType) => {

  let updatedRow = null;
  setRows((prevRows) => {
    const updatedRows = [...prevRows];
    const rowIndex = updatedRows.findIndex((row) => row.uuid === uuid);

    if (rowIndex !== -1) {
      const row = updatedRows[rowIndex];
    
      const updatedBenefits = { ...row.benefits, [key]: value!==0 ? benefitRates[key] : 0 };

      updatedRows[rowIndex] = { 
        ...row, 
        benefits: updatedBenefits,
        hasChanged: true,
      };
    }

    updatedRow = updatedRows[rowIndex];

    return updatedRows;
  });

  
  setTimeout(async () => {
    if (updatedRow) {
      if (roleType === 'certified'){
        updateRole(uuid, updatedRow.fullFTEsalary, updatedRow.fullFTEsalary_district, setRows, benefitRates);
      } else {
        updateRole(uuid, updatedRow.hourlyRate, setRows, benefitRates);
      }
    }
  }, 0);
};

export default createNewRole;