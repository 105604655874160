import React from 'react';
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, annotationPlugin);

const LineChart = ({ datasets, group_labels, value_labels, yaxisLabel, yMin, yMax }) => {
    // Get all unique years from all datasets
    const years = [...new Set(datasets.flatMap(data => data.map(item => item.YEAR)))].sort();

    const chartData = {
      labels: years,
      datasets: datasets.map((data, index) => ({
        label: `${group_labels[index]}`,
        data: years.map(year => {
            const found = data.find(item => item.YEAR === year);
            return found 
              ? value_labels.reduce((sum, label) => sum + (found[label] || 0), 0)
              : null;
          }),
        borderColor: index === 0 ? "blue" : index === 1 ? "green" : "red",
        backgroundColor: index === 0 ? "rgba(0, 0, 255, 0.2)" : index === 1 ? "rgba(0, 255, 0, 0.2)" : "rgba(255, 0, 0, 0.2)",
        tension: 0.3,
      })),
    };
  
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: { 
                title: { 
                    display: true, 
                    text: "Year", 
                    font: { size: 16 }
                },
                ticks: {
                    font: { size: 18 }
                }
            },
            y: { 
                min: yMin,
                max: yMax,
                title: { 
                    display: true, 
                    text: `${yaxisLabel}`, 
                    font: { size: 16 }
                },
                ticks: {
                    font: { size: 18 }
                },
                beginAtZero: true 
            }
        },
        plugins: {
            tooltip: {
                enabled: true
            },
            legend: {
                display: true,
                labels: {
                    font: { size: 14 }
                }
            },
            datalabels: {
                display: false
            }
        }
    };
  
    return <Line data={chartData} options={options} />;
};

export default LineChart; 