import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { database } from './firebaseConfig';
import { ref, get } from "firebase/database";
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import BudgetDash from './components/BudgetDash';
import FacultyDash from './components/FacultyDash';
import StudentsDash from './components/StudentsDash';
import AcademicsDash from './components/AcademicsDash';
import RevenueDash from './components/RevenueDash';
import DebtDash from './components/DebtDash';
import BudgetBuilder from './components/Build/Budget/BudgetBuilder';
import SalaryScheduleCertified from './components/Staff/SalaryScheduleCertified';
import SalaryScheduleClassified from './components/Staff/SalaryScheduleClassified';
import StaffManagement from './components/Staff/StaffManagementTable';
import RevenueCalc from './components/Revenue/RevenueInputs';
import ChatBot from './components/ChatBot';
import Header from './components/Header';
import PeerSelection from './components/PeerSelection';
import AuthWrapper from './components/AuthWrapper';
import AuthenticationCard from './components/AuthenticationCard';
import LoadingPage from './components/LoadingPage';
import './App.css';
import MonthlyReport from './components/Reporting/MonthlyReport';
import ReviewModule from './components/Reporting/Review';
import {sumFieldByGroup} from './utils';
import YearVersionSelect from './components/Utils/YearVersionSelect';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useAppStore from './stores/appStore';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data considered fresh for 5 minutes
      cacheTime: 1000 * 60 * 30, // Cache data for 30 minutes
    },
  },
});

function App() {
    // State declarations
    const [isFirstLogin, setIsFirstLogin] = useState(true);
    const [uniqueDistrictNames, setUniqueDistrictNames] = useState([]);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [userPermissions, setUserPermissions] = useState(false);
    const [userFound, setUserFound] = useState(false);
    
    // Get store actions
    const { 
        selectedDistrict, 
        selectedPeerGroup, 
        boardView, 
        setSelectedDistrict, 
        setSelectedPeerGroup, 
        setBoardView } = useAppStore();
    
    const [appState, setAppState] = useState({
        isLoading: true,
        error: null,
        data: {
            activeComponent: 'Overview',
            selectedDistrictInfo: {},
            peerDistrictInfo: {},
            peerGroupOptions: [],
            peerGroupData: {},
            districtMap: {},
            dashboardData: {},
            drilldownData: {},
            deseCodeMap: {}
        }
    });
    const [version, setVersion] = useState('0');
    const [fiscalYear, setFiscalYear] = useState('2026');
    const [availableFiscalYears, setAvailableFiscalYears] = useState([]);
    const [availableVersions, setAvailableVersions] = useState([]);
    const [sidebarMinimized, setSidebarMinimized] = useState(false);

    const toggleSidebar = () => {
        setSidebarMinimized((prev) => !prev);
    };

    // Disable track wheel changing number input fields
    useEffect(() => {
        const handleWheel = (event) => {
          if (document.activeElement.type === "number") {
            document.activeElement.blur();
          }
        };
    
        document.addEventListener("wheel", handleWheel, { passive: false });
    
        return () => {
          document.removeEventListener("wheel", handleWheel);
        };
      }, []);

    const refreshYearVersionSelections = async () => {
        const dataSnapshot = await get(ref(database, `/revenueMetadata/${appState.data.selectedDistrictInfo['DISTRICT_COUNTY_CODE']}`));
        const versions = [...new Set(Object.values(dataSnapshot.val()).flatMap(Object.keys))];
        const fyears = Object.keys(dataSnapshot.val()).filter(year => version in dataSnapshot.val()[year]);
        setAvailableFiscalYears(fyears.length>0 ? fyears : [fiscalYear]);
        setAvailableVersions(versions);
        //setAppState(prev => ({ ...prev, currentBudget}));
    }

    function expandArray(data, deseCodeMap) {
        const result = [];
        data.forEach((item) => {
          // Iterate over the keys of each object
          for (const key in item) {
            // Check if the key is not 'DISTRICT_COUNTY_CODE' or 'YEAR'
            if (key !== 'DISTRICT_COUNTY_CODE' && key !== 'YEAR' && key !== 'DISTRICT_NAME') {
              // Push a new object to the result array with the desired structure
              result.push({
                DISTRICT_NAME: item.DISTRICT_NAME,
                YEAR: item.YEAR,
                LABEL: deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)]? `${key.slice(0,4)} - ${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].DETAIL_DESCRIPTION}` : key,
                PARENT: deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)]? `${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].SUBCLASS} - ${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].SUBCLASS_DESCRIPTION}` : key,
                GPARENT: deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)]? `${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].CLASS} - ${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].CLASS_DESCRIPTION}` : key,
                GGPARENT: deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)]? `${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].GROUP}` : key,
                TOTAL_ALL_FUNDS: item[key].TOTAL_ALL_FUNDS,
                CAPITAL_PROJECTS: item[key].CAPITAL_PROJECTS,
                SPECIAL_REVENUE: item[key].SPECIAL_REVENUE,
                GENERAL_FUND: item[key].GENERAL_FUND,
                DEBT_SERVICE: item[key].DEBT_SERVICE,
                CERTIFICATED_SALARIES_6110: item[key].CERTIFICATED_SALARIES_6110,
                NONCERTIFICATED_SALARIES_6150: item[key].NONCERTIFICATED_SALARIES_6150,
                EMPLOYEE_BENEFITS_6200: item[key].EMPLOYEE_BENEFITS_6200,
                PURCHASED_SERVICES_6300: item[key].PURCHASED_SERVICES_6300,
                SUPPLIES_6400: item[key].SUPPLIES_6400,
                CAPITAL_OUTLAY_6500: item[key].CAPITAL_OUTLAY_6500,
                OTHER_OBJECTS_6600: item[key].OTHER_OBJECTS_6600,
                AGGREGATE: true
              });
            }
          }
        });

        const parentSum = sumFieldByGroup(result, 
                                           ['DISTRICT_NAME', 'YEAR', 'PARENT'], 
                                           ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE',
                                            'CERTIFICATED_SALARIES_6110', 'NONCERTIFICATED_SALARIES_6150', 'EMPLOYEE_BENEFITS_6200',
                                            'PURCHASED_SERVICES_6300', 'SUPPLIES_6400', 'CAPITAL_OUTLAY_6500', 'OTHER_OBJECTS_6600'
                                           ]);
        
        let parent_result = parentSum.map(obj => ({
            ...obj, // Spread the original object
            LABEL: obj.PARENT,
            PARENT: obj.GPARENT
            }))

        const gparentSum = sumFieldByGroup(parent_result, 
            ['DISTRICT_NAME', 'YEAR', 'PARENT'], 
            ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE',
             'CERTIFICATED_SALARIES_6110', 'NONCERTIFICATED_SALARIES_6150', 'EMPLOYEE_BENEFITS_6200',
             'PURCHASED_SERVICES_6300', 'SUPPLIES_6400', 'CAPITAL_OUTLAY_6500', 'OTHER_OBJECTS_6600']);

        let gparent_result = gparentSum.map(obj => ({
        ...obj, // Spread the original object
        LABEL: obj.PARENT,
        PARENT: obj.GGPARENT
        }))

        const ggparentSum = sumFieldByGroup(gparent_result, 
            ['DISTRICT_NAME', 'YEAR', 'PARENT'], 
            ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE',
             'CERTIFICATED_SALARIES_6110', 'NONCERTIFICATED_SALARIES_6150', 'EMPLOYEE_BENEFITS_6200',
             'PURCHASED_SERVICES_6300', 'SUPPLIES_6400', 'CAPITAL_OUTLAY_6500', 'OTHER_OBJECTS_6600']);

        let ggparent_result = ggparentSum.map(obj => ({
        ...obj, // Spread the original object
        LABEL: obj.PARENT,
        PARENT: 'Self'
        }))
      
        return [result, parent_result, gparent_result, ggparent_result].flat();
      }
    

    // Helper function
    const getKeyByValue = (obj, value) => {
        return Object.keys(obj).find(key => obj[key] === value);
    };

    // Main data fetching function
    const initializeAppData = async (districtName = null) => {
        try {
            setAppState(prev => ({ ...prev, isLoading: true }));
            
            // Store the district we want to use
            const targetDistrict = districtName || selectedDistrict;

            // Fetch district map if we don't have it
            let districtMap = appState.data.districtMap;
            if (Object.keys(districtMap).length === 0) {
                const districtMapSnapshot = await get(ref(database, '/districtCodeMap'));
                if (!districtMapSnapshot.exists()) {
                    throw new Error('District map not found');
                }
                districtMap = districtMapSnapshot.val();
            }
            setUniqueDistrictNames([...new Set(Object.values(districtMap))])

            const deseCodeMapSnapshot = await get(ref(database, '/deseCodeMap'));
            let deseCodeMap = deseCodeMapSnapshot.val();


            // Fetch peer groups using district map
            const selectedDistrictCode = getKeyByValue(districtMap, targetDistrict);
            const peerGroupsSnapshot = await get(ref(database, `/peerDefinitions/${selectedDistrictCode}`));
            if (!peerGroupsSnapshot.exists()) {
                throw new Error('Peer groups not found');
            }

            const peerGroups = peerGroupsSnapshot.val();
            const processedPeerGroups = {};

            // Process peer groups
            for (const key in peerGroups) {
                const ids = peerGroups[key];
                const names = ids.map(id => districtMap[id] || "Unknown");
                processedPeerGroups[key] = {
                    names,
                    IDs: ids
                };
            }

            // Fetch dashboard data for selected district and peers
            const selectedPeerGroup = 'Default';
            const peerIDs = processedPeerGroups[selectedPeerGroup].IDs;
            const dashboardDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/mainDashSummary/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });

            const dashboardResults = await Promise.all(dashboardDataPromises);
            const dashboardData = Object.fromEntries(dashboardResults.filter(([_, data]) => data !== null));

            // Fetch drilldown data for selected district and peers
            const drilldownDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/expenseDrilldown/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });

            const drilldownResults = await Promise.all(drilldownDataPromises);
            const drilldownDataJSON = Object.fromEntries(drilldownResults.filter(([_, data]) => data !== null));

            const drilldownDataArray = Object.entries(drilldownDataJSON).flatMap(([key, values]) => 
                Object.entries(values).map(([YEAR, details]) => ({
                  DISTRICT_COUNTY_CODE: key,
                  YEAR,
                  ...details
                }))
              );

            for(let i = 0; i<drilldownDataArray.length; i++){
                drilldownDataArray[i]['DISTRICT_NAME'] = drilldownDataArray[i]['1111']['DISTRICT_NAME'];
            }

            const drilldownData = expandArray(drilldownDataArray, deseCodeMap);

            ////////////////////
            // Fetch drilldown data for selected district and peers

            const revPromise = get(ref(database, `/revenueMetadata/${getKeyByValue(districtMap, targetDistrict)}`))
            revPromise.then(snapshot => {
                const data = snapshot.val();
                if(!data) return;
                const versions = [...new Set(Object.values(data).flatMap(Object.keys))];
                console.log('!!versions: ', versions)
                setAvailableFiscalYears(Object.keys(data).filter(year => version in data[year]));
                setAvailableVersions(versions);
                setVersion(versions[0])
            })
            
            /////////////////////
            
            // Update all state at once
            setAppState(prev => ({
                isLoading: false,
                error: null,
                data: {
                    ...prev.data,
                    selectedDistrict: targetDistrict,
                    districtMap,
                    peerGroupData: processedPeerGroups,
                    peerGroupOptions: Object.keys(peerGroups),
                    selectedPeerGroup,
                    peerDistrictInfo: {
                        IDs: processedPeerGroups[selectedPeerGroup].IDs,
                        names: processedPeerGroups[selectedPeerGroup].names
                    },
                    selectedDistrictInfo: {
                        DISTRICT_NAME: targetDistrict,
                        DISTRICT_COUNTY_CODE: getKeyByValue(districtMap, targetDistrict),
                        peerGroupOptions: {
                            IDs: processedPeerGroups[selectedPeerGroup].IDs,
                            names: processedPeerGroups[selectedPeerGroup].names
                        },
                        selectedPeerGroup: selectedPeerGroup
                    },
                    dashboardData,
                    drilldownData,
                    deseCodeMap
                }
            }));

        } catch (error) {
            console.error('Error initializing app:', error);
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                error: error.message
            }));
        }
    };

    // Initial data load
    useEffect(() => {
        initializeAppData();
    }, []);

    const updateDistrict = async (newDistrict) => {
        try {
            // Save to localStorage
            // Only call `initializeAppData` if the district changes
            if (newDistrict !== appState.data.selectedDistrict) {
                await initializeAppData(newDistrict);
            }
            localStorage.setItem('selectedDistrict', newDistrict);
        } catch (error) {
            console.error('Error changing district:', error);
        }
    };

    // Handle state changes
    const handleDistrictChange = async (event) => {
        const newDistrict = event.target.value;
        await updateDistrict(newDistrict);
        setSelectedDistrict(newDistrict);
    };

    const handlePeerGroupChange = async (event) => {
        const newPeerGroup = event.target.value;
        setSelectedPeerGroup(newPeerGroup);
        
        // Update the state with the new peer group selection
        setAppState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selectedPeerGroup: newPeerGroup,
                peerDistrictInfo: {
                    IDs: prev.data.peerGroupData[newPeerGroup].IDs,
                    names: prev.data.peerGroupData[newPeerGroup].names
                },
                selectedDistrictInfo: {
                    DISTRICT_NAME: prev.data.selectedDistrictInfo.DISTRICT_NAME,
                    DISTRICT_COUNTY_CODE: prev.data.selectedDistrictInfo.DISTRICT_COUNTY_CODE,
                    peerGroupOptions: {
                        IDs: prev.data.peerGroupData[newPeerGroup].IDs,
                        names: prev.data.peerGroupData[newPeerGroup].names
                    },
                },
            },
            
        }));

        

        // Fetch new dashboard data for the selected peer group
        await fetchDashboardDataForPeerGroup(newPeerGroup);
        await fetchDrilldownDataForPeerGroup(newPeerGroup);
    };

    const handleLogin = async (email) => {
        if (!isFirstLogin) return;

        const userRef = ref(database, `/userPermissions/`);
        const userSnapshot = await get(userRef);

        const userData = userSnapshot.val();
        const userPermissions = Object.values(userData).find(user => user.email === email.toLowerCase());

        if (userPermissions) {
            setUserFound(true);
            setUserPermissions(userPermissions);

            const { permissions } = userPermissions;

            if (permissions.district === 'All') {
                setSuperAdmin(true);
            } else if (selectedDistrict !== permissions.district) {
                setSelectedDistrict(permissions.district);
                await initializeAppData(permissions.district);
            }
        }

        setIsFirstLogin(false);

        return;
    };
    
    const fetchDashboardDataForPeerGroup = async (peerGroup) => {
        try {
            setAppState(prev => ({ ...prev, isLoading: true }));
    
            // Get the peer group IDs from state
            const peerIDs = appState.data.peerGroupData[peerGroup].IDs;
            const selectedDistrictCode = getKeyByValue(appState.data.districtMap, selectedDistrict);
    
            // Fetch dashboard data for the selected district and peer group
            const dashboardDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/mainDashSummary/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });
    
            const dashboardResults = await Promise.all(dashboardDataPromises);
            const dashboardData = Object.fromEntries(dashboardResults.filter(([_, data]) => data !== null));
    
            // Update the state with the new dashboard data
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                data: {
                    ...prev.data,
                    dashboardData
                }
            }));
        } catch (error) {
            console.error('Error fetching dashboard data for peer group:', error);
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                error: error.message
            }));
        }
    };

    const fetchDrilldownDataForPeerGroup = async (peerGroup) => {
        try {
            setAppState(prev => ({ ...prev, isLoading: true }));
    
            // Get the peer group IDs from state
            const peerIDs = appState.data.peerGroupData[peerGroup].IDs;
            const selectedDistrictCode = getKeyByValue(appState.data.districtMap, selectedDistrict);
    
            // Fetch dashboard data for the selected district and peer group
            const drilldownDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/expenseDrilldown/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });

            const drilldownResults = await Promise.all(drilldownDataPromises);
            const drilldownDataJSON = Object.fromEntries(drilldownResults.filter(([_, data]) => data !== null));

            const drilldownDataArray = Object.entries(drilldownDataJSON).flatMap(([key, values]) => 
                Object.entries(values).map(([YEAR, details]) => ({
                  DISTRICT_COUNTY_CODE: key,
                  YEAR,
                  ...details
                }))
              );

            for(let i = 0; i<drilldownDataArray.length; i++){
                drilldownDataArray[i]['DISTRICT_NAME'] = drilldownDataArray[i]['1111']['DISTRICT_NAME'];
            }

            const deseCodeMapSnapshot = await get(ref(database, '/deseCodeMap'));
            let deseCodeMap = deseCodeMapSnapshot.val();

            const drilldownData = expandArray(drilldownDataArray, deseCodeMap);


    
            // Update the state with the new dashboard data
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                data: {
                    ...prev.data,
                    drilldownData,
                    deseCodeMap
                }
            }));
        } catch (error) {
            console.error('Error fetching dashboard data for peer group:', error);
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                error: error.message
            }));
        }
    };

    const handleBoardViewChange = (event) => {
        setBoardView(event.target.value === 'true');
    };

    const setActiveComponent = (component) => {
        setAppState(prev => ({
            ...prev,
            data: { ...prev.data, activeComponent: component }
        }));
    };

    const updatePeerGroupOptions = async (newOptions) => {
        // Update the state with the new peer group options
        setAppState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                peerGroupOptions: newOptions
            }
        }));

        // Re-fetch data after updating peer group options
        await initializeAppData(); // You can pass the current selected district if needed
    };

    // Render loading state or error
    if (appState.isLoading) {
        return <LoadingPage/>;
    }

    if (appState.error) {
        return <div className="app-error">Error: {appState.error}</div>;
    }

    // Render component based on active selection
    const renderComponent = () => {
        switch (appState.data.activeComponent) {
            case 'Overview':
                return <Dashboard selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                  boardView={boardView} 
                                  dashboardData={appState.data.dashboardData}/>;
            case 'Budget':
                return <BudgetDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                   boardView={boardView} 
                                   dashboardData={appState.data.dashboardData}
                                   drilldownData={appState.data.drilldownData}
                                   deseCodeMap = {appState.data.deseCodeMap}
                                   />;
            case 'Faculty':
                return <FacultyDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={boardView} 
                                    dashboardData={appState.data.dashboardData}/>;
            case 'Students':
                return <StudentsDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                     boardView={boardView} 
                                     dashboardData={appState.data.dashboardData}/>;
            case 'Academics':
                return <AcademicsDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                      boardView={boardView} 
                                      dashboardData={appState.data.dashboardData}/>;
            case 'Revenue':
                return <RevenueDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={boardView} 
                                    dashboardData={appState.data.dashboardData}/>;
            case 'Debt':
                return <DebtDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                 boardView={boardView} 
                                 dashboardData={appState.data.dashboardData}/>;
            case 'SalaryCertified':
                return  <SalaryScheduleCertified selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                                 boardView={boardView} 
                                                 dashboardData={appState.data.dashboardData}
                                                 version = {version}
                                                 fiscalYear = {fiscalYear}/>;
                        
            case 'SalaryClassified':
                return <SalaryScheduleClassified selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={boardView} 
                                    dashboardData={appState.data.dashboardData}
                                    version = {version}
                                    fiscalYear = {fiscalYear}/>;
            case 'Staff':
                return <StaffManagement selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={boardView} 
                                    dashboardData={appState.data.dashboardData}
                                    version = {version}
                                    fiscalYear = {fiscalYear}/>;
            case 'Monthly':
            return <MonthlyReport selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                boardView={boardView} 
                                dashboardData={appState.data.dashboardData}/>;
            case 'Review':
                return <ReviewModule selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={boardView} 
                                    dashboardData={appState.data.dashboardData}/>;
            case 'RevenueInput':
            return <RevenueCalc selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                version={version}
                                fiscalYear={fiscalYear}/>;
            case 'Peers':
                return <PeerSelection selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                      updatePeerGroupOptions={updatePeerGroupOptions}/>;
            case 'BuildBudget':
                return <BudgetBuilder selectedDistrictInfo={appState.data.selectedDistrictInfo}
                                      drilldownData={appState.data.drilldownData}
                                      deseCodeMap={appState.data.deseCodeMap}
                                      version = {version}
                                      fiscalYear = {fiscalYear}/>;
            default:
                return <Dashboard selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                  boardView={boardView} 
                                  dashboardData={appState.data.dashboardData}/>;
        }
    };

    // Main render
    return (
       <QueryClientProvider client={queryClient}>
          <Router>
            <Routes>
              {/* Public Route: Login */}
              <Route path="/login" element={<AuthenticationCard userFound={userFound} isFirstLogin={isFirstLogin}/>} />
              {/* Protected Routes */}
                  <Route
                  path="/"
                  element={
                      <AuthWrapper onLogin={handleLogin} userFound={userFound} isFirstLogin={isFirstLogin}>
                      {userFound ? (
                          <div className="app">
                          <Header
                              peerGroupOptions={appState.data.peerGroupOptions}
                              superAdmin={superAdmin}
                              uniqueDistrictNames={uniqueDistrictNames.sort((a, b) => a.localeCompare(b))}
                              handleDistrictChange={handleDistrictChange}
                              handlePeerGroupChange={handlePeerGroupChange}
                              handleBoardViewChange={handleBoardViewChange}
                          />
                          <Sidebar
                              setActiveComponent={setActiveComponent}
                              activeComponent={appState.data.activeComponent}
                              boardView={boardView}
                              userPermissions={userPermissions}
                              sidebarMinimized={sidebarMinimized}
                              toggleSidebar={toggleSidebar}
                          />
                          {/* if active component is one of the Build module components, show sub-header YearVersionSelect*/}
                          {<div className={`main-component ${sidebarMinimized ? 'expanded' : ''}`}>
                              {['SalaryCertified', 'SalaryClassified', 'Staff', 'BuildBudget', 'RevenueInput'].includes(appState.data.activeComponent) && 
                              (<YearVersionSelect selectedDistrictInfo={appState.data.selectedDistrictInfo}
                                                  availableFiscalYears={availableFiscalYears}
                                                  availableVersions={availableVersions}
                                                  fiscalYear={fiscalYear}
                                                  version={version}
                                                  setFiscalYear={setFiscalYear}
                                                  setVersion={setVersion}
                                                  refreshSelections={refreshYearVersionSelections}
                              />)
                              }
                              {renderComponent()}
                           </div>
                          }
                          {false && (
                              <ChatBot
                              selectedSchool={selectedDistrict}
                              drilldownData={appState.data.drilldownData}
                              />
                          )}
                          </div>
                      ) : (
                          <Navigate to="/login" replace />
                      )}
                      </AuthWrapper>
                      }>
                  </Route>
            </Routes>
          </Router>
       </QueryClientProvider>
    )
}

export default App;
